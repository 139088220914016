import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { ReloadOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, DatePicker, Tooltip, Divider, Badge, Row, Button, Col } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import ProjectBasedJobForm from './ProjectBasedJobForm';
import Detail from './Detail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml, replaceHeadingsWithDivs } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
// actions
import { getAllData, getData, removeData, assignData } from '../../redux/actions/ProjectBasedJobActions';
import { getProjectActive } from '../../redux/actions/ProjectActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/project-based-job',
        breadcrumbName: 'Danh sách công việc',
    },
]

class ProjectBasedJobList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
        isOpenDetail: false,
        current_id: null,
        isOpenFormAssign: false,
        loadingAssign: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));

        this.loadData();
        this.props.getProjectActive();
        if (query.id) {
            this.onDetail(query.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllData(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getData(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    onRemove() {
        this.props.removeData(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    handleCancel() {
        this.setState({ selectedRowKeys: [] })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ isOpenFormAssign: false, loadingAssign: false, selectedRowKeys: [] })
    }

    onAssign(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loadingAssign: true })
                values.start_date = values.start_date ? values.start_date.format("YYYY-MM-DD") : null;
                values.deadline = values.deadline ? values.deadline.format("YYYY-MM-DD") : null;
                this.props.assignData(this.state.selectedRowKeys, values);
                this.loadData()
                this.onCancel()
            }
        });
    }

    render() {
        var { data_list, pagination, users, authUser, project_active_list } = this.props;
        var {
            isLoading,
            selectedRowKeys,
            isOpenForm,
            currentData,
            isOpenDetail,
            current_id,
            isOpenFormAssign,
            loadingAssign
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'STT',
                dataIndex: 'sort_order',
                key: 'sort_order',
                width: '50px',
                sorter: true
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: 'Tên công việc',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (text, record) => {
                    if (record.name) {
                        if (checkPermission('project_based_job', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {record.name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>{record.name}</>
                            )
                        }
                    } else {
                        return <Tag></Tag>
                    }
                }
            },
            {
                title: 'Dự án',
                dataIndex: 'project_name',
                key: 'project_name',
                width: '180px',
            },
            {
                title: 'Mô tả công việc',
                dataIndex: 'description',
                key: 'description',
                width: '200px',
                render: (text, record) => {
                    return (
                        <Tooltip placement="right" title={ReactHtmlParser(replaceHeadingsWithDivs(text))}>
                            <div style={{ wordBreak: "break-all" }}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Tình trạng',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    let status = '';
                    if (record.status == 1) {
                        status = <Tag color='#205072'>Đã hoàn thành</Tag>
                    } else if (record.status == 0) {
                        status = <Tag color='#d46c4e'>Chưa hoàn thành</Tag>
                    }
                    return (
                        <div>{status}</div>
                    )
                }
            },
            {
                title: 'Ngày bắt đầu',
                dataIndex: 'start_date',
                key: 'start_date',
                sorter: true,
                width: '130px',
                render: (text, record) => {
                    if (record.start_date) {
                        return (
                            <>
                                {moment(record.start_date).format('DD/MM/YYYY')}
                            </>
                        )
                    }
                }
            },
            {
                title: 'Deadline',
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    if (record.deadline) {
                        var diffDate = record.deadline ? moment(record.deadline).diff(moment().startOf('day'), 'days') : 0;
                        if (record.status == 1) { // Đã hoàn thành
                            return (
                                <div>
                                    {isValidDate(record.deadline, false)}
                                </div>
                            )
                        } else {
                            var show_text;
                            var badge_color;
                            if (diffDate) {
                                if (diffDate > 0) {
                                    show_text = `Còn ${diffDate} ngày`;
                                    badge_color = '#52c41a';
                                } else {
                                    show_text = `Quá ${-1 * diffDate} ngày`;
                                    badge_color = 'red';
                                }
                            } else {
                                show_text = "Hết hạn hôm nay";
                                badge_color = '#f50';
                            }
                            return (
                                <Badge count={show_text} size='small' className='custom-badge' color={badge_color}>
                                    <span>{isValidDate(text, false)}</span>
                                </Badge>
                            )
                        }
                    }
                }
            },
            {
                title: 'Hoàn thành',
                dataIndex: 'finish_day',
                key: 'finish_day',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    if (record.finish_day) {
                        var diffDate = record.finish_day ? moment(record.deadline).diff(moment(record.finish_day), 'days') : 0;
                        var show_text;
                        var badge_color;
                        if (diffDate) {
                            if (diffDate >= 0) {
                                show_text = `Trước hạn ${diffDate} ngày`;
                                badge_color = '#52c41a';
                            } else {
                                show_text = `Quá ${-1 * diffDate} ngày`;
                                badge_color = 'red';
                            }
                        } else {
                            show_text = "Đúng hạn";
                            badge_color = '#52c41a';
                        }
                        return (
                            <Badge count={show_text} size='small' className='custom-badge' color={badge_color}>
                                <span>{isValidDate(text, false)}</span>
                            </Badge>
                        )
                    }
                }
            },
            {
                title: 'Người thực hiện',
                dataIndex: 'user',
                key: 'user',
                width: '180px',
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>{record.created_user}</>
                    )
                }
            }
        ];
        var filters = [
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];

        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users || []}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người tạo"
                    showSearch={false}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.created_user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />,
                <BaseSelect
                    options={users || []}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người thực hiện"
                    showSearch={false}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.user_id) || null}
                    onChange={(value) => this.onChangeFilter("user_id", value)}
                />,
                <BaseSelect
                    options={[
                        { value: 1, label: 'Đã hoàn thành' },
                        { value: 0, label: 'Chưa hoàn thành' },
                    ]}
                    onChange={(value) => this.onChangeFilter("status", value)}
                    placeholder="Tình trạng"
                    defaultValue={parseInt(query.status) || null}
                    style={{ width: '150px' }}
                />
            );
        }

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách công việc" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('project_based_job', 'create')}
                        isShowDeleteButton={checkPermission('project_based_job', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.type}
                        activeFilter={
                            query.start_date ||
                            query.end_date ||
                            query.status ||
                            query.created_user_id ||
                            query.user_id ||
                            query.project_id
                        }
                        filters={filters}
                    >
                        {
                            checkPermission('project_based_job', 'assign') ? (
                                <>
                                    <Button className="table-button" type='primary' icon={<UserSwitchOutlined />} disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ isOpenFormAssign: true })}>Giao việc</Button>
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        <BaseSelect
                            options={project_active_list || []}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="Dự án"
                            showSearch={false}
                            style={{ width: '150px' }}
                            defaultValue={parseInt(query.project_id) || null}
                            onChange={(value) => this.onChangeFilter("project_id", value)}
                        />
                        <Divider type='vertical' />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>

                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </Row>
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    dataSource={data_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProjectBasedJobForm
                    visible={isOpenForm}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <Detail
                    visible={isOpenDetail}
                    currentData={currentData}
                    reloadList={() => this.loadData()}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />

                <Modal
                    title="Giao việc cho nhân viên"
                    visible={isOpenFormAssign}
                    header={false}
                    onOk={(e) => this.onAssign(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={loadingAssign}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onAssign(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nhân viên thực hiện">
                                    {getFieldDecorator("user_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={users || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Chọn nhân viên"
                                            showSearch={true}
                                            //mode="multiple"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày bắt đầu">
                                    {getFieldDecorator("start_date", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Deadline">
                                    {getFieldDecorator("deadline", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        data_list: state.ProjectBasedJobReducer.data_list,
        pagination: state.ProjectBasedJobReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser,
        project_active_list: state.ProjectReducer.project_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllData: (filter) => dispatch(getAllData(filter)),
        getData: (id) => dispatch(getData(id)),
        removeData: (ids) => dispatch(removeData(ids)),
        getProjectActive: (filter) => dispatch(getProjectActive(filter)),
        assignData: (ids, data) => dispatch(assignData(ids, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProjectBasedJobList' })(ProjectBasedJobList));

import React from 'react';
import api from '../../utils/api';
import { getBase64 } from '../../utils/helpers';
import img_loading from '../../assets/img/Ajux_loader.gif';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import htmlEditButton from "quill-html-edit-button";
Quill.register({
    'modules/imageResize': ImageResize,
    "modules/htmlEditButton": htmlEditButton
});

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store_name: null
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps?.store_name) {
            this.setState({ store_name: nextProps?.store_name })
        }
    }

    async imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async function () {
            const file = input.files[0];
            const fileBase64 = await getBase64(file);
            const file_name = file.name;
            const fileData = {
                //store_name: 'teebyhuman.com', // Hiện tại chưa truyền được tham số 'this.state.store_name' từ ngoài vào
                file_name: file_name,
                fileBase64: fileBase64
            }
            const range = this.quill.getSelection(true);
            this.quill.insertEmbed(range.index, 'image', img_loading);
            this.quill.setSelection(range.index + 1);
            await api.post('/upload', { files: [fileData] }).then(res => {
                if (res) {
                    const [image] = res.data.data
                    this.quill.deleteText(range.index, 1);
                    this.quill.insertEmbed(range.index, 'image', image);
                }
                //console.log(res)
            })
        }.bind(this);
    }

    render() {
        return (
            <ReactQuill
                {...this.props}
                modules={{
                    toolbar: {
                        container: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [{ 'size': ['small', false, 'large', 'huge'] }],
                            ['bold', 'italic', 'underline'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'align': [] }],
                            ['link', 'image', 'video'],
                            ['clean'],
                            [{ 'color': [] }, { 'background': [] }],
                        ],
                        handlers: {
                            image: this.imageHandler
                        }
                    },
                    clipboard: {
                        // toggle to add extra line breaks when pasting HTML:
                        matchVisual: false
                    },
                    imageResize: {
                        parchment: Quill.import('parchment'),
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    },
                    htmlEditButton: {}
                }}
            />
        );
    }
}

export default Editor;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col, Alert } from 'antd';
import { isMobile } from 'react-device-detect';
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
// actions
import { createBlacklist, updateBlacklist } from '../../redux/actions/BlacklistActions';

class BlacklistForm extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentData) {
                    await this.props.updateBlacklist(this.props.currentData.id, values);
                    this.onCancel()
                } else {
                    await this.props.createBlacklist(values);
                    this.props.reloadData()
                    this.props.form.resetFields();
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        //this.props.reloadData()
    }

    render() {
        const { } = this.state;
        var { visible, currentData } = this.props;
        //console.log(currentData)
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={[
                    <Button key="back" onClick={() => this.onCancel()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentData ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Alert
                                    message="Mẫu"
                                    description="Email: abcde@gmail.com, Phone: 5169877536, Address: 2177 Wyandotte Ave, Zipcode: 92290, States: NY, City: Carmichaels"
                                    type="info"
                                />
                                <br />
                                <Form.Item label="Value">
                                    {getFieldDecorator('value', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.value : ""
                                    })(<Input allowClear />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Type">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.type : 'EMAIL'
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Email", value: 'EMAIL' },
                                                { label: "Phone", value: 'PHONE' },
                                                { label: "Address", value: 'ADDRESS' },
                                                { label: "Zipcode", value: 'ZIPCODE' },
                                                { label: "States", value: 'STATES' },
                                                { label: "City", value: 'CITY' }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={2} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createBlacklist: (data) => dispatch(createBlacklist(data)),
        updateBlacklist: (id, data) => dispatch(updateBlacklist(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'BlacklistForm' })(BlacklistForm));

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Modal, Card } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { deleteObject } from '../../redux/actions/HandleS3ObjectsActions';

const { confirm } = Modal;

class DeleteObjectFrom extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount() {

    }

    handleDelete(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    var data = {
                        list_links: values.list_links
                    };
                    confirm({
                        title: 'Xác nhận',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Chắc chắn xóa các đối tượng này?',
                        okText: 'OK',
                        cancelText: 'Huỷ',
                        onOk: async () => {
                            await this.props.deleteObject(data).then((res) => {
                                console.log(res);
                                this.setState({ isLoading: false });
                            }).catch((err) => {
                                this.setState({ isLoading: false });
                            });
                        }
                    })
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    render() {
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Card className="mt-3">
                <Form layout='vertical'>
                    <Form.Item label="Nhập url đối tượng">
                        {getFieldDecorator('list_links', {
                            rules: [
                                {
                                    required: true,
                                    message: "Vui lòng nhập!",
                                },
                            ],
                            initialValue: null
                        })(
                            <Input.TextArea rows={20} placeholder=" Mỗi url trên 1 dòng theo mẫu sau:
                                https://img.vibeclassic.com/uploads/1.jpg
                                https://img.vibeclassic.com/uploads/2.jpg
                                https://img.vibeclassic.com/uploads/3.jpg" />
                        )}
                    </Form.Item>
                    <Button
                        loading={isLoading}
                        key="submit"
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={(e) => this.handleDelete(e)}
                    >
                        Xóa
                    </Button>
                </Form>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteObject: (data) => dispatch(deleteObject(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DeleteObjectFrom" })(DeleteObjectFrom));

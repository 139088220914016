import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Drawer, Descriptions, Divider, Space, Switch, Tag, Input, Comment, Row, Col, List } from 'antd'
import { EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { _newLine, downloadFromLink, isValidDate } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';
// actions
import { getData, updateProcess, updateStatus } from '../../redux/actions/WorkflowManagementActions';
import { createComment, removeComment } from '../../redux/actions/CommentsAction';

const { TextArea } = Input;
const { confirm } = Modal;
class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenFormUpdate: false,
            data: [],
            select: false,
            currentFiles: [],
            comments: [],
            submitting: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentFiles: nextProps?.currentData?.files_complete,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(['process', 'comment'], async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    const { currentFiles, data } = this.state;
                    values.files_complete = [...currentFiles, ...data];
                    //console.log(values)
                    await this.props.updateProcess(this.props.currentData.id, values);
                    this.cancelFormUpdate()
                }
                this.setState({ isloading: false })
            }
        });
    }

    onChange = (data) => {
        this.setState({
            ...this.state,
            data: data,
        });
    }

    handleCancel() {
        this.setState({ isOpenFormUpdate: false })
        this.props.onCancel()
        //this.props.reloadData()
    }

    cancelFormUpdate() {
        this.setState({
            isOpenFormUpdate: false,
            data: [],
            select: !this.state.select
        })
        this.props.reloadData()
        this.props.form.resetFields();
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    deleteCurrentFiles = (name) => {
        const items = [...this.state.currentFiles];
        this.setState({ currentFiles: items.filter(item => item.name !== name) });
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(['content'], async (err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                const { currentData } = this.props;
                await this.props.createComment({ type: 'work', content: values.content, parent_id: currentData.id }).then(res => {
                    this.reloadData();
                })
            }
        });
    }
    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData();
            }),
            onCancel() { },
        })
    }

    reloadData() {
        this.props.reloadData();
        this.setState({ isLoading: false, submitting: false })
        this.props.form.resetFields();
    }

    async onUpdateStatus(value) {
        await this.props.updateStatus(this.props.currentData?.id, { status: value })
        this.props.reloadList();
    }

    render() {
        const {
            isloading,
            isOpenFormUpdate,
            currentFiles,
            submitting
        } = this.state;
        var { visible, currentData, authUser } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log(currentData)
        let type = '';
        let priority = '';
        let approved_status = '';
        let vote = '';
        const currentFilesList = [];
        const comments = [];
        if (currentData) {
            if (currentData?.type == 'periodic') {
                type = <Tag color='#52c41a'>Định kỳ</Tag>
            } else if (currentData?.type == 'incurred') {
                type = <Tag color='#ffc069'>Phát sinh</Tag>
            }
            if (currentData?.priority == 0) {
                priority = <Tag color='#f00'>Làm gấp</Tag>
            } else if (currentData?.priority == 1) {
                priority = <Tag color='#ff7875'>Cao</Tag>
            } else if (currentData?.priority == 2) {
                priority = <Tag color='#fa8c16'>Trung bình</Tag>
            } else if (currentData?.priority == 3) {
                priority = <Tag color='#fadb14'>Thấp</Tag>
            }
            var diffDate = currentData?.deadline ? moment(currentData?.deadline).diff(moment().startOf('day'), 'days') : 0;
            var diffDateFinishDay = currentData?.finish_day ? moment(currentData?.deadline).diff(moment(currentData?.finish_day), 'days') : 0;

            if (currentData?.approved_status == 1) {
                approved_status = <Tag color='#52c41a'>Đã duyệt</Tag>
            } else if (currentData?.approved_status == 0) {
                approved_status = <Tag color='#ffc069'>Chưa duyệt</Tag>
            }

            if (currentData?.vote == 'good') {
                vote = <Tag color='#52c41a'>Tốt</Tag>
            } else if (currentData?.vote == 'normal') {
                vote = <Tag color='#fadb14'>Đạt yêu cầu</Tag>
            } else if (currentData?.vote == 'not_reached') {
                vote = <Tag color='#f00'>Chưa đạt</Tag>
            }

            currentFiles.map(item => {
                currentFilesList.push(
                    <div>
                        <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteCurrentFiles(item?.name)}
                        />
                    </div >
                )
            })

            currentData.comments.map(item => {
                var disabledDeleteComment = true;
                if (authUser.role_code == 'ADMIN') {
                    disabledDeleteComment = false
                } else if (authUser.id == item.sender) {
                    disabledDeleteComment = false
                }
                comments.push(
                    {
                        actions: [<Button disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <UserOutlined style={{ fontSize: '20px'}} classID='primary-color' />,
                        content: <p>{ReactHtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        var allowEdit = false;
        if (checkPermission('workflow_management', 'update_process')) {
            // Nếu đã 'Duyệt' thì chỉ admin mới có quyền sửa
            if (authUser.role_code == 'ADMIN') {
                allowEdit = true;
            }
            if (authUser.role_code != 'ADMIN' && currentData?.approved_status != 1) {
                allowEdit = true;
            }
        }

        return (
            <Drawer
                visible={visible}
                title="Chi tiết công việc"
                onClose={() => this.handleCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                extra={
                    <Space>
                        {
                            allowEdit ?
                                <Button onClick={() => this.setState({ isOpenFormUpdate: true })} type="primary" icon={<EditOutlined />}>Cập nhật tiến độ</Button>
                                : null
                        }
                    </Space>
                }
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Button key="back" onClick={() => this.props.onCancel()}>
                            Huỷ
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <div>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Loại công việc">
                                        {type}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Độ ưu tiên">
                                        {priority}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Tình trạng">
                                        <Switch
                                            disabled={currentData?.approved_status == 1}
                                            defaultChecked={currentData?.status}
                                            checkedChildren="Đã hoàn thành"
                                            unCheckedChildren="Chưa hoàn thành"
                                            onChange={(value) => this.onUpdateStatus(value)}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày bắt đầu">
                                        {isValidDate(currentData?.start_date, false)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            {
                                currentData?.deadline ? (
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Deadline">
                                                {
                                                    currentData?.status == 1 ? (
                                                        <>
                                                            {isValidDate(currentData?.deadline, false)}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {isValidDate(currentData?.deadline, false)} &nbsp;
                                                            {
                                                                diffDate ? (
                                                                    <>
                                                                        {
                                                                            diffDate > 0 ? <Tag color="#52c41a">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDate} ngày</Tag>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <Tag color="#f50">Hết hạn hôm nay</Tag>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Người thực hiện">
                                        <Tag>{currentData?.user}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Tiến độ">
                                        {currentData?.process}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Phản hồi">
                                        {ReactHtmlParser(currentData?.comment)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày hoàn thành">
                                        {
                                            currentData?.type == 'incurred' ? (
                                                <>
                                                    {
                                                        currentData?.finish_day ? (
                                                            <>
                                                                {isValidDate(currentData?.finish_day, false)}
                                                                <Divider type='vertical' />
                                                                {
                                                                    diffDateFinishDay ? (
                                                                        <span>
                                                                            {
                                                                                diffDateFinishDay >= 0 ? <Tag color="#52c41a">Trước hạn {diffDateFinishDay} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDateFinishDay} ngày</Tag>
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <Tag color="#52c41a">Đúng hạn</Tag>
                                                                    )
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                </>
                                            ) : null
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Trạng thái">
                                        {approved_status}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Đánh giá">
                                        {vote}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày tạo">
                                        {isValidDate(currentData?.created_at)} bởi: {currentData?.created_user}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Diễn giải">
                                        {currentData?.approved_des}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                        </Row>
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Mô tả công việc</span>
                            }
                            content={ReactHtmlParser(currentData?.description)}
                        />
                        {
                            currentData?.files_complete.length ? (
                                <>
                                    <Divider className="no-margin" />
                                    <Descriptions size="small">
                                        <Descriptions.Item label="File nội dung">
                                            {
                                                currentData?.files_complete.map(item => {
                                                    return (
                                                        <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                                                    )
                                                })
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </>
                            ) : null
                        }
                        <Divider />
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="File đính kèm">
                                        {
                                            currentData?.files.map(item => {
                                                return (
                                                    <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider />
                            </Col>
                        </Row>
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Ghi chú</span>
                            }
                            content={ReactHtmlParser(currentData?.note)}
                        />
                        <Divider />
                        <h6 className='mt-4'>THẢO LUẬN</h6>
                        {comments.length > 0 && <CommentList comments={comments} />}
                        <Comment
                            avatar={<UserOutlined style={{ fontSize: '20px' }} className='primary-color' />}
                            content={
                                <>
                                    <Form.Item>
                                        {getFieldDecorator('content', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: ""
                                        })(<TextArea rows={4} />)}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" loading={submitting} onClick={(e) => this.submitComment(e)} type="primary">
                                            Đăng
                                        </Button>
                                    </Form.Item>
                                </>
                            }
                        />
                    </div>
                ) : null}

                <Modal
                    width={isMobile ? '100%' : '40%'}
                    title="Cập nhật tiến độ"
                    visible={isOpenFormUpdate}
                    onOk={(e) => this.submit(e)}
                    confirmLoading={isloading}
                    onCancel={() => this.cancelFormUpdate()}
                    maskClosable={false}
                >
                    <Form layout="vertical">
                        <Form.Item label="Tiến độ công việc">
                            {getFieldDecorator('process', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: currentData ? currentData?.process : null
                            })(
                                <Input placeholder="1/4, 1/2 .... 100%" />
                            )}
                        </Form.Item>
                        <Form.Item label="File nội dung">
                            {getFieldDecorator('attach_file', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ''
                            })(
                                <DraggerFile onChangeData={this.onChange} select={this.state.select} />
                            )}
                            {currentFilesList}
                        </Form.Item>
                        <Form.Item label="Phản hồi">
                            {getFieldDecorator('comment', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: currentData ? _newLine(currentData?.comment) : null
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Drawer>
        )
    }
}
function mapStateToProps(state) {
    //console.log(state)
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateProcess: (id, data) => dispatch(updateProcess(id, data)),
        getData: (id) => dispatch(getData(id)),
        updateStatus: (id, data) => dispatch(updateStatus(id, data)),
        createComment: (data) => dispatch(createComment(data)),
        removeComment: (ids) => dispatch(removeComment(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));

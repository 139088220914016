import React, { Component } from 'react';
import { Badge } from 'antd';
import { connect } from 'react-redux';

class ShowMenuText extends Component {
    render() {
        var { mode, notify_trending } = this.props;
        if (mode === 'trending') {
            return (
                <Badge count={notify_trending ? notify_trending.count_trendig : 0} overflowCount={999} size='small' offset={[-2, -4]} style={{ backgroundColor: 'red' }}>
                    Trending
                </Badge>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        notify_trending: state.TrendingReducer.notify,
    }
}

export default connect(mapStateToProps)(ShowMenuText);
import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Modal, Image, DatePicker, Button, Card, Row, Col, Empty, Pagination } from 'antd';
import { connect } from 'react-redux';
import IdeaAddForm from './IdeaAddForm';
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import IdeaDetail from './IdeaDetail';
// actions
import { getListAll, removeIdea, updateIdea, getOneIdea } from '../../redux/actions/IdeaAction';
import { getAllNiche } from '../../redux/actions/NicheActions';
import { getAllProductType } from '../../redux/actions/ProductTypeActions';
import { NotificationManager } from 'react-notifications';

const { Meta } = Card;
const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/ideas',
        breadcrumbName: 'Danh sách ideas',
    }
]

class IdeaList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenAddForm: false,
            isOpenDetail: false,
            current_id: null,
            currentData: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        });
        if (query.id) {
            this.onDetail(query.id);
        }
        this.props.getAllNiche();
        this.props.getAllProductType();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAll(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onChangeTable(page, pageSize) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            page: page,
            per_page: pageSize,
        }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    toggleOpenAddForm(isOpenAddForm) {
        this.setState({
            isOpenAddForm: isOpenAddForm,
        });
    }

    onDeleteOne(id) {
        if (checkPermission('file_png', 'remove')) {
            confirm({
                title: 'Cảnh báo',
                content: 'Bạn chắc chắn muốn xoá bản ghi này?',
                okText: 'OK',
                cancelText: 'Huỷ',
                onOk: () => this.props.removeIdea([id]).then(() => {
                    setTimeout(() => {
                        Modal.destroyAll();
                    }, 800);
                    this.reloadData()
                }),
                onCancel() { },
            })
        } else {
            NotificationManager.error("Bạn không được cấp quyền thực hiện thao tác này!");
        }
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getOneIdea(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    render() {
        var {
            isLoading,
            isOpenAddForm,
            isOpenDetail,
            currentData,
            current_id
        } = this.state;
        const { ideas, pagination, niches, product_type, users } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        var filters = [
            <BaseSelect
                options={niches}
                optionValue="id"
                optionLabel="name"
                placeholder="Chọn niche"
                showSearch={false}
                style={{ width: '150px' }}
                defaultValue={parseInt(query.niche_id) || null}
                onChange={(value) => this.onChangeFilter("niche_id", value)}
            />,
            <BaseSelect
                options={product_type}
                optionValue="id"
                optionLabel="name"
                placeholder="Chọn loại sản phẩm"
                showSearch={false}
                style={{ width: '150px' }}
                defaultValue={parseInt(query.product_type_id) || null}
                onChange={(value) => this.onChangeFilter("product_type_id", value)}
            />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : ''} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : ''} />,
            <BaseSelect
                options={users}
                optionValue="user_id"
                optionLabel="full_name"
                placeholder="Người tạo"
                showSearch={false}
                style={{ width: '150px' }}
                defaultValue={parseInt(query.created_user_id) || null}
                onChange={(value) => this.onChangeFilter("created_user_id", value)}
            />
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách ideas" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('idea', 'create')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenAddForm(true)}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={
                            parseInt(query.niche_id) ||
                            parseInt(query.product_type_id) ||
                            parseInt(query.created_user_id)
                        }
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                </div>

                <Card>
                    {
                        ideas.length ? (
                            <Row gutter={20} style={{ height: 'max-content' }} key={1}>
                                <Image.PreviewGroup>
                                    {
                                        ideas.map(item => {
                                            return (
                                                <Col lg={4} md={8} sm={12} xs={24} key={item.id} className="mb-4" >
                                                    <Card
                                                        loading={isLoading}
                                                        hoverable
                                                        style={{ width: '100%', overflow: 'hidden' }}
                                                        size="small"
                                                        className='idea-list-card'
                                                        bodyStyle={{ padding: 0 }}
                                                        actions={[
                                                            <EditOutlined key="Edit" onClick={() => this.onDetail(item.id)} />,
                                                            <DeleteOutlined key="Delete" onClick={() => this.onDeleteOne(item.id)} />,
                                                        ]}
                                                    >
                                                        <Image
                                                            width="100%"
                                                            src={item.images_demo}
                                                        />
                                                        <Meta className='mt-2 p-1' description={item.name} />
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Image.PreviewGroup>
                            </Row>
                        ) : (
                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center' }} key={1}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Row>
                        )
                    }
                    <div style={{ textAlign: 'right', }}>
                        <Pagination
                            pageSize={pagination.perPage}
                            defaultPageSize={pagination.perPage}
                            defaultCurrent={pagination.currentPage}
                            total={pagination.total}
                            pageSizeOptions={['10', '20', '30', '40', '50', '100']}
                            showSizeChanger={true}
                            showTotal={total => `Tổng ${total} bản ghi`}
                            onChange={(page, pageSize) => this.onChangeTable(page, pageSize)}
                        />
                    </div>
                </Card>
                <IdeaAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.toggleOpenAddForm(false)}
                    reloadData={() => this.reloadData()}
                />

                <IdeaDetail
                    visible={isOpenDetail}
                    idea={currentData}
                    reloadData={() => { this.onDetail(current_id); this.reloadData() }}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        ideas: state.IdeaReducer.ideas,
        pagination: state.IdeaReducer.pagination,
        authUser: state.auth.authUser,
        niches: state.NicheReducer.niches,
        product_type: state.ProductType.product_type,
        users: state.config.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAll: filter => dispatch(getListAll(filter)),
        removeIdea: ids => dispatch(removeIdea(ids)),
        updateIdea: (id, data) => dispatch(updateIdea(id, data)),
        getAllNiche: (filter) => dispatch(getAllNiche(filter)),
        getAllProductType: (filter) => dispatch(getAllProductType(filter)),
        getOneIdea: (id) => dispatch(getOneIdea(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IdeaList);

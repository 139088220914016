import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // Bản build mặc định của ckeditor

//https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/react.html
import Editor from 'ckeditor5-custom-build/build/ckeditor'; // Sử dụng bản tự build online https://ckeditor.com/ckeditor-5/online-builder/

import UploadAdapter from './UploadAdapter';

class CKEditorComponent extends Component {
    render() {
        const { initialHeight } = this.props;
        const editorConfiguration = {
            toolbar: {
                // items: [
                //     'undo', 'redo',
                //     '|', 'heading',
                //     '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor','alignment',
                //     '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                //     '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
                //     '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                //     '|','sourceediting','table'
                // ],
                shouldNotGroupWhenFull: true
            },
            mediaEmbed: {
                // Cấu hình để nhúng video từ YouTube
                previewsInData: true,
                providers: [
                    {
                        name: 'YouTube',
                        url: /^https:\/\/(?:www\.)?youtube\.com\/watch\?v=(.+)$/,
                        html: match => {
                            return '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + match[1] + '" frameborder="0" allowfullscreen></iframe>';
                        }
                    }
                ]
            }
        };
        return (
            <CKEditor
                //editor={ClassicEditor}
                editor={Editor}
                data={this.props.editorData}
                config={editorConfiguration}
                onReady={editor => {
                    //console.log("editor: ", editor)
                    editor.ui.view.editable.element.style.minHeight = initialHeight;

                    //Add the upload adapter
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                        return new UploadAdapter(loader);
                    };

                    editor.model.document.on('change:data', () => {
                        const data = editor.getData();
                        //console.log("data: ", data)
                        this.props.onChange(data);
                    });

                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "min-height",
                            initialHeight,
                            editor.editing.view.document.getRoot()
                        );
                    })
                }}
            />
        );
    }
}

export default CKEditorComponent;


// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';

// User Actions
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_SPECIFIC_USER = "GET_SPECIFIC_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const UPDATE_SPECIFIC_USER = "UPDATE_SPECIFIC_USER";
export const REMOVE_USERS = "REMOVE_USERS";

// permission
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS";
export const GET_PERMISSIONS_BY_ROLE = "GET_PERMISSIONS_BY_ROLE";
export const GET_PERMISSIONS_BY_USER = "GET_PERMISSIONS_BY_USER";

// role
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const CREATE_NEW_ROLE = "CREATE_NEW_ROLE";
export const UPDATE_SPECIFIC_ROLE = "UPDATE_SPECIFIC_ROLE";
export const REMOVE_ROLES = "REMOVE_ROLES";

// config
export const GET_CONFIG = "GET_CONFIG";
export const SET_CONFIG = "SET_CONFIG";

// Rule txt
export const GET_TRADEMARK_KEYWORD = "GET_TRADEMARK_KEYWORD";
export const UPDATE_TRADEMARK_KEYWORD = "UPDATE_TRADEMARK_KEYWORD";

// Excel Templates
export const GET_ALL_EXCEL_TEMPLATES = "GET_ALL_EXCEL_TEMPLATES";
export const UPLOAD_NEW_EXCEL_TEMPLATES = "UPLOAD_NEW_EXCEL_TEMPLATES";
export const GET_EXCEL_TEMPLATES = "GET_EXCEL_TEMPLATES";
export const CREATE_NEW_EXCEL_TEMPLATES = "CREATE_NEW_EXCEL_TEMPLATES";
export const REMOVE_EXCEL_TEMPLATES = "EXCEL_TEMPLATES";
export const GET_FIELD_TEMP = "GET_FIELD_TEMP";
export const CREATE_FROM_ORIGIN_TEMPLATES = "CREATE_FROM_ORIGIN_TEMPLATES";
export const GET_ALL_NEW_TEMPLATES = "GET_ALL_NEW_TEMPLATES";
export const GET_ALL_DOWNLOADED_TEMPLATES = "GET_ALL_DOWNLOADED_TEMPLATES";
export const UPDATE_CURRENT_EXCEL_TEMPLATES = "UPDATE_CURRENT_EXCEL_TEMPLATES";
export const EXPORT_TEMPLATES = "EXPORT_TEMPLATES";

// Cloudinary
export const GET_CLOUDINARY = "GET_CLOUDINARY";
export const UPDATE_CLOUDINARY = "UPDATE_CLOUDINARY";

// Idea
export const GET_LIST_ALL = "GET_LIST_ALL";
export const GET_ONE_IDEA = "GET_ONE_IDEA";
export const CREATE_IDEA = "CREATE_IDEA";
export const UPDATE_IDEA = "UPDATE_IDEA";
export const REMOVE_IDEA = "REMOVE_IDEA";
export const UPLOAD_IDEA_FILE = "UPLOAD_IDEA_FILE";
export const GET_LIST_APPROVED = "GET_LIST_APPROVED";
export const UPDATE_IDEA_LIMIT_FIELD = "UPDATE_IDEA_LIMIT_FIELD";
export const GET_ALL_IDEA_SIMILAR = "GET_ALL_IDEA_SIMILAR";
export const GET_LIST_OVERVIEW = "GET_LIST_OVERVIEW";

// Product type
export const GET_ALL_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPE";
export const GET_SPECIFIC_PRODUCT_TYPE = "GET_SPECIFIC_PRODUCT_TYPE";
export const UPDATE_SPECIFIC_PRODUCT_TYPE = "UPDATE_SPECIFIC_PRODUCT_TYPE";
export const CREATE_NEW_PRODUCT_TYPE = "CREATE_NEW_PRODUCT_TYPE";
export const REMOVE_PRODUCT_TYPE = "REMOVE_PRODUCT_TYPE";
export const GET_PRODUCT_TYPE_ACTIVE = "GET_PRODUCT_TYPE_ACTIVE";

// Niche
export const GET_ALL_NICHE = "GET_ALL_NICHE";
export const GET_SPECIFIC_NICHE = "GET_SPECIFIC_NICHE";
export const UPDATE_SPECIFIC_NICHE = "UPDATE_SPECIFIC_NICHE";
export const CREATE_NEW_NICHE = "CREATE_NEW_NICHE";
export const REMOVE_NICHE = "REMOVE_NICHE";
export const GET_NICHE_ACTIVE = "GET_NICHE_ACTIVE";

// Design
export const GET_LIST_ALL_DESIGN = "GET_LIST_ALL_DESIGN";
export const GET_ONE_DESIGN = "GET_ONE_DESIGN";
export const CREATE_DESIGN = "CREATE_DESIGN";
export const UPDATE_DESIGN = "UPDATE_DESIGN";
export const REMOVE_DESIGN = "REMOVE_DESIGN";
export const UPLOAD_DESIGN_FILE = "UPLOAD_DESIGN_FILE";
export const GET_LIST_DESIGN_APPROVED = "GET_LIST_DESIGN_APPROVED";
export const UPDATE_OTHER_DESIGN = "UPDATE_OTHER_DESIGN";

// Price
export const GET_ALL_PRICE = "GET_ALL_PRICE";
export const GET_ONE_PRICE = "GET_ONE_PRICE";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const CREATE_PRICE = "CREATE_PRICE";
export const REMOVE_PRICE = "REMOVE_PRICE";

// Statis
export const STATIS_IDEA = "STATIS_IDEA";
export const STATIS_DESIGN = "STATIS_DESIGN";
export const STATIS_MONEY = "STATIS_MONEY";
export const STATIS_STAFF = "STATIS_STAFF";
export const STATIS_PRODUCT = "STATIS_PRODUCT";
export const STATIS_ORDER = "STATIS_ORDER";
export const STATIS_ORDER_BY_STORE = "STATIS_ORDER_BY_STORE";

// Paymnet STAFF
export const STAFF_PAYMENT_MONEY = "STAFF_PAYMENT_MONEY";
export const UPDATE_STATUS_STAFF_PAYMENT_MONEY = "UPDATE_STATUS_STAFF_PAYMENT_MONEY";
export const STAFF_PAYMENT_HISTORIES = "STAFF_PAYMENT_HISTORIES";
export const REMOVE_STAFF_PAYMENT_HISTORIES = "REMOVE_STAFF_PAYMENT_HISTORIES";
export const GET_ONE_STAFF_PAYMENT_HISTORIES = "GET_ONE_STAFF_PAYMENT_HISTORIES";
export const CREATE_STAFF_PAYMENT_HISTORIES = "CREATE_STAFF_PAYMENT_HISTORIES";
export const UPDATE_STAFF_PAYMENT_HISTORIES = "UPDATE_STAFF_PAYMENT_HISTORIES";
export const STAFF_PAYMENT_STATISTICAL = "STAFF_PAYMENT_STATISTICAL";

// Order
export const LIST_ORDERS = "LIST_ORDERS";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const GET_ONE_ORDER_MANAGEMENT = "GET_ONE_ORDER_MANAGEMENT";
export const UPDATE_ORDER_MANAGEMENT = "UPDATE_ORDER_MANAGEMENT";
export const GET_STATISTICAL_ORDERS = "GET_STATISTICAL_ORDERS";
export const SYNC_ORDERS = "SYNC_ORDERS";
export const ADD_SHIPMENT_TRACKING = "ADD_SHIPMENT_TRACKING";
export const RESEND_SHIPMENT_TRACKING = "RESEND_SHIPMENT_TRACKING";
export const IMPORT_TRACKING = "IMPORT_TRACKING";
export const EXPORT_ORDERS = "EXPORT_ORDERS";
export const UPDATE_COST_LENFUL_ORDERS = "UPDATE_COST_LENFUL_ORDERS";

// Holiday
export const GET_ALL_HOLIDAY = "GET_ALL_HOLIDAY";
export const GET_SPECIFIC_HOLIDAY = "GET_SPECIFIC_HOLIDAY";
export const UPDATE_SPECIFIC_HOLIDAY = "UPDATE_SPECIFIC_HOLIDAY";
export const CREATE_NEW_HOLIDAY = "CREATE_NEW_HOLIDAY";
export const REMOVE_HOLIDAY = "REMOVE_HOLIDAY";

// EMAIL_MARKETING
export const GET_ALL_EMAIL_MARKETING = "GET_ALL_EMAIL_MARKETING";
export const GET_SPECIFIC_EMAIL_MARKETING = "GET_SPECIFIC_EMAIL_MARKETING";
export const UPDATE_SPECIFIC_EMAIL_MARKETING = "UPDATE_SPECIFIC_EMAIL_MARKETING";
export const CREATE_NEW_EMAIL_MARKETING = "CREATE_NEW_EMAIL_MARKETING";
export const REMOVE_EMAIL_MARKETING = "REMOVE_EMAIL_MARKETING";
export const UPLOAD_FILE_EMAIL_MARKETING = "UPLOAD_FILE_EMAIL_MARKETING";

// Trends
export const GET_ALL_TRENDING = "GET_ALL_TRENDING";
export const GET_SPECIFIC_TRENDING = "GET_SPECIFIC_TRENDING";
export const UPDATE_SPECIFIC_TRENDING = "UPDATE_SPECIFIC_TRENDING";
export const CREATE_NEW_TRENDING = "CREATE_NEW_TRENDING";
export const REMOVE_TRENDING = "REMOVE_TRENDING";
export const GET_NOTIFY_TRENDING = "GET_NOTIFY_TRENDING";

// Training
export const GET_ALL_TRAINING = "GET_ALL_TRAINING";
export const GET_SPECIFIC_TRAINING = "GET_SPECIFIC_TRAINING";
export const UPDATE_SPECIFIC_TRAINING = "UPDATE_SPECIFIC_TRAINING";
export const CREATE_NEW_TRAINING = "CREATE_NEW_TRAINING";
export const REMOVE_TRAINING = "REMOVE_TRAINING";

// Guide
export const GET_ALL_GUIDE = "GET_ALL_GUIDE";
export const GET_SPECIFIC_GUIDE = "GET_SPECIFIC_GUIDE";
export const UPDATE_SPECIFIC_GUIDE = "UPDATE_SPECIFIC_GUIDE";
export const CREATE_NEW_GUIDE = "CREATE_NEW_GUIDE";
export const REMOVE_GUIDE = "REMOVE_GUIDE";

// Store
export const GET_ALL_STORE = "GET_ALL_STORE";
export const GET_SPECIFIC_STORE = "GET_SPECIFIC_STORE";
export const UPDATE_SPECIFIC_STORE = "UPDATE_SPECIFIC_STORE";
export const CREATE_NEW_STORE = "CREATE_NEW_STORE";
export const REMOVE_STORE = "REMOVE_STORE";
export const GET_ALL_STORE_BY_ACTIVE = "GET_ALL_STORE_BY_ACTIVE";
export const GET_ALL_STORE_BY_FILED = "GET_ALL_STORE_BY_FILED";
export const OPTIMIZER_STORE = "OPTIMIZER_STORE";
export const UPDATE_API_STORE = "UPDATE_API_STORE";
export const EXPORT_STORES = "EXPORT_STORES";

// Tool product
export const GET_ALL_TOOL_PRODUCT = "GET_ALL_TOOL_PRODUCT";
export const GET_SPECIFIC_TOOL_PRODUCT = "GET_SPECIFIC_TOOL_PRODUCT";
export const UPDATE_SPECIFIC_TOOL_PRODUCT = "UPDATE_SPECIFIC_TOOL_PRODUCT";
export const CREATE_NEW_TOOL_PRODUCT = "CREATE_NEW_TOOL_PRODUCT";
export const REMOVE_TOOL_PRODUCT = "REMOVE_TOOL_PRODUCT";

// Products
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const GET_ONE_PRODUCTS = "GET_ONE_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const CREATED_PRODUCTS = "CREATED_PRODUCTS";
export const UPDATE_STATTUS_PRODUCTS = "UPDATE_STATTUS_PRODUCTS";
export const GET_LIST_FINISHED_DESIGN = "GET_LIST_FINISHED_DESIGN";
export const PRODUCT_GET_DESIGN = "PRODUCT_GET_DESIGN";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRICE_PRODUCTS = "UPDATE_PRICE_PRODUCTS"
export const GET_PRODUCT_HISTORIES = "GET_PRODUCT_HISTORIES";
export const PRODUCT_GET_HISTORIES = "PRODUCT_GET_HISTORIES"
export const DELETE_PRODUCTS_HISTORIES = "DELETE_PRODUCTS_HISTORIES";
export const DELETE_PRODUCT_BY_PERMALINK = "DELETE_PRODUCT_BY_PERMALINK";

// Products description
export const GET_ALL_PRODUCTS_DESCRIPTION = "GET_ALL_PRODUCTS_DESCRIPTION";
export const GET_SPECIFIC_PRODUCTS_DESCRIPTION = "GET_SPECIFIC_PRODUCTS_DESCRIPTION";
export const UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION = "UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION";
export const CREATE_NEW_PRODUCTS_DESCRIPTION = "CREATE_NEW_PRODUCTS_DESCRIPTION";
export const REMOVE_PRODUCTS_DESCRIPTION = "REMOVE_PRODUCTS_DESCRIPTION";

// Products categories description 
export const GET_ALL_PRODUCTS_CAT_DES = "GET_ALL_PRODUCTS_CAT_DES";
export const GET_SPECIFIC_PRODUCTS_CAT_DES = "GET_SPECIFIC_PRODUCTS_CAT_DES";
export const UPDATE_SPECIFIC_PRODUCTS_CAT_DES = "UPDATE_SPECIFIC_PRODUCTS_CAT_DES";
export const CREATE_NEW_PRODUCTS_CAT_DES = "CREATE_NEW_PRODUCTS_CAT_DES";
export const REMOVE_PRODUCTS_CAT_DES = "REMOVE_PRODUCTS_CAT_DES";
export const PRODUCTS_CAT_DES_PUBLISH_TO_SITE = "PRODUCTS_CAT_DES_PUBLISH_TO_SITE";

// Cân đối tài chính
export const GET_ALL_REVENUE_EXPENDITURE = "GET_ALL_REVENUE_EXPENDITURE";
export const GET_SPECIFIC_REVENUE_EXPENDITURE = "GET_SPECIFIC_REVENUE_EXPENDITURE";
export const UPDATE_SPECIFIC_REVENUE_EXPENDITURE = "UPDATE_SPECIFIC_REVENUE_EXPENDITURE";
export const CREATE_NEW_REVENUE_EXPENDITURE = "CREATE_NEW_REVENUE_EXPENDITURE";
export const REMOVE_REVENUE_EXPENDITURE = "REMOVE_REVENUE_EXPENDITURE";
export const GET_STATISTICAL_REVENUE_EXPENDITURE = "GET_STATISTICAL_REVENUE_EXPENDITURE";
export const EXPORT_REVENUE_EXPENDITURE = "EXPORT_REVENUE_EXPENDITURE";
export const UPDATE_REVENUE_EXPENDITURE = "UPDATE_REVENUE_EXPENDITURE";

// Products Categories
export const LIST_PRODUCTS_CATEGORIES = "LIST_PRODUCTS_CATEGORIES";
export const LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE = 'LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE';

// Products Tags
export const LIST_PRODUCTS_TAGS = "LIST_PRODUCTS_TAGS";
export const CREATED_PRODUCTS_TAGS = "CREATED_PRODUCTS_TAGS";
export const DELETE_PRODUCTS_TAGS = "DELETE_PRODUCTS_TAGS";
export const LIST_TAGS_IN_PRODUCTS_FORM = "LIST_TAGS_IN_PRODUCTS_FORM";

// Quản lý công việc
export const GET_ALL_WORKFLOW = "GET_ALL_WORKFLOW";
export const GET_SPECIFIC_WORKFLOW = "GET_SPECIFIC_WORKFLOW";
export const UPDATE_SPECIFIC_WORKFLOW = "UPDATE_SPECIFIC_WORKFLOW";
export const CREATE_NEW_WORKFLOW = "CREATE_NEW_WORKFLOW";
export const REMOVE_WORKFLOW = "REMOVE_WORKFLOW";
export const UPDATE_PROCESS_WORKFLOW = "UPDATE_PROCESS_WORKFLOW";
export const UPDATE_STATUS_WORKFLOW = "UPDATE_STATUS_WORKFLOW";

// Báo c công việc
export const GET_ALL_WORK_REPORT = "GET_ALL_WORK_REPORT";
export const GET_SPECIFIC_WORK_REPORT = "GET_SPECIFIC_WORK_REPORT";
export const UPDATE_SPECIFIC_WORK_REPORT = "UPDATE_SPECIFIC_WORK_REPORT";
export const CREATE_NEW_WORK_REPORT = "CREATE_NEW_WORK_REPORT";
export const REMOVE_WORK_REPORT = "REMOVE_WORK_REPORT";
export const UPDATE_PROCESS_WORK_REPORT = "UPDATE_PROCESS_WORK_REPORT";
export const UPDATE_STATUS_WORK_REPORT = "UPDATE_STATUS_WORK_REPORT";

// Comment
export const CREATE_COMMENT = "CREATE_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

// Telegram
export const GET_ALL_TELEGRAM = "GET_ALL_TELEGRAM";
export const GET_SPECIFIC_TELEGRAM = "GET_SPECIFIC_TELEGRAM";
export const UPDATE_SPECIFIC_TELEGRAM = "UPDATE_SPECIFIC_TELEGRAM";
export const CREATE_NEW_TELEGRAM = "CREATE_NEW_TELEGRAM";
export const REMOVE_TELEGRAM = "REMOVE_TELEGRAM";

// Notification center
export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const UPDATE_STATUS_NOTIFICATION = "UPDATE_STATUS_NOTIFICATION";
export const UPDATE_NOTIFICATION_UNREAD = "UPDATE_NOTIFICATION_UNREAD";

// posts
export const GET_LIST_POSTS = "GET_LIST_POSTS";
export const GET_ONE_POSTS = "GET_ONE_POSTS";
export const CREATE_POSTS = "CREATE_POSTS";
export const UPDATE_POSTS = "UPDATE_POSTS";
export const REMOVE_POSTS = "REMOVE_POSTS";

// Article keyword
export const ARTICLE_GET_LIST = "ARTICLE_GET_LIST";
export const ARTICLE_GET_ONE = "ARTICLE_GET_ONE";
export const ARTICLE_UPDATE = "ARTICLE_UPDATE";
export const ARTICLE_REMOVE = "ARTICLE_REMOVE";
// Article
export const ARTICLE_GET_LIST_KEYWORD = 'ARTICLE_GET_LIST_KEYWORD';
export const ARTICLE_GET_ONE_KEYWORD = 'ARTICLE_GET_ONE_KEYWORD';
export const ARTICLE_CREATE_KEYWORD = 'ARTICLE_CREATE_KEYWORD';
export const ARTICLE_UPDATE_KEYWORD = 'ARTICLE_UPDATE_KEYWORD';
export const ARTICLE_REMOVE_KEYWORD = 'ARTICLE_REMOVE_KEYWORD';
export const ARTICLE_UPDATE_STATUS_KEYWORD = 'ARTICLE_UPDATE_STATUS_KEYWORD';
export const ARTICLE_RECREATE_KEYWORD = 'ARTICLE_RECREATE_KEYWORD';

// product import tool
export const UPDATE_DESCRIPTION_QUEUE = "UPDATE_DESCRIPTION_QUEUE";
export const PRODUCTS_IMPORT_QUEUE = "PRODUCTS_IMPORT_QUEUE";
export const CREATE_PRODUCTS_QUEUE = "CREATE_PRODUCTS_QUEUE";
export const REMOVE_PRODUCTS_QUEUE = "REMOVE_PRODUCTS_QUEUE";
export const GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE = "GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE";
export const UPDATE_PRODUCTS_IMPORT_QUEUE = "UPDATE_PRODUCTS_IMPORT_QUEUE";
export const PRODUCTS_IMPORT_NOW = "PRODUCTS_IMPORT_NOW";
export const DELETE_QUEUE_IMAGE = "DELETE_QUEUE_IMAGE";
export const SET_THUMBNAIL = "SET_THUMBNAIL";
export const UPDATE_SPECIFIC_RECORD = "UPDATE_SPECIFIC_RECORD";
export const RESET_IMPORT_STATUS_PRODUCTS_QUEUE = "RESET_IMPORT_STATUS_PRODUCTS_QUEUE";

// File png
export const GET_LIST_ALL_FILE_PNG = "GET_LIST_ALL_FILE_PNG";
export const GET_ONE_FILE_PNG = "GET_ONE_FILE_PNG";
export const CREATE_FILE_PNG = "CREATE_FILE_PNG";
export const UPDATE_FILE_PNG = "UPDATE_FILE_PNG";
export const REMOVE_FILE_PNG = "REMOVE_FILE_PNG";

// Check link
export const LINK_LIST = "LINK_LIST";
export const LINK_CHECK = "LINK_CHECK";
export const REMOVE_LINK = "REMOVE_LINK";

// Tool license
export const GET_ALL_LICENSE = "GET_ALL_LICENSE";
export const UPDATE_SPECIFIC_LICENSE = "UPDATE_SPECIFIC_LICENSE";
export const REMOVE_LICENSE = "REMOVE_LICENSE";

// Combine file txt
export const COMBINE_FILE_HANDLE_DATA = "COMBINE_FILE_HANDLE_DATA";

// Google indexing
export const GOOGLE_INDEXING_GET_LIST_LINK = "GOOGLE_INDEXING_GET_LIST_LINK";
export const GOOGLE_INDEXING_IMPORT_LINK = "GOOGLE_INDEXING_IMPORT_LINK";
export const GOOGLE_INDEXING_REMOVE_LINK = "GOOGLE_INDEXING_REMOVE_LINK";
export const GOOGLE_INDEXING_POST_TASK = "GOOGLE_INDEXING_POST_TASK";

// Đơn vị in
export const GET_ALL_PRINTING_UNIT = "GET_ALL_PRINTING_UNIT";
export const GET_ALL_PRINTING_UNIT_BY_ACTIVE = "GET_ALL_PRINTING_UNIT_BY_ACTIVE";
export const GET_SPECIFIC_PRINTING_UNIT = "GET_SPECIFIC_PRINTING_UNIT";
export const UPDATE_SPECIFIC_PRINTING_UNIT = "UPDATE_SPECIFIC_PRINTING_UNIT";
export const CREATE_NEW_PRINTING_UNIT = "CREATE_NEW_PRINTING_UNIT";
export const REMOVE_PRINTING_UNIT = "REMOVE_PRINTING_UNIT";

// Setting payment gateways
export const GET_ALL_PAYMENT_GATEWAYS = "GET_ALL_PAYMENT_GATEWAYS";
export const GET_SPECIFIC_PAYMENT_GATEWAYS = "GET_SPECIFIC_PAYMENT_GATEWAYS";
export const UPDATE_SPECIFIC_PAYMENT_GATEWAYS = "UPDATE_SPECIFIC_PAYMENT_GATEWAYS";
export const CREATE_NEW_PAYMENT_GATEWAYS = "CREATE_NEW_PAYMENT_GATEWAYS";
export const REMOVE_PAYMENT_GATEWAYS = "REMOVE_PAYMENT_GATEWAYS";

// Blacklist
export const GET_ALL_BLACKLIST = "GET_ALL_BLACKLIST";
export const GET_SPECIFIC_BLACKLIST = "GET_SPECIFIC_BLACKLIST";
export const UPDATE_SPECIFIC_BLACKLIST = "UPDATE_SPECIFIC_BLACKLIST";
export const CREATE_NEW_BLACKLIST = "CREATE_NEW_BLACKLIST";
export const REMOVE_BLACKLIST = "REMOVE_BLACKLIST";

// Chat gpt config
export const CHATGPT_GET_ALL = "CHATGPT_GET_ALL";
export const CHATGPT_GET_SPECIFIC = "CHATGPT_GET_SPECIFIC";
export const CHATGPT_UPDATE_SPECIFIC = "CHATGPT_UPDATE_SPECIFIC";
export const CHATGPT_CREATE_NEW = "CHATGPT_CREATE_NEW";
export const CHATGPT_REMOVE = "CHATGPT_REMOVE";

// Product desctiption content
export const GET_ALL_PRODUCTS_DESCRIPTION_CONTENT = 'GET_ALL_PRODUCTS_DESCRIPTION_CONTENT';
export const GET_SPECIFIC_PRODUCTS_DESCRIPTION_CONTENT = 'GET_SPECIFIC_PRODUCTS_DESCRIPTION_CONTENT';
export const UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION_CONTENT = 'UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION_CONTENT';
export const CREATE_NEW_PRODUCTS_DESCRIPTION_CONTENT = 'CREATE_NEW_PRODUCTS_DESCRIPTION_CONTENT';
export const REMOVE_PRODUCTS_DESCRIPTION_CONTENT = 'REMOVE_PRODUCTS_DESCRIPTION_CONTENT';
export const GET_RANDOM_PRODUCTS_DESCRIPTION_CONTENT = "GET_RANDOM_PRODUCTS_DESCRIPTION_CONTENT";

// Log gửi mail tracking
export const LOG_MAIL_TRACKING_GET_LIST = "LOG_MAIL_TRACKING_GET_LIST";
export const REMOVE_LOG_MAIL_TRACKING = "REMOVE_LOG_MAIL_TRACKING";

// 
export const GET_LIST_LOG_ORDER_SHIELD = "GET_LIST_LOG_ORDER_SHIELD";


// Quản lý dự án
export const GET_LIST_PROJECT = "GET_LIST_PROJECT";
export const GET_SPECIFIC_PROJECT = "GET_SPECIFIC_PROJECT";
export const UPDATE_SPECIFIC_PROJECT = "UPDATE_SPECIFIC_PROJECT";
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const GET_PROJECT_ACTIVE = "GET_PROJECT_ACTIVE";
export const CLONE_PROJECT = "CLONE_PROJECT";

// Công việc của dự án
export const GET_ALL_PROJECT_BASED_JOB = "GET_ALL_PROJECT_BASED_JOB";
export const GET_SPECIFIC_PROJECT_BASED_JOB = "GET_SPECIFIC_PROJECT_BASED_JOB";
export const UPDATE_SPECIFIC_PROJECT_BASED_JOB = "UPDATE_SPECIFIC_PROJECT_BASED_JOB";
export const CREATE_NEW_PROJECT_BASED_JOB = "CREATE_NEW_PROJECT_BASED_JOB";
export const REMOVE_PROJECT_BASED_JOB = "REMOVE_PROJECT_BASED_JOB";
export const UPDATE_RESULT_PROJECT_BASED_JOB = "UPDATE_RESULT_PROJECT_BASED_JOB";
export const UPDATE_STATUS_PROJECT_BASED_JOB = "UPDATE_STATUS_PROJECT_BASED_JOB";
export const ASSIGN_PROJECT_BASED_JOB = "ASSIGN_PROJECT_BASED_JOB";
export const GET_MAX_VALUE_PROJECT_BASED_JOB = "GET_MAX_VALUE_PROJECT_BASED_JOB";

// Firebase
export const ADD_DEVICE_TOKEN = "ADD_DEVICE_TOKEN";
export const GET_LIST_NOTIFICATIONS = "GET_LIST_NOTIFICATIONS";
export const GET_COUNT_NOTIFICATIONS = "GET_COUNT_NOTIFICATIONS";
export const UPDATE_IS_READ_NOTIFICATION = "UPDATE_IS_READ_NOTIFICATION";


//Trademark category
export const GET_LIST_TRADEMARK_CATEGORY = "GET_LIST_TRADEMARK_CATEGORY";
export const GET_SPECIFIC_TRADEMARK_CATEGORY = "GET_SPECIFIC_TRADEMARK_CATEGORY";
export const UPDATE_SPECIFIC_TRADEMARK_CATEGORY = "UPDATE_SPECIFIC_TRADEMARK_CATEGORY";
export const CREATE_NEW_TRADEMARK_CATEGORY = "CREATE_NEW_TRADEMARK_CATEGORY";
export const REMOVE_TRADEMARK_CATEGORY = "REMOVE_TRADEMARK_CATEGORY";
export const GET_TRADEMARK_CATEGORY_ACTIVE = "GET_TRADEMARK_CATEGORY_ACTIVE";
export const CHECK_MATCHING_TRADEMARK_CATEGORY = "CHECK_MATCHING_TRADEMARK_CATEGORY";

// Xóa object S3
export const DELETE_OBJECT_S3 = "DELETE_OBJECT_S3";


// Tùy chọn cột hiển thị
export const GET_LIST_USER_COLUMN_PREFERENCES = "GET_LIST_USER_COLUMN_PREFERENCES";
export const CREATE_NEW_USER_COLUMN_PREFERENCES = "CREATE_NEW_USER_COLUMN_PREFERENCES";
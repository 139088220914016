import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, Tooltip } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TelegramForm from './TelegramForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';

// actions
import { getAllTelegram, getTelegram, removeTelegram } from '../../redux/actions/TelegramActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/telegram',
        breadcrumbName: 'Danh sách nhóm',
    },
]

class TelegramList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllTelegram(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllTelegram(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/telegram',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/telegram',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getTelegram(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeTelegram(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
        this.reloadData()
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllTelegram(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { telegram, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '80px',
            },
            {
                title: 'Actions',
                align: 'center',
                width: '50px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('telegram', 'update') ? (
                                <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên nhóm chat',
                dataIndex: 'name',
                key: 'name',
                width: '250px',
                render: (text, record) => (
                    <div>{text}</div>
                )
            },
            {
                title: 'Group Id',
                dataIndex: 'chat_id',
                key: 'chat_id',
                width: '100px',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Route',
                dataIndex: 'route',
                key: 'route',
                width: '200px',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (text, record) => {
                    let status = ""
                    if (record.status == 1) {
                        status = <Tag color="#52c41a">Active</Tag>
                    } else if (record.status == 0) {
                        status = <Tag color="#f00">InActive</Tag>
                    }
                    return (
                        <>
                            <div>{status}</div>
                        </>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip placement="topLeft" title={text}>
                            <TextTruncate line={2} truncateText="…" text={text} />
                        </Tooltip>
                    </div>
                )
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách nhóm" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={telegram}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <TelegramForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        telegram: state.TelegramReducer.telegram,
        pagination: state.TelegramReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllTelegram: (filter) => dispatch(getAllTelegram(filter)),
        getTelegram: (id) => dispatch(getTelegram(id)),
        removeTelegram: (ids) => dispatch(removeTelegram(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TelegramList);

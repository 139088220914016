import api from '../../utils/api';
import {
    GET_ALL_PRODUCTS_DESCRIPTION,
    GET_SPECIFIC_PRODUCTS_DESCRIPTION,
    UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION,
    CREATE_NEW_PRODUCTS_DESCRIPTION,
    REMOVE_PRODUCTS_DESCRIPTION
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllProductsDescription = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/products-description', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_PRODUCTS_DESCRIPTION, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createProductsDescription = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products-description', data).then(res => {
            dispatch({ type: CREATE_NEW_PRODUCTS_DESCRIPTION, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getProductsDescription = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/products-description/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCTS_DESCRIPTION, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateProductsDescription = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/products-description/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PRODUCTS_DESCRIPTION, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeProductsDescription = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/products-description`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PRODUCTS_DESCRIPTION, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

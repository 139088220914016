import api from '../../utils/api';
import {
    GET_LIST_TRADEMARK_CATEGORY,
    GET_SPECIFIC_TRADEMARK_CATEGORY,
    UPDATE_SPECIFIC_TRADEMARK_CATEGORY,
    CREATE_NEW_TRADEMARK_CATEGORY,
    REMOVE_TRADEMARK_CATEGORY,
    GET_TRADEMARK_CATEGORY_ACTIVE,
    CHECK_MATCHING_TRADEMARK_CATEGORY
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListTrademarkCategory = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/trademark-category', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_TRADEMARK_CATEGORY, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getTrademarkCategoryActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/trademark-category/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_TRADEMARK_CATEGORY_ACTIVE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificTrademarkCategory = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/trademark-category/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_TRADEMARK_CATEGORY, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createTrademarkCategory = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/trademark-category', data).then(res => {
            dispatch({ type: CREATE_NEW_TRADEMARK_CATEGORY, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateTrademarkCategory = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/trademark-category/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_TRADEMARK_CATEGORY, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeTrademarkCategory = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/trademark-category`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_TRADEMARK_CATEGORY, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const checkMatching = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/trademark-category/check/matching', data).then(res => {
            //console.log(res)
            dispatch({ type: CHECK_MATCHING_TRADEMARK_CATEGORY, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

import api from '../../utils/api';
import {
    LIST_ORDERS,
    GET_ONE_ORDER,
    GET_ONE_ORDER_MANAGEMENT,
    UPDATE_ORDER_MANAGEMENT,
    GET_STATISTICAL_ORDERS,
    SYNC_ORDERS,
    ADD_SHIPMENT_TRACKING,
    RESEND_SHIPMENT_TRACKING,
    IMPORT_TRACKING,
    EXPORT_ORDERS,
    UPDATE_COST_LENFUL_ORDERS
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAllOrders = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/orders', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: LIST_ORDERS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const syncOrder = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/orders/sync', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: SYNC_ORDERS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneOrder = (order_id, params) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/orders/${order_id}`, { params: params }).then(res => {
            console.log("res: ", res)
            dispatch({
                type: GET_ONE_ORDER,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneOrderManagement = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/orders/order-management/${id}`).then(res => {
            dispatch({
                type: GET_ONE_ORDER_MANAGEMENT,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateOrderManagement = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/orders/order-management/${id}`, data).then(res => {
            dispatch({ type: UPDATE_ORDER_MANAGEMENT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateManyManagement = data => dispatch => {
    return new Promise((reslove, reject) => {
        return api.put('/orders/order-management/many/update', data).then((res) => {
            dispatch({
                type: UPDATE_ORDER_MANAGEMENT,
                payload: res.data
            });
            NotificationManager.success("Cập nhật thành công!");
            reslove(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/orders/statistical/total', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: GET_STATISTICAL_ORDERS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const addShipmentTracking = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/orders/add/shipment-tracking', data).then(res => {
            //console.log("res: ", res)
            dispatch({ type: ADD_SHIPMENT_TRACKING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const resendShipmentTracking = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/orders/resend/shipment-tracking', data).then(res => {
            dispatch({ type: RESEND_SHIPMENT_TRACKING, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const importTracking = (data, header) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/orders/import-tracking', data, header).then((res) => {
            dispatch({
                type: IMPORT_TRACKING,
                payload: res,
            });
            resolve(res);
        }).catch(err => {
            //NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const exportOrders = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/orders/export`, filter).then(res => {
            dispatch({ type: EXPORT_ORDERS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}

export const updateCostLenful = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/orders/lenful/update-cost`, data).then(res => {
            dispatch({ type: UPDATE_COST_LENFUL_ORDERS, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}
import {
    GET_LIST_LOG_ORDER_SHIELD
} from '../actions/types';

const INIT_STATE = {
    list_log_order_shield: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 50
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_LOG_ORDER_SHIELD: {
            return {
                ...state,
                list_log_order_shield: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        default: return { ...state };
    }
}

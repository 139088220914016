import {
    LOG_MAIL_TRACKING_GET_LIST
} from '../actions/types';

const INIT_STATE = {
    list_log_mail_tracking: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 40
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOG_MAIL_TRACKING_GET_LIST: {
            return {
                ...state,
                list_log_mail_tracking: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        default: return { ...state };
    }
}

import {
    LIST_PRODUCTS_CATEGORIES,
    LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE
} from '../actions/types';

const INIT_STATE = {
    categories: [],
    currentCategories: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 400
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_PRODUCTS_CATEGORIES: {
            return {
                ...state,
                categories: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            }
        }
        case LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE: {
            let listCategories = [...state.categories, ...action.payload.data];
            return {
                ...state,
                categories: listCategories,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            }
        }
        default:
            return { ...state }
    }

};

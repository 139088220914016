import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Row, Col, TreeSelect, Drawer, Space, Typography, Divider, Input, Select } from 'antd';
import { makeTree } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { isMobile } from 'react-device-detect';
import Editor from "../../components/Editor";
// actions
import { createProductsDescription, updateProductsDescription } from '../../redux/actions/ProductsDescriptionActions';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getStore, getStoreByField } from '../../redux/actions/StoreActions';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getRamdomProductsDescriptionContent } from '../../redux/actions/ProductsDescriptionContentActions';
import { chatGPTCompletions } from '../../redux/actions/ChatGptActions';

const { Paragraph } = Typography;

class ProductsDescriptionForm extends Component {

    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            isLoadingCat: false,
            showCategory: true,
            currentType: null,
            current_store: null,
            categoryId: null,
            categoryName: null,
            currentProductType: null,
            isloadingContent: false,
            showBtnWrite: false,
            isloadingWriteContent: false,
            currentStoreId: null,
            messages_initialValue: null,
            currentProductTypeName: null
        };
    }

    componentDidMount() {
        this.props.getStoreByField({ platform: ['pod', 'shield'] })
        this.props.getProductTypeByActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData) {
            if (nextProps.currentData.type == 'product_des' || nextProps.currentData.type == 'trust_site') {
                this.setState({ showCategory: false });
            } else {
                this.setState({ showCategory: true });
            }
        }
    }

    onChangeStore(value) {
        this.setState({ isLoadingCat: true, currentStoreId: value })
        this.props.currentStore(value);
        this.props.getAllCategories({ store_id: value }).then(res => {
            this.setState({ isLoadingCat: false })
        });
        this.props.getStore(value).then(res => {
            this.setState({ current_store: res.name });
        })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                values.category_id = values.category;
                delete values.category;
                delete values.messages;
                if (this.props.currentData) {
                    values.category_name = this.state.categoryName != null ? this.state.categoryName : [this.props.currentData.category_name];
                    await this.props.updateProductsDescription(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    values.category_name = this.state.categoryName;
                    await this.props.createProductsDescription(values);
                    //this.props.form.resetFields();
                    this.props.form.setFieldsValue({ content: '' })
                    this.setState({ isloading: false })
                    this.props.getAllCategories({ store_id: values.store_id })
                    this.props.reloadData()
                }
                //console.log(values)
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({
            isloading: false,
            showCategory: true,
            currentType: null,
            currentProductType: null,
            categoryName: null,
            categoryId: null,
            messages_initialValue: null,
            currentProductTypeName: null,
            showBtnWrite: false
        })
    }

    onChangeType(value) {
        this.setState({ currentType: value });
        if (value == 'niche_des') {
            this.setState({
                showBtnWrite: true
            });
        }
        if (value === 'niche_des' || value === 'video' || value === 'image') {
            this.setState({ showCategory: true })
        } else {
            this.setState({
                showCategory: false,
                categoryId: null,
                categoryName: null,
                showBtnWrite: false,
                messages_initialValue: null
            })
        }
    }

    onChangeProductType = (id, value) => {
        let catName = [this.state.categoryName];
        this.appendMessages(catName, value.label);
        this.setState({ currentProductType: id, currentProductTypeName: value.label });
    }

    handleCategoryChange = (id, name) => {
        this.appendMessages(name, this.state.currentProductTypeName);
        this.setState({
            categoryId: id,
            categoryName: name
        });
    };

    appendMessages(cat_name, type_name) {
        let catName = [cat_name];
        let messages_initialValue = "Viết một đoạn mô tả dài giải nghĩa cho từ khóa sau bằng tiếng Anh: " + catName + ' ' + type_name;
        this.props.form.setFieldsValue({
            messages: messages_initialValue
        })
    }

    addDefaultContent() {
        this.setState({ isloadingContent: true })
        const { currentType, currentProductType } = this.state;
        this.props.getRamdomProductsDescriptionContent({ type: currentType, product_type_id: currentProductType }).then(res => {
            //console.log(res)
            if (res && res.status == "success") {
                this.props.form.setFieldsValue({
                    content: res.data
                })
                this.setState({ isloadingContent: false })
            }
        }).catch(err => {
            this.setState({ isloadingContent: false })
        })
    }

    writeContent() {
        const { currentStoreId, categoryId } = this.state;
        this.setState({ isloadingWriteContent: true })
        this.props.chatGPTCompletions({
            categoryId: categoryId,
            store_id: currentStoreId,
            messages: this.props.form.getFieldValue('messages')
        }).then(res => {
            //console.log("result: ", res)
            this.props.form.setFieldsValue({
                content: res
            })
            this.setState({ isloadingWriteContent: false })
        }).catch(err => {
            this.setState({ isloadingWriteContent: false })
        })
    }

    render() {
        const {
            isloading,
            isLoadingCat,
            showCategory,
            isloadingContent,
            showBtnWrite,
            isloadingWriteContent,
            messages_initialValue
        } = this.state;
        var { visible, currentData, categories, stores, producttype } = this.props;
        const { getFieldDecorator } = this.props.form;
        const catTree = makeTree(categories || [], 0);
        const product_type_list = producttype.map(item => {
            return { value: item.id, label: item.name }
        });

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.handCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={isloading}
                                onClick={(e) => this.submit(e)}
                            >
                                {currentData ? "Cập nhật" : "Lưu"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại nội dung">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.type : null
                                    })(
                                        <BaseSelect
                                            style={{ width: '100%' }}
                                            options={[
                                                { label: "Mô tả niche", value: 'niche_des' },
                                                { label: "Mô tả sản phẩm", value: 'product_des' },
                                                { label: "Content trust site", value: 'trust_site' },
                                                { label: "Video", value: 'video' },
                                                { label: "Hình ảnh", value: 'image' },
                                            ]}
                                            placeholder="Chọn loại nội dung"
                                            onChange={(value) => this.onChangeType(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Store">
                                    {getFieldDecorator("store_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.store_id : null
                                    })(
                                        <BaseSelect
                                            options={stores}
                                            optionValue="id"
                                            optionLabel="name"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder="Chọn store"
                                            onChange={(value) => this.onChangeStore(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại sản phẩm">
                                    {getFieldDecorator("product_type_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.product_type_id : null
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="Chọn loại sản phẩm"
                                            optionFilterProp="children"
                                            onChange={this.onChangeProductType}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={product_type_list}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                showCategory ? (
                                    <Col xs={24} md={12}>
                                        <Form.Item label='Categories'>
                                            {getFieldDecorator("category", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData ? currentData.category_id : null
                                            })(
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                    treeData={catTree}
                                                    showSearch
                                                    loading={isLoadingCat}
                                                    treeNodeFilterProp='title'
                                                    allowClear
                                                    placeholder="Chọn categories"
                                                    treeDefaultExpandAll
                                                    onChange={this.handleCategoryChange}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        {showBtnWrite && !currentData ? (
                            <Row gutter={16}>
                                <Col xs={24} md={18}>
                                    <Form.Item label="Messages">
                                        {getFieldDecorator("messages", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng nhập!",
                                                },
                                            ],
                                            initialValue: messages_initialValue
                                        })(<Input allowClear />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Button style={{ marginTop: '30px' }} loading={isloadingWriteContent} type='primary' onClick={() => this.writeContent()}>Tạo nội dung tự động</Button>
                                </Col>
                            </Row>
                        ) : null}
                        <Form.Item label="Nội dung">
                            <div className='paramer-content'>
                                <b>Parameter</b>:
                                <Paragraph copyable>[store_name]</Paragraph>
                                <Paragraph copyable>[store_url]</Paragraph>
                                <Paragraph copyable>[product_name]</Paragraph>
                                <Paragraph copyable>[product_category]</Paragraph>
                                {!showCategory && !currentData ? (
                                    <>
                                        <Divider type="vertical" />
                                        <Button loading={isloadingContent} size='small' type='primary' onClick={() => this.addDefaultContent()}>Tạo nội dung tự động</Button>
                                    </>
                                ) : null}
                            </div>
                            {getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng nhập!",
                                    },
                                ],
                                initialValue: currentData ? currentData.content : null
                            })(
                                <Editor store_name={this.state.current_store} />
                            )}
                        </Form.Item>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

const mapStateToProps = state => {
    //console.log(state.ProductsCategories)
    return {
        categories: state.ProductsCategories.categories,
        stores: state.StoreReducer.store_by_field_list,
        producttype: state.ProductType.producttype
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createProductsDescription: (data) => dispatch(createProductsDescription(data)),
        updateProductsDescription: (id, data) => dispatch(updateProductsDescription(id, data)),
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getStore: (id) => dispatch(getStore(id)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter)),
        getRamdomProductsDescriptionContent: filter => dispatch(getRamdomProductsDescriptionContent(filter)),
        chatGPTCompletions: data => dispatch(chatGPTCompletions(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductsDescriptionForm' })(ProductsDescriptionForm));

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Drawer, Button, Input, Col, Row, DatePicker, Divider } from "antd";
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
// actions
import { createOtherDesign } from '../../redux/actions/DesignAction';
import { _priority, _request_type } from "../../utils/helpers";

class DesignAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            image: [],
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const params = {
                        name: values.name,
                        images_demo: this.state.image,
                        user_design_id: values.user_design_id,
                        description: values.description,
                        request_des_for_design: values.request_des_for_design,
                        deadline: values.deadline,
                        request_type: values.request_type,
                        priority: values.priority,
                        option: 'in_design'
                    }
                    //console.log(params)
                    await this.props.createOtherDesign(params);
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        const { image } = this.state;
        this.props.form.setFieldsValue({
            images_demo: image
        });
        NotificationManager.success("Image complete.");
    }

    onClose() {
        this.setState({
            isLoading: false,
            showOptionMockup: false
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        const { visible, users_design } = this.props;
        const { isLoading, isResetUpload } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Thêm yêu cầu thiết kế"
                width={isMobile ? '100%' : '50%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tên">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Nhân viên thiết kế">
                                    {getFieldDecorator("user_design_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={users_design || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Nhân viên thiết kế"
                                            showSearch
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Deadline">
                                    {getFieldDecorator("deadline", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Yêu cầu">
                                    {getFieldDecorator("request_type", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: 'clone'
                                    })(
                                        <BaseRadios
                                            options={_request_type}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Độ ưu tiên">
                                    {getFieldDecorator('priority', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: 'binhthuong'
                                    })(
                                        <BaseRadios options={_priority} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ảnh mẫu (tối đa 10 ảnh)">
                            {getFieldDecorator('images_demo', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={10}
                                    onChangeData={this.onChange}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Mô tả yêu cầu cho thiết kế">
                            {getFieldDecorator('request_des_for_design', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
        users: state.config.users,
        users_design: state.config.users_design
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOtherDesign: (data) => dispatch(createOtherDesign(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DesignAddForm" })(DesignAddForm));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Col, Row, Divider, DatePicker, InputNumber } from 'antd';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import generatePassword from 'generate-password';
import { isMobile } from 'react-device-detect';
import { _newLine, isValidDateForm } from '../../utils/helpers';
// actions
import { createUser, updateUser } from '../../redux/actions/UserActions';

class UserForm extends Component {
    state = {
        loading: false,
        userRole: null
    }

    componentDidMount() {

    }

    onChangeRole(value) {
        this.setState({ userRole: value })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ loading: true });
                try {
                    if (this.props.user) {
                        await this.props.updateUser(this.props.user.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createUser(values);
                        this.onClose();
                    }
                    //console.log(values)
                } catch (error) {
                    this.setState({ loading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ loading: false });
    }

    render() {
        var { visible, user, config } = this.props;
        var { loading, userRole } = this.state;
        const { getFieldDecorator } = this.props.form;
        const statuses = [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Inactive' },
        ];

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={user ? `Sửa nhân viên: ${user.full_name}` : 'Thêm nhân viên mới'}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '45%'}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Divider type="vertical" />
                            <Button key="submit" type="primary" onClick={(e) => this.submit(e)} loading={loading}>
                                {user ? 'Sửa' : 'Thêm mới'}
                            </Button>
                        </div>
                    }
                >
                    {visible ?
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Họ tên">
                                        {getFieldDecorator('full_name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền họ tên!',
                                                },
                                            ],
                                            initialValue: user ? user?.full_name : null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Username">
                                        {getFieldDecorator('user_name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền username!',
                                                },
                                            ],
                                            initialValue: user ? user?.user_name : null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="E-mail">
                                        {getFieldDecorator('user_email', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'Email không hợp lệ!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền E-mail!',
                                                },
                                            ],
                                            initialValue: user ? user?.user_email : null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>

                                {/* Only show password input when create user */}
                                {
                                    user ? null : (
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Mật khẩu" hasFeedback>
                                                {getFieldDecorator('user_password', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền mật khẩu!',
                                                        },
                                                        {
                                                            validator: this.validateToNextPassword,
                                                        },
                                                    ],
                                                    initialValue: generatePassword.generate({
                                                        length: 10,
                                                        numbers: true
                                                    })
                                                })(<Input.Password autoComplete={"new-password"} />)}
                                            </Form.Item>
                                        </Col>
                                    )
                                }

                                <Col xs={24} md={12}>
                                    <Form.Item label="Vai trò user">
                                        {getFieldDecorator('role', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn vai trò!',
                                                },
                                            ],
                                            initialValue: user ? user?.role : null
                                        })(
                                            <BaseSelect
                                                options={config.roles}
                                                optionValue="id"
                                                optionLabel="name"
                                                defaultText="Chọn vai trò"
                                                placeholder="Chọn vai trò"
                                                onChange={(value) => this.onChangeRole(value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                {
                                    userRole != 1 ? (
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Ngày hết hạn sử dụng CMS">
                                                {getFieldDecorator('expire', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                        },
                                                    ],
                                                    initialValue: user ? isValidDateForm(user?.expire, false) : null
                                                })(
                                                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    ) : null
                                }

                                <Col xs={24} md={12}>
                                    <Form.Item label="Lương ">
                                        {getFieldDecorator('amount_salary', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập!",
                                                },
                                            ],
                                            initialValue: user ? user?.amount_salary : null
                                        })(
                                            <InputNumber
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                step={50000}
                                                min={0}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="ID telegram">
                                        {getFieldDecorator('telegram_id', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng nhập!',
                                                },
                                            ],
                                            initialValue: user ? user?.telegram_id : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Trạng thái">
                                        {getFieldDecorator('user_active', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn trạng thái!',
                                                },
                                            ],
                                            initialValue: user ? user?.user_active : null
                                        })(
                                            <BaseRadios options={statuses} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tài khoản ngân hàng">
                                        {getFieldDecorator('account_bank', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng nhập!',
                                                },
                                            ],
                                            initialValue: user ? _newLine(user?.account_bank) : null
                                        })(
                                            <Input.TextArea rows={2} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: user ? _newLine(user?.note) : null
                                        })(
                                            <Input.TextArea rows={2} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        : null}
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    // console.log(state)
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createUser: (data) => dispatch(createUser(data)),
        updateUser: (id, data) => dispatch(updateUser(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UserForm' })(UserForm));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, ExportOutlined, CheckCircleTwoTone, CloseCircleTwoTone, RocketOutlined, RetweetOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table, Button, Modal, Divider, Tag, Tooltip, Switch, Typography, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import StoreForm from './StoreForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, downloadFromLink } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import { NotificationManager } from 'react-notifications';
import appConfig from '../../config';

import moment from 'moment';
// actions
import { getAllStore, getStore, removeStore, optimizerStore, exportStore, updateApiStore } from '../../redux/actions/StoreActions';

const { confirm } = Modal;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/store',
        breadcrumbName: 'Danh sách cửa hàng',
    },
]

class StoreList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null,
        isLoadingOptimizer: false,
        loadingUpdateApi: false
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllStore(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllStore(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/store',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/store',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getStore(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllStore(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onRemove() {
        this.props.removeStore(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            //this.reloadData()
        })
    }

    onOptimizer(id) {
        this.setState({ isLoadingOptimizer: true })
        this.props.optimizerStore([id])
        NotificationManager.success("Đang xóa cache và clean wp table");
        this.setState({ isLoadingOptimizer: false, selectedRowKeys: [] })
    }

    manyOptimizer() {
        this.setState({ isLoadingOptimizer: true })
        this.props.optimizerStore(this.state.selectedRowKeys)
        NotificationManager.success("Đang xóa cache và clean wp table");
        this.setState({ isLoadingOptimizer: false, selectedRowKeys: [] })
    }

    onUpdateApi(id) {
        this.setState({ loadingUpdateApi: true })
        this.props.updateApiStore(id).then(res => {
            this.setState({ loadingUpdateApi: false })
        }).catch(err => {
            console.log(err)
            this.setState({ loadingUpdateApi: false })
        })
    }

    do_export() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportStore(query).then((res) => {
            console.log("res: ", res)
            setTimeout(() => {
                const response = {
                    file: `${appConfig.MEDIA_URL}/${res.path}`,
                };
                downloadFromLink(response.file, "_ecompublic_danh_store.xlsx");
            }, 100);
            this.setState({ isLoadingExport: false });
        }).catch(err => {
            this.setState({ isLoadingExport: false });
        });

    }

    render() {
        var { stores, pagination, users, authUser } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData, isLoadingOptimizer, isLoadingExport, loadingUpdateApi } = this.state;
        //console.log(stores)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: 'Actions',
                align: 'center',
                key: 'actions',
                width: '130px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('store', 'update') ? (
                                <>
                                    <Tooltip title="Sửa">
                                        <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                                        <Divider type="vertical" />
                                    </Tooltip>
                                    <Tooltip title="Xóa cache">
                                        <RocketOutlined className='primary-color item-action-btn' onClick={() => this.onOptimizer(record.id)} />
                                    </Tooltip>
                                    <Tooltip title="Cập nhật api">
                                        <Divider type="vertical" />
                                        <RetweetOutlined className='primary-color item-action-btn' onClick={() => this.onUpdateApi(record.id)} />
                                    </Tooltip>
                                    <Tooltip title="Cài đặt api">
                                        <Divider type="vertical" />
                                        <a href={`${record.url}/wp-content/plugins/woocommerce-api-ecompublic/setup.php`} target="_blank">
                                            <SettingOutlined className='primary-color item-action-btn' />
                                        </a>
                                    </Tooltip>
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên của hàng',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                    <div className='ecom-paragraph'>
                        <Paragraph copyable={{ tooltips: false }}>{text}</Paragraph>
                    </div>
                )
            },
            {
                title: 'Admin',
                dataIndex: 'admin_url',
                key: 'admin_url',
                width: '100px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <a href={text} target='_blank'>
                            <Tooltip title={ReactHtmlParser(text)}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </a>
                    </div>
                )
            },
            // {
            //     title: 'Link tool',
            //     dataIndex: 'tool_url',
            //     key: 'tool_url',
            //     width: '200px',
            //     render: (text, record) => (
            //         <div style={{ wordBreak: "break-all" }}>
            //             <a href={text} target='_blank'>
            //                 <Tooltip title={ReactHtmlParser(text)}>
            //                     <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
            //                 </Tooltip>
            //             </a>
            //         </div>
            //     )
            // },
            {
                title: 'Nhân viên quản lý',
                width: '200px',
                render: (text, record) => {
                    if (record.listUser.length > 0) {
                        return (
                            <>
                                {
                                    record.listUser.map(item => {
                                        return (
                                            <Tag>{item.full_name}</Tag>
                                        )
                                    })
                                }
                            </>
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Giới hạn SP',
                dataIndex: 'product_limit',
                key: 'product_limit',
                align: 'center',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Tag>{text}</Tag>
                        )
                    } else {
                        return (
                            <Tag>Unlimit</Tag>
                        )
                    }
                }
            },
            {
                title: 'Submit url',
                dataIndex: 'number_url_submit_index',
                key: 'number_url_submit_index',
                align: 'center',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Tag>{text}</Tag>
                        )
                    } else {
                        return (
                            <Tag></Tag>
                        )
                    }
                }
            },
            {
                title: 'Thống kê SP',
                dataIndex: 'product_statistics',
                key: 'product_statistics',
                align: 'center',
                sorter: true,
                render: (text, record) => {
                    var color = 'red';
                    if (text == 'yes') {
                        color = 'green';
                    }
                    if (text) {
                        return (
                            <Tag color={color} style={{ textTransform: 'capitalize' }}>{text}</Tag>
                        )
                    }
                }
            },
            {
                title: 'Platform',
                dataIndex: 'platform',
                key: 'platform',
                align: 'center',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Tag style={{ textTransform: 'capitalize' }}>{text}</Tag>
                        )
                    }
                }
            },
            {
                title: 'Ngày thêm',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '150px',
            }
        ];

        if (authUser.role_code != 'ADMIN') {
            columns.filter(x => x.key === 'id').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'actions').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'admin_url').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'note').forEach(x => columns.splice(columns.indexOf(x), 1));
        }

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách cửa hàng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('store', 'create')}
                        isShowDeleteButton={checkPermission('store', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            authUser.role_code == 'ADMIN' ? (
                                <>
                                    <BaseSelect
                                        options={users || []}
                                        optionValue="user_id"
                                        optionLabel="full_name"
                                        showSearch
                                        placeholder="Nhận viên quản lý"
                                        style={{ width: '200px' }}
                                        onChange={(value) => this.onChangeFilter('staff_id', value)}
                                        defaultValue={query.staff_id ? parseInt(query.staff_id) : null}
                                        className="table-button"
                                    />
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }

                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã kích hoạt' },
                                { value: 0, label: 'Chưa kích hoạt' }
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            defaultText="Trạng thái"
                            placeholder="Trạng thái"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '120px' }}
                            className="table-button"
                        />
                        <Divider type="vertical" />
                        <BaseSelect
                            options={[
                                { value: 'pod', label: 'Pod' },
                                { value: 'dropship', label: 'Dropship' },
                                { value: 'shield', label: 'Shield' },
                                { value: 'news', label: 'News' }
                            ]}
                            onChange={(value) => this.onChangeFilter("platform", value)}
                            defaultText="Platform"
                            placeholder="Platform"
                            defaultValue={query.platform || null}
                            style={{ width: '100px' }}
                            className="table-button"
                        />
                        {/* {
                            checkPermission('store', 'update') ? (
                                <>
                                    <Divider type="vertical" />
                                    <Button className="table-button" loading={isLoadingOptimizer} disabled={!selectedRowKeys.length ? true : false} onClick={() => this.manyOptimizer()} type='primary'>Xóa cache</Button>
                                </>
                            ) : null
                        } */}
                        <Divider type="vertical" />
                        <Button className="table-button" loading={isLoadingExport} type='default' onClick={() => this.do_export()} icon={<ExportOutlined />}>Export excel</Button>

                        {
                            loadingUpdateApi ? (
                                <>
                                    <Divider type="vertical" />
                                    <LoadingOutlined />
                                </>
                            ) : null
                        }
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={stores}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <StoreForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stores: state.StoreReducer.stores,
        pagination: state.StoreReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllStore: (filter) => dispatch(getAllStore(filter)),
        getStore: (id) => dispatch(getStore(id)),
        removeStore: ids => dispatch(removeStore(ids)),
        optimizerStore: (ids) => dispatch(optimizerStore(ids)),
        updateApiStore: (id) => dispatch(updateApiStore(id)),
        exportStore: (filter) => dispatch(exportStore(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreList);

import api from '../../utils/api';
import {
    GET_LIST_ALL_FILE_PNG,
    GET_ONE_FILE_PNG,
    CREATE_FILE_PNG,
    UPDATE_FILE_PNG,
    REMOVE_FILE_PNG
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAll = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/file-png', { params: filter }).then(res => {
            //console.log(res)
            dispatch({
                type: GET_LIST_ALL_FILE_PNG,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneFilePng = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/file-png/${id}`).then(res => {
            dispatch({
                type: GET_ONE_FILE_PNG,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createFilePng = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/file-png/create', data).then(res => {
            dispatch({
                type: CREATE_FILE_PNG,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateFilePng = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/file-png/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_FILE_PNG,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeFilePng = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/file-png', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_FILE_PNG,
                payload: ids
            });
            //console.log(res)
            if (res.data.data == 0) {
                NotificationManager.error(res.data.msg);
            } else {
                NotificationManager.success(res.data.msg);
            }
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import { Input, Button, Divider, Card, Form, Tabs } from 'antd';
import { connect } from 'react-redux';
import BaseRadioList from '../../components/Elements/BaseRadios';
import {
    MailOutlined,
    AmazonOutlined
} from '@ant-design/icons';
// actions
import { setConfig } from '../../redux/actions/ConfigActions';
import { NotificationManager } from 'react-notifications';
import { Typography } from 'antd';
const { Title } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/config',
        breadcrumbName: 'Cài đặt trong hệ thống',
    },
]

class Config extends Component {
    formRef = React.createRef();

    state = {
        config: {
            roles: []
        },
        loading: false
    }

    async submit(values) {
        var updatedValues = { ...this.props.config, ...values };
        delete updatedValues.roles;
        delete updatedValues.khachhang;
        delete updatedValues.users;
        delete updatedValues.users_design;
        delete updatedValues.users_idea;
        delete updatedValues.admins;
        delete updatedValues.amazonS3;

        this.setState({ loading: true });
        await this.props.setConfig(updatedValues);
        this.setState({ loading: false });
    }

    render() {
        var { loading } = this.state;
        var { user, config } = this.props;

        if (user.role_code != "ADMIN") {
            return (
                <div className="text-center mt-4">
                    <Title>Permission access is denied</Title>
                </div>
            )
        }

        const formItemLayout = {
            labelAlign: 'left',
            labelCol: {
                md: { span: 24 },
                lg: { span: 8 },
            },
            wrapperCol: {
                md: { span: 24 },
                lg: { span: 16 },
            },
        };

        return (
            <div>
                <PageTitle routes={routes} title="Cài đặt" />
                <Card size='small' className='mt-3'>
                    <Form
                        {...formItemLayout}
                        onFinish={(values) => this.submit(values)}
                        onFinishFailed={(e) => { NotificationManager.error('Vui lòng điền đầy đủ các trường bắt buộc ở tất cả các tab') }}
                        initialValues={{
                            ...config,
                            mail_smtp_secure: config.mail_smtp_secure ? config.mail_smtp_secure : 'ssl',
                        }}
                        ref={this.formRef}
                    >
                        <Tabs>
                            <Tabs.TabPane key="mail" tab={<span><MailOutlined style={{ fontSize: '18px' }} />Cài đặt Email hệ thống</span>}>
                                <Divider orientation="left">Email gửi thông báo</Divider>
                                <Form.Item label="SMTP Host" name="mail_smtp_host" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Kiểu mã hoá (Encryption)" name="mail_smtp_secure" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <BaseRadioList
                                        options={[
                                            { label: 'SSL', value: 'ssl' },
                                            { label: 'TLS', value: 'tls' }
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label="Xác thực (Authentication)" name="mail_smtp_authentication" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <BaseRadioList
                                        options={[
                                            { label: 'Có', value: true },
                                            { label: 'Không', value: false }
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label="SMTP Port" name="mail_smtp_port" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Email (dùng để gửi mail trong hệ thống)" name="support_email" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Mật khẩu email" name="password_support_email" rules={[{ required: false, message: "Bắt buộc" }]}>
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="s3" tab={<span><AmazonOutlined style={{ fontSize: '18px' }} />Cài đặt Amazon S3</span>}>
                                {/* <Form.Item label="Bucket Name" name="bucketName" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input />
                                </Form.Item> */}
                                <Form.Item label="Region" name="region" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Access Key Id" name="accessKeyId" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                                <Form.Item label="Secret Access Key" name="secretAccessKey" rules={[{ required: true, message: "Bắt buộc" }]}>
                                    <Input.Password autoComplete="new-password" />
                                </Form.Item>
                            </Tabs.TabPane>
                        </Tabs>

                        <div className="text-right">
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Cập nhật
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: state.config,
        user: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setConfig: (data) => dispatch(setConfig(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Config);
import api from '../../utils/api';
import {
    GET_LIST_ALL_DESIGN,
    GET_ONE_DESIGN,
    CREATE_DESIGN,
    UPDATE_DESIGN,
    REMOVE_DESIGN,
    UPDATE_OTHER_DESIGN,
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAll = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/design', { params: filter }).then(res => {
            //console.log(res)
            dispatch({
                type: GET_LIST_ALL_DESIGN,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneDesign = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/design/${id}`).then(res => {
            dispatch({
                type: GET_ONE_DESIGN,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createDesign = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/design', data).then(res => {
            dispatch({
                type: CREATE_DESIGN,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createOtherDesign = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/design/create-other', data).then(res => {
            dispatch({
                type: CREATE_DESIGN,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateDesign = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/design/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_DESIGN,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateOtherDesign = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/design/update/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_OTHER_DESIGN,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const approved = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/design/approved/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_DESIGN,
                payload: res.data.data
            });
            //NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const approvedMany = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/design/approved/many`, data).then(res => {
            dispatch({
                type: UPDATE_DESIGN,
                payload: res.data.data
            });
            //NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeDesign = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/design', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_DESIGN,
                payload: ids
            });
            //console.log(res)
            if (res.data.data == 0) {
                NotificationManager.error(res.data.msg);
            } else {
                NotificationManager.success(res.data.msg);
            }
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
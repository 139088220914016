import api from '../../utils/api';
import {
    GET_ALL_LICENSE,
    UPDATE_SPECIFIC_LICENSE,
    REMOVE_LICENSE,
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllLicense = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/tool-license', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_LICENSE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateLicense = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/tool-license/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_LICENSE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeLicense = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/tool-license`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_LICENSE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
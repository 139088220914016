import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone, EyeOutlined, } from '@ant-design/icons';
import { Table, Modal, Divider, Tag, Tooltip, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import PaymentGatewaysForm from './PaymentGatewaysForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';

// actions
import { getAllPaymentGateways, getPaymentGateways, removePaymentGateways } from '../../redux/actions/PaymentGatewaysActions';
import { getStoreByField } from '../../redux/actions/StoreActions';

const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/payment-gateways',
        breadcrumbName: 'Cài đặt cổng thanh toán',
    },
]

class PaymentGatewaysList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllPaymentGateways(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStoreByField({ platform: ['pod', 'dropship'] })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllPaymentGateways(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getPaymentGateways(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllPaymentGateways(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onRemove() {
        this.props.removePaymentGateways(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            //this.reloadData()
        })
    }

    render() {
        var { payment_gateways_list, pagination, stores } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData } = this.state;
        //console.log(payment_gateways_list)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '30px',
            },
            {
                title: '#',
                align: 'center',
                key: 'actions',
                width: '20px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('payment_gateways', 'update') ? (
                                <>
                                    <Tooltip title="Xem chi tiết">
                                        <EyeOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    </Tooltip>
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: '240px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Shield',
                dataIndex: 'shield_url',
                key: 'shield_url',
                width: '250px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div className='ecom-paragraph'>
                                <Paragraph copyable={{ tooltips: false }}>{record.shield_url}</Paragraph>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Total amount',
                width: '120px',
                dataIndex: 'total_amount',
                key: 'total_amount',
                render: (text, record) => {
                    if (text) {
                        return (
                            <>{text}$</>
                        )
                    }
                }
            },
            {
                title: 'Store',
                width: '200px',
                dataIndex: 'listStore',
                key: 'listStore',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '150px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Tình trạng',
                dataIndex: 'account_status',
                key: 'account_status',
                width: '100px',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    if (text == 'normal') return <Tag color='#52c41a'>Normal</Tag>;
                    if (text == 'hold_21day') return <Tag color='#FFBF00'>21 day</Tag>;
                    if (text == 'hold_180day') return <Tag color='#e74c3c'>180 day</Tag>;
                    if (text == 'other') return <Tag color='#722F37'>Other</Tag>;
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            {record.created_user}
                        </>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách tài khoản nhận thanh toán" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('payment_gateways', 'create')}
                        isShowDeleteButton={checkPermission('payment_gateways', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            options={stores || []}
                            optionValue="id"
                            optionLabel="name"
                            showSearch
                            defaultText="Store"
                            placeholder="Store"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('store_id', value)}
                            defaultValue={query.store_id ? parseInt(query.store_id) : ''}
                            className="table-button"
                        />
                        <Divider type="vertical" />
                        <BaseSelect
                            options={[
                                { label: "Normal", value: 'normal' },
                                { label: "Hold 21 day", value: 'hold_21day' },
                                { label: "Hold 180 day", value: 'hold_180day' },
                                { label: "Other", value: 'other' }
                            ]}
                            onChange={(value) => this.onChangeFilter("account_status", value)}
                            defaultText="Tình trạng"
                            placeholder="Tình trạng"
                            defaultValue={parseInt(query.account_status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />
                        <Divider type="vertical" />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã kích hoạt' },
                                { value: 0, label: 'Chưa kích hoạt' }
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            defaultText="Trạng thái"
                            placeholder="Trạng thái"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />

                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={payment_gateways_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <PaymentGatewaysForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        payment_gateways_list: state.PaymentGatewaysReducer.payment_gateways_list,
        pagination: state.PaymentGatewaysReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser,
        stores: state.StoreReducer.store_by_field_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPaymentGateways: (filter) => dispatch(getAllPaymentGateways(filter)),
        getPaymentGateways: (id) => dispatch(getPaymentGateways(id)),
        removePaymentGateways: ids => dispatch(removePaymentGateways(ids)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGatewaysList);

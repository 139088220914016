import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Modal, Tooltip } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import PrintingUnitForm from './PrintingUnitForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';

import moment from 'moment';
// actions
import { getAllPrintingUnit, getPrintingUnit, removePrintingUnit } from '../../redux/actions/PrintingUnitActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/store',
        breadcrumbName: 'Danh sách nhà cung cấp',
    },
]

class PrintingUnitList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllPrintingUnit(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllPrintingUnit(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/printing-unit',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/printing-unit',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getPrintingUnit(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllPrintingUnit(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onRemove() {
        this.props.removePrintingUnit(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    render() {
        var { printing_unit, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('printing_unit', 'update') ? (
                                <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                width: '200px',
            },
            {
                title: 'Url',
                dataIndex: 'url',
                key: 'url',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <a href={text} target='_blank'>
                            <Tooltip title={ReactHtmlParser(text)}>
                                <TextTruncate line={4} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </a>
                    </div>
                )
            },

            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Ngày thêm',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '150px',
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Nhà cung cấp" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('printing_unit', 'create')}
                        //isShowDeleteButton={checkPermission('printing_unit', 'remove')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={printing_unit}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <PrintingUnitForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        printing_unit: state.PrintingUnitReducer.printing_unit,
        pagination: state.PrintingUnitReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPrintingUnit: (filter) => dispatch(getAllPrintingUnit(filter)),
        getPrintingUnit: (id) => dispatch(getPrintingUnit(id)),
        removePrintingUnit: (ids) => dispatch(removePrintingUnit(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintingUnitList);

import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import { Form } from '@ant-design/compatible';
import { Input, Button, Card, Tabs } from 'antd';
import { connect } from 'react-redux';
import { ContainerOutlined } from '@ant-design/icons';
// actions
import { getTrademarkKeyword, updateTrademarkKeyword } from '../../redux/actions/TrademarkKeywordActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/trademark-keyword',
        breadcrumbName: 'Update trademark keyword',
    },
]

class TrademarkKeyword extends Component {

    state = {
        loading: false
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.getTrademarkKeyword().then(() => {
            this.setState({ isLoading: false });
        })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ loading: true });
                await this.props.updateTrademarkKeyword(values);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        var { loading } = this.state;
        var { trademark_keyword } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageTitle routes={routes} title="Update trademark keyword" />
                <Card className='mt-2'>
                    <Form>
                        <Tabs>
                            <Tabs.TabPane key="data" tab={<span><ContainerOutlined style={{ fontSize: '18px' }} />Keywords</span>}>
                                <div className='mb-3' style={{ fontStyle: 'italic' }}>
                                    Dùng cho tool import sản phẩm (Mỗi từ khóa cách nhau bởi dấu phẩy. Cấu trúc từ khóa dạng url, ví dụ: mama-saurus, mamasaurus, sleep-token)
                                </div>
                                <Form.Item>
                                    {getFieldDecorator('contents', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ],
                                        initialValue: trademark_keyword ? trademark_keyword.contents : ""
                                    })(<Input.TextArea rows={20} />)}
                                </Form.Item>
                            </Tabs.TabPane>
                        </Tabs>
                        <div className="text-right">
                            <Button onClick={(e) => this.submit(e)} type="primary" htmlType="submit" loading={loading}>
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        trademark_keyword: state.TrademarkKeywordReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTrademarkKeyword: () => dispatch(getTrademarkKeyword()),
        updateTrademarkKeyword: (data) => dispatch(updateTrademarkKeyword(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TrademarkKeyword' })(TrademarkKeyword));

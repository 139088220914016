import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, DatePicker, Divider, Button, Tag } from 'antd';
import StatisticNumber from '../../components/StatisticNumber';
import moment from 'moment';
import _ from 'lodash';
import { Pie as Pie_Chart } from '@ant-design/plots';
import BaseSelect from '../../components/Elements/BaseSelect';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar
} from 'recharts';
import { statisProduct, statisOrder, statisOrderByStore } from '../../redux/actions/StatisticalAction';
import { getStoreByField } from '../../redux/actions/StoreActions';

function handleOrdertData(month, year, order_successArr, cancelledArr, refundedArr, pendingArr, on_holdArr, failedArr, profitArr, salesArr, percentArr) {
    var endOfMonth = parseInt(moment([year, month - 1]).endOf('month').format('DD'));
    var days = _.times(endOfMonth + 1);
    days.splice(0, 1);

    var data = days.map(day => {
        let item0 = order_successArr.find(item => item.day == day);
        let item3 = cancelledArr.find(item => item.day == day);
        let item4 = refundedArr.find(item => item.day == day);
        let item5 = pendingArr.find(item => item.day == day);
        let item6 = on_holdArr.find(item => item.day == day);
        let item7 = failedArr.find(item => item.day == day);
        let item8 = profitArr.find(item => item.day == day);
        let item9 = salesArr.find(item => item.day == day);
        let item10 = percentArr.find(item => item.day == day);

        return {
            day: day,
            order_success: item0 ? item0.count : 0,
            cancelled: item3 ? item3.count : 0,
            refunded: item4 ? item4.count : 0,
            pending: item5 ? item5.count : 0,
            on_hold: item6 ? item6.count : 0,
            failed: item7 ? item7.count : 0,
            profit: item8 ? item8.count : 0,
            sales: item9 ? item9.sales : 0,
            percent: item10 ? item10.percent : 0
        }
    });

    return _.sortBy(data, 'day');
}

function handleData(month, year, metrics) {
    var endOfMonth = parseInt(moment([year, month - 1]).endOf('month').format('DD'));
    var days = _.times(endOfMonth + 1);
    days.splice(0, 1);

    var data = days.map(day => {
        let items = metrics.map(itemKey => {
            if (Array.isArray(itemKey.data)) {
                return {
                    name: itemKey.name,
                    metrics: itemKey.data.find(item => item.post_day == day) == undefined ? [] : itemKey.data.find(item => item.post_day == day)
                }
            } else {
                return {
                    name: itemKey.name,
                    metrics: []
                }
            }
        })
        const extractedKeys = {}
        items.forEach(o => extractedKeys[o?.name] = o?.metrics.count == undefined ? 0 : parseInt(o?.metrics.count));

        return {
            day: day,
            ...extractedKeys
        }
    });

    return _.sortBy(data, 'day');
}

class Home extends Component {
    state = {
        isLoading: false,
        isLoadingProduct: false,
        month: moment().format('M'),
        year: moment().format('Y'),
    }

    async componentDidMount() {
        this.setState({ isLoading: true, isLoadingProduct: true })
        let month = this.state.month;
        let year = this.state.year;
        this.props.getStoreByField({ product_statistics: 'yes' });

        await this.props.statisOrder({ month: month, year: year, status: 'order_success' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'cancelled' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'refunded' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'pending' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'on-hold' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'failed' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrder({ month: month, year: year, status: 'profit' }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisOrderByStore({ month: month, year: year }).then(res => {
            this.setState({ isLoading: false })
        });
        await this.props.statisProduct({ month: month, year: year }).then(res => {
            this.setState({ isLoadingProduct: false })
        });
    }

    changeMonth(date) {
        this.setState({
            month: date.format('M'),
            year: date.format('Y')
        }, () => {
            let month = this.state.month;
            let year = this.state.year;
            this.props.statisOrder({ month: month, year: year, status: 'order_success' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'cancelled' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'refunded' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'pending' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'on-hold' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'failed' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrder({ month: month, year: year, status: 'profit' }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisOrderByStore({ month: month, year: year }).then(res => {
                this.setState({ isLoading: false })
            });
            this.props.statisProduct({ month: month, year: year }).then(res => {
                this.setState({ isLoadingProduct: false })
            });
        })
    }

    reloadData() {
        this.setState({
            isLoading: true,
            isLoadingProduct: true
        });
        let month = this.state.month;
        let year = this.state.year;
        this.props.statisOrder({ month: month, year: year, status: 'order_success' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'cancelled' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'refunded' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'pending' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'on-hold' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'failed' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrder({ month: month, year: year, status: 'profit' }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisOrderByStore({ month: month, year: year }).then(res => {
            this.setState({ isLoading: false })
        });
        this.props.statisProduct({ month: month, year: year }).then(res => {
            this.setState({ isLoadingProduct: false })
        });
    }

    async onChangeStaff(staff_id) {
        this.setState({ isLoadingProduct: true })
        let month = this.state.month;
        let year = this.state.year;
        this.props.getStoreByField({ product_statistics: 'yes', staff_id: staff_id });
        await this.props.statisProduct({ month: month, year: year, staff_id: staff_id }).then(res => {
            this.setState({ isLoadingProduct: false })
        });
    }

    render() {
        const { isLoading, isLoadingProduct, month, year } = this.state;
        const { authUser, users, statis_product, statis_order, store_by_field_list, statis_order_by_store, store_by_order, store_by_order_total } = this.props;

        var orderData = handleOrdertData(
            month, year,
            statis_order.order_success,
            statis_order.cancelled,
            statis_order.refunded,
            statis_order.pending,
            statis_order.on_hold,
            statis_order.failed,
            statis_order.profit,
            statis_order.profit,
            statis_order.profit
        );
        var productData = handleData(month, year, statis_product);
        var orderByStoreData = handleData(month, year, statis_order_by_store);

        const config = {
            appendPadding: 0,
            data: store_by_order_total,
            angleField: 'count',
            colorField: 'name',
            radius: 0.8,
            legend: false,
            tooltip: true,
            label: {
                type: 'outer',
                content: '{name}\n({value})',
            },
            interactions: [
                {
                    type: 'element-active',
                },
            ],
        };

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length) {
                //console.log("payload: ", payload)
                return (
                    <div className="custom-tooltip-chart">
                        <p className="label">{label}</p>
                        <p className="desc">
                            {
                                payload.map((site) => {
                                    return (
                                        //<Tag color={site.color}>{site.name} : {site.value}</Tag>
                                        <Tag>{site.name} : {site.value}</Tag>
                                    )
                                })
                            }
                        </p>
                    </div>
                );
            }

            return null;
        };

        return (
            <div className='mt-3'>
                <div style={{ marginBottom: '10px' }}>
                    <div>
                        <DatePicker.MonthPicker allowClear={false} style={{ width: '200px' }} format="MM/YYYY" placeholder="Chọn tháng" onChange={(date) => this.changeMonth(date)} />
                        <Divider type="vertical" />
                        <Button loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </div>
                </div>
                {
                    authUser.role_code == "ADMIN" ? (
                        <Card
                            className="mb-4"
                            size='small'
                            title={`Đơn hàng tháng ${month + '/' + year}`}
                            loading={isLoading}
                        >
                            <Row gutter={{ xs: 8, sm: 16, md: 16 }}>
                                <Col md={24} sm={24} className="mb-2">
                                    <Row gutter={16} className="mb-2" justify="space-between" style={{ margin: 0 }}>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title="Order success"
                                                value={statis_order ? statis_order.order_success_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title="Cancelled"
                                                value={statis_order ? statis_order.cancelled_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title="Refunded"
                                                value={statis_order ? statis_order.refunded_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title={`Pending`}
                                                value={statis_order ? statis_order.pending_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title={`On hold`}
                                                value={statis_order ? statis_order.on_hold_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title={`Failed`}
                                                value={statis_order ? statis_order.failed_total : 0}
                                                precision={0}
                                                unit=""
                                            />
                                        </Card>
                                        <Card className="product-statistic-card-dashboard">
                                            <StatisticNumber
                                                title="Profit"
                                                value={statis_order ? statis_order.total_profit : 0}
                                                precision={2}
                                                unit="$"
                                            />
                                        </Card>
                                    </Row>
                                    <ResponsiveContainer width="100%" height={250}>
                                        <LineChart
                                            width={700}
                                            height={300}
                                            data={orderData}
                                            margin={{
                                                top: 15, right: 5, left: 0, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="2 2" />
                                            <XAxis dataKey="day" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="order_success" name="Order success" stroke="green" strokeWidth={2} />
                                            <Line type="monotone" dataKey="cancelled" name="Cancelled" stroke="#660000" strokeWidth={1} />
                                            <Line type="monotone" dataKey="refunded" name="Refunded" stroke="#003a8c" strokeWidth={1} />
                                            <Line type="monotone" dataKey="pending" name="Pending" stroke="#ffd480" strokeWidth={1} />
                                            <Line type="monotone" dataKey="on_hold" name="On hold" stroke="#ad8b00" strokeWidth={1} />
                                            <Line type="monotone" dataKey="failed" name="Failed" stroke="#f00" strokeWidth={1} />
                                            {/* <Line type="monotone" dataKey="profit" name="Profit" stroke="#52c41a" unit="$" strokeWidth={2} /> */}
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <Divider orientation="left" orientationMargin={0} style={{ fontSize: '14px' }}>
                                        Báo cáo doanh thu
                                    </Divider>
                                    <ResponsiveContainer width="100%" height={150}>
                                        <LineChart
                                            width={700}
                                            height={150}
                                            data={orderData}
                                            margin={{
                                                top: 5, right: 5, left: 0, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="2 2" />
                                            <XAxis dataKey="day" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="sales" name="Doanh số" stroke="#108ee9" unit="$" strokeWidth={1} />
                                            <Line type="monotone" dataKey="profit" name="Lợi nhuận" stroke="#52c41a" unit="$" strokeWidth={1} />
                                            <Line type="monotone" dataKey="percent" name="Tỷ lệ" stroke="#17a2b8" unit="%" strokeWidth={1} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                    <Divider orientation="left" orientationMargin={0} style={{ fontSize: '14px' }}>
                                        Đơn hàng theo store: {month + '/' + year}
                                    </Divider>
                                    <Row gutter={{ xs: 8, sm: 16, md: 16 }} justify="center">
                                        <Col md={14} sm={24}>
                                            <ResponsiveContainer width="100%" height={250}>
                                                <LineChart
                                                    width={700}
                                                    height={250}
                                                    data={orderByStoreData}
                                                    margin={{
                                                        top: 15, right: 5, left: 0, bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="2 2" />
                                                    <XAxis dataKey="day" />
                                                    <YAxis />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                    {store_by_order.map((s) => (
                                                        <Line type="monotone" dataKey={s.name} strokeWidth={1} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                                                    ))}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </Col>
                                        <Col md={10} sm={24}>
                                            <Pie_Chart height={250} {...config} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    ) : null
                }

                <Card
                    className="mb-4"
                    title={<>{`Sản phẩm tháng ${month + '/' + year}`} <i style={{ fontWeight: 'normal' }}>(Theo giờ UTC+0)</i></>}
                    size='small'
                    loading={isLoadingProduct}
                    extra={<>
                        {
                            authUser.role_code == "ADMIN" ? (
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    defaultText="Nhận viên quản lý"
                                    placeholder="Nhận viên quản lý"
                                    style={{ width: '200px' }}
                                    onChange={(value) => this.onChangeStaff(value)}
                                />
                            ) : null
                        }
                    </>}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 16 }}>
                        <Col md={24} sm={24} className="mb-2">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={700}
                                    height={300}
                                    data={productData}
                                    margin={{
                                        top: 15, right: 5, left: 0, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="2 2" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend />
                                    {store_by_field_list.map((s) => (
                                        <Line type="monotone" dataKey={s.name} strokeWidth={1} stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </div >
        )
    }

}

function mapStateToProps(state) {
    //console.log(state)
    return {
        statis_product: state.StatisticalReducer.statis_product,
        statis_order: state.StatisticalReducer.statis_order,
        statis_order_by_store: state.StatisticalReducer.statis_order_by_store.statis,
        store_by_order: state.StatisticalReducer.statis_order_by_store.store,
        store_by_order_total: state.StatisticalReducer.statis_order_by_store.total,
        authUser: state.auth.authUser,
        store_by_field_list: state.StoreReducer.store_by_field_list,
        users: state.config.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        statisProduct: (filter) => dispatch(statisProduct(filter)),
        statisOrder: (filter) => dispatch(statisOrder(filter)),
        statisOrderByStore: (filter) => dispatch(statisOrderByStore(filter)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
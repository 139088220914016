import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Modal, Divider, Switch, Drawer, Tag } from 'antd';
import TableActionBar from '../../components/TableActionBar';
import { isMobile } from 'react-device-detect';
import ProductsDescriptionContentForm from './ProductsDescriptionContentForm';
import { cleanObject, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';

import moment from 'moment';
//actions
import {
    getAllProductsDescriptionContent,
    getProductsDescriptionContent,
    updateProductsDescriptionContent,
    removeProductsDescriptionContent
} from '../../redux/actions/ProductsDescriptionContentActions';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';

const { confirm } = Modal;

class ProductsDescriptionContentList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null,
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getAllProductsDescriptionContent().then(res => {
            this.setState({ isLoading: false })
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getProductsDescriptionContent(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeProductsDescriptionContent([id]).then(() => {
                this.reloadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    reloadData() {
        this.setState({ isLoading: true })
        this.props.getAllProductsDescriptionContent().then(res => {
            this.setState({ isLoading: false })
        });
    }

    onChangeFilter(name, value) {
        var query;
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.setState({ isLoading: true })
        this.props.getAllProductsDescriptionContent(query).then(res => {
            this.setState({ isLoading: false })
        });
    }

    render() {
        var { content, producttype, visible } = this.props;
        var { isLoading, isOpenForm, currentData } = this.state;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: '#',
                align: 'center',
                width: '80px',
                render: (text, record) => (
                    <>
                        <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id, record.store_id)} />
                        <Divider type='vertical' />
                        <DeleteOutlined className='item-action-btn-remove item-action-btn' onClick={() => this.openAlert(record.id)} />
                    </>
                )
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                key: 'content',
                width: '300px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </div>
                    )
                }
            },
            {
                title: 'Loại sản phẩm',
                width: '120px',
                dataIndex: 'product_type_name',
                key: 'product_type_name'
            },
            {
                title: 'Loại nội dung',
                width: '100px',
                dataIndex: 'type',
                key: 'type',
                align: 'center',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                render: (text, record) => (
                    <div>
                        <Switch
                            defaultChecked={record.status}
                            checkedChildren="Active"
                            unCheckedChildren="InActive"
                            onChange={(value) => this.props.updateProductsDescriptionContent(record.id, { status: value, action: 'status' })}
                        />
                    </div>
                )
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>
                            {moment(record.created_at).format('DD/MM/YYYY HH:m:s')}
                        </div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                render: (text, record) => {
                    return (
                        <div>
                            {record.created_user}
                        </div>
                    )
                }
            }
        ];

        return (
            <Drawer
                visible={visible}
                title="Nội dung mặc định"
                onClose={() => this.props.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '70%'}
                footer={false}
                push={0}
            >
                {visible ?
                    <div>
                        <div className='mb-4'>
                            <TableActionBar
                                isShowAddButton={true}
                                isShowDeleteButton={false}
                                onAdd={() => this.toggleOpenForm(true)}
                                showSearch={false}
                                showFilter={false}
                            >
                                <BaseSelect
                                    options={producttype}
                                    optionValue="id"
                                    optionLabel="name"
                                    defaultText="Chọn loại sản phẩm"
                                    showSearch={false}
                                    style={{ width: '150px' }}
                                    className="table-button"
                                    onChange={(value) => this.onChangeFilter("product_type_id", value)}
                                />
                                <Divider type="vertical" />
                                <BaseSelect
                                    style={{ width: '150px' }}
                                    options={[
                                        { label: "Mô tả sản phẩm", value: 'product_des' },
                                        { label: "Content trust site", value: 'trust_site' }
                                    ]}
                                    onChange={(value) => this.onChangeFilter("type", value)}
                                    defaultText="Loại nội dung"
                                    className="table-button"
                                />
                                <Divider type="vertical" />
                                <BaseSelect
                                    options={[
                                        { value: 1, label: 'Active' },
                                        { value: 0, label: 'InActive' }
                                    ]}
                                    onChange={(value) => this.onChangeFilter("status", value)}
                                    defaultText="Trạng thái"
                                    style={{ width: '150px' }}
                                    className="table-button"
                                />
                            </TableActionBar>
                        </div>
                        <Table
                            rowKey="id"
                            size="small"
                            dataSource={content}
                            columns={columns}
                            loading={isLoading}
                            pagination={{
                                showTotal: total => `Tổng ${total} bản ghi`,
                                defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100']
                            }}
                            scroll={{
                                x: 'max-content'
                            }}
                        />
                        <ProductsDescriptionContentForm
                            visible={isOpenForm}
                            currentData={currentData}
                            reloadData={() => this.reloadData()}
                            onCancel={() => this.toggleOpenForm(false)}
                        />
                    </div>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        content: state.ProductsDescriptionContentReducer.content,
        pagination: state.ProductsDescriptionContentReducer.pagination,
        producttype: state.ProductType.producttype
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProductsDescriptionContent: (filter) => dispatch(getAllProductsDescriptionContent(filter)),
        getProductsDescriptionContent: (id) => dispatch(getProductsDescriptionContent(id)),
        removeProductsDescriptionContent: (ids) => dispatch(removeProductsDescriptionContent(ids)),
        updateProductsDescriptionContent: (id, data) => dispatch(updateProductsDescriptionContent(id, data)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDescriptionContentList);

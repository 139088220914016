import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Drawer, Button, Input, Col, Row, DatePicker, Divider, Image, Space } from "antd";
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "save-as";
// actions
import { createOtherDesign } from '../../redux/actions/DesignAction';
import { updateOrderManagement } from '../../redux/actions/OrdersAction';
import { DownloadOutlined } from "@ant-design/icons";
import { _priority, _request_type } from "../../utils/helpers";

class DesignAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            image: [],
            loadingDownload: false
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                const { currentProduct, currentOrder } = this.props;
                try {
                    const images_demo2 = [];
                    if (currentProduct && currentProduct?.images != null) {
                        currentProduct.images.map((e) => {
                            images_demo2.push(e.src);
                        })
                    }
                    const params = {
                        name: values.name,
                        images_demo: this.state.image,
                        images_demo2: images_demo2,
                        user_design_id: values.user_design_id,
                        description: values.description,
                        request_des_for_design: values.request_des_for_design,
                        deadline: values.deadline ? values.deadline.format("YYYY-MM-DD") : null,
                        request_type: values.request_type,
                        additional_amount: values.additional_amount,
                        option: 'in_order',
                        store_id: currentOrder.store_id,
                        product_id: currentProduct.product_id,
                        order_id: currentOrder.ID,
                        priority: values.priority,
                        order_management_id: this.props.currentData.id
                    }
                    //console.log(params)
                    await this.props.createOtherDesign(params);
                    this.props.reloadData();
                    this.props.reloadData2();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Image complete.");
    }

    onClose() {
        this.setState({
            isLoading: false,
            image: []
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    downloadAllPrintFile() {
        const { currentProduct } = this.props;
        var zip = new JSZip();
        //console.log(zip);
        var count = 0;
        var zipFilename = `print_files_${(new Date().toJSON().slice(0, 10))}_${new Date().toLocaleTimeString()}.zip`;
        if (currentProduct.design.list_design && currentProduct.design.list_design.length && Array.isArray(currentProduct.design.list_design)) {
            this.setState({ loadingDownload: true })
            const self = this;
            currentProduct.design.list_design.forEach(function (url) {
                var filename = new URL(url).pathname.split('/').pop();
                // loading a file and add it in a zip file
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    if (count === currentProduct.design.list_design.length) {
                        zip.generateAsync({ type: "blob" }).then(function (content) {
                            saveAs(content, zipFilename);
                            self.setState({ loadingDownload: false })
                        });
                    }
                });
            });
        }
    }

    render() {
        const { visible, users_design, currentOrder, currentProduct, store_url, productColor, productPrintOnThe, personalizedText } = this.props;
        const { isLoading, isResetUpload, loadingDownload } = this.state;
        const { getFieldDecorator } = this.props.form;
        //console.log("currentOrder: ", currentOrder)

        var pColor = productColor ? `In trên phôi màu: ${productColor?.meta_value}\n` : '';
        var pPrintOnThe = productPrintOnThe ? `Vị trí in: ${productPrintOnThe}\n` : '';
        var personalized = personalizedText ? `Personalized Text: ${personalizedText?.meta_value}` : '';

        return (
            <Drawer
                visible={visible}
                title="Gửi yêu cầu thiết kế"
                width={isMobile ? '100%' : '50%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tên sản phẩm">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentProduct ? currentProduct.name : null
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Nhân viên thiết kế">
                                    {getFieldDecorator("user_design_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={users_design || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Nhân viên thiết kế"
                                            showSearch
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Deadline">
                                    {getFieldDecorator("deadline", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Yêu cầu">
                                    {getFieldDecorator("request_type", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: 'clone'
                                    })(
                                        <BaseRadios
                                            options={_request_type}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Độ ưu tiên">
                                    {getFieldDecorator('priority', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: 'binhthuong'
                                    })(
                                        <BaseRadios options={_priority} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ảnh mẫu (tối đa 10 ảnh)">
                            <Row gutter={16}>
                                {
                                    currentProduct && currentProduct.images ? (
                                        <Col xs={24} md={12}>
                                            <Image.PreviewGroup>
                                                <Space wrap>
                                                    {
                                                        currentProduct.images.map(item => {
                                                            return (
                                                                <Image
                                                                    height={50}
                                                                    src={item?.src}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Col>
                                    ) : null
                                }
                                <Col xs={24} md={12}>
                                    {getFieldDecorator('images_demo', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChange}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label={<b>File in (PNG)</b>}>
                            {
                                currentProduct.design && currentProduct.design.status == 1 && currentProduct.design.list_design_small && currentProduct.design.list_design_small.length && Array.isArray(currentProduct.design.list_design_small) ? (
                                    <>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentProduct.design.list_design_small.map(item => {
                                                        return (
                                                            <Image
                                                                width={50}
                                                                src={item}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                        <div className="mt-2">&nbsp;</div>
                                        <Button icon={<DownloadOutlined />} loading={loadingDownload} onClick={() => this.downloadAllPrintFile()} type='primary'>Tải xuống file in</Button>
                                    </>
                                ) : null
                            }
                            {
                                currentProduct.design ? (
                                    <>
                                        <Divider type="vertical" />
                                        <a href={`/design?id=${currentProduct.design?.id}`} target="_blank">Xem chi tiết design</a>
                                    </>
                                ) : null
                            }

                        </Form.Item>
                        <Form.Item label="Mô tả yêu cầu cho thiết kế">
                            {getFieldDecorator('request_des_for_design', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentProduct ? store_url + '/product/' + currentProduct.slug : null
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: pColor + pPrintOnThe + personalized
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
        users_design: state.config.users_design,
        users: state.config.users
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createOtherDesign: (data) => dispatch(createOtherDesign(data)),
        updateOrderManagement: (id, data) => dispatch(updateOrderManagement(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DesignAddForm" })(DesignAddForm));

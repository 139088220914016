import api from '../../utils/api';
import {
    GET_ALL_NICHE,
    GET_SPECIFIC_NICHE,
    UPDATE_SPECIFIC_NICHE,
    CREATE_NEW_NICHE,
    REMOVE_NICHE,
    GET_NICHE_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllNiche = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/niche', {params: filter}).then(res => {
            dispatch({type: GET_ALL_NICHE, payload: res.data.data});
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getNicheByActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/niche/niche-by-active', {params: filter}).then(res => {
            //console.log(res)
            dispatch({type: GET_NICHE_ACTIVE, payload: res.data});
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createNiche = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/niche', data).then(res => {
            dispatch({type: CREATE_NEW_NICHE, payload: res.data.data});
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getNiche = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/niche/${id}`).then(res => {
            dispatch({type: GET_SPECIFIC_NICHE, payload: res.data.data});
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateNiche = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/niche/${id}`, data).then(res => {
            dispatch({type: UPDATE_SPECIFIC_NICHE, payload: res.data.data});
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeNiche = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/niche`, {data: {ids: ids}}).then(res => {
            dispatch({type: REMOVE_NICHE, payload: ids});
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

import React, { Component } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Image } from 'antd';

class ShowListCurrentImage extends Component {

    render() {
        const { currentImages } = this.props;

        var arr = [];
        if (currentImages && Array.isArray(currentImages)) {
            currentImages.map(item => {
                arr.push(
                    <div className="list-img-thumbnail">
                        <Image
                            height={'100%'}
                            src={item?.src}
                        />
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.props.onDelete(item?.id)}
                        />
                    </div>
                )
            })
        }
        return (
            <>
                {arr}
            </>
        );
    }
}

export default ShowListCurrentImage;
import {
    LIST_ORDERS,
    GET_ONE_ORDER,
    GET_ONE_ORDER_MANAGEMENT,
    GET_STATISTICAL_ORDERS
} from '../actions/types';

const INIT_STATE = {
    list_orders: [],
    currentOrder: [],
    statisticalOrder: null,
    currentOrderManagement: null,
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 50
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_ORDERS:
            return {
                ...state,
                list_orders: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_ONE_ORDER:
            return {
                ...state,
                currentOrder: action.payload
            };
        case GET_STATISTICAL_ORDERS:
            return {
                ...state,
                statisticalOrder: action.payload
            };
        case GET_ONE_ORDER_MANAGEMENT:
            return {
                ...state,
                currentOrderManagement: action.payload
            };
        default:
            return { ...state }
    }
};

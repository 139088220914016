import api from '../../utils/api';
import {
    GET_ALL_REVENUE_EXPENDITURE,
    GET_SPECIFIC_REVENUE_EXPENDITURE,
    UPDATE_SPECIFIC_REVENUE_EXPENDITURE,
    CREATE_NEW_REVENUE_EXPENDITURE,
    REMOVE_REVENUE_EXPENDITURE,
    GET_STATISTICAL_REVENUE_EXPENDITURE,
    EXPORT_REVENUE_EXPENDITURE,
    UPDATE_REVENUE_EXPENDITURE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllData = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/revenue-expenditure', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_REVENUE_EXPENDITURE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/revenue-expenditure', data).then(res => {
            dispatch({ type: CREATE_NEW_REVENUE_EXPENDITURE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/revenue-expenditure/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_REVENUE_EXPENDITURE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateData = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/revenue-expenditure/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_REVENUE_EXPENDITURE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeData = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/revenue-expenditure`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_REVENUE_EXPENDITURE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/revenue-expenditure/statistical/total', { params: filter }).then(res => {
            //console.log(res.data.data)
            dispatch({
                type: GET_STATISTICAL_REVENUE_EXPENDITURE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const exportRevenueExpenditure = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/revenue-expenditure/export`, filter).then(res => {
            dispatch({ type: EXPORT_REVENUE_EXPENDITURE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}

export const manyUpdate = (ids, type) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/revenue-expenditure/many-update`, { ids: ids, type: type }).then(res => {
            dispatch({ type: UPDATE_REVENUE_EXPENDITURE, payload: ids });
            NotificationManager.success("Update ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
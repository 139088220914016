import React, { Component } from 'react';
import { Modal, Row, Col, Alert } from 'antd';
import { connect } from 'react-redux';
// actions
import { importTracking } from '../../redux/actions/OrdersAction';

class ImportTracking extends Component {

    state = {
        isLoadingFile: false,
        file: null,

    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoadingFile: true
        })
        const formData = new FormData();
        formData.append('file', this.state.file)
        this.props.importTracking(formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            //console.log("res: ", res)
            this.props.onSetMessage(res.data.msg, 'success')
            this.handleCancel();
        }).catch(err => {
            this.props.onSetMessage(err.response.data.msg, 'error')
            this.handleCancel();
        })
    }

    handleCancel = () => {
        this.setState({
            isLoadingFile: false,
            file: null
        });
        this.props.onCancel()
        this.fileInput.value = "";
    }

    onChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    render() {
        const { isLoadingFile } = this.state;
        const { visible } = this.props;
        return (
            <Modal
                title="Import file csv"
                width="40%"
                visible={visible}
                onOk={(e) => this.onSubmit(e)}
                confirmLoading={isLoadingFile}
                onCancel={this.handleCancel}
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Alert
                            message={
                                <>
                                    Chỉ áp dụng cho đơn vị in <a target='_blank' href='https://bestprintcare.com/'>https://bestprintcare.com</a>
                                </>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
                <Row gutter={16} className='mt-3'>
                    <Col xs={24} md={24}>
                        <form >
                            <input accept=".csv" type="file" onChange={this.onChange} ref={ref => this.fileInput = ref} />
                        </form>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        importTracking: (data, header) => dispatch(importTracking(data, header))
    }
}

export default connect(null, mapDispatchToProps)(ImportTracking);
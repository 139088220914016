import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, Select, Drawer, Table, Card } from 'antd';
import { SettingOutlined, HistoryOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { round } from 'lodash';
// actions
import { createPaymentGateways, updatePaymentGateways } from '../../redux/actions/PaymentGatewaysActions';
const { Option } = Select;

class PaymentGatewaysForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentData) {
                    await this.props.updatePaymentGateways(this.props.currentData.id, values);
                    this.props.reloadData()
                    this.onCancel()
                } else {
                    await this.props.createPaymentGateways(values);
                    this.props.reloadData()
                    this.onCancel()
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        //this.props.reloadData()
    }

    render() {
        const { isLoading } = this.state;
        var { visible, currentData, stores } = this.props;
        const { getFieldDecorator } = this.props.form;

        const columns = [
            {
                title: 'Store',
                width: '200px',
                dataIndex: 'store',
                key: 'store'
            },
            {
                title: 'Order count',
                width: '100px',
                dataIndex: 'order_count',
                key: 'order_count'
            },
            {
                title: 'Total amount',
                width: '120px',
                dataIndex: 'total_amount',
                key: 'total_amount',
                render: (text, record) => {
                    if (text) {
                        return (
                            <>{round(text, 2)}$</>
                        )
                    }
                }
            },
        ];

        return (
            <Drawer
                visible={visible}
                title={`${currentData ? currentData.shield_url : ''}`}
                onClose={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={false}
            >
                {visible ?
                    <div>
                        <Row gutter={16}>
                            {currentData ? (
                                <Col xs={24} md={24}>
                                    <Card title={<><HistoryOutlined /> Store history</>} size='small'>
                                        <Table
                                            size="small"
                                            dataSource={currentData ? currentData.list_store_history : []}
                                            columns={columns}
                                            loading={isLoading}
                                            pagination={false}
                                            scroll={{
                                                x: 'max-content'
                                            }}
                                        />
                                    </Card>
                                    <br />
                                </Col>
                            ) : null}

                            <Col xs={24} md={24}>
                                <Card title={<><SettingOutlined /> Setting</>} size='small'>
                                    <Form layout='vertical' autoComplete='off'>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Email">
                                                    {getFieldDecorator('email', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.email : null
                                                    })(<Input allowClear placeholder='nguyenlhnguyen_api1.gmail.com (payment gateway account)' />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Shield Url">
                                                    {getFieldDecorator('shield_url', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.shield_url : ""
                                                    })(<Input allowClear placeholder='https://devianartdesigns.com' />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Store">
                                                    {getFieldDecorator('store_ids', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.currentStore : []
                                                    })(
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Chọn store"
                                                            optionLabelProp="label"
                                                            allowClear={true}
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                        >
                                                            {stores && stores.length ? (
                                                                <>
                                                                    {
                                                                        stores.map((item) => {
                                                                            return (
                                                                                <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            ) : null}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Client ID">
                                                    {getFieldDecorator('client_id', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.client_id : ""
                                                    })(<Input.Password />)}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Secret key">
                                                    {getFieldDecorator('secret_key', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.secret_key : ""
                                                    })(<Input.Password />)}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Platform">
                                                    {getFieldDecorator('platform', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.platform : 'paypal'
                                                    })(
                                                        <BaseRadioList
                                                            options={[
                                                                { label: "Paypal", value: 'paypal' },
                                                                { label: "Stripe", value: 'stripe' }
                                                            ]}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Trạng thái">
                                                    {getFieldDecorator('status', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.status : 1
                                                    })(
                                                        <BaseRadioList
                                                            options={[
                                                                { label: "Kích hoạt", value: 1 },
                                                                { label: "Chưa kích hoạt", value: 0 }
                                                            ]}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Tình trạng tài khoản">
                                                    {getFieldDecorator('account_status', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? currentData.account_status : 'normal'
                                                    })(
                                                        <BaseRadioList
                                                            options={[
                                                                { label: "Normal", value: 'normal' },
                                                                { label: "Hold 21 day", value: 'hold_21day' },
                                                                { label: "Hold 180 day", value: 'hold_180day' },
                                                                { label: "Other", value: 'other' }
                                                            ]}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item label="Ghi chú">
                                            {getFieldDecorator('note', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: currentData ? _newLine(currentData.note) : ''
                                            })(
                                                <Input.TextArea rows={3} />
                                            )}
                                        </Form.Item>
                                    </Form>
                                    <div style={{ textAlign: 'right', }}>
                                        <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                            Cập nhật
                                        </Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    : null
                }
            </Drawer>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users,
        stores: state.StoreReducer.store_by_field_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createPaymentGateways: (data) => dispatch(createPaymentGateways(data)),
        updatePaymentGateways: (id, data) => dispatch(updatePaymentGateways(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PaymentGatewaysForm' })(PaymentGatewaysForm));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckCircleOutlined, ClockCircleOutlined, EditOutlined, SwapOutlined } from '@ant-design/icons';
import { Descriptions, Image, Button, Alert, Space, Divider, Comment, Drawer, Row, Col, Tag } from 'antd';
import IdeaEditForm from './IdeaEditForm';
import DesignAddForm from './DesignAddForm';
import { Form } from "@ant-design/compatible";
import ReactHtmlParser from 'react-html-parser';
// actions
import { getOneIdea, updateIdea } from '../../redux/actions/IdeaAction';
import { checkPermission } from '../../utils/permission';
import { isMobile } from 'react-device-detect';
import { isValidDate, downloadFromLink } from '../../utils/helpers';

class IdeaDetail extends Component {
    state = {
        isLoading: true,
        isOpenEditForm: false,
        currentIdea: null,
        isOpenTransToDesignForm: false,
    }

    componentDidMount() {

    }

    toggleOpenEditForm(isOpenEditForm, idea = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentIdea: idea
        });
    }

    onEdit(id) {
        this.props.getOneIdea(id).then((res) => {
            this.toggleOpenEditForm(true, res)
        })
    }

    openTransToDesignForm(isOpenTransToDesignForm, idea = null) {
        this.setState({
            isOpenTransToDesignForm: isOpenTransToDesignForm,
            currentIdea: idea
        });
    }

    onTransToDesign(id) {
        this.props.getOneIdea(id).then((res) => {
            this.openTransToDesignForm(true, res)
        })
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    render() {
        var { idea, visible } = this.props;
        var {
            isOpenTransToDesignForm,
            isOpenEditForm,
            currentIdea,
        } = this.state;

        var design_status = <Tag><ClockCircleOutlined /> Chưa gửi yêu cầu thiết kế</Tag>;
        if (idea) {
            if (idea?.design_status == 0) {
                design_status = <Tag color='#faad14'><ClockCircleOutlined /> Chưa thiết kế</Tag>
            } else if (idea?.design_status == 1) {
                design_status = <Tag color='green'><CheckCircleOutlined /> Đã có file thiết kế</Tag>
            }
        }

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={`#${idea?.id}`}
                    width={isMobile ? '100%' : '55%'}
                    onClose={() => this.props.onCancel()}
                    maskClosable={false}
                    footer={false}
                    extra={
                        <>
                            {
                                checkPermission('idea', 'send_request_design') ? <> <Button className='fix-buttom-mobile' type="primary" onClick={() => this.onTransToDesign(idea?.id)} icon={<SwapOutlined />}>Gửi yêu cầu thiết kế</Button> <Divider type="vertical" /> </> : null
                            }
                            {
                                checkPermission('idea', 'update') ? <><Button type="primary" className='fix-buttom-mobile' onClick={() => this.onEdit(idea?.id)} icon={<EditOutlined />}>Sửa</Button></> : null
                            }
                        </>
                    }
                >
                    {visible && idea ? (
                        <>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mt-2'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Tên sản phẩm</span>}
                                        content={<>{idea?.name}</>}
                                    />
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thiết kế">
                                            {design_status}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ảnh mẫu">
                                            {
                                                idea?.images_demo.length ? (
                                                    <Image.PreviewGroup>
                                                        <Space wrap>
                                                            {
                                                                idea?.images_demo.map(item => {
                                                                    return (
                                                                        <Image
                                                                            height={50}
                                                                            src={item?.src}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Space>
                                                    </Image.PreviewGroup>
                                                ) : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="File đính kèm">
                                            {
                                                idea?.files.map(item => {
                                                    return (
                                                        <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                                                    )
                                                })
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24} className='wrap-alert'>
                                    <Alert
                                        message={<strong>Mô tả yêu cầu thiết kế</strong>}
                                        description={ReactHtmlParser(idea?.request_des_for_design)}
                                        type="info"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mt-3'>
                                    <Comment
                                        author={<span className='ant-descriptions-item-label'>Ghi chú</span>}
                                        content={<>{ReactHtmlParser(idea?.note)}</>}
                                    />
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Niche">
                                            {idea?.niche_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Loại sản phẩm">
                                            {idea?.product_type_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(idea?.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày cập nhật">
                                            {isValidDate(idea?.updated_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {idea?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người cập nhật">
                                            {idea.updated_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                        </>
                    ) : null
                    }
                </Drawer>
                <IdeaEditForm
                    visible={isOpenEditForm}
                    idea={currentIdea}
                    onCancel={() => this.toggleOpenEditForm(false)}
                    reloadData={() => this.props.reloadData()}
                />
                <DesignAddForm
                    visible={isOpenTransToDesignForm}
                    currentIdea={currentIdea}
                    onCancel={() => this.openTransToDesignForm(false)}
                    reloadData={() => this.props.reloadData()}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOneIdea: id => dispatch(getOneIdea(id)),
        updateIdea: (id, data) => dispatch(updateIdea(id, data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "IdeaDetail" })(IdeaDetail));
import api from '../../utils/api';
import {
    CREATE_COMMENT,
    REMOVE_COMMENT
} from './types';
import { NotificationManager } from 'react-notifications'

export const createComment = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/comments', data).then(res => {
            dispatch({ type: CREATE_COMMENT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeComment = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/comments`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_COMMENT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

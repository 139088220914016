import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Row, Col, TreeSelect, Drawer, Space, Input, Radio } from 'antd';
import { makeTree } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { isMobile } from 'react-device-detect';
import Editor from "../../components/Editor";
import { NotificationManager } from 'react-notifications';

// actions
import { createProductsCatDes, updateProductsCatDes } from '../../redux/actions/ProductCatDesActions';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getStore, getStoreByField } from '../../redux/actions/StoreActions';
import { chatGPTCompletions } from '../../redux/actions/ChatGptActions';


class ProductsCatDesForm extends Component {

    constructor() {
        super();
        this.state = {
            isloading: false,
            isLoadingCat: false,
            currentType: null,
            current_store: null,
            categoryId: null,
            categoryName: null,
            currentStoreId: null,
        };
    }

    componentDidMount() {
        this.props.getStoreByField({ platform: ['pod'] })
    }

    onChangeStore(value) {
        this.setState({ isLoadingCat: true, currentStoreId: value })
        this.props.currentStore(value);
        this.props.getAllCategories({ store_id: value }).then(res => {
            this.setState({ isLoadingCat: false })
        });
        this.props.getStore(value).then(res => {
            this.setState({ current_store: res.name });
        })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                values.category_id = values.category;
                delete values.category;
                delete values.messages;
                if (this.props.currentData) {
                    values.category_name = this.state.categoryName != null ? this.state.categoryName : [this.props.currentData.category_name];
                    await this.props.updateProductsCatDes(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    values.category_name = this.state.categoryName;
                    this.props.createProductsCatDes(values)
                    setTimeout(() => {
                        this.props.reloadData();
                        this.props.form.setFieldsValue({ keywords: null, category: null })
                        this.setState({ isloading: false })
                        this.props.getAllCategories({ store_id: values.store_id })
                        //this.handCancel()
                        NotificationManager.info("Đang tạo nội dung tự động. Hệ thống sẽ thông báo sau khi hoàn thành", "Thông báo", 15000);
                    }, 800);
                }
                //console.log(values)
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({
            isloading: false,
            currentType: null,
            currentProductType: null,
            categoryName: null,
            categoryId: null
        })
    }

    handleCategoryChange = (id, name) => {
        this.setState({
            categoryId: id,
            categoryName: name
        });
    };

    render() {
        const {
            isloading,
            isLoadingCat
        } = this.state;

        var { visible, currentData, categories, stores } = this.props;

        const { getFieldDecorator } = this.props.form;
        const catTree = makeTree(categories || [], 0);

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.handCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={isloading}
                                onClick={(e) => this.submit(e)}
                            >
                                {currentData ? "Cập nhật" : "Tạo nội dung tự động"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Store">
                                    {getFieldDecorator("store_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.store_id : null
                                    })(
                                        <BaseSelect
                                            options={stores || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            //additionalLabel="platform"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder="Chọn store"
                                            onChange={(value) => this.onChangeStore(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label='Categories'>
                                    {getFieldDecorator("category", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.category_id : null
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={catTree}
                                            showSearch
                                            loading={isLoadingCat}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn categories"
                                            treeDefaultExpandAll
                                            onChange={this.handleCategoryChange}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14}>
                                <Form.Item label="Publish">
                                    {getFieldDecorator('publish_status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.publish_status : 0
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>Đã đăng lên site</Radio>
                                            <Radio value={0}>Chưa đăng lên site</Radio>

                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            currentData ? (
                                <Form.Item label="Nội dung">
                                    {getFieldDecorator('contents', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng nhập!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.contents : null
                                    })(
                                        <Editor />
                                    )}
                                </Form.Item>
                            ) : (
                                <Form.Item label="Từ khóa">
                                    {getFieldDecorator('keywords', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng nhập!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input.TextArea rows={22} placeholder="Mỗi từ khóa trên 1 dòng
                                        từ khóa 1
                                        từ khóa 2
                                        ......" />
                                    )}
                                </Form.Item>
                            )
                        }
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.ProductsCategories.categories,
        stores: state.StoreReducer.store_by_field_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createProductsCatDes: (data) => dispatch(createProductsCatDes(data)),
        updateProductsCatDes: (id, data) => dispatch(updateProductsCatDes(id, data)),
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getStore: (id) => dispatch(getStore(id)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter)),
        chatGPTCompletions: data => dispatch(chatGPTCompletions(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductsCatDesForm' })(ProductsCatDesForm));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './home';
import UserPermission from './user/UserPermission';
import UserDetail from './user/UserDetail';
import UserList from './user/UserList';
import Profile from './auth/Profile';
import Config from './config';
import ProductType from './product-type/ProductTypeList';
import Niche from './niche/NicheList';
import DesignList from './design/DesignList';
import OrdersList from './order/OrdersList';
import HolidaysList from './holiday/HolidaysList';
import TrendingList from './trending/TrendingList';
import StoreList from './store/StoreList';
import ProductsList from './products/ProductsList';
import RevenueExpenditureList from './revenue-expenditure/RevenueExpenditureList';
import WorkflowManagementList from './workflow-management/WorkflowManagementList';
import TrainingList from './training/TrainingList';
import TrainingDetail from './training/TrainingDetail';
import GuideList from './guide/GuideList';
import GuideDetail from './guide/GuideDetail';
import TelegramList from './telegram/TelegramList';
import ProcessList from './products-import/ProcessList';
import ProductsTagsList from './products/ProductsTagsList';
import ProductsDescriptionList from './products-description/ProductsDescriptionList';
import WorkReportList from './work-report/WorkReportList';
import FilePngList from './file-png/FilePngList';
import ToolLicenseList from './tool-license/ToolLicenseList';
import GoogleIndexingList from './google-indexing/GoogleIndexingList';
import PrintingUnitList from './printing-unit/PrintingUnitList';
import PaymentGatewaysList from './payment-gateways/PaymentGatewaysList';
import BlacklistList from './blacklist/BlacklistList';
import ChatGPT from './chatgpt/ChatGptList';
import LogMailTrackingList from './log-mail-tracking/LogMailTrackingList';
import LogOrderShieldList from './log-order-shield/LogOrderShieldList';
import PostsList from './posts/PostsList';
import Idea from './idea/IdeaList';
import ProjectList from './project/ProjectList';
import ProjectBasedJobList from './project-based-job/ProjectBasedJobList';
import ProductsCatDesList from './products-cat-des/ProductsCatDesList';
import TrademarkCategoryList from './trademark-category/TrademarkCategoryList';
import DeleteObjectFrom from './handle-s3-objects/DeleteObjectFrom';
import TrademarkKeyword from './trademark-keyword';

import { Typography } from 'antd';
const { Title } = Typography;

const ProtectedRoute = ({ authUser, permission, component: Component, ...rest }) => (
    <Route
        {...rest}
        // render={(props) => (
        //     authUser ? <Component {...props} /> : <Redirect to='/login' />
        // )} 
        render={(props) => {
            if (authUser) {
                let availablePermissions = Object.keys(authUser.permissions);
                let isAccess = false;
                if (authUser.role_code == 'ADMIN') isAccess = true;
                else {
                    if (!permission || availablePermissions.indexOf(permission) >= 0) isAccess = true;
                }

                if (isAccess) return <Component {...props} />;
                // else return <Redirect to='/error/403' />;
                else return (
                    <div className="text-center mt-4">
                        <Title>Permission access is denied</Title>
                    </div>
                )
            } else {
                return <Redirect to='/login' />;
            }
        }}
    />
)

const routes = [
    {
        path: '/',
        component: Home,
        permission: null
    },
    {
        path: '/profile',
        component: Profile,
        permission: null
    },
    {
        path: '/users',
        component: UserList,
        permission: 'users'
    },
    {
        path: '/users/:id',
        component: UserDetail,
        permission: 'users'
    },
    {
        path: '/permissions',
        component: UserPermission,
        permission: 'permissions'
    },
    // config
    {
        path: '/config',
        component: Config,
        permission: 'config'
    },
    {
        path: '/product-type',
        component: ProductType,
        permission: 'product_type'
    },
    {
        path: '/niche',
        component: Niche,
        permission: 'niche'
    },
    {
        path: '/design',
        component: DesignList,
        permission: 'design'
    },
    {
        path: '/revenue-expenditure',
        component: RevenueExpenditureList,
        permission: 'revenue_expenditure'
    },
    {
        path: '/orders-list',
        component: OrdersList,
        permission: 'orders'
    },
    {
        path: '/holidays',
        component: HolidaysList,
        permission: 'holiday'
    },
    {
        path: '/trending',
        component: TrendingList,
        permission: 'trending'
    },
    {
        path: '/store',
        component: StoreList,
        permission: 'store'
    },
    {
        path: '/payment-gateways',
        component: PaymentGatewaysList,
        permission: 'payment_gateways'
    },
    {
        path: '/blacklist',
        component: BlacklistList,
        permission: 'blacklist'
    },
    {
        path: '/products',
        component: ProductsList,
        permission: 'products'
    },
    {
        path: '/product-tags',
        component: ProductsTagsList,
        permission: 'product_tags'
    },
    {
        path: '/workflow-management',
        component: WorkflowManagementList,
        permission: 'workflow_management'
    },
    {
        path: '/training',
        component: TrainingList,
        permission: 'training'
    },
    {
        path: '/training/:id',
        component: TrainingDetail,
        permission: 'training'
    },
    {
        path: '/guide',
        component: GuideList,
        permission: 'guide'
    },
    {
        path: '/guide/:id',
        component: GuideDetail,
        permission: 'guide'
    },
    {
        path: '/telegram',
        component: TelegramList,
        permission: 'telegram'
    },
    {
        path: '/products-import',
        component: ProcessList,
        permission: 'products_import'
    },
    {
        path: '/products-description',
        component: ProductsDescriptionList,
        permission: 'products_description'
    },
    {
        path: '/work-report',
        component: WorkReportList,
        permission: 'work_report'
    },
    {
        path: '/file-png',
        component: FilePngList,
        permission: 'file_png'
    },
    {
        path: '/tool-license',
        component: ToolLicenseList,
        permission: 'tool_license'
    },
    {
        path: '/google-indexing',
        component: GoogleIndexingList,
        permission: 'google_indexing'
    },
    {
        path: '/printing-unit',
        component: PrintingUnitList,
        permission: 'printing_unit'
    },
    {
        path: '/chatgpt',
        component: ChatGPT,
        permission: 'chatgpt'
    },
    {
        path: '/log-mail-tracking',
        component: LogMailTrackingList,
        permission: 'log_mail_tracking'
    },
    {
        path: '/log-order-shield',
        component: LogOrderShieldList,
        permission: 'log_order_shield'
    },
    {
        path: '/posts',
        component: PostsList,
        permission: 'posts'
    },
    {
        path: '/ideas',
        component: Idea,
        permission: 'idea'
    },
    {
        path: '/project',
        component: ProjectList,
        permission: 'project'
    },
    {
        path: '/project-based-job',
        component: ProjectBasedJobList,
        permission: 'project_based_job'
    },
    {
        path: '/product-cat-des',
        component: ProductsCatDesList,
        permission: 'product_cat_des'
    },
    {
        path: '/trademark-category',
        component: TrademarkCategoryList,
        permission: 'trademark_category'
    },
    {
        path: '/delete-object',
        component: DeleteObjectFrom,
        permission: 'handle_s3_objects'
    },
    {
        path: '/trademark-keyword',
        component: TrademarkKeyword,
        permission: 'trademark_keyword'
    }
];

//console.log(routes);  

class Routes extends Component {

    render() {
        var { authUser } = this.props;

        return (
            <Switch>
                {
                    routes.map((route, index) => {
                        return (
                            <ProtectedRoute exact authUser={authUser} permission={route.permission} path={route.path} component={route.component} key={index} />
                        )
                    })
                }
            </Switch>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state);
    return {
        authUser: state.auth.authUser
    }
}

export default connect(mapStateToProps, null)(Routes);


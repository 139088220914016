import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import Editor from "../../components/Editor";
// actions
import { createProductType, updateProductType } from '../../redux/actions/ProductTypeActions';

class ProductTypeForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.product_type) {
                    await this.props.updateProductType(this.props.product_type.id, values);
                    this.onClose();
                } else {
                    await this.props.createProductType(values);
                    this.onClose();
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        const { } = this.state;
        var { visible, product_type } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title={product_type ? "Sửa loại sản phẩm" : "Thêm loại sản phẩm"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {product_type ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Tên danh mục">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: product_type ? product_type.name : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Mô tả">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: product_type ? product_type.description : ""
                            })(
                                <Editor />
                            )}
                        </Form.Item>
                        <Form.Item label="Đơn vị fulfill">
                            {getFieldDecorator('unit_fulfill', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: product_type ? product_type.unit_fulfill : ""
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: product_type ? product_type.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Kích hoạt", value: 1 },
                                        { label: "Chưa kích hoạt", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createProductType: (data) => dispatch(createProductType(data)),
        updateProductType: (id, data) => dispatch(updateProductType(id, data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'ProductTypeForm' })(ProductTypeForm));

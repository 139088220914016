import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Drawer, Button, Input, Col, Row, Divider, Table, Tooltip, Modal, Space } from "antd";
import { CheckCircleOutlined, IssuesCloseOutlined, CheckSquareOutlined } from '@ant-design/icons';
import BaseRadios from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { NotificationManager } from 'react-notifications'
// actions
import { addShipmentTracking, resendShipmentTracking } from '../../redux/actions/OrdersAction';

function isUrlValid(urlInput) {
    var res = urlInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
        return false;
    else
        return true;
}

class ShipmentTracking extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isLoading2: false,
            isOpenOption: false,
            current_id: null
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                const { currentData } = this.props;
                if (!isUrlValid(values.tracking_url)) {
                    NotificationManager.error("Tracking url không hợp lệ");
                    this.setState({ isLoading: false });
                    return false;
                }
                try {
                    const params = {
                        tracking_url: values.tracking_url,
                        tracking_number: values.tracking_code,
                        store_id: currentData.store_id,
                        order_id: currentData.order_id,
                        send_to_customer: values.send_to_customer,
                        sync_to_payment_gateway: values.sync_to_payment_gateway
                    }
                    //console.log(params)
                    if (currentData) {
                        await this.props.addShipmentTracking(params);
                        this.props.reloadData();
                        this.props.reloadData2();
                        this.props.form.resetFields();
                        this.setState({
                            isLoading: false
                        });
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    async isOpenResendTracking(id) {
        this.setState({ current_id: id, isOpenOption: true });
    }

    handleResendTracking(e) {
        e.preventDefault();
        this.props.form.validateFields(['send_to_customer2', 'sync_to_payment_gateway2'], async (err, values) => {
            if (!err) {
                this.setState({ isLoading2: true })
                try {
                    const params = {
                        id: this.state.current_id,
                        send_to_customer: values.send_to_customer2,
                        sync_to_payment_gateway: values.sync_to_payment_gateway2
                    }
                    await this.props.resendShipmentTracking(params).then(res => {
                        this.props.form.resetFields();
                        this.props.reloadData2();
                        this.setState({ isLoading2: false, isOpenOption: false })
                    });
                } catch (error) {
                    this.setState({ isLoading2: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        const { visible, currentData } = this.props;
        const { isLoading, isLoading2, isOpenOption } = this.state;
        const { getFieldDecorator } = this.props.form;
        //console.log("currentData: ", currentData)
        const columns = [
            {
                title: 'Actions',
                align: 'center',
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="Gửi lại mã tracking cho khách hoặc sync tracking lên cổng thanh toán">
                                <Button onClick={() => this.isOpenResendTracking(record.id)} size="small">Gửi lại</Button>
                            </Tooltip>
                        </>
                    )
                }
            },
            {
                title: 'Mã tracking',
                dataIndex: 'tracking_code',
                align: 'left',
                width: '140px',
                render: (text, record) => {
                    return (
                        <a target='_blank' href={record.tracking_url}>{record.tracking_code}</a>
                    )
                }
            },
            {
                title: 'Shipped at',
                dataIndex: 'shipped_at',
                key: 'shipped_at',
                align: 'center',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        )
                    }
                }
            },
            {
                title: 'Delivered at',
                dataIndex: 'delivered_at',
                key: 'delivered_at',
                align: 'center',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        )
                    }
                }
            },
            {
                title: () => <Tooltip title="Sync payment gateway">Sync</Tooltip>,
                dataIndex: 'sync_payment_gateway',
                key: 'sync_payment_gateway',
                align: 'center',
                render: (text, record) => {
                    if (text == 'Done') {
                        return (
                            <Tooltip title="Đồng bộ lên cổng thanh toán thành công">
                                <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />
                            </Tooltip>
                        )
                    } else if (text == 'None') {
                        return (
                            <Tooltip title="Chưa đồng bộ lên cổng thanh toán">
                                <IssuesCloseOutlined style={{ color: "#f00", fontSize: '18px' }} />
                            </Tooltip>
                        )
                    } else if (text == 'WebDone') {
                        return (
                            <Tooltip title="Submit lên store thành công">
                                <CheckSquareOutlined style={{ color: "green", fontSize: '18px' }} />
                            </Tooltip>
                        )
                    }
                    else if (text == 'WebNone') {
                        return (
                            <Tooltip title="Chưa submit lên store">
                                <CheckCircleOutlined style={{ color: "#911212", fontSize: '18px' }} />
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: 'Last send',
                dataIndex: 'last_send',
                key: 'last_send',
                align: 'center',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        )
                    }
                }
            }
        ];

        return (
            <Drawer
                visible={visible}
                title="Theo dõi đơn hàng"
                width={isMobile ? '100%' : '50%'}
                onClose={() => this.onClose()}
                maskClosable={false}
            >
                {visible ? (
                    <>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tracking url">
                                        {getFieldDecorator("tracking_url", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input placeholder="https://t.17track.net/en?nums=9274890278835155827385" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tracking code">
                                        {getFieldDecorator("tracking_code", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input placeholder="9274890278835155827385" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Gửi mail cho khách">
                                                {getFieldDecorator("send_to_customer", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: 1
                                                })(
                                                    <BaseRadios
                                                        options={
                                                            [
                                                                { value: 1, label: "Có" },
                                                                { value: 0, label: "Không" },
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Đồng bộ lên cổng thanh toán">
                                                {getFieldDecorator("sync_to_payment_gateway", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: 1
                                                })(
                                                    <BaseRadios
                                                        options={
                                                            [
                                                                { value: 1, label: "Có" },
                                                                { value: 0, label: "Không" },
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-4" justify="end">
                                <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                    Cập nhật
                                </Button>
                            </Row>
                            <Divider />
                            <Table
                                rowKey="id"
                                size='small'
                                className="table-striped-rows"
                                tableLayout='auto'
                                columns={columns}
                                loading={isLoading2}
                                dataSource={currentData ? currentData.shipment_tracking : []}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />
                        </Form>

                        <Modal
                            visible={isOpenOption}
                            onCancel={() => this.setState({ isOpenOption: false })}
                            maskClosable={false}
                            width={isMobile ? '100%' : '20%'}
                            closable={false}
                            footer={
                                <div style={{ textAlign: 'right', }}>
                                    <Space>
                                        <Button key="back" onClick={() => this.setState({ isOpenOption: false })}>
                                            HỦY
                                        </Button>
                                        <Button
                                            key="submit"
                                            type="primary"
                                            loading={isLoading2}
                                            onClick={(e) => this.handleResendTracking(e)}
                                        >
                                            GỬI
                                        </Button>
                                    </Space>
                                </div>
                            }
                        >
                            {isOpenOption ?
                                <Form layout='vertical'>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Gửi mail cho khách">
                                                {getFieldDecorator("send_to_customer2", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: 1
                                                })(
                                                    <BaseRadios
                                                        options={
                                                            [
                                                                { value: 1, label: "Có" },
                                                                { value: 0, label: "Không" },
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Đồng bộ lên cổng thanh toán">
                                                {getFieldDecorator("sync_to_payment_gateway2", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: 1
                                                })(
                                                    <BaseRadios
                                                        options={
                                                            [
                                                                { value: 1, label: "Có" },
                                                                { value: 0, label: "Không" },
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                : null}
                        </Modal>
                    </>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addShipmentTracking: (data) => dispatch(addShipmentTracking(data)),
        resendShipmentTracking: (data) => dispatch(resendShipmentTracking(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ShipmentTracking" })(ShipmentTracking));

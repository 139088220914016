import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Modal, Divider, Tag, Tooltip, Typography } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import BlacklistForm from './BlacklistForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';

import moment from 'moment';
// actions
import { getAllBlacklist, getBlacklist, removeBlacklist } from '../../redux/actions/BlacklistActions';

const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/blacklist',
        breadcrumbName: 'Blacklist',
    },
]

class BlacklistList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllBlacklist(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllBlacklist(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getBlacklist(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllBlacklist(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onRemove() {
        this.props.removeBlacklist(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            //this.reloadData()
        })
    }

    render() {
        var { blacklist_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData } = this.state;
        //console.log(blacklist_list)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '30px',
            },
            {
                title: '#',
                align: 'center',
                key: 'actions',
                width: '20px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('blacklist', 'update') ? (
                                <>
                                    <Tooltip title="Sửa">
                                        <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    </Tooltip>
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                width: '250px',
                render: (text, record) => (
                    <div className='ecom-paragraph'>
                        <Paragraph copyable={{ tooltips: false }}>{text}</Paragraph>
                    </div>
                )
            },
            {
                title: 'Type',
                width: '100px',
                dataIndex: 'type',
                key: 'type',
                sorter: true,
                render: (text, record) => (
                    <>
                        <Tag>{text}</Tag>
                    </>
                )
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '150px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Ngày thêm',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Người thêm',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '120px',
                render: (text, record) => {
                    return (
                        <>
                            {record.created_user}
                        </>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Blacklist" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('blacklist', 'create')}
                        isShowDeleteButton={checkPermission('blacklist', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            options={[
                                { label: "Email", value: 'EMAIL' },
                                { label: "Phone", value: 'PHONE' },
                                { label: "Address", value: 'ADDRESS' },
                                { label: "Zipcode", value: 'ZIPCODE' },
                                { label: "States", value: 'STATES' },
                                { label: "City", value: 'CITY' }
                            ]}
                            onChange={(value) => this.onChangeFilter("type", value)}
                            placeholder="Type"
                            defaultValue={query.type || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />
                        <Divider type="vertical" />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã kích hoạt' },
                                { value: 0, label: 'Chưa kích hoạt' }
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            defaultText="Trạng thái"
                            placeholder="Trạng thái"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />

                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={blacklist_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <BlacklistForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        blacklist_list: state.BlacklistReducer.blacklist_list,
        pagination: state.BlacklistReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllBlacklist: (filter) => dispatch(getAllBlacklist(filter)),
        getBlacklist: (id) => dispatch(getBlacklist(id)),
        removeBlacklist: ids => dispatch(removeBlacklist(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistList);

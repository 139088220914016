import api from '../../utils/api';
import {
    GET_ALL_TRENDING,
    GET_SPECIFIC_TRENDING,
    UPDATE_SPECIFIC_TRENDING,
    CREATE_NEW_TRENDING,
    REMOVE_TRENDING,
    GET_NOTIFY_TRENDING
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllTrending = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/trending', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_TRENDING, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createTrending = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/trending', data).then(res => {
            dispatch({ type: CREATE_NEW_TRENDING, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getTrending = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/trending/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_TRENDING, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateTrending = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/trending/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_TRENDING, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeTrending = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/trending`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_TRENDING, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getNotifyTrending = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/trending/notify/count', { params: filter }).then(res => {
            dispatch({ type: GET_NOTIFY_TRENDING, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
import {
    GET_ALL_HOLIDAY,
    GET_SPECIFIC_HOLIDAY,
    UPDATE_SPECIFIC_HOLIDAY,
    CREATE_NEW_HOLIDAY,
    REMOVE_HOLIDAY
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    holidays: [],
    currentHoliday: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_HOLIDAY: {
            return {
                ...state,
                holidays: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_SPECIFIC_HOLIDAY: {
            return { ...state, currentHoliday: action.payload }
        }
        case CREATE_NEW_HOLIDAY: {
            return { ...state, holidays: [action.payload, ...state.holidays], total: state.total + 1 }
        }
        case UPDATE_SPECIFIC_HOLIDAY: {
            let index = state.holidays.findIndex((holiday) => {
                return holiday.id == action.payload.id;
            });

            let temp = [...state.holidays];
            temp[index] = action.payload;
            return { ...state, holidays: temp }
        }
        case REMOVE_HOLIDAY: {
            let temp = state.holidays.filter(holiday => {
                return action.payload.indexOf(holiday.id) < 0;
            });
            return { ...state, holidays: temp }
        }
        default: return { ...state };
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, DatePicker, Row, Col, Drawer, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { _newLine, downloadFromLink } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import Editor from "../../components/Editor";
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';

// actions
import { createData, updateData } from '../../redux/actions/WorkflowManagementActions';

class WorkflowManagementForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            showDate: false,
            data: [],
            select: false,
            currentFiles: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentFiles: nextProps?.currentData?.files,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isloading: true })
                values.start_date = values.start_date ? values.start_date.format("YYYY-MM-DD") : null;
                values.deadline = values.deadline ? values.deadline.format("YYYY-MM-DD") : null;
                if (this.props.currentData) {
                    const { currentFiles, data } = this.state;
                    values.files = [...currentFiles, ...data];
                    //console.log(values)
                    await this.props.updateData(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    values.files = this.state.data;
                    await this.props.createData(values);
                    this.handCancel()
                    this.props.reloadData()
                }
                this.setState({ isloading: false })
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false, showDate: false, data: [], select: !this.state.select })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentData !== this.props.currentData) {
            const { currentData } = this.props;
            if (currentData) {
                this.setState({ showDate: currentData.type })
            }
        }
    }

    onChangeType(val) {
        this.setState({ showDate: val })
    }

    onChange = (data) => {
        this.setState({
            ...this.state,
            data: data,
        });
    }

    deleteCurrentFiles = (name) => {
        const items = [...this.state.currentFiles];
        this.setState({ currentFiles: items.filter(item => item.name !== name) });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    render() {
        const { isloading, showDate, currentFiles } = this.state;
        var { visible, currentData, users } = this.props;
        const { getFieldDecorator } = this.props.form;
        const currentFilesList = [];
        if (currentData) {
            if (currentFiles && Array.isArray(currentFiles))
                currentFiles.map(item => {
                    currentFilesList.push(
                        <div>
                            <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                            <DeleteOutlined
                                title="Xóa"
                                className="btn-delete-img"
                                onClick={() => this.deleteCurrentFiles(item?.name)}
                            />
                        </div >
                    )
                })
        }

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa công việc" : "Thêm mới công việc"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Tạo"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Người thực hiện">
                            {getFieldDecorator('user_id', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: currentData ? currentData.user_id : ""
                            })(
                                <BaseSelect
                                    options={users}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    defaultText="Chọn người thực hiện"
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại công việc">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.type : "periodic"
                                    })(
                                        <BaseRadios
                                            options={[
                                                { value: 'periodic', label: 'Công việc định kỳ' },
                                                { value: 'incurred', label: 'Công việc phát sinh' }
                                            ]}
                                            onChange={(val) => this.onChangeType(val)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Độ ưu tiên">
                                    {getFieldDecorator('priority', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.priority : 1
                                    })(
                                        <BaseRadios
                                            options={[
                                                { value: 0, label: 'Làm gấp' },
                                                { value: 1, label: 'Cao' },
                                                { value: 2, label: 'Trung bình' },
                                                { value: 3, label: 'Thấp' }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày bắt đầu">
                                    {getFieldDecorator("start_date", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? moment(currentData.start_date).isValid() ? moment(currentData.start_date) : null : ''
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                showDate == 'incurred' ? (
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Deadline">
                                            {getFieldDecorator("deadline", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: currentData ? moment(currentData.deadline).isValid() ? moment(currentData.deadline) : null : ''
                                            })(
                                                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        <Form.Item label="File đính kèm">
                            {getFieldDecorator('attach_file', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ''
                            })(
                                <DraggerFile onChangeData={this.onChange} select={this.state.select} />
                            )}
                            {currentFilesList}
                        </Form.Item>
                        <Form.Item label="Mô tả công việc">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.description : ''
                            })(
                                <Editor />
                            )}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'WorkflowManagementForm' })(WorkflowManagementForm));

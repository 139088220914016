import api from '../../utils/api';
import {
    STATIS_IDEA,
    STATIS_DESIGN,
    STATIS_MONEY,
    STATIS_STAFF,
    STATIS_PRODUCT,
    STATIS_ORDER,
    STATIS_ORDER_BY_STORE
} from './types';
import { NotificationManager } from 'react-notifications'

export const statisIdea = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-idea', { params: filter }).then(res => {
            dispatch({
                type: STATIS_IDEA,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisDesign = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-design', { params: filter }).then(res => {
            dispatch({
                type: STATIS_DESIGN,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisMoney = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-money', { params: filter }).then(res => {
            dispatch({
                type: STATIS_MONEY,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisStaff = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-staff', { params: filter }).then(res => {
            dispatch({
                type: STATIS_STAFF,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisProduct = (filter = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-product', { params: filter }).then(res => {
            dispatch({
                type: STATIS_PRODUCT,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisOrder = (filter = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-order', { params: filter }).then(res => {
            dispatch({
                type: STATIS_ORDER,
                payload: {
                    data: res.data.data,
                    status: filter.status ? filter.status : 'order_success'
                }
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const statisOrderByStore = (filter = {}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/statistical/statis-order-store', { params: filter }).then(res => {
            dispatch({
                type: STATIS_ORDER_BY_STORE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
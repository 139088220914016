import {
    GET_ALL_PRODUCTS_CAT_DES
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    items: {
        store_id: null,
        store_url: null,
        data: []
    },
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS_CAT_DES: {
            return {
                ...state,
                items: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    data: action.payload.data.data
                },
                pagination: {
                    currentPage: parseInt(action.payload.data.current_page),
                    total: parseInt(action.payload.data.total),
                    perPage: parseInt(action.payload.data.per_page),
                }
            }
        }
        default: return { ...state };
    }
}

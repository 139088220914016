import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Row, Col, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
// actions
import { updateCostLenful } from '../../redux/actions/OrdersAction';

class SyncCostLenful extends Component {

    state = {
        isLoading: false
    }

    async onSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            try {
                if (!err) {
                    this.setState({ isLoading: true })
                    values.strFromDate = values.strFromDate ? moment(values.strFromDate).format('YYYY-MM-DD') : null;
                    values.strToDate = values.strToDate ? moment(values.strToDate).format('YYYY-MM-DD') : null;
                    //console.log(values)
                    await this.props.updateCostLenful(values).then(res => {
                        this.props.reloadData();
                        this.setState({ isLoading: false })
                        this.handleCancel();
                    })
                }
            } catch (error) {
                console.log(error)
                this.setState({ isLoading: false });
            }
        });
    }

    handleCancel = () => {
        this.props.onCancel()
    }

    render() {
        const { isLoading } = this.state;
        const { visible } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title="Chọn ngày"
                width="30%"
                visible={visible}
                onOk={(e) => this.onSubmit(e)}
                confirmLoading={isLoading}
                onCancel={this.handleCancel}
            >
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item label="Từ ngày">
                                {getFieldDecorator('strFromDate', {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    initialValue: moment(moment().subtract(3, 'days'), 'YYYY-MM-DD')
                                })(
                                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label="Đến ngày">
                                {getFieldDecorator('strToDate', {
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    initialValue: moment(moment(), 'YYYY-MM-DD')
                                })(
                                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}


const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCostLenful: (filter) => dispatch(updateCostLenful(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'SyncCostLenful' })(SyncCostLenful));
import api from '../../utils/api';
import {
    GET_ALL_BLACKLIST,
    GET_SPECIFIC_BLACKLIST,
    UPDATE_SPECIFIC_BLACKLIST,
    CREATE_NEW_BLACKLIST,
    REMOVE_BLACKLIST
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllBlacklist = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/blacklist', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_BLACKLIST, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createBlacklist = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/blacklist', data).then(res => {
            dispatch({ type: CREATE_NEW_BLACKLIST, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getBlacklist = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/blacklist/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_BLACKLIST, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateBlacklist = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/blacklist/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_BLACKLIST, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeBlacklist = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/blacklist`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_BLACKLIST, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
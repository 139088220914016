import api from '../../utils/api';
import {
    GET_ALL_STORE,
    GET_ALL_STORE_BY_ACTIVE,
    GET_SPECIFIC_STORE,
    UPDATE_SPECIFIC_STORE,
    CREATE_NEW_STORE,
    REMOVE_STORE,
    GET_ALL_STORE_BY_FILED,
    OPTIMIZER_STORE,
    UPDATE_API_STORE,
    EXPORT_STORES
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllStore = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/store', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_STORE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStoreActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/store/by-active', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_STORE_BY_ACTIVE,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStoreByField = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/store/by-active', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_STORE_BY_FILED,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createStore = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/store', data).then(res => {
            dispatch({ type: CREATE_NEW_STORE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStore = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/store/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_STORE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateStore = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/store/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_STORE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeStore = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/store`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_STORE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// Xóa cache, clean wp table
export const optimizerStore = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/store/optimizer/purge`, { ids: ids }).then(res => {
            dispatch({ type: OPTIMIZER_STORE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// Cập nhật plugin api
export const updateApiStore = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/store/api/update`, { id: id }).then(res => {
            dispatch({ type: UPDATE_API_STORE, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const exportStore = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/store/export`, filter).then(res => {
            dispatch({ type: EXPORT_STORES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}
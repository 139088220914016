import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Table, Checkbox, Row, Col, Modal, Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { TableOutlined } from '@ant-design/icons';
import { getListColumns, createColumns } from '../redux/actions/UserColumnPreferencesActions';

class DynamicTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColumns: props.columns.map(column => column.key),
            dataSource: props.dataSource || [],
            visible: false,
        };
        this.tableWrapperRef = createRef();
        this.scrollInterval = null;
    }

    componentDidMount() {
        const { userId, moduleKey } = this.props;
        this.props.getListColumns({ user_id: userId, module_key: moduleKey }).then(res => {
            if (res && res.data.length > 0) {
                this.setState({ selectedColumns: res.data });
            }

        }).catch(err => {
            console.log(err)
        });
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
        this.clearScrollInterval();
    }

    scrollBy = (distance) => {
        if (this.tableWrapperRef.current) {
            this.tableWrapperRef.current.querySelector('.ant-table-content').scrollBy({ left: distance, behavior: 'smooth' });
        }
    };

    handleKeyDown = (event) => {
        if (this.tableWrapperRef.current && !this.scrollInterval) {
            if (event.code === 'ArrowLeft') {
                event.preventDefault();
                this.clearScrollInterval();
                this.scrollBy(-200);
                this.scrollInterval = setInterval(() => {
                    this.scrollBy(-300);
                }, 300);
            } else if (event.code === 'ArrowRight') {
                event.preventDefault();
                this.clearScrollInterval();
                this.scrollBy(200);
                this.scrollInterval = setInterval(() => {
                    this.scrollBy(300);
                }, 300);
            }
        }
    };

    handleKeyUp = () => {
        this.clearScrollInterval();
    };

    clearScrollInterval = () => {
        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    };

    handleColumnChange = (checkedValues) => {
        const { moduleKey } = this.props;
        this.setState({ selectedColumns: checkedValues });
        this.props.createColumns({ module_key: moduleKey, columns: checkedValues });
    };

    renderColumns() {
        const { columns } = this.props;
        const { selectedColumns } = this.state;
        return columns.map(column => ({
            ...column,
            visible: selectedColumns.includes(column.key),
        })).filter(column => column.visible);
    }

    onClose() {
        this.setState({ visible: false });
    }

    render() {
        const { visible, selectedColumns } = this.state;
        const { columns, dataSource, isLoading, pagination, rowSelection, ref, rowKey, rowClassName } = this.props;

        return (
            <div ref={this.tableWrapperRef}>
                <Button onClick={() => this.setState({ visible: true })} className='custom-column-table-btn' size='small' icon={<TableOutlined />}>Cột</Button>
                <Table
                    rowKey={rowKey}
                    className='table-striped-rows'
                    size="small"
                    tableLayout='auto'
                    dataSource={dataSource || []}
                    columns={this.renderColumns()}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    rowClassName={rowClassName}
                    ref={ref}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.props.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <Modal
                    visible={visible}
                    title="Chọn cột hiển thị"
                    onCancel={() => this.onClose()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '45%'}
                    footer={false}
                >
                    <Checkbox.Group
                        defaultValue={selectedColumns}
                        onChange={this.handleColumnChange}
                    >
                        <Row>
                            {columns.map(option => (
                                <Col xs={24} md={8} key={option.key}>
                                    <Checkbox value={option.key}>
                                        {typeof option.title === 'function' ? <div>{option.title()}</div> : option.title}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        getListColumns: (filter) => dispatch(getListColumns(filter)),
        createColumns: (data) => dispatch(createColumns(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable);


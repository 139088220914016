import {
    CHATGPT_GET_ALL,
    CHATGPT_GET_SPECIFIC,
    CHATGPT_UPDATE_SPECIFIC,
    CHATGPT_REMOVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    chatgpt_list: [],
    currentChatGPT: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHATGPT_GET_ALL: {
            return {
                ...state,
                chatgpt_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case CHATGPT_GET_SPECIFIC: {
            return { ...state, currentChatGPT: action.payload }
        }

        case CHATGPT_UPDATE_SPECIFIC: {
            let index = state.chatgpt_list.findIndex((store) => {
                return store.id == action.payload.id;
            });

            let temp = [...state.chatgpt_list];
            temp[index] = action.payload;
            return { ...state, chatgpt_list: temp }
        }
        case CHATGPT_REMOVE: {
            let temp = state.chatgpt_list.filter(store => {
                return action.payload.indexOf(store.id) < 0;
            });
            return { ...state, chatgpt_list: temp }
        }
        default: return { ...state };
    }
}

import api from '../../utils/api';
import {
    GET_ALL_PRODUCTS_CAT_DES,
    GET_SPECIFIC_PRODUCTS_CAT_DES,
    UPDATE_SPECIFIC_PRODUCTS_CAT_DES,
    CREATE_NEW_PRODUCTS_CAT_DES,
    REMOVE_PRODUCTS_CAT_DES,
    PRODUCTS_CAT_DES_PUBLISH_TO_SITE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllProductsCatDes = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/product-cat-des', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_PRODUCTS_CAT_DES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createProductsCatDes = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/product-cat-des', data).then(res => {
            dispatch({ type: CREATE_NEW_PRODUCTS_CAT_DES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const publishToSite = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-cat-des/publish/to-site`, data).then(res => {
            dispatch({ type: PRODUCTS_CAT_DES_PUBLISH_TO_SITE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getProductsCatDes = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/product-cat-des/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PRODUCTS_CAT_DES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateProductsCatDes = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/product-cat-des/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PRODUCTS_CAT_DES, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeProductsCatDes = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/product-cat-des`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PRODUCTS_CAT_DES, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col, Select } from 'antd';
import { isMobile } from 'react-device-detect';
import { _newLine } from '../../utils/helpers';
import BaseRadioList from '../../components/Elements/BaseRadios';
// actions
import { chatGPTCreate, chatGPTUpdate } from '../../redux/actions/ChatGptActions';

class ChatGptForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.google_service_account;
            if (!err) {
                if (this.props.currentData) {
                    await this.props.chatGPTUpdate(this.props.currentData.id, values);
                    this.onCancel()
                } else {
                    await this.props.chatGPTCreate(values);
                    this.props.reloadData()
                    this.onCancel()
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={[
                    <Button key="back" onClick={() => this.onCancel()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentData ? "Cập nhật" : "Thêm"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.email : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Api key">
                                    {getFieldDecorator('api_key', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.api_key : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chatGPTCreate: (data) => dispatch(chatGPTCreate(data)),
        chatGPTUpdate: (id, data) => dispatch(chatGPTUpdate(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ChatGptForm' })(ChatGptForm));

import {
    GET_ALL_STORE,
    GET_ALL_STORE_BY_ACTIVE,
    GET_SPECIFIC_STORE,
    UPDATE_SPECIFIC_STORE,
    CREATE_NEW_STORE,
    REMOVE_STORE,
    GET_ALL_STORE_BY_FILED
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    stores: [],
    currentStore: null,
    store_active_list: [],
    store_by_field_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 50
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_STORE: {
            return {
                ...state,
                stores: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_ALL_STORE_BY_ACTIVE: {
            return {
                ...state,
                store_active_list: action.payload,
            }
        }
        case GET_ALL_STORE_BY_FILED: {
            return {
                ...state,
                store_by_field_list: action.payload,
            }
        }
        case GET_SPECIFIC_STORE: {
            return { ...state, currentStore: action.payload }
        }

        case UPDATE_SPECIFIC_STORE: {
            let index = state.stores.findIndex((store) => {
                return store.id == action.payload.id;
            });

            let temp = [...state.stores];
            temp[index] = action.payload;
            return { ...state, stores: temp }
        }
        case REMOVE_STORE: {
            let temp = state.stores.filter(store => {
                return action.payload.indexOf(store.id) < 0;
            });
            return { ...state, stores: temp }
        }
        default: return { ...state };
    }
}

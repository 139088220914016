import api from '../../utils/api';
import {
    GET_ALL_WORKFLOW,
    GET_SPECIFIC_WORKFLOW,
    UPDATE_SPECIFIC_WORKFLOW,
    CREATE_NEW_WORKFLOW,
    REMOVE_WORKFLOW,
    UPDATE_PROCESS_WORKFLOW,
    UPDATE_STATUS_WORKFLOW
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllData = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/workflow-management', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_WORKFLOW, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/workflow-management', data).then(res => {
            dispatch({ type: CREATE_NEW_WORKFLOW, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/workflow-management/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_WORKFLOW, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateData = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/workflow-management/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_WORKFLOW, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateProcess = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/workflow-management/update-process/${id}`, data).then(res => {
            dispatch({ type: UPDATE_PROCESS_WORKFLOW, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateStatus = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/workflow-management/update-status/${id}`, data).then(res => {
            dispatch({ type: UPDATE_STATUS_WORKFLOW, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeData = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/workflow-management`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_WORKFLOW, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const approvedData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/workflow-management/approved/many`, data).then(res => {
            //console.log(res)
            dispatch({
                type: UPDATE_SPECIFIC_WORKFLOW,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg)
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

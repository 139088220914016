import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Modal, Row, Divider, Col, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import UploadImage from './UploadImage';
import { isMobile } from 'react-device-detect';
import { customDataImageUpload } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications'

// actions
import { updateDesign } from "../../redux/actions/DesignAction";

class DesignUploadFileForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            design_front_side_file: [],
            design_back_side_file: [],
            design_left_chest_file: [],
            design_right_sleeve_file: [],
            design_left_sleeve_file: [],
            design_collar_file: [],
            design_mockup_file: [],
            uploadPercentage: 0
        };
    }

    componentDidMount() {

    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    delete values.design_front_side
                    delete values.design_back_side
                    delete values.design_left_chest
                    delete values.design_right_sleeve
                    delete values.design_left_sleeve
                    delete values.design_collar
                    const { design } = this.props;
                    const {
                        design_front_side_file,
                        design_back_side_file,
                        design_left_chest_file,
                        design_right_sleeve_file,
                        design_left_sleeve_file,
                        design_collar_file,
                        design_mockup_file
                    } = this.state;
                    if (design_mockup_file.length) {
                        values.design_mockup_file = customDataImageUpload(design_mockup_file)
                    }
                    if (design_front_side_file.length) {
                        values.design_front_side_file = customDataImageUpload(design_front_side_file)
                    }
                    if (design_back_side_file.length) {
                        values.design_back_side_file = customDataImageUpload(design_back_side_file)
                    }
                    if (design_left_chest_file.length) {
                        values.design_left_chest_file = customDataImageUpload(design_left_chest_file)
                    }
                    if (design_right_sleeve_file.length) {
                        values.design_right_sleeve_file = customDataImageUpload(design_right_sleeve_file)
                    }
                    if (design_left_sleeve_file.length) {
                        values.design_left_sleeve_file = customDataImageUpload(design_left_sleeve_file)
                    }
                    if (design_collar_file.length) {
                        values.design_collar_file = customDataImageUpload(design_collar_file)
                    }
                    if (design) {
                        await this.props.updateDesign(design.id, values).then(res => {
                            this.props.reloadData();
                            this.onClose();
                        });
                    }
                    //console.log('values: ', values)
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            design_front_side_file: [],
            design_back_side_file: [],
            design_left_chest_file: [],
            design_right_sleeve_file: [],
            design_left_sleeve_file: [],
            design_collar_file: [],
            design_mockup_file: []
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    onChangeUploadImage = (data, position) => {
        this.setState((prevState) => ({
            isResetUpload: false,
            [position]: data,
        }));
        NotificationManager.success("Nạp file thành công")
    };

    render() {
        const { visible } = this.props;
        const { isLoading, isResetUpload, uploadPercentage } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title="Upload file thiết kế"
                width={isMobile ? '100%' : '50%'}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.props.onCancel()}
                confirmLoading={isLoading}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.props.onCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            icon={<UploadOutlined />}
                            onClick={(e) => this.submit(e)}
                        >
                            Upload
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Mockup" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_front_side', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_mockup_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Mặt trước" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_front_side', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_front_side_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Mặt sau" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_back_side', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_back_side_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Ngực trái" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_left_chest', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_left_chest_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tay áo phải" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_right_sleeve', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_right_sleeve_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tay áo trái" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_left_sleeve', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_left_sleeve_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Cổ áo" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        {getFieldDecorator('design_collar', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={6}
                                                accept=".png, .jpeg, .jpg"
                                                onChangeData={(data) => this.onChangeUploadImage(data, "design_collar_file")}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ) : null
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateDesign: (id, data) => dispatch(updateDesign(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DesignUploadFileForm" })(DesignUploadFileForm));

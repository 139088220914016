import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, TreeSelect, Row, Col } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';

import { getTrademarkCategoryActive, createTrademarkCategory, updateTrademarkCategory } from '../../redux/actions/TrademarkCategoryActions';
import { _newLine, makeTree } from '../../utils/helpers';

class TrademarkCategoryForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getTrademarkCategoryActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateTrademarkCategory(this.props.currentItem.id, values);
                    this.onClose();
                } else {
                    await this.props.createTrademarkCategory(values);
                    //this.onClose();
                    this.props.reloadData()
                    this.props.form.resetFields();
                }
            }
        });
    }

    onClose() {
        this.props.reloadData()
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        const { } = this.state;
        var { visible, currentItem, trademark_category_active } = this.props;

        const { getFieldDecorator } = this.props.form;
        const trademarkCategoryTree = makeTree(trademark_category_active || [], 0);

        return (
            <Modal
                visible={visible}
                title={currentItem ? "Sửa bản ghi" : "Tạo mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.onClose()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentItem ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Danh mục">
                                    {getFieldDecorator('parent', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.parent : 0
                                    })(
                                        <TreeSelect
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={trademarkCategoryTree}
                                            showSearch
                                            multiple={false}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn một"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: currentItem ? _newLine(currentItem.note) : ""
                            })(
                                <Input.TextArea />
                            )}
                        </Form.Item>
                        <Form.Item label="Trạng thái">
                            {getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentItem ? currentItem.status : 1
                            })(
                                <BaseRadioList
                                    options={[
                                        { label: "Kích hoạt", value: 1 },
                                        { label: "Chưa kích hoạt", value: 0 }
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        trademark_category: state.TrademarkCategoryReducer.trademark_category,
        trademark_category_active: state.TrademarkCategoryReducer.trademark_category_active
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createTrademarkCategory: (data) => dispatch(createTrademarkCategory(data)),
        updateTrademarkCategory: (id, data) => dispatch(updateTrademarkCategory(id, data)),
        getTrademarkCategoryActive: (filter) => dispatch(getTrademarkCategoryActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TrademarkCategoryForm' })(TrademarkCategoryForm));

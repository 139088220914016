import {
    GET_LIST_ALL,
    GET_ONE_IDEA,
    GET_LIST_APPROVED,
    GET_ALL_IDEA_SIMILAR,
    GET_LIST_OVERVIEW
} from '../actions/types';

const INIT_STATE = {
    ideas: [],
    ideaSimilar: [],
    ideas_approved: [],
    idea_overview: [],
    currentIdea: null,
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_ALL:
            return {
                ...state,
                ideas: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_LIST_OVERVIEW:
            return {
                ...state,
                idea_overview: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_ALL_IDEA_SIMILAR: {
            return {
                ...state,
                ideaSimilar: action.payload.data
            }
        }
        case GET_LIST_APPROVED:
            return {
                ...state,
                ideas_approved: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_ONE_IDEA:
            return {
                ...state,
                currentIdea: action.payload
            };
        default:
            return { ...state }
    }
};

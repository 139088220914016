import {
    GET_LIST_PROJECT,
    UPDATE_SPECIFIC_PROJECT,
    CREATE_NEW_PROJECT,
    REMOVE_PROJECT,
    GET_PROJECT_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    project_list: [],
    project_active_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_PROJECT: {
            return {
                ...state,
                project_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_PROJECT_ACTIVE: {
            return {
                ...state,
                project_active_list: action.payload.data,
            }
        }

        case CREATE_NEW_PROJECT: {
            return {
                ...state,
                project_list: [action.payload, ...state.project_list],
                project_active_list: [action.payload, ...state.project_active_list],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_PROJECT: {
            let index = state.project_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.project_list];
            temp[index] = action.payload;
            //
            let index2 = state.project_active_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp2 = [...state.project_active_list];
            temp[index2] = action.payload;

            return { ...state, project_list: temp, project_active_list: temp2 }
        }
        case REMOVE_PROJECT: {
            let temp = state.project_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.project_active_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, project_list: temp, project_active_list: temp2 }
        }
        default: return { ...state };
    }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Divider } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import { _newLine, customDataImageUpload, downloadFromLink } from '../../utils/helpers';
import ShowListCurrentImage from '../../components/ShowListCurrentImage';
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';

// actions
import { updateIdea } from "../../redux/actions/IdeaAction";
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getNicheByActive } from '../../redux/actions/NicheActions';

class IdeaEditForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            activeTab: '',
            isResetUpload: false,
            images: [],
            currentImages: [],
            files: [],
            select: false,
            currentFiles: [],
        };
    }

    componentDidMount() {
        this.props.getProductTypeByActive();
        this.props.getNicheByActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.idea != this.props.idea) {
            if (nextProps?.idea?.images_demo && Array.isArray(nextProps?.idea?.images_demo)) {
                this.setState({ currentImages: nextProps?.idea?.images_demo })
            } else {
                this.setState({ currentImages: [] })
            }
            this.setState({
                currentFiles: nextProps?.idea?.files,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isLoading: true });
                const { currentImages, images, currentFiles, files } = this.state;
                values.images_demo = customDataImageUpload(images, currentImages);
                values.files = [...currentFiles, ...files];
                //console.log(values)
                try {
                    await this.props.updateIdea(this.props.idea.id, values);
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChangeTab(activeTab) {
        this.setState({ activeTab })
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            images: [],
            files: [],
            select: !this.state.select
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            images: data
        });
        const { images } = this.state;
        this.props.form.setFieldsValue({
            images_demo: images
        });
        NotificationManager.success("Image complete.");
    }

    deleteCurrentImages = (id) => {
        const items = [...this.state.currentImages];
        this.setState({ currentImages: items.filter(item => item.id !== id) });
    }

    onChangeFiles = (files) => {
        this.setState({
            ...this.state,
            files: files,
        });
    }

    deleteCurrentFiles = (name) => {
        const items = [...this.state.currentFiles];
        this.setState({ currentFiles: items.filter(item => item.name !== name) });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    render() {
        const { visible, idea, producttype, niche } = this.props;
        const { isLoading, isResetUpload, currentImages, currentFiles } = this.state;
        const { getFieldDecorator } = this.props.form;

        const currentFilesList = [];
        if (idea) {
            if (currentFiles && Array.isArray(currentFiles))
                currentFiles.map(item => {
                    currentFilesList.push(
                        <div>
                            <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                            <DeleteOutlined
                                title="Xóa"
                                className="btn-delete-img"
                                onClick={() => this.deleteCurrentFiles(item?.name)}
                            />
                        </div >
                    )
                })
        }

        return (
            <Drawer
                visible={visible}
                title={`Cập nhật idea: #${idea?.id}`}
                width={isMobile ? '100%' : '50%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Tên sản phẩm">
                                        {getFieldDecorator("name", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: idea?.name ?? null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Loại sản phẩm">
                                        {getFieldDecorator("product_type_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: idea?.product_type_id ?? null
                                        })(
                                            <BaseSelect
                                                options={producttype}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn loại sản phẩm"
                                                showSearch
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Niche">
                                        {getFieldDecorator("niche_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: idea?.niche_id ?? null
                                        })(
                                            <BaseSelect
                                                options={niche}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn Niche"
                                                showSearch
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="File đính kèm">
                                        {getFieldDecorator('attach_file', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: ''
                                        })(
                                            <DraggerFile onChangeData={this.onChangeFiles} select={this.state.select} />
                                        )}
                                        {currentFilesList}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Mô tả yêu cầu thiết kế">
                                        {getFieldDecorator('request_des_for_design', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: _newLine(idea?.request_des_for_design)
                                        })(
                                            <Input.TextArea rows={4} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: _newLine(idea?.note)
                                        })(
                                            <Input.TextArea rows={4} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ảnh sản phẩm mẫu (tối đa 4 ảnh)" className='flex-upload'>
                                        <ShowListCurrentImage currentImages={currentImages} onDelete={this.deleteCurrentImages} />
                                        {getFieldDecorator('images_demo', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={4}
                                                onChangeData={this.onChange}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
        producttype: state.ProductType.producttype,
        niche: state.NicheReducer.niche
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateIdea: (id, data) => dispatch(updateIdea(id, data)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        getNicheByActive: (filter) => dispatch(getNicheByActive(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "IdeaEditForm" })(IdeaEditForm));

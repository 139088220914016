import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Breadcrumb, Tag } from 'antd';
import { isMobile, MobileView } from 'react-device-detect';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import { NoticeBar } from 'antd-mobile';
import moment from 'moment';

var { Content } = Layout;

class AppLayout extends Component {

    componentDidMount() {
        //console.log(this.state)
    }

    render() {
        const { authUser } = this.props;
        if (authUser) {
            var diffDate = authUser.expire ? moment(authUser.expire).diff(moment().startOf('day'), 'days') : 0;
        } else {
            diffDate = '';
        }
        return (
            <Layout>
                <AppHeader />
                <Layout>
                    {
                        isMobile ? null : <AppSidebar />
                    }
                    <Layout style={{ padding: '0 10px 10px' }}>
                        {
                            authUser ? (
                                authUser.role_code !== "ADMIN" ? (
                                    <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }} mode="closable">
                                        Thời hạn sử dụng CMS của bạn đến ngày  <b>{moment(authUser.expire).format('DD-MM-YYYY')} </b> &nbsp;
                                        {
                                            diffDate ? (
                                                <>
                                                    {
                                                        diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : null
                                                    }
                                                </>
                                            ) : (
                                                <Tag color="#f50">Hết hạn hôm nay</Tag>
                                            )
                                        }
                                    </NoticeBar>
                                ) : null
                            ) : null
                        }
                        <Content className="content-wrapper">
                            {this.props.children}
                        </Content>
                        <AppFooter />
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
const mapStateToProps = state => {
    //console.log(state)
    return {
        authUser: state.auth.authUser
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

//export default AppLayout;
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
import api from '../../utils/api';
import {
    LOG_MAIL_TRACKING_GET_LIST,
    REMOVE_LOG_MAIL_TRACKING
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListLogMailTracking = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/log-mail-tracking', { params: filter }).then(res => {
            dispatch({ type: LOG_MAIL_TRACKING_GET_LIST, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeLogMailTracking = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/log-mail-tracking`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_LOG_MAIL_TRACKING, payload: ids });
            NotificationManager.success(res.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
import React, { Component } from 'react';
import { Form } from "@ant-design/compatible";
import { Table, Modal, Input, Space, Drawer, Button, Row, Image, Col, Popconfirm, TreeSelect, Select } from 'antd';
import { ImportOutlined, DeleteOutlined, ForwardOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { makeTree } from '../../utils/helpers';

// actions
import { createQueue } from '../../redux/actions/ProductsImportAction';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getAllTagsInProductForm, createdTags } from '../../redux/actions/ProductsTagsAction';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getStoreByField } from '../../redux/actions/StoreActions';

const { Option } = Select;

class ImporterViewForm extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            isLoadingCat: false,
            isLoadingTags: false,
            visible_modal: false,
            items: [],
            loadingTable: false,
            storeId: null,
            loadingAddTags: false,
            categoryId: null,
            categoryName: null,
            tags: []
        }
        this.nameField = React.createRef();
    }

    componentDidMount() {
        this.props.getStoreByField({ platform: ['pod', 'shield'] })
        this.props.getProductTypeByActive();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            const { items } = this.props;
            if (items) {
                this.setState({ items: items })
            }
        }
    }

    onChangeStore(value) {
        this.setState({ storeId: value })
        this.setState({ isLoadingCat: true, isLoadingTags: true })
        this.props.getAllCategories({ store_id: value }).then(res => {
            this.setState({ isLoadingCat: false })
        });
        this.props.getAllTagsInProductForm({ store_id: value }).then(res => {
            this.setState({ isLoadingTags: false })
        });
    }

    async onImporter(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            //console.log(values)
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { items, tags, categoryName } = this.state;
                    const params = {
                        store_id: values.store_id,
                        category_id: values.categories,
                        category_name: categoryName,
                        product_type_id: values.product_type_id,
                        tags: tags,
                        regular_price: values.regular_price,
                        sale_price: values.sale_price,
                        status: values.status,
                        visibility: values.visibility,
                        items: items
                    }
                    //console.log('import: ', params)
                    await this.props.createQueue(params).then(res => {
                        //console.log('res: ', res)
                        this.props.onSetMessage(`Thêm thành công (${res.length}) sản phẩm vào hàng đợi`, 'success')
                        this.handleCancel()
                        this.onClose()
                        this.props.reloadData()
                    }).catch(err => {
                        this.props.onSetMessage(err.response.data.msg, 'error')
                        this.handleCancel()
                        this.onClose()
                        this.props.reloadData()
                    });
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChangeItem(name, value, itemKey) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id === itemKey);
        let currentItem = items[index];
        currentItem[name] = value;
        this.setState({ items: items });
    }

    handleDelete = (id) => {
        const items = [...this.state.items];
        this.setState({ items: items.filter(item => item.id !== id) });
    };

    handleCancel() {
        this.props.form.resetFields();
        this.setState({ visible_modal: false, isLoading: false })
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ items: [], storeId: null })
    }

    async createTags(e) {
        //console.log(this.nameField)
        e.preventDefault();
        this.props.form.validateFields(['tagsAdd'], async (err, values) => {
            if (!err) {
                if (this.state.newTagsAddValue) {
                    this.setState({ loadingAddTags: true })
                    values.store_id = this.state.storeId;
                    values.name = this.state.newTagsAddValue;
                    delete values.tagsAdd
                    //console.log(values)
                    await this.props.createdTags(values).then(res => {
                        this.props.getAllTagsInProductForm({ store_id: this.state.storeId });
                        //this.nameField.current.state.value = '';
                        this.setState({ loadingAddTags: false })
                    })
                }
            }
        });
    }

    handleCategoryChange = (id, name) => {
        this.setState({
            categoryId: id,
            categoryName: name
        });
    };

    handleTagsChange = (id, e) => {
        var filtered = e.filter(function (el) {
            return el.value != null;
        });
        const result = filtered.map(item => {
            return {
                tagsId: item.value,
                tagsName: item.children
            }
        })
        this.setState({ tags: result });
    };

    render() {
        var {
            isLoading,
            visible_modal,
            items,
            isLoadingTags,
            loadingTable,
            isLoadingCat,
            storeId,
            loadingAddTags
        } = this.state;
        const { visible, stores, categories, tags, imageList, producttype } = this.props;
        const { getFieldDecorator } = this.props.form;

        const catTree = makeTree(categories || [], 0);
        const defaultValueTags = [];
        if (tags) {
            //console.log("Tag: ", tags);
            tags.map((tag) => {
                defaultValueTags.push(tag.id);
            })
        }
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '40px',
            },
            {
                title: 'Image',
                width: '80px',
                render: (text, record) => {
                    if (typeof record.thumbnail != 'undefined') {
                        return (
                            <div>
                                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                    <Image
                                        width={50}
                                        src={record.thumbnail?.data}
                                    />
                                </div>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Gallery',
                width: '80px',
                render: (text, record) => {
                    if (Array.isArray(record.gallery)) {
                        var width;
                        if (record.gallery.length <= 1) {
                            width = 50;
                        } else if (record.gallery.length <= 2) {
                            width = 25;
                        } else {
                            width = 20;
                        }
                        return (
                            <div>
                                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                    <Image.PreviewGroup>
                                        {
                                            record.gallery.map(item => {
                                                return (
                                                    <Image
                                                        width={width}
                                                        src={item?.data}
                                                    />
                                                )
                                            })
                                        }
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Tên sản phẩm',
                dataIndex: 'folderName',
                key: 'folderName',
                render: (text, record) => (
                    <Input defaultValue={text} onBlur={(e) => this.onChangeItem('folderName', e.target.value, record.id)} />
                )
            },
            {
                title: 'Mô tả',
                dataIndex: 'description',
                key: 'description',
                render: (text, record) => (
                    <Input.TextArea defaultValue={text} onBlur={(e) => this.onChangeItem('description', e.target.value, record.id)} />
                )
            },
            {
                title: 'Regular price ($)',
                dataIndex: 'regular_price',
                key: 'regular_price',
                width: '140px',
                render: (text, record) => (
                    <Input onBlur={(e) => this.onChangeItem('regular_price', e.target.value, record.id)} />
                )
            },
            {
                title: 'Sale price ($)',
                dataIndex: 'sale_price',
                key: 'sale_price',
                width: '120px',
                render: (text, record) => (
                    <Input onBlur={(e) => this.onChangeItem('sale_price', e.target.value, record.id)} />
                )
            },
            {
                title: "Action",
                width: 70,
                align: 'center',
                render: (text, record, i) =>
                    items.length >= 1 ? (
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.id)}>
                            <DeleteOutlined style={{ fontSize: 18, color: 'red' }} />
                        </Popconfirm>
                    ) : null,
            }
        ];

        return (
            <div>
                <Drawer
                    visible={visible}
                    width="100%"
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    closable={false}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Space>
                                <Button key="back" onClick={() => this.onClose()}>
                                    Huỷ
                                </Button>
                                <Button
                                    key="submit"
                                    type="primary"
                                    icon={<ForwardOutlined />}
                                    onClick={(e) => this.setState({ visible_modal: true })}
                                >
                                    NEXT
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <Table
                        rowKey="id"
                        size='small'
                        bordered
                        tableLayout='auto'
                        columns={columns}
                        //dataSource={items.sort(dynamicSort('folderName')) || []}
                        dataSource={items || []}
                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100'] }}
                        loading={loadingTable}
                        title={() => <>List thành công: <b>{items.length} sản phẩm</b> - <b>{imageList.length} ảnh</b></>}
                        scroll={{
                            x: 'max-content'
                        }}
                    />
                    <Modal
                        closable={!isLoading}
                        visible={visible_modal}
                        title="OPTIONS"
                        onOk={(e) => this.onImporter(e)}
                        width={isMobile ? '100%' : '50%'}
                        onCancel={() => this.handleCancel()}
                        maskClosable={false}
                        footer={[
                            <Button disabled={isLoading} key="back" onClick={() => this.handleCancel()}>
                                Huỷ
                            </Button>,
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                icon={<ImportOutlined />}
                                onClick={(e) => this.onImporter(e)}
                            >
                                Import
                            </Button>,
                        ]}
                    >
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Store">
                                        {getFieldDecorator("store_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                        })(
                                            <BaseSelect
                                                disabled={isLoading}
                                                options={stores}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch={true}
                                                style={{ width: '100%' }}
                                                defaultText="Chọn store"
                                                onChange={(value) => this.onChangeStore(value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Loại sản phẩm">
                                        {getFieldDecorator("product_type_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <BaseSelect
                                                disabled={isLoading}
                                                options={producttype || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn loại sản phẩm"
                                                showSearch
                                                allowClear
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label='Categories'>
                                {getFieldDecorator("categories", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng chọn!",
                                        },
                                    ]
                                })(
                                    <TreeSelect
                                        disabled={isLoading}
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                        treeData={catTree}
                                        multiple={true}
                                        showSearch
                                        loading={isLoadingCat}
                                        treeNodeFilterProp='title'
                                        allowClear
                                        placeholder="Chọn một hoặc nhiều categories"
                                        treeDefaultExpandAll
                                        onChange={this.handleCategoryChange}
                                    />
                                )}
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={24} md={10}>
                                    <Form.Item label='Add tags'>
                                        <Input.Group compact>
                                            <Input
                                                disabled={!storeId || isLoading}
                                                ref={this.nameField}
                                                name="tagsAdd"
                                                onKeyPress={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                onBlur={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                onPaste={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                allowClear
                                                style={{ width: '80%' }}
                                            />
                                            <Button disabled={!storeId || isLoading} onClick={(e) => this.createTags(e)} type="primary">Add</Button>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={14}>
                                    <Form.Item label='Select tags'>
                                        {getFieldDecorator("tags", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: [] //defaultValueTags
                                        })(
                                            <Select
                                                disabled={isLoading}
                                                loading={isLoadingTags}
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Chọn một hoặc nhiều tags"
                                                onChange={this.handleTagsChange}
                                                optionLabelProp="label"
                                                showSearch={true}
                                                allowClear={true}
                                            >
                                                {
                                                    tags && tags.length ? (
                                                        <>
                                                            {
                                                                tags.map((item) => {
                                                                    return (
                                                                        <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Regular price ($)">
                                        {getFieldDecorator("regular_price", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                        })(
                                            <Input disabled={isLoading} placeholder='21.95' />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label='Sale price ($)'>
                                        {getFieldDecorator("sale_price", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                        })(
                                            <Input disabled={isLoading} placeholder='15.5' />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label='Status'>
                                        {getFieldDecorator("status", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: 'pending'
                                        })(
                                            <BaseRadios
                                                disabled={isLoading}
                                                options={
                                                    [
                                                        { value: 'publish', label: "Publish" },
                                                        { value: 'draft', label: "Draft" },
                                                        { value: 'pending', label: "Pending" },
                                                        { value: 'private', label: "Private" }
                                                    ]
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Drawer>
            </div >
        )
    }
}

const mapStateToProps = state => {
    //console.log(state)
    return {
        stores: state.StoreReducer.store_by_field_list,
        categories: state.ProductsCategories.categories,
        tags: state.ProductsTags.tags_in_product_form?.items,
        producttype: state.ProductType.producttype
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getAllTagsInProductForm: filter => dispatch(getAllTagsInProductForm(filter)),
        createQueue: (data) => dispatch(createQueue(data)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        createdTags: (data) => dispatch(createdTags(data)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ImporterViewForm" })(ImporterViewForm));

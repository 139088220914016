import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { EyeOutlined, ExportOutlined, SyncOutlined, TransactionOutlined, FireOutlined, ClockCircleOutlined, CheckSquareOutlined, CheckCircleOutlined, ReloadOutlined, QuestionCircleOutlined, IssuesCloseOutlined, ImportOutlined } from '@ant-design/icons';
import { Modal, Table, Tag, Divider, DatePicker, Tooltip, Row, Col, Card, Button, InputNumber, Typography, Select, Alert, Tabs, Badge } from 'antd';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import qs from 'qs';
import moment from 'moment';
import { decode } from 'html-entities';
import _ from 'lodash';
import { cleanObject, downloadFromLink } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import TextTruncate from 'react-text-truncate';
import { NotificationManager } from 'react-notifications'
import OrderCostForm from './OrderCostForm';
import OrderDetailDrawer from './OrderDetailDrawer';
import { checkPermission } from '../../utils/permission';
import StatisticNumber from '../../components/StatisticNumber';
import { isMobile } from 'react-device-detect';
import ImportTracking from './ImportTracking';
import ReactHtmlParser from 'react-html-parser';
import appConfig from '../../config';
import SyncCostLenful from './SyncCostLenful';
import DynamicTable from '../../components/DynamicTable';

// actions
import {
    getListAllOrders,
    updateManyManagement,
    getOneOrder,
    getOneOrderManagement,
    getStatistical,
    syncOrder,
    exportOrders
} from '../../redux/actions/OrdersAction';
import { getStoreByField } from '../../redux/actions/StoreActions';
const { CheckableTag } = Tag;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/orders-list',
        breadcrumbName: 'Danh sách đơn hàng',
    }
]

const { Option } = Select;

const filterTags = [
    {
        value: 'processing',
        name: 'Đang xử lý'
    },

    {
        value: 'completed',
        name: 'Đã hoàn thành'
    },

    {
        value: 'cancelled',
        name: 'Đã hủy'
    },
    {
        value: 'refunded',
        name: 'Đã hoàn lại tiền'
    },
    {
        value: 'pending',
        name: 'Chờ thanh toán'
    },
    {
        value: 'on-hold',
        name: 'Tạm giữ'
    },
    {
        value: 'failed',
        name: 'Thất bại'
    }
]

class OrdersList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentData: null,
            isOpenPopupSync: false,
            syncLoading: false,
            visible_trand_to_user: false,
            loadingTransToUser: false,
            isOpenDetail: false,
            currentOrder: null,
            isOpenFormImportTracking: false,
            importResponseMessage: null,
            importResponseType: null,
            loadingCostForm: false,
            isOpenSyncCostLenful: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getStoreByField({ platform: ['pod', 'dropship', 'shield'] })
        this.props.getStatistical(query);
        this.props.getListAllOrders(query).then(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAllOrders(query).then(() => {
                this.setState({ isLoading: false });
            })
            this.props.getStatistical(query);
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/orders-list',
            search: qs.stringify(query),
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getStatistical(query);
        this.props.getListAllOrders(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        //console.log(value)
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/orders-list',
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    onCancel() {
        this.setState({ selectedRowKeys: [], isOpenPopupSync: false, visible_trand_to_user: false, loadingTransToUser: false })
        this.props.form.resetFields();
    }

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getOneOrderManagement(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onDetail(order_id, store_id) {
        //console.log(order_id, store_id, id)
        this.setState({ isLoading: true })
        this.props.getOneOrder(order_id, { store_id: store_id }).then(res => {
            //console.log("res: ", res)
            this.toggleOrderDetail(true, res);
            this.setState({ isLoading: false })
        })
    }

    toggleOrderDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentOrder: data });
    }

    reloadOMnData = (order_id, store_id) => {
        this.setState({ loadingCostForm: true })
        this.props.getOneOrder(order_id, { store_id: store_id }).then(res => {
            this.setState({ loadingCostForm: false })
            this.setState({ currentOrder: res });
        });
    }

    async onSyncOrder(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ syncLoading: true });
                try {
                    //console.log(values)
                    await this.props.syncOrder(values);
                    this.reloadData();
                    this.setState({ syncLoading: false });
                } catch (error) {
                    this.setState({ syncLoading: false });
                }
            }
        });
    }

    async onTransferToUserFuiFill(e) {
        e.preventDefault();
        this.props.form.validateFields(['user_id'], async (err, values) => {
            if (!err) {
                this.setState({ loadingTransToUser: true })
                values.id = this.state.selectedRowKeys;
                await this.props.updateManyManagement(values);
                this.reloadData();
                this.onCancel()
            }
        });
    }

    onCheckTag(value, checked) {
        //console.log(value, checked)
        var query = qs.parse(this.props.location.search.slice(1));
        var temp = query.order_status ? query.order_status.split(',') : [];
        if (checked) temp.push(value);
        else temp = temp.filter(item => item != value);

        this.onChangeFilter('order_status', temp.join(','));
    }

    onChangeTab(value) {
        console.log(value)
    }

    onSetMessage = (message, type) => {
        this.setState({ importResponseMessage: message, importResponseType: type })
    }

    closeFromImportTracking = () => {
        this.setState({ isOpenFormImportTracking: false })
    }

    do_export() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportOrders(query).then((res) => {
            //console.log("res: ", res)
            setTimeout(() => {
                const response = {
                    file: `${appConfig.MEDIA_URL}/${res.path}`,
                };
                downloadFromLink(response.file, "_ecompublic_danh_sach_don_hang.xlsx");
            }, 100);
            this.setState({ isLoadingExport: false });
        }).catch(err => {
            this.setState({ isLoadingExport: false });
        });

    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenForm,
            currentData,
            isOpenPopupSync,
            syncLoading,
            visible_trand_to_user,
            loadingTransToUser,
            isOpenDetail,
            currentOrder,
            isOpenFormImportTracking,
            importResponseMessage,
            importResponseType,
            isLoadingExport,
            loadingCostForm,
            isOpenSyncCostLenful
        } = this.state;
        const { list_orders, pagination, statisticalOrder, stores, authUser, users, printing_unit_active } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        const { getFieldDecorator } = this.props.form;
        var hasSelected = selectedRowKeys.length > 0;
        //console.log('currentOrder: ', currentOrder)

        console.log("list_orders: ", list_orders)

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        var alllowViewStatistical = false;
        if (authUser.role_code == 'ADMIN') {
            alllowViewStatistical = true;
        }

        const columns = [
            {
                title: 'STT',
                dataIndex: 'id',
                key: 'id',
                sorter: true,
                width: '50px',
                fixed: isMobile ? '' : 'left'
            },
            {
                title: '#',
                align: 'center',
                key: 'action',
                fixed: isMobile ? '' : 'left',
                render: (text, record) => {
                    return (
                        <>
                            {
                                checkPermission('orders', 'detail') ? (
                                    <a className='item-action-btn' onClick={() => this.onDetail(record.order_id, record.store_id)}>
                                        <Tooltip title="Xem chi tiết đơn hàng">
                                            <EyeOutlined className='primary-color' />
                                        </Tooltip>
                                    </a>
                                ) : null
                            }

                        </>
                    )
                }
            },
            {
                title: 'Order',
                dataIndex: 'order_id',
                sorter: true,
                key: 'order_id',
                width: '210px',
                fixed: isMobile ? '' : 'left',
                render: (text, record) => {
                    var badge_text = '';
                    if (record.shipping_lines && record.shipping_lines?.name == 'Priority Shipping (3-5 days)') {
                        badge_text = "FAST"
                    }
                    var name = "" + record.order_id + " " + record.billing.first_name + " " + record.billing.last_name;
                    var is_blacklist = false;
                    if (record?.is_blacklist && Array.isArray(record?.is_blacklist?.value) && record?.is_blacklist?.value.length > 0) {
                        is_blacklist = true
                    }

                    if (checkPermission('orders', 'view_on_website')) {
                        return (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Tooltip placement='right' title={
                                    <ul style={{ listStyle: "none", margin: '0', padding: '0', width: '100%' }}>
                                        {
                                            record?.line_items.map((item, index) => {
                                                return (
                                                    <li className='tooltip-list-item'>
                                                        <Paragraph copyable={{
                                                            tooltips: false,
                                                            text: record.store_url + '/product/' + item.slug,
                                                            format: 'text/plain',
                                                        }}
                                                            style={{ color: '#fff' }}
                                                        >
                                                            {index + 1} - {decode(item.name)}
                                                        </Paragraph>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                }>
                                    {is_blacklist ? (
                                        <Badge count="Blacklist" size='small' offset={[0, -6]} style={{ backgroundColor: '#000' }}>
                                            <Badge count={badge_text} size='small' offset={[0, -6]} style={{ marginRight: 60 }}>
                                                <a href={`${record.store_url}/wp-admin/post.php?post=${record.order_id}&action=edit`} target="_blank">
                                                    {name}
                                                </a>
                                            </Badge>
                                        </Badge>
                                    ) :
                                        <Badge count={badge_text} size='small' offset={[0, -6]}>
                                            <a href={`${record.store_url}/wp-admin/post.php?post=${record.order_id}&action=edit`} target="_blank">
                                                {name}
                                            </a>
                                        </Badge>
                                    }
                                </Tooltip>
                            </div >
                        )
                    } else {
                        return (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Tooltip placement='right' title={
                                    <ul style={{ listStyle: "none", margin: '0', padding: '0', width: '100%' }}>
                                        {
                                            record?.line_items.map((item, index) => {
                                                return (
                                                    <li className='tooltip-list-item'>
                                                        <Paragraph copyable={{
                                                            tooltips: false,
                                                            text: record.store_url + '/product/' + item.slug,
                                                            format: 'text/plain',
                                                        }}
                                                            style={{ color: '#fff' }}
                                                        >
                                                            {index + 1} - {decode(item.name)}
                                                        </Paragraph>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                }>
                                    {is_blacklist ? (
                                        <Badge count="Blacklist" size='small' offset={[0, -6]} style={{ backgroundColor: '#000' }}>
                                            <Badge count={badge_text} size='small' offset={[0, -6]} style={{ marginRight: 60 }}>
                                                <a className='item-action-btn primary-color'>
                                                    {name}
                                                </a>
                                            </Badge>
                                        </Badge>
                                    ) :
                                        <Badge count={badge_text} size='small' offset={[0, -6]}>
                                            <a className='item-action-btn primary-color'>
                                                {name}
                                            </a>
                                        </Badge>
                                    }
                                </Tooltip>
                            </div >
                        )
                    }
                }
            },
            {
                title: 'Country',
                align: 'center',
                width: '45px',
                key: 'country',
                render: (text, record) => {
                    if (record.shipping) {
                        return (
                            <>{record.shipping?.country}</>
                        )
                    }
                }
            },
            {
                title: "Gateway",
                dataIndex: 'payment_gateway',
                key: 'payment_gateway',
                sorter: false,
                render: (text, record) => {
                    var color = '#1BAE70';
                    if (text == 'lenful') {
                        color = '#293381';
                    } else if (text == 'N/A') {
                        color = '#dedede';
                    } else if (text == 'ecompublic') {
                        text = 'ecom';
                    }
                    if (text) {
                        return (
                            <Tag color={color} style={{ textTransform: 'capitalize' }}>{text}</Tag>
                        )
                    }
                }
            },
            {
                title: 'Store',
                align: 'left',
                dataIndex: 'store_name',
                sorter: true,
                key: 'store_name',
                width: '50px',
                render: (text, record) => {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            <Tooltip title={record.store_name}>
                                <TextTruncate text={record.store_name} line={1} truncateText="" />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Shield',
                align: 'left',
                dataIndex: 'proxy_url',
                sorter: true,
                key: 'proxy_url',
                width: '100px',
                render: (text, record) => {
                    if (record?.proxy_url) {
                        return (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Tooltip title={record?.proxy_url.replace(/^https:\/\//, '')}>
                                    <TextTruncate text={record?.proxy_url.replace(/^https:\/\//, '')} line={1} truncateText="" />
                                </Tooltip>
                            </div>
                        )
                    }
                }
            },
            {
                title: () => <Tooltip title="Tiền đặt hàng">Total</Tooltip>,
                dataIndex: 'order_amount',
                key: 'order_amount',
                sorter: true,
                align: 'left',
                render: (text, record) => {
                    return (
                        <div style={{ fontWeight: '500' }}>
                            {text}{record.currency_symbol}
                        </div>
                    )
                }
            },
            {
                title: () => <Tooltip title="Phí PayPal">Paypal</Tooltip>,
                dataIndex: 'fee_payment',
                key: 'fee_payment',
                sorter: true,
                align: 'left',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div style={{ fontWeight: '500' }}>
                                {text}{record.currency_symbol}
                            </div>
                        )
                    }
                }
            },
            // {
            //     title: 'Net paypal',
            //     dataIndex: 'net_paypal',
            //     key: 'net_paypal',
            //     align: 'left',
            //     render: (text, record) => {
            //         if (text) {
            //             return (
            //                 <div style={{ fontWeight: '500' }}>
            //                     {text}{record.currency_symbol}
            //                 </div>
            //             )
            //         }
            //     }
            // },
            {
                title: () => <Tooltip title="Phí thuê cổng thanh toán">Fee gateway</Tooltip>,
                dataIndex: 'fee_payment_gateway',
                key: 'fee_payment_gateway',
                sorter: true,
                align: 'left',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div style={{ fontWeight: '500' }}>
                                {text}{record.currency_symbol}
                            </div>
                        )
                    }
                }
            },
            // {
            //     title: 'Net gateway',
            //     dataIndex: 'net_payment_gateway',
            //     key: 'net_payment_gateway',
            //     align: 'left',
            //     render: (text, record) => {
            //         if (text) {
            //             return (
            //                 <div style={{ fontWeight: '500' }}>
            //                     {text}{record.currency_symbol}
            //                 </div>
            //             )
            //         }
            //     }
            // },
            {
                title: () => <Tooltip title="Phí thiết kế file in">Png</Tooltip>,
                dataIndex: 'png_design_amount',
                key: 'png_design_amount',
                sorter: true,
                align: 'left',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div style={{ fontWeight: '500' }}>
                                {text}{record.currency_symbol}
                            </div>
                        )
                    }
                }
            },
            {
                title: () => <Tooltip title="Phí fulfi + chuyển đổi">Cost</Tooltip>,
                align: 'left',
                sorter: true,
                dataIndex: 'fulfillment_amount',
                key: 'fulfillment_amount',
                render: (text, record) => {
                    return (
                        <div style={{ fontWeight: '500' }}>
                            {record.fulfillment_amount}{record.fulfillment_amount ? record.currency_symbol : null}
                        </div>
                    )
                }
            },
            {
                title: 'Profit',
                dataIndex: 'profit',
                key: 'profit',
                align: 'left',
                render: (text, record) => {
                    return (
                        <div className='profit'>
                            {record.profit}{record.profit ? record.currency_symbol : null}
                        </div>
                    )
                }
            },
            {
                title: 'Percent',
                dataIndex: 'percent',
                key: 'percent',
                align: 'left',
                render: (text, record) => {
                    var textColor = '#0040ff';
                    if (text <= 29) {
                        textColor = "#ff0000";
                    } else if (text >= 40) {
                        textColor = '#009900';
                    }
                    return (
                        <div style={{ fontWeight: '500', color: textColor }}>
                            {record.percent}%
                        </div>
                    )
                }
            },
            {
                title: 'Status',
                //align: 'center',
                dataIndex: 'status',
                key: 'status',
                width: '90px',
                sorter: true,
                render: (text, record) => {
                    var status = '';
                    if (record?.status == 'NewOrder') {
                        status = <Tag color='#00c331'>{record?.status}</Tag>
                    } else if (record?.status == 'Designing') {
                        status = <Tag color='#138086'>{record?.status}</Tag>
                    } else if (record?.status == 'FulFillment') {
                        status = <Tag color='#534666'>{record?.status}</Tag>
                    } else if (record?.status == 'Shipped') {
                        status = <Tag color='#cd7672'>{record?.status}</Tag>
                    } else if (record?.status == 'Delivered') {
                        status = <Tag color='green'>{record?.status}</Tag>
                    } else if (record?.status == 'OutForDelivery') {
                        status = <Tag color='#f00'>{record?.status}</Tag>
                    } else if (record?.status == 'Refund') {
                        status = <Tag color='yellow'>{record?.status}</Tag>
                    } else if (record?.status == 'issue_need_to_check') {
                        status = <Tag color='#a70d0d'>Need to check</Tag>
                    }
                    return (
                        <div>{status}</div>
                    )
                }
            },
            {
                title: 'Design',
                align: 'center',
                dataIndex: 'design_status',
                key: 'design_status',
                width: '50px',
                render: (text, record) => {
                    let design_status = '';
                    if (record?.design_status == 0) {
                        design_status = <Tooltip title="Chưa design"><ClockCircleOutlined style={{ color: "#faad14", fontSize: '18px' }} /></Tooltip>
                    } else if (record?.design_status == 1) {
                        design_status = <Tooltip title="Đã có file png"><CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} /></Tooltip>
                    } else if (record?.design_status == 2) {
                        design_status = <Tooltip title="Trạng thái dessign khác nhau cho mỗi sản phẩm"><IssuesCloseOutlined style={{ color: "#108fe9", fontSize: '18px' }} /></Tooltip>
                    }
                    return (
                        <div>{design_status}</div>
                    )
                }
            },
            {
                title: 'Order status',
                dataIndex: 'order_status',
                key: 'order_status',
                align: 'left',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    var tag = "";
                    if (record.order_status === 'pending') {
                        tag = <Tag color="#ffec3d">{text}</Tag>
                    } else if (record.order_status === 'processing') {
                        tag = <Tag color="#1890ff">{text}</Tag>
                    } else if (record.order_status === 'on-hold') {
                        tag = <Tag color="#ad8b00">{text}</Tag>
                    } else if (record.order_status === 'completed') {
                        tag = <Tag color="green">{text}</Tag>
                    } else if (record.order_status === 'cancelled') {
                        tag = <Tag color="#660000">{text}</Tag>
                    } else if (record.order_status === 'refunded') {
                        tag = <Tag color="#003a8c">{text}</Tag>
                    } else if (record.order_status === 'failed') {
                        tag = <Tag color="#f00">{text}</Tag>
                    }
                    return (
                        <div>{tag}</div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                width: '210px',
                dataIndex: 'note',
                key: 'note',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Tooltip title={record.note}>
                                    <TextTruncate text={record.note} line={1} truncateText="..." />
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Đơn vị in',
                align: 'left',
                width: '100px',
                dataIndex: 'printing_unit',
                key: 'printing_unit',
                render: (text, record) => {
                    if (Array.isArray(record.printing_unit)) {
                        return (
                            <>
                                {
                                    record.printing_unit.map(value => (
                                        <Tag>{value}</Tag>
                                    ))
                                }
                            </>
                        )
                    }
                }
            },
            {
                title: 'Mã in',
                align: 'left',
                width: '160px',
                key: 'print_code',
                render: (text, record) => {
                    if (record.print_code) {
                        return (
                            <div className='ecom-paragraph'>
                                <Paragraph copyable={{ tooltips: false }}>{record.print_code}</Paragraph>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Mã tracking',
                align: 'left',
                dataIndex: 'tracking_code',
                key: 'tracking_code',
                width: '120px',
                render: (text, record) => {
                    if (record.tracking_number && Array.isArray(record.tracking_number)) {
                        return (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <ul className="custom-ul">
                                    {
                                        record.tracking_number.map(item => {
                                            return (
                                                <li>
                                                    <a target='_blank' href={item.tracking_url}>
                                                        <Tooltip title={item.tracking_code}>
                                                            <TextTruncate text={decode(item.tracking_code)} line={1} truncateText="..." />
                                                        </Tooltip>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    }
                }
            },
            {
                title: () => <Tooltip title="Đồng bộ tracking lên cổng thanh toán">Sync <QuestionCircleOutlined /></Tooltip>,
                align: 'left',
                dataIndex: 'sync_tracking_status',
                key: 'sync_tracking_status',
                render: (text, record) => {
                    if (record.tracking_number && Array.isArray(record.tracking_number)) {
                        return (
                            <ul className="custom-ul">
                                {
                                    record.tracking_number.map(item => {
                                        return (
                                            <li>
                                                {
                                                    item.sync_payment_gateway == 'Done' ? (
                                                        <Tooltip title="Đồng bộ lên cổng thanh toán thành công">
                                                            <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                                {
                                                    item.sync_payment_gateway == 'None' ? (
                                                        <Tooltip title="Chưa đồng bộ lên cổng thanh toán">
                                                            <IssuesCloseOutlined style={{ color: "#f00", fontSize: '18px' }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                                {
                                                    item.sync_payment_gateway == 'WebDone' ? (
                                                        <Tooltip title="Submit lên store thành công">
                                                            <CheckSquareOutlined style={{ color: "green", fontSize: '18px' }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                                {
                                                    item.sync_payment_gateway == 'WebNone' ? (
                                                        <Tooltip title="Chưa submit lên store">
                                                            <IssuesCloseOutlined style={{ color: "#911212", fontSize: '18px' }} />
                                                        </Tooltip>
                                                    ) : null
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    }
                }
            },
            {
                title: 'FulFill User',
                dataIndex: 'fulfi_user',
                key: 'fulfi_user',
                sorter: false,
                align: 'left',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Tag>{text}</Tag>
                        )
                    } else {
                        return (
                            <Tag>N/A</Tag>
                        )
                    }
                }
            },
            {
                title: 'User update',
                dataIndex: 'full_name',
                key: 'full_name',
                sorter: false,
                align: 'left',
                render: (text, record) => {
                    return (
                        <>
                            <div>{text}</div>
                        </>
                    )
                }
            },
            {
                title: 'Created UTC+7',
                dataIndex: 'date_created_vn',
                key: 'date_created_vn',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Modified UTC+7',
                dataIndex: 'date_modified_vn',
                key: 'date_modified_vn',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        </>
                    )
                }
            }
        ];

        // remove column
        if (authUser.id == 1085) { // 1085 = lenful
            columns.filter(x => x.key === 'fulfi_user').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'produfull_namect_type_name').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'sync_tracking_status').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'tracking_code').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'printing_unit').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'png_design_amount').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'fulfillment_amount').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'profit').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'percent').forEach(x => columns.splice(columns.indexOf(x), 1));
            columns.filter(x => x.key === 'note').forEach(x => columns.splice(columns.indexOf(x), 1));
        }

        var filters = [
            <BaseSelect
                options={[
                    { value: 'ecompublic', label: 'Ecompublic' },
                    { value: 'lenful', label: 'Lenful' },
                    { value: 'N/A', label: 'N/A' }
                ]}
                onChange={(value) => this.onChangeFilter("payment_gateway", value)}
                placeholder="Cổng thanh toán"
                style={{ width: '170px' }}
                defaultValue={query.payment_gateway ? query.payment_gateway : null}
            />,
            <BaseSelect
                options={[
                    { value: 'NewOrder', label: 'New Order' },
                    { value: 'Designing', label: 'Designing' },
                    { value: 'FulFillment', label: 'FulFillment' },
                    { value: 'Shipped', label: 'Shipped' },
                    { value: 'Delivered', label: 'Delivered' },
                    { value: 'OutForDelivery', label: 'Out for delivery' },
                    { value: 'Refund', label: 'Refund' },
                    { value: 'issue_need_to_check', label: 'Issue need to check' }
                ]}
                onChange={(value) => this.onChangeFilter("status", value)}
                placeholder="Status"
                style={{ width: '150px' }}
                defaultValue={query.status ? query.status : null}
            />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />,
            <BaseSelect
                options={printing_unit_active}
                optionValue="id"
                optionLabel="name"
                placeholder="Đơn vị in"
                showSearch={true}
                style={{ width: '170px' }}
                defaultValue={query.printing_unit ? parseInt(query.printing_unit) : null}
                onChange={(value) => this.onChangeFilter("printing_unit", value)}
            />
        ];

        var AllowTransToUserFulfill = false;
        if (authUser.role_code == 'ADMIN') {
            AllowTransToUserFulfill = true;
        }

        if (checkPermission('orders', 'change_fulfi_user')) {
            filters.push(
                <BaseSelect
                    options={users}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Nhân viên Fulfill"
                    showSearch={true}
                    style={{ width: '170px' }}
                    defaultValue={query.fulfi_user_id ? parseInt(query.fulfi_user_id) : null}
                    onChange={(value) => this.onChangeFilter("fulfi_user_id", value)}
                />
            )
        }

        if (checkPermission('orders', 'sync_cost_lenful')) {
            filters.unshift(
                <Button icon={<TransactionOutlined />} onClick={() => this.setState({ isOpenSyncCostLenful: true })}>Sync cost Lenful</Button>
            )
        }

        var selectedTags = query.order_status ? query.order_status.split(',') : [];

        const rowClassName = (record, index) => {
            // Thay đổi màu nền cho các dòng nếu là blacklist
            if (record.is_blacklist && Array.isArray(record.is_blacklist.value) && record.is_blacklist.value.length > 0) {
                return "is-blacklist";
            }
        };

        const table_order = <DynamicTable
            rowKey="id"
            dataSource={list_orders || []}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            rowSelection={rowSelection}
            moduleKey="orders"
            rowClassName={rowClassName}
            onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách đơn hàng" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        disabled={!selectedRowKeys.length ? true : false}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={query.start_date || query.end_date || query.status || parseInt(query.printing_unit) || parseInt(query.fulfi_user_id) || query.payment_gateway || null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        {
                            checkPermission('orders', 'sync_order') ? (
                                <>
                                    <Button className="table-button" icon={<SyncOutlined />} type='primary' onClick={() => this.setState({ isOpenPopupSync: true })}>Sync</Button>
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }
                        {
                            AllowTransToUserFulfill ? (
                                <>
                                    <Button className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visible_trand_to_user: true })}>User fulfill</Button>
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        <Divider type="vertical" />
                        {
                            checkPermission('orders', 'import_tracking') ? (
                                <>
                                    <Button className="table-button" icon={<ImportOutlined />} onClick={() => this.setState({ isOpenFormImportTracking: true })}>Import tracking</Button>
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('orders', 'export') ? (
                                <>
                                    <Button className="table-button" loading={isLoadingExport} type='default' onClick={() => this.do_export()} icon={<ExportOutlined />}>Export excel</Button>
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }
                        <BaseSelect
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(query.store_id) || null}
                            optionLabel="name"
                            placeholder="Chọn store"
                            style={{ width: '200px' }}
                            className="table-button"
                        />
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        <span style={{ marginRight: 8, fontWeight: 'bold' }}>Order status:</span>
                        {
                            filterTags.map(tag => (
                                <CheckableTag
                                    key={tag.value}
                                    checked={selectedTags.indexOf(tag.value) > -1}
                                    onChange={checked => this.onCheckTag(tag.value, checked)}
                                    style={{ fontWeight: '500' }}
                                >
                                    {tag.name}
                                </CheckableTag>
                            ))
                        }
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                    {alllowViewStatistical ? (
                        <Row gutter={16} className="mb-2" justify="space-between" style={{ margin: 0 }}>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title="Tiền đặt hàng"
                                    value={statisticalOrder?.total_order_amount || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title="Phí PayPal"
                                    value={statisticalOrder?.total_fee_payment || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title="Phí thuê cổng (6%)"
                                    value={statisticalOrder?.total_fee_payment_gateway || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title="Phí thiết kế"
                                    value={statisticalOrder?.total_png_design_amount || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title="Phí fulfi + chuyển đổi"
                                    value={statisticalOrder?.total_fulfillment_amount || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                            <Card className="product-statistic-card-order-list">
                                <StatisticNumber
                                    title={`Lợi nhuận (${statisticalOrder?.percent || 0}%)`}
                                    value={statisticalOrder?.total_profit || 0}
                                    precision={2}
                                    unit="$"
                                />
                            </Card>
                        </Row>
                    ) : null}
                </div>
                {
                    importResponseMessage && importResponseType ? (
                        <Alert
                            message={ReactHtmlParser(importResponseMessage)}
                            //type={importResponseType}
                            type='info'
                            //showIcon
                            className="mb-2"
                            closable
                            onClose={() => this.onSetMessage(null, null)}
                        />
                    ) : null
                }
                <Card size='small' bodyStyle={{ padding: 0 }} className='has-custom-column-table-btn'>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>All orders</span>} key="all">
                            {table_order}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Pending fulfillment</span>} key="not_fulfill">
                            {table_order}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Issue orders</span>} key="issue">
                            {table_order}
                        </TabPane>
                    </Tabs>
                </Card>
                <OrderCostForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />

                <OrderDetailDrawer
                    visibleDrawer={isOpenDetail}
                    currentOrder={currentOrder ? currentOrder.item : null}
                    currentData={currentOrder ? currentOrder.cost : null}
                    store_url={currentOrder ? currentOrder.store_url : null}
                    store_id={currentOrder ? currentOrder.store_id : null}
                    onCancel={() => this.toggleOrderDetail(false)}
                    reloadData={() => this.reloadData()}
                    reloadOMnData={this.reloadOMnData}
                    loadingCostForm={loadingCostForm}
                />

                <Modal
                    width={isMobile ? '100%' : '50%'}
                    title="ĐỒNG BỘ ĐƠN HÀNG"
                    visible={isOpenPopupSync}
                    onOk={(e) => this.onSyncOrder(e)}
                    onCancel={() => this.onCancel()}
                    confirmLoading={syncLoading}
                    maskClosable={false}
                >
                    <div className="mb-4">
                        Đồng bộ đơn hàng từ store trong trường hợp hệ thống không thể tự động đồng bộ
                    </div>
                    <Form layout="vertical">
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Store" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('store_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: []
                                    })(
                                        // <BaseSelect
                                        //     options={stores || []}
                                        //     onChange={(value) => this.onChangeFilter("store_id", value)}
                                        //     optionValue="id"
                                        //     optionLabel="name"
                                        //     placeholder="Chọn store"
                                        //     showSearch
                                        //     mode='muti'
                                        //     style={{ width: '100%' }}
                                        // />
                                        <Select
                                            mode="multiple"
                                            className='w-100'
                                            placeholder="Chọn một hoặc nhiều store"
                                            optionLabelProp="label"
                                            allowClear={true}
                                            showSearch={true}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option key={98083} value="all" label="Đồng bộ tất cả">Đồng bộ tất cả</Option>
                                            {
                                                stores.map((item) => {
                                                    return (
                                                        <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col span={12}>
                                <Form.Item label="Số trang" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('page', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: 1
                                    })(
                                        <InputNumber className='w-100' />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Số bản ghi trên trang" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('per_page', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: 50
                                    })(
                                        <InputNumber className='w-100' />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    visible={visible_trand_to_user}
                    header={false}
                    onOk={(e) => this.onTransferToUserFuiFill(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={loadingTransToUser}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onTransferToUserFuiFill(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    {visible_trand_to_user ? (
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="User fuifill">
                                        {getFieldDecorator("user_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: ''
                                        })(
                                            <BaseSelect
                                                options={users}
                                                optionValue="user_id"
                                                optionLabel="full_name"
                                                defaultText="Chọn user"
                                                showSearch={true}
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ) : null
                    }
                </Modal>
                <ImportTracking
                    visible={isOpenFormImportTracking}
                    onCancel={this.closeFromImportTracking}
                    onSetMessage={this.onSetMessage}
                />
                <SyncCostLenful
                    visible={isOpenSyncCostLenful}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenSyncCostLenful: false })}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        list_orders: state.OrdersReducer.list_orders,
        pagination: state.OrdersReducer.pagination,
        stores: state.StoreReducer.store_by_field_list,
        statisticalOrder: state.OrdersReducer.statisticalOrder,
        users: state.config.users,
        authUser: state.auth.authUser,
        printing_unit_active: state.PrintingUnitReducer.printing_unit_active
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAllOrders: filter => dispatch(getListAllOrders(filter)),
        getOneOrderManagement: id => dispatch(getOneOrderManagement(id)),
        getStatistical: filter => dispatch(getStatistical(filter)),
        syncOrder: filter => dispatch(syncOrder(filter)),
        getOneOrder: (id, params) => dispatch(getOneOrder(id, params)),
        updateManyManagement: (data) => dispatch(updateManyManagement(data)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter)),
        exportOrders: (filter) => dispatch(exportOrders(filter))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "OrdersList" })(OrdersList));
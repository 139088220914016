import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Space, TreeSelect } from "antd";
import { } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import UploadImage from '../../components/UploadImage';
import Editor from "../../components/Editor";
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { makeTree, convertToSlug } from '../../utils/helpers';

// actions
import { createdProduct } from '../../redux/actions/ProductsAction';
import { createdTags } from '../../redux/actions/ProductsTagsAction';

class ProductsAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            image: [],
            gallery: [],
            isResetUpload: false
        };
        this.nameField = React.createRef();
    }

    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                delete values.tagsAdd
                this.setState({ isLoading: true });
                const { image, gallery } = this.state;
                try {
                    const params = {
                        mode: 'create_one',
                        store_id: this.props.store_id,
                        categories: values.categories,
                        tags: values.tags,
                        regular_price: values.regular_price,
                        sale_price: values.sale_price,
                        status: values.status,
                        items: [
                            {
                                new_title: values.name,
                                slug: values.slug,
                                new_description: values.description,
                                images: [...image, ...gallery],
                                //image: this.state.image,
                                //gallery: this.state.gallery
                            }
                        ]
                    }
                    //console.log("Add one: ", params)
                    if (image.length) {
                        await this.props.createdProduct(params).then(res => {
                            this.onClose()
                        })
                    } else {
                        NotificationManager.error("Product gallery or Product image not complete. Please wait a moment");
                        this.setState({ isLoading: false });
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            image: [],
            gallery: [],
            isGalleryComplete: false,
            slug: null
        });
        this.props.form.resetFields();
        this.props.onCancel();
        this.props.reloadData()
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
    }

    onChangeGallery = (data) => {
        this.setState({
            isResetUpload: false,
            gallery: data
        });
        NotificationManager.success("Gallery image complete.");
    }

    createSlug(e) {
        var slug = convertToSlug(e.target.value);
        this.props.form.setFieldsValue({ slug: slug });
    }

    async createTags(e) {
        //console.log(this.nameField)
        e.preventDefault();
        this.props.form.validateFields(['tagsAdd'], async (err, values) => {
            if (!err) {
                if (this.state.newTagsAddValue) {
                    values.store_id = this.props.store_id;
                    values.name = this.state.newTagsAddValue;
                    delete values.tagsAdd
                    //console.log(values)
                    await this.props.createdTags(values).then(res => {
                        //this.nameField.current.state.value = '';
                        this.props.reloadData()
                    })
                }
            }
        });
    }

    render() {
        const { visible, categories, tags } = this.props;
        const { isLoading, isResetUpload } = this.state;
        const { getFieldDecorator } = this.props.form;
        const defaultValueTags = [];
        if (tags) {
            tags.map((tag) => {
                defaultValueTags.push(tag.id);
            })
        }
        const catTree = makeTree(categories || [], 0);
        //console.log("categories: ", catTree)
        return (
            <Drawer
                visible={visible}
                title="Thêm mới sản phẩm"
                width={isMobile ? '100%' : '60%'}
                onClose={() => this.props.onCancel()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.props.onCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Form.Item label="Tên sản phẩm">
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: null
                            })(<Input
                                onKeyPress={(e) => this.createSlug(e)}
                                onBlur={(e) => this.createSlug(e)}
                            />)}
                        </Form.Item>
                        <Form.Item label="Permalink">
                            {getFieldDecorator("slug", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <Input addonBefore={`${this.props.store_url}/product/`} addonAfter="/" />
                            )}
                        </Form.Item>

                        <Form.Item label='Categories'>
                            {getFieldDecorator("categories", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ]
                            })(
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                    treeData={catTree}
                                    showSearch
                                    multiple={true}
                                    treeNodeFilterProp='title'
                                    allowClear
                                    placeholder="Chọn một hoặc nhiều categories"
                                    treeDefaultExpandAll
                                />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Regular price ($)">
                                    {getFieldDecorator("regular_price", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input placeholder='21.95' />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label='Sale price ($)'>
                                    {getFieldDecorator("sale_price", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input placeholder='15.5' />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item label='Add tags'>
                                    <Input.Group compact>
                                        <Input
                                            ref={this.nameField}
                                            name="tagsAdd"
                                            onKeyPress={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                            onBlur={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                            onPaste={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                            allowClear
                                            style={{ width: '75%' }}
                                        />
                                        <Button onClick={(e) => this.createTags(e)} type="primary">Add</Button>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={16}>
                                <Form.Item label='Select tags'>
                                    {getFieldDecorator("tags", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: [] //defaultValueTags
                                    })(
                                        <BaseSelect
                                            isloading={this.props.isLoading}
                                            options={tags || []}
                                            optionValue="id"
                                            mode="multiple"
                                            optionLabel="name"
                                            showSearch={true}
                                            placeholder="Chọn một hoặc nhiều tags"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label='Status'>
                                    {getFieldDecorator("status", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: 'publish'
                                    })(
                                        <BaseRadios
                                            options={
                                                [
                                                    { value: 'publish', label: "Publish" },
                                                    { value: 'draft', label: "Draft" },
                                                    { value: 'pending', label: "Pending" },
                                                    { value: 'private', label: "Private" }
                                                ]
                                            }
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Mô tả">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng điền!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <Editor />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Product Image">
                                    {getFieldDecorator('image', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={false}
                                            maxCount={1}
                                            onChangeData={this.onChangeImage}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Product Gallery">
                                    {getFieldDecorator('gallery', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeGallery}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createdProduct: (data) => dispatch(createdProduct(data)),
        createdTags: (data) => dispatch(createdTags(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductsAddForm" })(ProductsAddForm));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col, Select, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import BaseRadioList from '../../components/Elements/BaseRadios';
import DraggerFile from './DraggerFile';
import { _newLine, downloadFromLink } from '../../utils/helpers';
import appConfig from '../../config';
// actions
import { createStore, updateStore } from '../../redux/actions/StoreActions';

const { Option } = Select;

class StoreForm extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            currentFiles: [],
            select: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentFiles: nextProps?.currentData?.files,
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.google_service_account;
            if (!err) {
                if (this.props.currentData) {
                    const { currentFiles, data } = this.state;
                    values.google_service_account_files = [...currentFiles, ...data];
                    //console.log(values)
                    await this.props.updateStore(this.props.currentData.id, values);
                    this.onCancel()
                } else {
                    values.google_service_account_files = this.state.data;
                    await this.props.createStore(values);
                    this.props.reloadData()
                    this.onCancel()
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ data: [], currentFiles: [], select: !this.state.select })
        //this.props.reloadData()
    }

    createAdminUrl(e) {
        this.props.form.setFieldsValue({ admin_url: `${e.target.value}/wp-admin` });
    }

    onChange = (data) => {
        this.setState({
            ...this.state,
            data: data,
        });
    }

    deleteCurrentFiles = (name) => {
        const items = [...this.state.currentFiles];
        this.setState({ currentFiles: items.filter(item => item.name !== name) });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: `${appConfig.MEDIA_URL}${url}`,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    render() {
        const { currentFiles } = this.state;
        var { visible, currentData, users } = this.props;
        //console.log(currentData)
        const { getFieldDecorator } = this.props.form;
        const currentFilesList = [];
        if (currentData) {
            currentFiles.map(item => {
                currentFilesList.push(
                    <div>
                        <Button size='small' type='link' onClick={() => this.onDownload(item.data)}>{item.name}</Button>
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteCurrentFiles(item?.name)}
                        />
                    </div >
                )
            })
        }
        return (
            <Modal
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={[
                    <Button key="back" onClick={() => this.onCancel()}>
                        Huỷ
                    </Button>,
                    <Button key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentData ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Tên của hàng">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.name : ""
                                    })(<Input placeholder='tshirtlowprice.com' />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Url">
                                    {getFieldDecorator('url', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.url : ""
                                    })(<Input
                                        onKeyPress={(e) => this.createAdminUrl(e)}
                                        onBlur={(e) => this.createAdminUrl(e)}
                                        placeholder='https://tshirtlowprice.com'
                                    />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Nhân viên quản lý">
                                    {getFieldDecorator('user_ids', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.currentUser : []
                                    })(
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Chọn nhân viên quản lý"
                                            optionLabelProp="label"
                                            allowClear={true}
                                            showSearch={true}
                                        >
                                            {
                                                users.map((item) => {
                                                    return (
                                                        <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Admin url">
                                    {getFieldDecorator('admin_url', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.admin_url : ""
                                    })(<Input placeholder='https://tshirtlowprice.com/wp-admin' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Thống kê sản phẩm">
                                    {getFieldDecorator('product_statistics', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.product_statistics : 'yes'
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Có", value: 'yes' },
                                                { label: "Không", value: 'no' }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Platform">
                                    {getFieldDecorator('platform', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.platform : 'pod'
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Pod", value: 'pod' },
                                                { label: "Dropship", value: 'dropship' },
                                                { label: 'Shield', value: 'shield' },
                                                { label: "News", value: 'news' }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Giới hạn sản phẩm/ngày">
                                    {getFieldDecorator('product_limit', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.product_limit : 200
                                    })(
                                        <InputNumber min={0} step={200} style={{ width: '100%' }} placeholder='0: Unlimit' />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Google Indexing (service account auth json)">
                            {getFieldDecorator('google_service_account', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ''
                            })(
                                <DraggerFile onChangeData={this.onChange} select={this.state.select} />
                            )}
                            {currentFilesList}
                        </Form.Item>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createStore: (data) => dispatch(createStore(data)),
        updateStore: (id, data) => dispatch(updateStore(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'StoreForm' })(StoreForm));

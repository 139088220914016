import {
    GET_ALL_NICHE,
    GET_SPECIFIC_NICHE,
    UPDATE_SPECIFIC_NICHE,
    CREATE_NEW_NICHE,
    REMOVE_NICHE,
    GET_NICHE_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    niches: [],
    niche: [],
    currentNiche: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_NICHE: {
            return {
                ...state,
                niches: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_NICHE_ACTIVE: {
            return {
                ...state,
                niche: action.payload.data,
            }
        }
        case GET_SPECIFIC_NICHE: {
            return { ...state, currentNiche: action.payload }
        }
        case CREATE_NEW_NICHE: {
            return { ...state, niches: [action.payload, ...state.niches], total: state.total + 1 }
        }
        case UPDATE_SPECIFIC_NICHE: {
            let index = state.niches.findIndex((niche) => {
                return niche.id == action.payload.id;
            });

            let temp = [...state.niches];
            temp[index] = action.payload;
            return { ...state, niches: temp }
        }
        case REMOVE_NICHE: {
            let temp = state.niches.filter(niche => {
                return action.payload.indexOf(niche.id) < 0;
            });
            return { ...state, niches: temp }
        }
        default: return { ...state };
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Checkbox, Alert, Button, Spin, Divider } from 'antd';
import BaseCheckboxes from '../../components/Elements/BaseCheckboxes';
import BaseSelect from '../../components/Elements/BaseSelect';
import { chunkGroup } from '../../utils/permission';
import _ from 'lodash';
import IntlMessages from '../../components/IntlMessage';
import { NotificationManager } from 'react-notifications';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
// actions
import { getAllPermissions, updateRolePermissions, getPermissionsByRole, getPermissionsByUser } from '../../redux/actions/PermissionActions';
import { getAllUsers } from '../../redux/actions/UserActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/permissions',
        breadcrumbName: 'Phân quyền',
    },
]

const PermissionGroup = ({ groups, permissionGroup, onCheckAll, onCheck }) => (
    <React.Fragment>
        {
            groups.map((groupChunk, index) => {
                return (
                    <Row gutter={16} style={{ height: 'max-content' }} key={index}>
                        {
                            groupChunk.map((group, index) => {
                                let groupName = group[0].route_name.split('.')[1];
                                let groupChecked = false;
                                if (permissionGroup[groupName]) {
                                    if (permissionGroup[groupName].length === group.length) groupChecked = true;
                                }

                                return (
                                    <Col lg={6} md={6} sm={24} xs={24} key={index} className="mb-4" >
                                        <Card
                                            title={<IntlMessages id={`admin.${groupName}`} />}
                                            size='small'
                                            extra={
                                                <Checkbox
                                                    onChange={(e) => onCheckAll(e, groupName, group)}
                                                    defaultChecked={groupChecked}
                                                ></Checkbox>
                                            }
                                            style={{ marginBottom: '20px', height: '100%' }}
                                        >
                                            <BaseCheckboxes
                                                options={group}
                                                optionLabel="name"
                                                optionValue="id"
                                                vertical
                                                onChange={(checkedValues) => onCheck(checkedValues, groupName)}
                                                value={permissionGroup[groupName]}
                                                intl={true}
                                            />
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            })
        }
    </React.Fragment>
)

function handlePermissions(permissions) {
    var permissionGroup = {};
    permissions.forEach(permission => {
        let groupName = permission.route_name.split('.')[1];
        if (!permissionGroup[groupName]) {
            permissionGroup[groupName] = [permission.id];
        } else {
            permissionGroup[groupName] = [...permissionGroup[groupName], permission.id];
        }
    })

    return permissionGroup;
}

class UserPermission extends Component {
    state = {
        permissionGroup: {},
        isLoading: false,
        currentRole: '',
        currentUser: '',
        roles: []
    }

    componentDidMount() {
        this.props.getAllPermissions();

    }

    onChangeRole(value) {
        this.setState({ currentRole: value, isLoading: true }, () => {
            this.props.getAllUsers({ role: value })
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        })
    }

    onChangeUser(user) {
        if (user) {
            this.setState({ currentUser: user, isLoading: true }, () => {
                this.props.getPermissionsByUser(this.state.currentUser).then(permissions => {
                    var permissionGroup = handlePermissions(permissions);

                    this.setState({ permissionGroup: permissionGroup, isLoading: false });
                })
            })
        } else {
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        }
    }

    onCheck = (checkedValues, groupName) => {
        this.setState({
            permissionGroup: {
                ...this.state.permissionGroup,
                [groupName]: checkedValues
            }
        })
    }

    onCheckAll = (e, groupName, group) => {
        let values = group.map(item => item.id)
        if (e.target.checked) {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: values
                }
            })
        } else {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: []
                }
            })
        }
    }

    submit() {
        if (!this.state.currentRole) {
            NotificationManager.error("Bạn chưa chọn vai trò!")
        } else {
            let keys = Object.keys(this.state.permissionGroup);
            let permissionArr = [];
            keys.forEach(key => {
                permissionArr = [...permissionArr, ...this.state.permissionGroup[key]];
            });

            var data = {
                role: this.state.currentRole,
                permissions: permissionArr
            }

            if (this.state.currentUser) data.user = this.state.currentUser;

            this.props.updateRolePermissions(data);
        }
    }

    render() {
        var { permissions, config } = this.props;
        var { isLoading, currentRole, permissionGroup, currentUser } = this.state;

        var roles = [];
        if (config.roles) {
            roles = config.roles.slice(1);
        }
        var users = [];
        if (currentRole) {
            users = config.users.filter(item => item.role == currentRole);
        }

        //console.log(permissions);
        const SetttingPermissions = ['store', 'config', 'chatgpt', 'telegram', 'payment_gateways', 'blacklist'];
        const QuanTrinHeThongPermissions = ['user'];
        const BusinessManagementPermissions = ['workflow_management', 'work_report'];
        const QuanLyThuchiPermissions = ['revenue_expenditure', 'orders'];
        const productsPermissions = ['products', 'product_cat_des', 'products_import', 'product_categories', 'product_tags'];
        const ThonTinChungPermissions = ['trending', 'products_description', 'holiday', 'niche', 'product_type'];
        const HuongDanDaoTaoPermissions = ['training', 'guide'];
        const ToolsPermissions = ['google_indexing', 'trademark_keyword', 'handle_s3_objects', 'log_mail_tracking', 'log_order_shield', 'tool_license'];
        const FulfillmentPermissions = ['file_png', 'printing_unit', 'trademark_category'];
        const ProjectPermissions = ['project', 'project_based_job'];

        let productsGroup = [];
        let SetttingGroup = [];
        let QuanTrinHeThongGroup = [];
        let BusinessManagementGroup = [];
        let QuanLyThuchiGroup = [];
        let ThonTinChungGroup = [];
        let HuongDanDaoTaoGroup = [];
        let ToolsGroup = [];
        let FulfillmentGroup = [];
        let others = [];
        let ProjectGroup = [];

        permissions.forEach(permission => {
            if (productsPermissions.indexOf(permission.resource) >= 0) {
                productsGroup.push(permission);
            } else if (SetttingPermissions.indexOf(permission.resource) >= 0) {
                SetttingGroup.push(permission);
            } else if (QuanTrinHeThongPermissions.indexOf(permission.resource) >= 0) {
                QuanTrinHeThongGroup.push(permission);
            } else if (BusinessManagementPermissions.indexOf(permission.resource) >= 0) {
                BusinessManagementGroup.push(permission);
            } else if (QuanLyThuchiPermissions.indexOf(permission.resource) >= 0) {
                QuanLyThuchiGroup.push(permission);
            } else if (ThonTinChungPermissions.indexOf(permission.resource) >= 0) {
                ThonTinChungGroup.push(permission);
            } else if (HuongDanDaoTaoPermissions.indexOf(permission.resource) >= 0) {
                HuongDanDaoTaoGroup.push(permission);
            } else if (ToolsPermissions.indexOf(permission.resource) >= 0) {
                ToolsGroup.push(permission);
            } else if (FulfillmentPermissions.indexOf(permission.resource) >= 0) {
                FulfillmentGroup.push(permission);
            } else if (ProjectPermissions.indexOf(permission.resource) >= 0) {
                ProjectGroup.push(permission);
            } else {
                others.push(permission);
            }
        });

        SetttingGroup = chunkGroup(SetttingGroup);
        QuanTrinHeThongGroup = chunkGroup(QuanTrinHeThongGroup);
        BusinessManagementGroup = chunkGroup(BusinessManagementGroup);
        QuanLyThuchiGroup = chunkGroup(QuanLyThuchiGroup);
        productsGroup = chunkGroup(productsGroup)
        ThonTinChungGroup = chunkGroup(ThonTinChungGroup);
        HuongDanDaoTaoGroup = chunkGroup(HuongDanDaoTaoGroup);
        ToolsGroup = chunkGroup(ToolsGroup);
        FulfillmentGroup = chunkGroup(FulfillmentGroup);
        ProjectGroup = chunkGroup(ProjectGroup)
        others = chunkGroup(others);

        return (
            <div className="mb-4">
                <PageTitle title="Phân quyền user" routes={routes} />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showSearch={false}
                        showFilter={false}
                    >
                        Chọn loại quyền:
                        <Divider type='vertical' />
                        <BaseSelect
                            options={roles}
                            optionValue="id"
                            allowClear={false}
                            optionLabel="name"
                            showSearch
                            value={currentRole || null}
                            style={{ width: '250px' }}
                            placeholder="Chọn một vai trò"
                            onChange={(value) => this.onChangeRole(value)}
                            className="table-button"
                        />
                        <Divider type='vertical' />
                        <BaseSelect
                            options={users}
                            showSearch
                            allowClear={false}
                            value={currentUser || null}
                            optionValue="user_id"
                            optionLabel="full_name"
                            style={{ width: '250px' }}
                            placeholder="Tất cả nhân viên"
                            onChange={(value) => this.onChangeUser(value)}
                            className="table-button"
                        />

                    </TableActionBar>
                </div>
                <div className="mt-4"></div>
                <Alert
                    message="Lưu ý"
                    description="User với quyền Super Admin luôn có toàn bộ quyền truy cập."
                    type="info"
                />
                <div className="mt-4"></div>
                {
                    isLoading ? (
                        <div className="text-center">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <React.Fragment>
                            <Divider orientation="left" orientationMargin="0">Setting</Divider>
                            <PermissionGroup groups={SetttingGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Quản trị hệ thống</Divider>
                            <PermissionGroup groups={QuanTrinHeThongGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Business Management</Divider>
                            <PermissionGroup groups={BusinessManagementGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Quản lý thu chi</Divider>
                            <PermissionGroup groups={QuanLyThuchiGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Quản lý sản phẩm</Divider>
                            <PermissionGroup groups={productsGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Thông tin chung</Divider>
                            <PermissionGroup groups={ThonTinChungGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Hướng dẫn - Đào tạo</Divider>
                            <PermissionGroup groups={HuongDanDaoTaoGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Tools</Divider>
                            <PermissionGroup groups={ToolsGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Fulfillment</Divider>
                            <PermissionGroup groups={FulfillmentGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Quản lý dự án</Divider>
                            <PermissionGroup groups={ProjectGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />

                            <Divider orientation="left" orientationMargin="0">Các quyền khác</Divider>
                            <PermissionGroup groups={others} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                            <Row justify="end">
                                <Button type="primary" onClick={() => this.submit()}>Xác nhận</Button>
                            </Row>
                        </React.Fragment>
                    )
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state.permission)
    return {
        permissions: state.permission.permissions,
        permissionsByRole: state.permission.permissionsByRole,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPermissions: () => dispatch(getAllPermissions()),
        updateRolePermissions: (data) => dispatch(updateRolePermissions(data)),
        getPermissionsByRole: (role) => dispatch(getPermissionsByRole(role)),
        getPermissionsByUser: (user) => dispatch(getPermissionsByUser(user)),
        getAllUsers: (filter) => dispatch(getAllUsers(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermission);
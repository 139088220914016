import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UploadOutlined, CheckOutlined, EditOutlined, ExclamationCircleOutlined, UserOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Descriptions, Card, Tag, Button, Image, Row, List, Col, Space, Radio, Input, Drawer, Typography, Modal, Divider, Comment, InputNumber, Switch, Alert } from 'antd';
import DesignUploadFileForm from './DesignUploadFileForm';
import DesignEditForm from './DesignEditForm';
import moment from 'moment';
import { Form } from "@ant-design/compatible";
import { NotificationManager } from 'react-notifications'
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "save-as";
import no_image from '../../assets/img/no-image.png';

// actions
import { approved, updateDesign, getOneDesign } from '../../redux/actions/DesignAction';
import { getAllNiche } from '../../redux/actions/NicheActions';
import { getAllProductType } from '../../redux/actions/ProductTypeActions';
import { checkPermission } from '../../utils/permission';
import { createComment, removeComment } from '../../redux/actions/CommentsAction';
import { ConvertRequestType, isValidDate } from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { confirm } = Modal;
const { TextArea } = Input;
const { Paragraph } = Typography;

class DesignDetailDrawer extends Component {
    state = {
        isOpenUploadFileForm: false,
        currentDesign: null,
        isOpenFormApproved: false,
        confirmLoading: false,
        loadingDownload: false,
        isOpenEditForm: false,
        currentData: null
    }

    toggleOpenUploadFileForm(isOpenUploadFileForm, design = null) {
        this.setState({
            isOpenUploadFileForm: isOpenUploadFileForm,
            currentDesign: design
        });
    }

    onOpenFormUploadFile() {
        this.toggleOpenUploadFileForm(true, this.props.design)
    }

    handleCancel() {
        this.reloadData();
        this.props.form.resetFields();
        this.setState({ isOpenFormApproved: false, confirmLoading: false, loadingDownload: false })
    }

    async approvedDesign(e) {
        e.preventDefault();
        this.props.form.validateFields(['approved_status', 'approved_des'], async (err, values) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                const { design } = this.props;
                var title = '';
                var content = '';
                if (values.approved_status == 0) {
                    title = "Xác nhận Chưa Duyệt sản phẩm"
                    content = "Chắc chắn Chưa Duyệt sản phẩm này"
                } else if (values.approved_status == 1) {
                    title = "Xác nhận Duyệt sản phẩm"
                    content = "Chắc chắn Duyệt sản phẩm này"
                } else if (values.approved_status == 2) {
                    title = "Xác nhận Không Duyệt sản phẩm"
                    content = "Chắc chắn Không Duyệt sản phẩm này"
                }
                try {
                    confirm({
                        title: title,
                        icon: <ExclamationCircleOutlined />,
                        content: content,
                        okText: 'OK',
                        cancelText: 'Huỷ',
                        onCancel: () => {
                            this.setState({ confirmLoading: false });
                        },
                        onOk: async () => {
                            if (design) {
                                this.props.approved(design.id, values).then(async (res) => {
                                    if (res.status == "success") {
                                        NotificationManager.success(res.msg)
                                    }
                                    this.handleCancel()
                                })
                            } else {
                                NotificationManager.error("Lỗi không tìm thấy bản ghi")
                            }
                        }
                    })
                } catch (error) {
                    this.setState({ confirmLoading: false });
                }
            }
        });
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(['content'], async (err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                const { design } = this.props;
                await this.props.createComment({ type: 'design', content: values.content, parent_id: design.id }).then(res => {
                    this.props.form.resetFields();
                    this.reloadData();
                    this.setState({ submitting: false })
                })
            }
        });
    }

    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData();
            }),
            onCancel() { },
        })
    }

    async updateStatusDesign(value) {
        const { design } = this.props;
        await this.props.updateDesign(design.id, { status: value })
        this.reloadData();
    }

    reloadData() {
        this.props.reloadData();
    }

    downloadAllPrintFile() {
        const { design } = this.props;
        var zip = new JSZip();
        var count = 0;
        var zipFilename = `print_files_${(new Date().toJSON().slice(0, 10))}_${new Date().toLocaleTimeString()}.zip`;

        if (design.list_design && design.list_design.length && Array.isArray(design.list_design)) {
            this.setState({ loadingDownload: true });
            const self = this;

            design.list_design.forEach(function (url) {
                var filename = new URL(url).pathname.split('/').pop();

                // Tải nội dung file và thêm vào file zip
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err) {
                        throw err; // hoặc xử lý lỗi theo cách khác
                    }

                    zip.file(filename, data, { binary: true });
                    count++;

                    // So sánh với độ dài của list_design
                    if (count === design.list_design.length) {
                        // Tạo file zip và tải về
                        zip.generateAsync({ type: "blob" }).then(function (content) {
                            saveAs(content, zipFilename);
                            self.setState({ loadingDownload: false });
                        });
                    }
                });
            });
        }
    }


    toggleOpenEditForm(isOpenEditForm, data = null) {
        this.setState({ isOpenEditForm: isOpenEditForm, currentData: data });
    }

    onEdit(id) {
        this.props.getOneDesign(id).then(res => {
            console.log(res)
            this.toggleOpenEditForm(true, res);
        })
    }

    render() {
        var { visible, design, authUser } = this.props;
        var {
            isOpenUploadFileForm,
            currentDesign,
            isOpenFormApproved,
            confirmLoading,
            submitting,
            isOpenEditForm,
            currentData,
            loadingDownload
        } = this.state;
        const { getFieldDecorator } = this.props.form;

        var allowEdit = false;
        var diffDate;
        const comments = [];
        let approved_title = "Người duyệt"
        let approved_date_title = "Ngày duyệt"
        let approved_status = <Tag color="#ffc069">Chưa duyệt</Tag>
        if (design) {
            if (checkPermission('design', 'update')) {
                // Nếu đã 'Duyệt' thì chỉ admin mới có quyền sửa
                if (authUser.role_code == 'ADMIN') {
                    allowEdit = true;
                }
                if (authUser.role_code == 'DESIGN' && design.approved_status != 1) {
                    allowEdit = true;
                }
            }

            if (design.approved_status === 1) {
                approved_status = <Tag color="#52c41a">Đã duyệt</Tag>
            } else if (design.approved_status === 2) {
                approved_status = <Tag color="#f00">Không được duyệt</Tag>
                approved_title = "Người cập nhật"
                approved_date_title = "Ngày cập nhật"
            }
            diffDate = design.deadline ? moment(design.deadline).diff(moment().startOf('day'), 'days') : 0;
            design.comments.map(item => {
                var disabledDeleteComment = true;
                if (authUser.role_code == 'ADMIN') {
                    disabledDeleteComment = false
                } else if (authUser.id == item.sender) {
                    disabledDeleteComment = false
                }
                comments.push(
                    {
                        actions: [<Button size='small' disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <UserOutlined style={{ fontSize: '20px' }} className='primary-color' />,
                        content: <p>{ReactHtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={`#${design ? design?.id : ''}`}
                    width={isMobile ? '100%' : '70%'}
                    onClose={() => this.props.onCancel()}
                    maskClosable={false}
                    footer={false}
                    extra={
                        checkPermission('design', 'update') ? (
                            <Button type="primary" onClick={() => this.onEdit(design?.id)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible && design ? (
                        <>
                            <Row gutter={16}>
                                <Col xs={24} md={14}>
                                    <Card
                                        title={<>Thông tin yêu cầu</>}
                                        size='small'
                                        bordered={false}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={{ paddingLeft: 0, fontSize: '1.1rem', border: 'none' }}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={24} className='wrap-alert'>
                                                <Alert
                                                    message={<strong>Ghi chú</strong>}
                                                    description={ReactHtmlParser(design?.description)}
                                                    type="info"
                                                />
                                            </Col>
                                            <Col xs={24} md={24} className='mt-2'>
                                                <Comment
                                                    author={<span className='ant-descriptions-item-label'>Tên sản phẩm</span>}
                                                    content={<>{design?.name}</>}
                                                />
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Yêu cầu thiết kế">
                                                        {ConvertRequestType(design?.request_type)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Deadline">
                                                        {isValidDate(design?.deadline, false)}
                                                        <Divider type='vertical' />
                                                        {
                                                            diffDate ? (
                                                                <>
                                                                    {
                                                                        diffDate > 0 ? <Tag color="#52c41a">Còn lại {diffDate} ngày</Tag> :
                                                                            design?.status == 0 ? (
                                                                                <>
                                                                                    <Tag color="red">Quá hạn {-1 * diffDate} ngày</Tag>
                                                                                </>
                                                                            ) : null
                                                                    }
                                                                </>
                                                            ) : (
                                                                <Tag color="#f50">Hết hạn hôm nay</Tag>
                                                            )
                                                        }
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Order ID">
                                                        {
                                                            design?.order_id ? (
                                                                <div className='ecom-paragraph'>
                                                                    <Paragraph copyable>{design?.order_id}</Paragraph>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Nhân viên thiết kế">
                                                        <Tag>{design?.user_design}</Tag>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ảnh mẫu">
                                                        {
                                                            design?.images_demo.length ? (
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.images_demo.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            ) : null
                                                        }
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Comment
                                                    author={<span className='ant-descriptions-item-label'>Mô tả yêu cầu</span>}
                                                    content={<>{ReactHtmlParser(design?.request_des_for_design)}</>}
                                                />
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày tạo">
                                                        {isValidDate(design?.created_at)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ngày cập nhật">
                                                        {isValidDate(design?.updated_at)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Người tạo">
                                                        {design?.created_user}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Người cập nhật">
                                                        {design.updated_user}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24} md={10}>
                                    <Card
                                        style={isMobile ? { marginTop: '1.5em' } : null}
                                        title={<>Thiết kế</>}
                                        size='small'
                                        headStyle={{ fontSize: '1.1rem' }}
                                        extra={
                                            <>
                                                {
                                                    checkPermission('design', 'approved') && design?.status == 1 ? <><Button size='small' type="primary" onClick={() => this.setState({ isOpenFormApproved: true })} icon={<CheckOutlined />}>Duyệt</Button> </> : null
                                                }
                                            </>
                                        }
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={24} className='mb-3'>
                                                <h6 className='h6-title'>Mockup</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_mockup_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_mockup_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Mặt trước</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_front_side_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_front_side_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Mặt sau</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_back_side_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_back_side_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Ngực trái</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_left_chest_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_left_chest_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Tay áo phải</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_right_sleeve_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_right_sleeve_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Tay áo trái</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_left_sleeve_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_left_sleeve_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-3'>
                                                <h6 className='h6-title'>Cổ áo</h6>
                                                <Card size="small" className='border-dashed' bodyStyle={{ padding: 5, minHeight: 60 }}>
                                                    {
                                                        design?.design_collar_small.length ? (
                                                            <div className='file-png-design-list'>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            design?.design_collar_small.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        height={50}
                                                                                        src={item}
                                                                                        fallback={no_image}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </div>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={24} style={{ textAlign: 'right' }}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="">
                                                        <Button type="primary" icon={<DownloadOutlined />} loading={loadingDownload} onClick={() => this.downloadAllPrintFile()} >Tải xuống file in</Button>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="">
                                                        {
                                                            allowEdit ? <Button type="primary" onClick={() => this.onOpenFormUploadFile(design.id)} icon={<UploadOutlined />}>Upload files thiết kế</Button> : null
                                                        }
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Tình trạng">
                                                        <Switch
                                                            disabled={design?.approved_status == 1}
                                                            defaultChecked={design?.status}
                                                            checkedChildren="Đã hoàn thiện"
                                                            unCheckedChildren="Chưa hoàn thiện"
                                                            onChange={(value) => this.updateStatusDesign(value)}
                                                        />
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Trạng thái">
                                                        {approved_status}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label={approved_title}>
                                                        {design?.approved_by_user}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label={approved_date_title}>
                                                        {isValidDate(design?.approved_date)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Diễn giải">
                                                        {design?.approved_des}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Card
                                        title={<><FontAwesomeIcon icon="fa-solid fa-comment-dots" /> Thảo luận</>}
                                        size='small'
                                        className='mt-4'
                                        bordered={false}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={{ paddingLeft: 0, fontSize: '1.1rem' }}
                                    >
                                        {comments.length > 0 && <CommentList comments={comments} />}
                                        <Comment
                                            avatar={<UserOutlined style={{ fontSize: '20px' }} className='primary-color' />}
                                            content={
                                                <Form>
                                                    <Form.Item>
                                                        {getFieldDecorator('content', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng điền!',
                                                                },
                                                            ],
                                                            initialValue: ""
                                                        })(<TextArea rows={3} />)}
                                                    </Form.Item>
                                                    <Button htmlType="submit" loading={submitting} onClick={(e) => this.submitComment(e)} type="primary">
                                                        Đăng
                                                    </Button>
                                                </Form>
                                            }
                                        />
                                    </Card>
                                </Col>
                            </Row>
                            <DesignUploadFileForm
                                visible={isOpenUploadFileForm}
                                design={currentDesign}
                                onCancel={() => this.toggleOpenUploadFileForm(false)}
                                reloadData={() => this.reloadData()}
                            />

                            <Modal
                                width={isMobile ? '100%' : '40%'}
                                title="Duyệt sản phẩm"
                                visible={isOpenFormApproved}
                                onOk={(e) => this.approvedDesign(e)}
                                confirmLoading={confirmLoading}
                                onCancel={() => this.handleCancel()}
                                maskClosable={false}
                            >
                                <Form layout="vertical">
                                    <Form.Item label="Option" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('approved_status', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Field is required.'
                                                }
                                            ],
                                            initialValue: 1
                                        })(
                                            <Radio.Group>
                                                <Radio value={1}>Duyệt</Radio>
                                                <Radio value={2}>Không được duyệt</Radio>
                                                <Radio value={0}>Chưa duyệt</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Diễn giải" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('approved_des', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Field is required.'
                                                }
                                            ],
                                            initialValue: design ? design.approved_des : null
                                        })(
                                            <Input.TextArea rows={2} />
                                        )}
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </>
                    ) : null
                    }
                </Drawer>
                <DesignEditForm
                    visible={isOpenEditForm}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenEditForm(false)}
                    reloadData={() => this.reloadData()}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        niche: state.NicheReducer.niche,
        product_type: state.ProductType.product_type,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        approved: (id, data) => dispatch(approved(id, data)),
        getAllNiche: (filter) => dispatch(getAllNiche(filter)),
        getAllProductType: (filter) => dispatch(getAllProductType(filter)),
        createComment: (data) => dispatch(createComment(data)),
        updateDesign: (id, data) => dispatch(updateDesign(id, data)),
        removeComment: (ids) => dispatch(removeComment(ids)),
        getOneDesign: id => dispatch(getOneDesign(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DesignDetailDrawer" })(DesignDetailDrawer));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Descriptions, Typography, Tooltip, Image, List, Table, Empty, Col, Row, Drawer, Divider, Tag, Input, Button, Spin } from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';
import DesignAddForm from './DesignAddForm'
import ShipmentTracking from './ShipmentTracking';
import { NotificationManager } from 'react-notifications'
// actions
import { updateOrderManagement, getOneOrder } from '../../redux/actions/OrdersAction';
import { checkMatching } from '../../redux/actions/TrademarkCategoryActions';
import HtmlParser from 'react-html-parser';

const { Text, Paragraph } = Typography;

class OrderDetailDrawer extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenDesignAddForm: false,
            currentProduct: null,
            productColor: null,
            productPrintOnThe: null,
            isOpenShipmentTracking: false,
            personalizedText: null,
            data: null,
            matchedItems: {}
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visibleDrawer && nextProps.currentOrder && nextProps.currentOrder.products) {
            nextProps.currentOrder.products.forEach(product => {
                this.onCheckTrademark(product.name, product);
            });
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            //console.log(values)
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateOrderManagement(this.props.currentData.id, values);
                    this.props.reloadData()
                    this.props.reloadOMnData(this.props.currentData.order_id, this.props.currentData.store_id);
                }
                this.setState({ isloading: false })
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleCancelDesignForm() {
        this.setState({ isOpenDesignAddForm: false })
        this.props.form.resetFields();
    }

    openFormAddDesign(product_id) {
        const filterObj = this.props.currentOrder.products.filter((e) => e.product_id == product_id);
        if (filterObj.length) {
            //console.log("filterObj: ", filterObj)
            const productColor = filterObj[0].meta_data.filter((e) => e.meta_key == 'Color');
            const productPrintOnThe = filterObj[0].meta_data.filter((e) => (e.meta_key == 'PRINT BOTH SIDES?' || e.meta_key == 'PRINT ON THE'));
            const personalizedText = filterObj[0].meta_data.filter((e) => (e.meta_key == 'Personalized Text' || e.meta_key == 'Personalized Name' || e.meta_key == 'Personalized'));
            var printPosition = productPrintOnThe[0]?.meta_value.split('|');
            var print_Position;
            if (Array.isArray(printPosition)) {
                print_Position = printPosition[0];
            }
            //console.log("productPrintOnThe: ", printPosition[0])
            this.setState({ isOpenDesignAddForm: true, currentProduct: filterObj[0], productColor: productColor[0], productPrintOnThe: print_Position, personalizedText: personalizedText[0] })
        } else {
            NotificationManager.error('Data not found')
        }
    }

    getData() {
        const { currentData } = this.props;
        this.props.getOneOrder(currentData.order_id, { store_id: currentData.store_id }).then(res => {
            this.setState({ data: res });
        });
    }

    openShipmentTracking() {
        this.getData();
        this.setState({ isOpenShipmentTracking: true })
    }

    onCheckTrademark(name, record, action = null) {
        this.props.checkMatching({ title: name }).then(res => {
            console.log(res);
            const { data } = res;
            const matchedItems = { ...this.state.matchedItems };

            if (Array.isArray(data)) {
                const keywords = data.map(item => item.keyword.toLowerCase()); // Chuyển các từ khóa về dạng chữ thường

                const highlightedName = name.replace(
                    new RegExp(keywords.join('|'), 'gi'), // Tạo một biểu thức chính quy từ các từ khóa
                    matched => `<span style="background-color: yellow; color: red;font-weight: bold;">${matched}</span>` // Đánh dấu từng từ khớp
                );

                matchedItems[name] = (
                    <>
                        <Paragraph copyable={{ text: this.props.store_url + '/product/' + record.slug, tooltips: ['Copy url'] }}>
                            {HtmlParser(highlightedName)}
                        </Paragraph>
                        {data.map((item, index) => (
                            <div key={index} className='notice-text'>{item.note}</div>
                        ))}
                    </>
                );
                this.setState({ matchedItems });
            } else {
                if (action == 'click') {
                    NotificationManager.info(data);
                }
            }
        });
    }


    render() {
        const { isloading, matchedItems, isOpenDesignAddForm, currentProduct, productColor, productPrintOnThe, personalizedText, isOpenShipmentTracking, data } = this.state;
        var { visibleDrawer, currentOrder, store_url, currentData, users, printing_unit_active, loadingCostForm } = this.props;
        const { getFieldDecorator } = this.props.form;

        //console.log(users)
        const meta_data_columns = [
            {
                title: 'Options',
                dataIndex: 'meta_key',
                key: 'meta_key',
                width: '100px'
            },
            {
                title: 'Value',
                dataIndex: 'meta_value',
                key: 'meta_value',
                render: (text, record) => {
                    var label = '', value = '', color = '';
                    var valueArr = record.meta_value.split('|')
                    label = valueArr[0]
                    value = valueArr[1]
                    color = valueArr[2]
                    if (record.meta_key == 'Style') {
                        return (
                            <>
                                <div><b>Label:</b>  {label}</div>
                                <div>
                                    {isValidHttpUrl(value) ? (
                                        <Image
                                            width={50}
                                            src={value}
                                        />
                                    ) : null}
                                </div>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <div><b>Label:</b>  {label}</div>
                                <div><b>Value:</b> <span style={{ backgroundColor: color }}>{color}</span>
                                    {value}
                                </div>
                            </>
                        )
                    }
                }
            },
        ];

        const fee_lines_columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, record) => {
                    if (record.meta_data && record.meta_data?._fee_amount) {
                        return (
                            <div>{record.meta_data?._fee_amount}{currentOrder ? currentOrder.currency_symbol : null}</div>
                        )
                    }
                }
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                render: (text, record) => {
                    return (
                        <div>{text}{currentOrder ? currentOrder.currency_symbol : null}</div>
                    )
                }
            },
            {
                title: 'Total tax',
                dataIndex: 'total_tax',
                key: 'total_tax',
                render: (text, record) => {
                    return (
                        <div>{text}{currentOrder ? currentOrder.currency_symbol : null}</div>
                    )
                }
            },
        ];

        const shipping_lines_columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Method',
                dataIndex: 'method_id',
                key: 'method_id',
                render: (text, record) => {
                    if (record.meta_data && record.meta_data?.method_id) {
                        return (
                            <div>{record.meta_data?.method_id}</div>
                        )
                    }
                }
            },
            {
                title: 'Cost',
                dataIndex: 'cost',
                key: 'cost',
                render: (text, record) => {
                    if (record.meta_data && record.meta_data?.cost) {
                        return (
                            <div>{record.meta_data?.cost}{currentOrder ? currentOrder.currency_symbol : null}</div>
                        )
                    }
                }
            },
            {
                title: 'Total tax',
                dataIndex: 'total_tax',
                key: 'total_tax',
                render: (text, record) => {
                    if (record.meta_data && record.meta_data?.total_tax) {
                        return (
                            <div>{record.meta_data?.total_tax}{currentOrder ? currentOrder.currency_symbol : null}</div>
                        )
                    }
                }
            },
        ];

        const columns = [
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                width: '30px',
                render: (image, record) => {
                    if (image) {
                        return (
                            <Image
                                width={50}
                                src={image.src}
                            />
                        )
                    }
                }
            },
            {
                title: 'Actions',
                align: 'center',
                width: '140px',
                render: (text, record) => {
                    return (
                        <>
                            {
                                checkPermission('orders', 'send_request_design') ? (
                                    <>
                                        <Tooltip title="Gửi yêu cầu thiết kế">
                                            <BgColorsOutlined onClick={() => this.openFormAddDesign(record.product_id)} className='primary-color item-action-btn' style={{ fontSize: '1.3rem' }} />
                                        </Tooltip>
                                        <Divider type='vertical' />
                                    </>
                                ) : null
                            }
                            <Button onClick={() => this.onCheckTrademark(record.name, record, 'click')} size='small' type='primary'>Check TM</Button>
                        </>
                    )
                }
            },
            {
                title: 'Item',
                dataIndex: 'name',
                key: 'name',
                width: '300px',
                render: (text, record) => {
                    const matchedText = matchedItems[text] || text; // Lấy từ khớp từ state
                    if (matchedItems[text]) {
                        return (
                            <div className='ecom-paragraph'>{matchedText}</div>
                        )
                    } else {
                        return (
                            <div className='ecom-paragraph'>
                                <Paragraph copyable={{ text: store_url + '/product/' + record.slug, tooltips: ['Copy url'] }}>
                                    {text}
                                </Paragraph>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Cost',
                dataIndex: 'subtotal',
                key: 'subtotal',
                width: '50px',
                //sorter: (a, b) => a.subtotal - b.subtotal,
                render: (text, record) => {
                    return (
                        <div>{text}{currentOrder ? currentOrder.currency_symbol : null}</div>
                    )
                }
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                width: '80px',
                //sorter: (a, b) => a.quantity - b.quantity,
                render: (text, record) => {
                    return (
                        <div>
                            x {text}
                        </div>
                    )
                }
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                width: '80px',
                sorter: (a, b) => a.total - b.total,
                render: (text, record) => {
                    return (
                        <>
                            <div>{text}{currentOrder ? currentOrder.currency_symbol : null}</div>
                            <small>{Math.round((record.subtotal - text) * 100) / 100}$ discount</small>
                        </>
                    )
                }
            }
        ];

        if (currentData) {
            var textColor = '#0040ff';
            if (currentData.percent <= 29) {
                textColor = "#ff0000";
            } else if (currentData.percent >= 40) {
                textColor = '#009900';
            }
        }

        var full_address = '';
        var state_address = '';
        if (currentOrder) {
            if (currentOrder.shipping.state) {
                state_address = currentOrder.shipping.state;
            }
            full_address = currentOrder.shipping.address_1 + ' ' + currentOrder.shipping.address_2 + ' ' + currentOrder.shipping.city + ' ' + state_address + ' ' + currentOrder.shipping.postcode + ' ' + currentOrder.shipping.country;
        }

        var is_blacklist = '';
        var isblacklist = false;
        if (currentData?.is_blacklist && Array.isArray(currentData?.is_blacklist?.value) && currentData?.is_blacklist?.value.length > 0) {
            is_blacklist = <Tag color='red'>Blacklist</Tag>
            isblacklist = true
        } else {
            isblacklist = false
        }

        return (
            <>
                <Drawer
                    visible={visibleDrawer}
                    title={<span style={isblacklist ? { color: 'red' } : { color: 'normal' }}>
                        {
                            `Order #${currentOrder ? currentOrder.ID : null} - ${store_url ? store_url.replace('https://', '') : null}`
                        }
                        &nbsp;{is_blacklist}
                    </span>}
                    onClose={() => this.onCancel()}
                    maskClosable={false}
                    bodyStyle={{ paddingTop: 0 }}
                    headerStyle={{ borderBottom: 0, paddingBottom: 10 }}
                    width={isMobile ? '100%' : '90%'}
                    extra={
                        <>
                            {
                                checkPermission('orders', 'view_on_website') ? (
                                    <a target='_blank' href={`${store_url}/wp-admin/post.php?post=${currentOrder ? currentOrder.ID : null}&action=edit`}>
                                        <Button size='small'>Xem trên web</Button>
                                    </a>
                                ) : null
                            }
                        </>
                    }
                >
                    {visibleDrawer && currentOrder ?
                        (
                            <div>
                                <Row gutter={16}>
                                    <Col xs={24} md={16}>
                                        <Divider orientation="left" orientationMargin="0">
                                            Chi tiết đơn hàng
                                        </Divider>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Descriptions
                                                    size='small'
                                                >
                                                    <Descriptions.Item span={3}>
                                                        {/* {moment(currentData.date_created_vn).format('DD/MM/YYYY HH:mm:ss')} */}
                                                        Payment via {currentData.payment_method_title} <span style={{ fontWeight: '600' }}>(<i>{currentData?.transaction_id}</i>)</span>. Paid on {moment(currentData.date_created).format('DD/MM/YYYY HH:mm:ss')}, By: {currentOrder ? currentOrder.payer_paypal_address : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Text strong>Billing</Text>
                                                <br />
                                                <Text>
                                                    {currentOrder ? currentOrder.billing.first_name : null}&nbsp;
                                                    {currentOrder ? currentOrder.billing.last_name : null}
                                                </Text>
                                                <br />
                                                <Text>
                                                    {currentOrder ? currentOrder.billing.address_1 : null}&nbsp;
                                                    {currentOrder ? currentOrder.billing.address_2 : null}
                                                </Text>
                                                <br />
                                                <Text>
                                                    {currentOrder ? currentOrder.billing.city : null},&nbsp;
                                                    {currentOrder ? currentOrder.billing.state : null}&nbsp;
                                                    {currentOrder ? currentOrder.billing.postcode : null}&nbsp;
                                                    {currentOrder ? currentOrder.billing.country : null}
                                                </Text>
                                                <br />
                                                <Text>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.billing.email : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Email</b>: {currentOrder ? currentOrder.billing.email : null}&nbsp;
                                                    </Paragraph>
                                                </Text>
                                                <Text>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.billing.phone : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Phone</b>: {currentOrder ? currentOrder.billing.phone : null}
                                                    </Paragraph>
                                                </Text>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Text strong>Shipping</Text>
                                                <div className='fix-row-shipping'>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.first_name + ' ' + currentOrder.shipping.last_name : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>First name: </b>{currentOrder ? currentOrder.shipping.first_name : null}&nbsp;
                                                        <b>Last name: </b>{currentOrder ? currentOrder.shipping.last_name : null}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.address_1 : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Address line 1: </b>{currentOrder ? currentOrder.shipping.address_1 : null}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.address_2 : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Address line 2: </b>{currentOrder ? currentOrder.shipping.address_2 : null}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.city : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>City: </b>{currentOrder ? currentOrder.shipping.city : null}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: state_address ? state_address : 'null',
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>State: </b>{currentOrder && currentOrder.shipping.state ? currentOrder.shipping.state : 'null'}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.postcode : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Postcode: </b>{currentOrder ? currentOrder.shipping.postcode : null}
                                                    </Paragraph>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentOrder ? currentOrder.shipping.country : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <b>Country: </b>{currentOrder ? currentOrder.shipping.country : null}
                                                    </Paragraph>

                                                </div>
                                                <Paragraph style={{ margin: 0, clear: 'both' }} copyable={{ text: currentOrder && currentOrder.shipping.phone != null ? currentOrder.shipping.phone : currentOrder ? currentOrder.billing.phone : null, tooltips: false }}>
                                                    <b>Phone</b>: {currentOrder && currentOrder.shipping.phone != null ? currentOrder.shipping.phone : currentOrder ? currentOrder.billing.phone : null}
                                                </Paragraph>
                                                <Paragraph style={{ margin: 0, clear: 'both' }} copyable={
                                                    {
                                                        text: full_address,
                                                        tooltips: false
                                                    }
                                                }>
                                                    Copy all address
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                        {
                                            currentOrder.products && currentOrder.products.length > 0 ? (
                                                <>
                                                    <Divider />
                                                    <Row gutter={16} className='mb-4'>
                                                        <Col xs={24} md={24}>
                                                            <Table
                                                                rowKey="id"
                                                                size='small'
                                                                columns={columns}
                                                                dataSource={currentOrder.products || []}
                                                                pagination={false}
                                                                defaultExpandAllRows={true}
                                                                expandable={{
                                                                    expandedRowRender: (record, index, indent, expanded) => {
                                                                        return <Table
                                                                            size='small'
                                                                            dataSource={record.meta_data || []}
                                                                            columns={meta_data_columns}
                                                                            pagination={false}
                                                                        />
                                                                    },
                                                                }}
                                                                scroll={{
                                                                    x: 'max-content',
                                                                    //y: 'max-content',
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null
                                        }
                                        {
                                            currentOrder.fee_lines && currentOrder.fee_lines.length > 0 ? (
                                                <>
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Table
                                                                title={() => <h6>Fee lines</h6>}
                                                                size='small'
                                                                dataSource={currentOrder.fee_lines || []}
                                                                columns={fee_lines_columns}
                                                                pagination={false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null
                                        }

                                        {
                                            currentOrder.shipping_lines && currentOrder.shipping_lines.length > 0 ? (
                                                <>
                                                    <Row gutter={16} className='mt-4'>
                                                        <Col xs={24} md={24}>
                                                            <Table
                                                                title={() => <h6>Shipping lines</h6>}
                                                                size='small'
                                                                dataSource={currentOrder.shipping_lines || []}
                                                                columns={shipping_lines_columns}
                                                                pagination={false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null
                                        }

                                        <Row gutter={16} className='mt-4'>
                                            <Col xs={24} md={24}>
                                                <Descriptions layout="horizontal" size='small'>
                                                    <Descriptions.Item label="Items Subtotal" span={3}>
                                                        {currentOrder ? currentOrder.subtotal : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Coupon(s)" span={3}>
                                                        -{currentOrder && currentOrder.coupon ? currentOrder.coupon : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Shipping" span={3}>
                                                        {currentOrder ? currentOrder.shipping_total : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Order Total" span={3}>
                                                        {currentOrder ? currentOrder.total : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                                                <Descriptions layout="horizontal">
                                                    <Descriptions.Item label="Paid" span={3}>
                                                        {currentOrder ? currentOrder.total : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="PayPal Fee" span={3}>
                                                        {currentOrder ? currentData.fee_payment : null}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="PayPal Payout" span={3}>
                                                        {currentOrder ? currentOrder.paypal_payout : 0}
                                                        {currentOrder ? currentOrder.currency_symbol : null}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider style={{ marginTop: '0px', marginBottom: '15px' }} />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        {
                                            checkPermission('orders', 'update') ? (
                                                <>
                                                    <Spin tip="Loading..." spinning={loadingCostForm}>
                                                        <Divider orientation="left" orientationMargin="0">
                                                            Chi phí đơn hàng
                                                        </Divider>
                                                        <div className='box-cost'>
                                                            {/* <div>Tài khoản nhận thanh toán: <Tag>{currentData ? currentData.payment_receiving_account : null}</Tag></div> */}
                                                            <div>Shield: <Tag>{currentData?.proxy_url}</Tag></div>
                                                            <Divider />
                                                            <Row gutter={16}>
                                                                <Col xs={24} md={12}>
                                                                    <div style={{ fontWeight: '500', color: '#00aaff' }}>
                                                                        Profit: {currentData.profit}{currentData.profit ? currentData.currency_symbol : null}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <div style={{ fontWeight: '500', color: textColor }}>
                                                                        Percent: {currentData.percent}%
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Divider />
                                                            <Form layout='vertical'>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item label="Nhân viên Fulfill">
                                                                            {getFieldDecorator('fulfi_user_id', {
                                                                                rules: [
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Field is required.'
                                                                                    }
                                                                                ],
                                                                                initialValue: currentData ? parseInt(currentData.fulfi_user_id) : null
                                                                            })(
                                                                                <BaseSelect
                                                                                    options={users || []}
                                                                                    optionValue="user_id"
                                                                                    optionLabel="full_name"
                                                                                    defaultText="Chọn nhân viên"
                                                                                    showSearch={true}
                                                                                    style={{ width: '100%' }}
                                                                                    disabled={!checkPermission('orders', 'change_fulfi_user')}
                                                                                />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item label="Status">
                                                                            {getFieldDecorator('status', {
                                                                                rules: [
                                                                                    {
                                                                                        required: true,
                                                                                        message: 'Field is required.'
                                                                                    }
                                                                                ],
                                                                                initialValue: currentData ? currentData.status : 'NewOrder'
                                                                            })(
                                                                                <BaseSelect
                                                                                    options={[
                                                                                        { value: 'NewOrder', label: 'New Order' },
                                                                                        { value: 'Designing', label: 'Designing' },
                                                                                        { value: 'FulFillment', label: 'FulFillment' },
                                                                                        { value: 'Shipped', label: 'Shipped' },
                                                                                        { value: 'Delivered', label: 'Delivered' },
                                                                                        { value: 'OutForDelivery', label: 'Out for delivery' },
                                                                                        { value: 'Refund', label: 'Refund' },
                                                                                        { value: 'issue_need_to_check', label: 'Issue need to check' }
                                                                                    ]}
                                                                                    defaultText="Status"
                                                                                    style={{ width: '100%' }}
                                                                                />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item label="Cost (Phí fulfi + chuyển đổi)">
                                                                            {getFieldDecorator('fulfillment_amount', {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Vui lòng điền!',
                                                                                    },
                                                                                ],
                                                                                initialValue: currentData ? currentData.fulfillment_amount : null
                                                                            })(
                                                                                <Input suffix="$" placeholder='Phí fulfi + chuyển đổi' />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item label="Phí thiết kế file in">
                                                                            {getFieldDecorator('png_design_amount', {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Vui lòng điền!',
                                                                                    },
                                                                                ],
                                                                                initialValue: currentData ? currentData.png_design_amount : null
                                                                            })(
                                                                                <Input suffix="$" />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    {/* <Col xs={24} md={12}>
                                                                        <Form.Item label="Phí PayPal">
                                                                            {getFieldDecorator('fee_payment', {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Field is required.'
                                                                                    }
                                                                                ],
                                                                                initialValue: currentData ? currentData.fee_payment : null
                                                                            })(
                                                                                <Input disabled suffix="$" />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col xs={24} md={12}>
                                                                        <Form.Item label="Phí thuê cổng">
                                                                            {getFieldDecorator('fee_payment_gateway', {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Vui lòng điền!',
                                                                                    },
                                                                                ],
                                                                                initialValue: currentData ? currentData.fee_payment_gateway : null
                                                                            })(
                                                                                <Input disabled suffix="$" />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col> */}
                                                                </Row>
                                                                <Form.Item label="Đơn vị in">
                                                                    {getFieldDecorator('printing_unit', {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: 'Vui lòng điền!',
                                                                            },
                                                                        ],
                                                                        initialValue: currentData ? currentData.printing_unit : []
                                                                    })(
                                                                        <BaseSelect
                                                                            options={printing_unit_active || []}
                                                                            optionValue="id"
                                                                            optionLabel="name"
                                                                            mode="multiple"
                                                                            placeholder="Chọn một hoặc nhiều đơn vị in"
                                                                            showSearch={true}
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                                <Row gutter={16}>
                                                                    <Col xs={24} md={24}>
                                                                        <Form.Item label="Mã in">
                                                                            {getFieldDecorator('print_code', {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message: 'Vui lòng điền!',
                                                                                    },
                                                                                ],
                                                                                initialValue: currentData ? currentData.print_code : null
                                                                            })(
                                                                                <Input placeholder='Mỗi mã cách nhau dấu (,) 608864/299886,608832/299886' />
                                                                            )}
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Form.Item>
                                                                    <Button type='dashed' onClick={() => this.openShipmentTracking()} style={{ width: '100%', color: '#108ee9' }}>
                                                                        Xem mã tracking
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item label="Ghi chú">
                                                                    {getFieldDecorator('note', {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: "Vui lòng chọn!",
                                                                            },
                                                                        ],
                                                                        initialValue: currentData ? currentData.note : ''
                                                                    })(
                                                                        <Input.TextArea rows={4} />
                                                                    )}
                                                                </Form.Item>
                                                                {
                                                                    checkPermission('orders', 'update') ? (
                                                                        <div style={{ textAlign: 'right', }}>
                                                                            <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                                                                Cập nhật
                                                                            </Button>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </Form>
                                                        </div>
                                                    </Spin>
                                                </>
                                            ) : null
                                        }
                                        <Divider orientation="left" orientationMargin="0">
                                            Order note
                                        </Divider>
                                        <div>
                                            {
                                                currentOrder.order_note && currentOrder.order_note.length > 0 ? (
                                                    <List
                                                        dataSource={currentOrder ? currentOrder.order_note : []}
                                                        renderItem={item => (
                                                            <List.Item>
                                                                {item.note}
                                                                <List.Item.Meta
                                                                    description={
                                                                        <>
                                                                            {moment(item.date_created_gmt ? item.date_created_gmt : null).format('DD/MM/YYYY HH:m:s')}
                                                                        </>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : null
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div >
                        )
                        : (
                            <Empty />
                        )}
                </Drawer>

                <DesignAddForm
                    visible={isOpenDesignAddForm}
                    onCancel={() => this.handleCancelDesignForm()}
                    currentOrder={currentOrder}
                    currentProduct={currentProduct || null}
                    productColor={productColor || null}
                    productPrintOnThe={productPrintOnThe || null}
                    personalizedText={personalizedText || null}
                    currentData={currentData}
                    store_url={store_url}
                    reloadData={() => this.props.reloadData()}
                    reloadData2={() => this.props.reloadOMnData(this.props.currentData.order_id, this.props.currentData.store_id)}
                />
                <ShipmentTracking
                    visible={isOpenShipmentTracking}
                    currentData={data ? data.cost : null}
                    onCancel={() => this.setState({ isOpenShipmentTracking: false })}
                    reloadData={() => this.props.reloadData()}
                    reloadData2={() => this.getData()}
                />
            </>
        )
    }
}

function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

function mapStateToProps(state) {
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
        printing_unit_active: state.PrintingUnitReducer.printing_unit_active
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateOrderManagement: (id, data) => dispatch(updateOrderManagement(id, data)),
        getOneOrder: (id, params) => dispatch(getOneOrder(id, params)),
        checkMatching: (data) => dispatch(checkMatching(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'OrderDetailDrawer' })(OrderDetailDrawer));

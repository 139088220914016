import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Drawer, Descriptions, Divider, Switch, Tag, Input, Comment, Row, Col } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { _newLine, isValidDate } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import ProjectBasedJobForm from './ProjectBasedJobForm';
import { checkPermission } from '../../utils/permission';
// actions
import { getData, updateStatus, updateResult } from '../../redux/actions/ProjectBasedJobActions';
import CommentsComponent from '../../components/CommentsComponent';

class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenUpdateResult: false,
            isOpenFormEdit: false
        };
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(['result'], async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    //console.log(values)
                    await this.props.updateResult(this.props.currentData.id, values);
                    this.cancelUpdateResult()
                }
                this.setState({ isloading: false })
            }
        });
    }

    handleCancel() {
        this.setState({ isOpenUpdateResult: false })
        this.props.onCancel()
        this.props.reloadList()
    }

    reloadData() {
        this.props.reloadData();
        this.setState({ isLoading: false })
        this.props.form.resetFields();
    }

    onUpdateStatus(value) {
        this.props.updateStatus(this.props.currentData?.id, { status: value })
        this.props.reloadList();
        this.props.reloadData();
    }

    cancelUpdateResult() {
        this.setState({ isOpenUpdateResult: false })
        this.props.reloadData()
        this.props.form.resetFields();
    }

    render() {
        const { isOpenUpdateResult, isloading, isOpenFormEdit } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        if (currentData) {
            var diffDate = currentData?.deadline ? moment(currentData?.deadline).diff(moment().startOf('day'), 'days') : 0;
            var diffDateFinishDay = currentData?.finish_day ? moment(currentData?.deadline).diff(moment(currentData?.finish_day), 'days') : 0;
        }

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Chi tiết công việc"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        <div style={{ display: 'flex' }}>
                            {checkPermission('project_based_job', 'update') ? (
                                <Button type="primary" onClick={() => this.setState({ isOpenFormEdit: true })} icon={<EditOutlined />}>
                                    Sửa
                                </Button>
                            ) : null}
                            <Divider type='vertical' />
                            {checkPermission('project_based_job', 'update_result') ? (
                                <Button onClick={() => this.setState({ isOpenUpdateResult: true })} type="primary" icon={<EditOutlined />}>
                                    Cập nhật kết quả
                                </Button>
                            ) : null}
                        </div>
                    }
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Button key="back" onClick={() => this.props.onCancel()}>
                                Huỷ
                            </Button>
                        </div>
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="STT">
                                            <Tag>{currentData?.sort_order}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên công việc">
                                            {currentData?.name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Dự án">
                                            {currentData?.project_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tình trạng">
                                            <Switch
                                                disabled={!checkPermission('project_based_job', 'update_status')}
                                                defaultChecked={currentData?.status}
                                                checkedChildren="Đã hoàn thành"
                                                unCheckedChildren="Chưa hoàn thành"
                                                onChange={(value) => this.onUpdateStatus(value)}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày bắt đầu">
                                            {isValidDate(currentData?.start_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                {
                                    currentData?.deadline ? (
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Deadline">
                                                    {
                                                        currentData?.status == 1 ? (
                                                            <>
                                                                {isValidDate(currentData?.deadline, false)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isValidDate(currentData?.deadline, false)} &nbsp;
                                                                {
                                                                    diffDate ? (
                                                                        <>
                                                                            {
                                                                                diffDate > 0 ? <Tag color="#52c41a">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDate} ngày</Tag>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <Tag color="#f50">Hết hạn hôm nay</Tag>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    ) : null
                                }
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người thực hiện">
                                            <Tag>{currentData?.user}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày hoàn thành">
                                            {
                                                currentData?.finish_day ? (
                                                    <>
                                                        {isValidDate(currentData?.finish_day, false)}
                                                        <Divider type='vertical' />
                                                        {
                                                            diffDateFinishDay ? (
                                                                <span>
                                                                    {
                                                                        diffDateFinishDay >= 0 ? <Tag color="#52c41a">Trước hạn {diffDateFinishDay} ngày</Tag> : <Tag color="red">Quá hạn {-1 * diffDateFinishDay} ngày</Tag>
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <Tag color="#52c41a">Đúng hạn</Tag>
                                                            )
                                                        }
                                                    </>
                                                ) : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)} bởi: {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                            <Comment
                                author={<span className='ant-descriptions-item-label'>Kết quả công việc</span>
                                }
                                content={ReactHtmlParser(currentData?.result)}
                            />
                            <Divider />
                            <Comment
                                author={<span className='ant-descriptions-item-label'>Mô tả công việc</span>
                                }
                                content={<div className='chingo-content-detail'>{ReactHtmlParser(currentData?.description)}</div>}
                            />
                            <Divider />
                            <Comment
                                author={<span className='ant-descriptions-item-label'>Ghi chú</span>
                                }
                                content={ReactHtmlParser(currentData?.note)}
                            />
                            <Divider />
                            <CommentsComponent
                                reloadData={() => this.reloadData()}
                                commentsData={currentData.comments}
                                type="project_based_job"
                                parentId={currentData?.id}
                            />

                        </div>
                    ) : null}
                </Drawer>

                <Drawer
                    width={isMobile ? '100%' : '40%'}
                    title="Cập nhật kết quả công việc"
                    visible={isOpenUpdateResult}
                    onClose={() => this.cancelUpdateResult()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.cancelUpdateResult()}>
                                Huỷ
                            </Button>
                            <Divider type='vertical' />
                            <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                {currentData ? "Cập nhật" : "Tạo"}
                            </Button>
                        </div>
                    }
                >
                    <Form layout="vertical">
                        <Form.Item label="Nội dung kết quả">
                            {getFieldDecorator('result', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: currentData ? _newLine(currentData?.result) : null
                            })(
                                <Input.TextArea rows={8} />
                            )}
                        </Form.Item>
                    </Form>
                </Drawer>
                <ProjectBasedJobForm
                    visible={isOpenFormEdit}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenFormEdit: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getData: (id) => dispatch(getData(id)),
        updateStatus: (id, data) => dispatch(updateStatus(id, data)),
        updateResult: (id, data) => dispatch(updateResult(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));

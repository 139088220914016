import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined, IeOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Modal, Divider, Card, Pagination, Row, Comment, Drawer, List, Avatar, Space, Input } from 'antd';
import PageTitle from '../../components/PageTitle';
import { Form } from '@ant-design/compatible';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import GuideForm from './GuideForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import avatar from '../../assets/img/favicon.png';
import "react-quill/dist/quill.core.css";
import { isMobile } from 'react-device-detect';

import moment from 'moment';
// actions
import { getAllGuide, getGuide, removeGuide } from '../../redux/actions/GuideActions';
import { createComment, removeComment } from '../../redux/actions/CommentsAction';

const { confirm } = Modal;
const { TextArea } = Input;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/guide',
        breadcrumbName: 'Guide',
    },
]

class GuideList extends Component {

    state = {
        isLoading: false, // loading table
        isOpenForm: false,
        currentData: null,
        openView: false,
        comments: []
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllGuide(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllGuide(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: '/guide',
            search: qs.stringify(query)
        });
    }

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getGuide(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onView(id) {
        this.props.getGuide(id).then(res => {
            this.setState({ openView: true, currentData: res });
        })
    }

    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeGuide([id]).then(() => {
                this.reloadData()
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllGuide(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                const { currentData } = this.state;
                await this.props.createComment({ type: 'guide', content: values.content, parent_id: currentData.id }).then(res => {
                    this.loadData();
                })
            }
        });
    }
    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.loadData();
            }),
            onCancel() { },
        })
    }

    loadData() {
        this.props.form.resetFields();
        this.props.getGuide(this.state.currentData.id).then(res => {
            this.setState({ currentData: res, submitting: false, });
        })
    }

    render() {
        var { guide, pagination, authUser } = this.props;
        var { isLoading, isOpenForm, openView, currentData, submitting } = this.state;
        var query = qs.parse(this.props.location.search.slice(1));
        const { getFieldDecorator } = this.props.form;

        const IconText = ({ icon, text }) => (
            <Space>
                <span style={{ cursor: 'pointer' }}>
                    {React.createElement(icon)}&nbsp;
                    {text}
                </span>
            </Space>
        );
        const comments = [];
        if (currentData) {
            currentData.comments.map(item => {
                var disabledDeleteComment = true;
                if (authUser.role_code == 'ADMIN') {
                    disabledDeleteComment = false
                } else if (authUser.id == item.sender) {
                    disabledDeleteComment = false
                }
                comments.push(
                    {
                        actions: [<Button disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <UserOutlined style={{ fontSize: '20px' }} className='primary-color' />,
                        content: <p>{ReactHtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        return (
            <div>
                <PageTitle routes={routes} title="Guide" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('guide', 'create')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                    </TableActionBar>
                </div>
                <Card>
                    <List
                        itemLayout="vertical"
                        loading={isLoading}
                        rowKey='id'
                        size="large"
                        dataSource={guide}
                        renderItem={item => {
                            const IconAction = [
                                <IconText icon={MessageOutlined} text={item.countComment || 0} key="list-vertical-message" />
                            ];
                            IconAction.push(<a onClick={() => this.onView(item.id)} key="list-vertical-view-on-pages"><EyeOutlined /> Xem nhanh</a>)
                            IconAction.push(<Link to={`/guide/${item.id}`} key="list-vertical-detail"><IeOutlined /> Xem chi tiết</Link>)

                            if (checkPermission('guide', 'update')) {
                                IconAction.push(<a onClick={() => this.onEdit(item.id)} type='link' key="list-vertical-edit"><EditOutlined /> Chỉnh sửa</a>)
                            }
                            if (checkPermission('guide', 'remove')) {
                                IconAction.push(<a onClick={() => this.openAlert(item.id)} type='link' key="list-vertical-delete"><DeleteOutlined /> Xóa</a>)
                            }
                            return (
                                <List.Item
                                    key={item.title}
                                    actions={IconAction}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar src={avatar} />}
                                        title={item.title}
                                        description={
                                            <>
                                                <div>{moment(item.created_at).format('DD/MM/YYYY')} by: {item.created_user}</div>
                                            </>
                                        }
                                    />
                                    <TextTruncate line={3} truncateText="…" text={stripHtml(item.content)} />
                                </List.Item>
                            )
                        }
                        }
                    />
                    < Row justify="end" >
                        <Pagination {...pagination} onChange={(page) => this.onChangeFilter('page', page)} />
                    </Row >
                </Card >
                <GuideForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />

                <Drawer
                    visible={openView}
                    title={currentData ? currentData?.title : 'CHI TIẾT'}
                    onClose={() => this.setState({ openView: false })}
                    maskClosable={true}
                    width={isMobile ? '100%' : '60%'}
                >
                    {
                        openView ?
                            <div>
                                {
                                    currentData ? (
                                        <>
                                            <div className="view ql-editor training-img">
                                                {ReactHtmlParser(currentData?.content)}
                                            </div>
                                            <Divider />
                                            <h6>THẢO LUẬN</h6>
                                            <br />
                                            {comments.length > 0 && <CommentList comments={comments} />}
                                            <Comment
                                                avatar={<UserOutlined style={{ fontSize: '20px' }} className='primary-color' />}
                                                content={
                                                    <>
                                                        <Form.Item>
                                                            {getFieldDecorator('content', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Vui lòng điền!',
                                                                    },
                                                                ],
                                                                initialValue: ""
                                                            })(<TextArea rows={4} />)}
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button htmlType="submit" loading={submitting} onClick={(e) => this.submitComment(e)} type="primary">
                                                                Đăng
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                }
                                            />
                                        </>
                                    ) : null
                                }
                            </div>
                            : null
                    }
                </Drawer>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        guide: state.Guide.guide,
        pagination: state.Guide.pagination,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllGuide: (filter) => dispatch(getAllGuide(filter)),
        getGuide: (id) => dispatch(getGuide(id)),
        removeGuide: (ids) => dispatch(removeGuide(ids)),
        createComment: (data) => dispatch(createComment(data)),
        removeComment: (ids) => dispatch(removeComment(ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'GuideList' })(GuideList));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, RocketOutlined, RetweetOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table, Button, Modal, Divider, Tag, Tooltip, Switch, Typography, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import ChatGptForm from './ChatGptForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import { NotificationManager } from 'react-notifications';

import moment from 'moment';
// actions
import { chatGPTGetAll, chatGPTGetOne, chatGPTRemove } from '../../redux/actions/ChatGptActions';

const { confirm } = Modal;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/chatgpt',
        breadcrumbName: 'Cấu hình Chat GPT',
    },
]

class ChatGptList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.chatGPTGetAll(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.chatGPTGetAll(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/chatgpt',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/chatgpt',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.chatGPTGetOne(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.chatGPTGetAll(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onRemove() {
        this.props.chatGPTRemove(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            //this.reloadData()
        })
    }

    render() {
        var { chatgpt_list, pagination, users, authUser } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData } = this.state;
        //console.log(stores)
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: '#',
                align: 'center',
                key: 'actions',
                width: '50px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('chatgpt', 'update') ? (
                                <>
                                    <Tooltip title="Sửa">
                                        <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    </Tooltip>
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => (
                    <div className='ecom-paragraph'>
                        <Paragraph copyable={{ tooltips: false }}>{text}</Paragraph>
                    </div>
                )
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={ReactHtmlParser(text)}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            },
            {
                title: 'Ngày thêm',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '150px',
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Cấu hình Chat GPT" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('chatgpt', 'create')}
                        isShowDeleteButton={checkPermission('chatgpt', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã kích hoạt' },
                                { value: 0, label: 'Chưa kích hoạt' }
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            defaultText="Trạng thái"
                            placeholder="Trạng thái"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '150px' }}
                            className="table-button"
                        />

                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={chatgpt_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ChatGptForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        chatgpt_list: state.ChatGptReducer.chatgpt_list,
        pagination: state.ChatGptReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        chatGPTGetAll: (filter) => dispatch(chatGPTGetAll(filter)),
        chatGPTGetOne: (id) => dispatch(chatGPTGetOne(id)),
        chatGPTRemove: ids => dispatch(chatGPTRemove(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatGptList);

import {
    GET_LIST_NOTIFICATIONS,
    GET_COUNT_NOTIFICATIONS
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    notifications: [],
    pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 20
    },
    countNotifications: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_NOTIFICATIONS: {
            let listNotifications = [...action.payload.data, ...state.notifications];

            // Sắp xếp lại mảng theo id giảm dần
            listNotifications.sort((a, b) => b.id - a.id);

            // Loại bỏ các bản ghi trùng lặp
            listNotifications = listNotifications.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);

            return {
                ...state,
                notifications: listNotifications,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_COUNT_NOTIFICATIONS:
            return {
                ...state,
                countNotifications: action.payload.data
            };
        default:
            return { ...state };
    }
}

import {
    GET_TRADEMARK_KEYWORD,
    UPDATE_TRADEMARK_KEYWORD
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRADEMARK_KEYWORD: {
            return {
                ...action.payload
            }
        }
        case UPDATE_TRADEMARK_KEYWORD: {
            return {
                ...state
            }
        }
        default: return { ...state };
    }
}

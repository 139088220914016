import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Space, Image, TreeSelect } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import Editor from "../../components/Editor";
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { makeTree, convertToSlug } from '../../utils/helpers';

// actions
import { updateProduct } from "../../redux/actions/ProductsAction";
import { createdTags } from '../../redux/actions/ProductsTagsAction';

class ProductsEditForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            thumbnail: [],
            gallery: [],
            currentGallery: [],
            currentThumbnail: [],
            showUpload: false
        };
        this.nameField = React.createRef();
    }

    componentDidMount() {
        //console.log("optional-chaining: ", this.props.item?.gallery);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.item != this.props.item) {
            this.setState({
                currentGallery: nextProps?.item?.gallery,
                currentThumbnail: nextProps?.item?.thumbnail
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                delete values.tagsAdd
                this.setState({ isLoading: true });
                const { thumbnail, gallery, currentThumbnail, currentGallery } = this.state;
                try {
                    const new_thumbnail = [];
                    if (typeof currentThumbnail?.src != 'undefined') {
                        new_thumbnail.push(currentThumbnail?.src)
                    } else if (thumbnail) {
                        new_thumbnail.push(...thumbnail)
                    }
                    const new_gallery = [];
                    currentGallery.map(img => {
                        new_gallery.push(img.src)
                    })
                    values.store_id = this.props.store_id
                    values.images = [...new_thumbnail, ...[...gallery, ...new_gallery]]
                    //console.log(values)
                    await this.props.updateProduct(this.props.item.ID, values).then(res => {
                        //console.log(res)
                        this.props.reloadData();
                        this.onClose();
                    });
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            thumbnail: [],
            gallery: [],
            showUpload: false
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    onChangeThumbnail = (data) => {
        this.setState({
            isResetUpload: false,
            thumbnail: data,
            currentThumbnail: []
        });
    }

    onChangeGallery = (data) => {
        this.setState({
            isResetUpload: false,
            gallery: data
        });
        NotificationManager.success("Gallery image complete.");
    }

    deleteCurrentGallery = (id) => {
        const items = [...this.state.currentGallery];
        this.setState({ currentGallery: items.filter(item => item.id !== id) });
    }

    deleteCurrentThumbnail() {
        this.setState({ currentThumbnail: [], showUpload: true })
    }

    createSlug(e) {
        var slug = convertToSlug(e.target.value);
        this.props.form.setFieldsValue({ slug: slug });
    }

    async createTags(e) {
        //console.log(this.nameField)
        e.preventDefault();
        this.props.form.validateFields(['tagsAdd'], async (err, values) => {
            if (!err) {
                if (this.state.newTagsAddValue) {
                    values.store_id = this.props.store_id;
                    values.name = this.state.newTagsAddValue;
                    delete values.tagsAdd
                    //console.log(values)
                    await this.props.createdTags(values).then(res => {
                        //this.nameField.current.state.value = '';
                        this.props.reloadData()
                    })
                }
            }
        });
    }

    render() {
        const { visible, item, categories, tags } = this.props;
        const { isLoading, isResetUpload, currentThumbnail, currentGallery, showUpload } = this.state;
        const { getFieldDecorator } = this.props.form;
        const cat_selected = [];
        const tags_selected = [];
        const currentGalleryList = [];
        //console.log("item: ", item)
        if (item) {
            item.categories.map((category) => {
                cat_selected.push(category.id);
            })
            item.tags.map((tag) => {
                tags_selected.push(tag.id);
            })
            currentGallery.map(image => {
                currentGalleryList.push(
                    <div className="list-img-thumbnail">
                        <Image
                            width={55}
                            src={image?.src}
                        />
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteCurrentGallery(image?.id)}
                        />
                    </div>
                )
            })
        }
        const catTree = makeTree(categories || [], 0);

        return (
            <Drawer
                visible={visible}
                title="Cập nhật sản phẩm"
                width={isMobile ? '100%' : '60%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ? (
                    <>
                        <Form layout='vertical'>
                            <Form.Item label="Tên sản phẩm">
                                {getFieldDecorator("name", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: item ? item.name : null
                                })(<Input
                                    onKeyPress={(e) => this.createSlug(e)}
                                    onBlur={(e) => this.createSlug(e)}
                                />)}
                            </Form.Item>
                            <Form.Item label="Permalink">
                                {getFieldDecorator("slug", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: item ? item.slug : null
                                })(
                                    <Input addonBefore={`${this.props.store_url}/product/`} addonAfter="/" />
                                )}
                            </Form.Item>
                            <Form.Item label='Categories'>
                                {getFieldDecorator("categories", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: item ? cat_selected : null
                                })(
                                    <TreeSelect
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                        treeData={catTree}
                                        multiple={true}
                                        showSearch={true}
                                        treeNodeFilterProp='title'
                                        allowClear
                                        placeholder="Chọn một hoặc nhiều categories"
                                        treeDefaultExpandAll
                                    />
                                )}
                            </Form.Item>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Regular price ($)">
                                        {getFieldDecorator("regular_price", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: item ? item.regular_price : null
                                        })(
                                            <Input placeholder='21.95' />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label='Sale price ($)'>
                                        {getFieldDecorator("sale_price", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: item ? item.sale_price : null
                                        })(
                                            <Input placeholder='15.5' />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Add tags'>
                                        <Input.Group compact>
                                            <Input
                                                ref={this.nameField}
                                                name="tagsAdd"
                                                onKeyPress={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                onBlur={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                onPaste={(e) => this.setState({ newTagsAddValue: e.target.value })}
                                                allowClear
                                                style={{ width: '75%' }}
                                            />
                                            <Button onClick={(e) => this.createTags(e)} type="primary">Add</Button>
                                        </Input.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Form.Item label='Select tags'>
                                        {getFieldDecorator("tags", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: tags_selected
                                        })(
                                            <BaseSelect
                                                isloading={this.props.isLoading}
                                                options={tags || []}
                                                optionValue="id"
                                                mode="multiple"
                                                optionLabel="name"
                                                showSearch={true}
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label='Status'>
                                {getFieldDecorator("status", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: item ? item.status : null
                                })(
                                    <BaseRadios
                                        options={
                                            [
                                                { value: 'publish', label: "Publish" },
                                                { value: 'draft', label: "Draft" },
                                                { value: 'pending', label: "Pending" },
                                                { value: 'private', label: "Private" }
                                            ]
                                        }
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Mô tả">
                                {getFieldDecorator('description', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng điền!",
                                        },
                                    ],
                                    initialValue: item ? item.description : null
                                })(
                                    <Editor />
                                )}
                            </Form.Item>
                            {/* <Row gutter={16}> // Để xử lý sau, trong code sql chưa có chức năng update ảnh sp
                                <Col xs={24} md={12}>
                                    <Form.Item label="Product Image">
                                        {
                                            !showUpload ? (
                                                <div className="list-img-thumbnail">
                                                    <Image
                                                        width={55}
                                                        src={currentThumbnail?.src}
                                                    />
                                                    <DeleteOutlined title="Xóa" className="btn-delete-img" onClick={() => this.deleteCurrentThumbnail()} />
                                                </div>
                                            ) : (
                                                <>
                                                    {getFieldDecorator('image', {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: null
                                                    })(
                                                        <UploadImage
                                                            multiple={false}
                                                            maxCount={1}
                                                            onChangeData={this.onChangeThumbnail}
                                                            isResetUpload={isResetUpload}
                                                        />
                                                    )}
                                                </>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Product Gallery">
                                        {currentGalleryList}
                                        {getFieldDecorator('gallery', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={10}
                                                onChangeData={this.onChangeGallery}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Form>
                    </>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProduct: (id, data) => dispatch(updateProduct(id, data)),
        createdTags: (data) => dispatch(createdTags(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductsEditForm" })(ProductsEditForm));

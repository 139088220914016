import {
    LIST_PRODUCTS_TAGS,
    LIST_TAGS_IN_PRODUCTS_FORM
} from '../actions/types';

const INIT_STATE = {
    list_tags: {
        store_id: null,
        store_url: null,
        items: []
    },
    tags_in_product_form: {
        store_id: null,
        store_url: null,
        items: []
    },
    currentTags: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_PRODUCTS_TAGS:
            return {
                ...state,
                list_tags: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    items: action.payload.data
                },
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };

        case LIST_TAGS_IN_PRODUCTS_FORM:
            return {
                ...state,
                tags_in_product_form: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    items: action.payload.data
                }
            };

        default:
            return { ...state }
    }
};

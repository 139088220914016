import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import UserReducer from "./UserReducer";
import PermissionReducer from "./PermissionReducer";
import RoleReducer from "./RoleReducer";
import ConfigReducer from "./ConfigReducer";
import ProductType from './ProductTypeReducer';
import NicheReducer from './NicheReducer';
import DesignReducer from './DesignReducer';
import StatisticalReducer from './StatisticalReducer';
import OrdersReducer from './OrdersReducer';
import HolidayReducer from './HolidayReducer';
import TrendingReducer from './TrendingReducer';
import StoreReducer from './StoreReducer';
import ProductsReducer from './ProductsReducer';
import ProductsCategories from './ProductsCategoriesReducer';
import ProductsTags from './ProductsTagsReducer';
import RevenueExpenditureReducer from './RevenueExpenditureReducer';
import WorkflowManagementReducer from './WorkflowManagementReducer';
import WorkReportReducer from './WorkReportReducer';
import TrainingReducer from './TrainingReducer';
import GuideReducer from './GuideReducer';
import TelegramReducer from './TelegramReducer';
import ProductsImportReducer from './ProductsImportReducer';
import ProductsDescriptionReducer from './ProductsDescriptionReducer';
import FilePngReducer from './FilePngReducer';
import ToolLicenseReducer from "./ToolLicenseReducer";
import GoogleIndexingReducer from './GoogleIndexingReducer';
import PrintingUnitReducer from './PrintingUnitReducer';
import PaymentGatewaysReducer from './PaymentGatewaysReducer';
import BlacklistReducer from './BlacklistReducer';
import ChatGptReducer from './ChatGptReducer';
import ProductsDescriptionContentReducer from './ProductsDescriptionContentReducer';
import LogMailTrackingReducer from './LogMailTrackingReducer';
import LogOrderShieldReducer from './LogOrderShieldReducer';
import PostsReducer from './PostsReducer';
import IdeaReducer from './IdeaReducer';
import ProjectReducer from './ProjectReducer';
import ProjectBasedJobReducer from './ProjectBasedJobReducer';
import NotificationsReducer from './NotificationsReducer';
import ProductCatDesReducer from './ProductCatDesReducer'
import TrademarkCategoryReducer from './TrademarkCategoryReducer';
import TrademarkKeywordReducer from './TrademarkKeywordReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    permission: PermissionReducer,
    role: RoleReducer,
    config: ConfigReducer,
    ProductType: ProductType,
    NicheReducer: NicheReducer,
    DesignReducer: DesignReducer,
    StatisticalReducer: StatisticalReducer,
    OrdersReducer: OrdersReducer,
    HolidayReducer: HolidayReducer,
    TrendingReducer: TrendingReducer,
    StoreReducer: StoreReducer,
    ProductsReducer: ProductsReducer,
    ProductsCategories: ProductsCategories,
    ProductsTags: ProductsTags,
    RevenueExpenditure: RevenueExpenditureReducer,
    WorkflowManagement: WorkflowManagementReducer,
    Training: TrainingReducer,
    Guide: GuideReducer,
    TelegramReducer: TelegramReducer,
    ProductsImportReducer: ProductsImportReducer,
    ProductsDescriptionReducer: ProductsDescriptionReducer,
    WorkReportReducer: WorkReportReducer,
    FilePngReducer: FilePngReducer,
    ToolLicenseReducer: ToolLicenseReducer,
    GoogleIndexingReducer: GoogleIndexingReducer,
    PrintingUnitReducer: PrintingUnitReducer,
    PaymentGatewaysReducer: PaymentGatewaysReducer,
    BlacklistReducer: BlacklistReducer,
    ChatGptReducer: ChatGptReducer,
    ProductsDescriptionContentReducer: ProductsDescriptionContentReducer,
    LogMailTrackingReducer: LogMailTrackingReducer,
    LogOrderShieldReducer: LogOrderShieldReducer,
    PostsReducer: PostsReducer,
    IdeaReducer: IdeaReducer,
    ProjectReducer: ProjectReducer,
    ProjectBasedJobReducer: ProjectBasedJobReducer,
    NotificationsReducer: NotificationsReducer,
    ProductCatDesReducer: ProductCatDesReducer,
    TrademarkCategoryReducer: TrademarkCategoryReducer,
    TrademarkKeywordReducer: TrademarkKeywordReducer
});

export default rootReducer;
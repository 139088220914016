import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, DatePicker, Row, Col, Drawer, Divider } from 'antd';
import UploadImage from '../../components/UploadImage';
import moment from 'moment';
import { _newLine } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
// actions
import { createHoliday, updateHoliday } from '../../redux/actions/HolidayActions';

class HolidayForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            image: []
        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                values.date = values.date ? values.date.format("YYYY-MM-DD") : null;
                if (this.state.image.length) {
                    //const [image] = this.state.image;
                    values.image = this.state.image;
                }
                if (this.props.currentData) {
                    await this.props.updateHoliday(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    await this.props.createHoliday(values);
                    this.handCancel()
                    this.props.reloadData()
                }
            }
        });
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Image complete.");
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false, image: [], isResetUpload: true })
    }

    render() {
        const { isloading, isResetUpload } = this.state;
        var { visible, currentData } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isloading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Tên">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Vui lòng điền!',
                                    },
                                ],
                                initialValue: currentData ? currentData.name : ""
                            })(<Input />)}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Thời gian">
                                    {getFieldDecorator('date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? (moment(currentData.date).isValid() ? moment(currentData.date) : null) : null
                                    })(
                                        <DatePicker format="DD/MM" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Quốc gia">
                                    {getFieldDecorator('country', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.country : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ý nghĩa">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.description) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Các hoạt động">
                                    {getFieldDecorator('activities', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? _newLine(currentData.activities) : ''
                                    })(
                                        <Input.TextArea rows={3} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Quotes">
                                    {getFieldDecorator('quotes', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? _newLine(currentData.quotes) : ''
                                    })(
                                        <Input.TextArea rows={3} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ảnh mẫu">
                            {getFieldDecorator("image", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={10}
                                    uploadText="Chọn ảnh"
                                    onChangeData={this.onChangeImage}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createHoliday: (data) => dispatch(createHoliday(data)),
        updateHoliday: (id, data) => dispatch(updateHoliday(id, data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'HolidayForm' })(HolidayForm));

module.exports = {
    "admin.user.list": "Xem danh sách user",
    "admin.user.create": "Thêm mới user",
    "admin.user.detail": "Xem chi tiết user",
    "admin.user.update": "Cập nhật thông tin user",
    "admin.user.remove": "Xoá user",

    "admin.permissions.list": "Xem danh sách quyền",
    "admin.permissions.update": "Phân quyền",
    "admin.permissions.get_by_role": "Lấy danh sách quyền theo vai trò",

    "admin.config.update": "Cấu hình email hệ thống",

    "admin.niche.list": "Xem danh sách niche",
    "admin.niche.create": "Tạo mới niche",
    "admin.niche.detail": "Xem chi tiết niche",
    "admin.niche.update": "Cập nhật niche",
    "admin.niche.remove": "Xóa niche",

    "admin.product_type.list": "Xem danh sách loại sản phẩm",
    "admin.product_type.create": "Tạo mới loại sản phẩm",
    "admin.product_type.detail": "Xem chi tiết loại sản phẩm",
    "admin.product_type.update": "Cập nhật loại sản phẩm",
    "admin.product_type.remove": "Xóa loại sản phẩm",

    "admin.design.list": "Xem danh sách design",
    "admin.design.create": "Thêm mới design",
    "admin.design.detail": "Xem chi tiết design",
    "admin.design.update": "Cập nhật design",
    "admin.design.remove": "Xóa design",
    "admin.design.approved": "Duyệt design",

    "admin.orders.list": "Danh sách đơn hàng",
    "admin.orders.detail": "Chi tiết đơn hàng",
    "admin.orders.update": "Cập nhật chi phí đơn hàng",
    "admin.orders.sync_order": "Đồng bộ đơn hàng",
    "admin.orders.view_on_website": "Xem đơn hàng trên web",
    "admin.orders.import_tracking": "Import tracking",
    "admin.orders.export": "Export excel",
    "admin.orders.send_request_design": "Gửi yêu cầu cho thiết kế",
    "admin.orders.sync_cost_lenful": "Sync cost Lenful",
    "admin.orders.change_fulfi_user": "Thay đổi nhân viên Fulfill",

    "admin.holiday.list": "Danh sách các ngày lễ",
    "admin.holiday.create": "Tạo mới",
    "admin.holiday.detail": "Chi tiết",
    "admin.holiday.update": "Cập nhật",
    "admin.holiday.remove": "Xóa",

    "admin.trending.list": "Danh sách trending",
    "admin.trending.create": "Tạo mới",
    "admin.trending.detail": "Chi tiết",
    "admin.trending.update": "Cập nhật",
    "admin.trending.remove": "Xóa",

    "admin.telegram.list": "Xem danh sách",
    "admin.telegram.create": "Tạo mới",
    "admin.telegram.detail": "Chi tiết",
    "admin.telegram.update": "Cập nhật",
    "admin.telegram.remove": "Xóa",

    "admin.store.list": "Danh sách store",
    "admin.store.create": "Tạo mới",
    "admin.store.detail": "Chi tiết",
    "admin.store.update": "Cập nhật",
    "admin.store.remove": "Xóa",

    "admin.products.list": "Danh sách sản phẩm",
    "admin.products.create": "Tạo mới",
    "admin.products.remove": "Xóa",
    "admin.products.detail": "Chi tiết",
    "admin.products.update": "Cập nhật",

    "admin.product_categories.list": "Danh mục sản phẩm",

    "admin.product_tags.list": "Danh sách",
    "admin.product_tags.create": "Thêm mới",
    "admin.product_tags.remove": "Xóa",

    "admin.revenue_expenditure.list": "Xem các khoản thu, chi",
    "admin.revenue_expenditure.create": "Thêm mới",
    "admin.revenue_expenditure.detail": "Xem chi tiết",
    "admin.revenue_expenditure.update": "Cập nhật",
    "admin.revenue_expenditure.remove": "Xóa",

    "admin.workflow_management.list": "Xem danh sách công việc",
    "admin.workflow_management.create": "Thêm mới công việc",
    "admin.workflow_management.detail": "Xem chi tiết công việc",
    "admin.workflow_management.update": "Cập nhật công việc",
    "admin.workflow_management.remove": "Xóa công việc",
    "admin.workflow_management.approved": "Duyệt công việc",
    "admin.workflow_management.update_process": "Cập nhật tiến độ công việc",
    "admin.workflow_management.update_status": "Cập nhật tình trạng công việc",

    "admin.training.list": "Xem danh sách",
    "admin.training.create": "Thêm mới",
    "admin.training.detail": "Xem chi tiết",
    "admin.training.update": "Cập nhật",
    "admin.training.remove": "Xóa",

    "admin.guide.list": "Xem danh sách",
    "admin.guide.create": "Thêm mới",
    "admin.guide.detail": "Xem chi tiết",
    "admin.guide.update": "Cập nhật",
    "admin.guide.remove": "Xóa",

    "admin.products_import.list_queue": "Xem và import sản phẩm",
    "admin.products_import.remove_queue": "Xóa sản phẩm khỏi hàng đợi",

    "admin.products_description.list": "Xem danh sách mô tả sản phẩm",
    "admin.products_description.create": "Tạo mới",
    "admin.products_description.update": "Cập nhật",
    "admin.products_description.remove": "Xóa",

    "admin.work_report.list": "Xem danh sách",
    "admin.work_report.create": "Tạo mới",
    "admin.work_report.update": "Cập nhật",
    "admin.work_report.detail": "Xem chi tiết",
    "admin.work_report.remove": "Xóa",
    "admin.work_report.comment": "Nhận xét",

    "admin.file_png.list": "Danh sách file",
    "admin.file_png.create": "Thêm mới file",
    "admin.file_png.update": "Cập nhật file",
    "admin.file_png.detail": "Chi tiết file",
    "admin.file_png.remove": "Xóa file",

    "admin.tool_license.list": "Danh sách license",
    "admin.tool_license.update": "Cập nhật license",
    "admin.tool_license.remove": "Xóa license",

    "admin.google_indexing.list_link": "Danh sách links",
    "admin.google_indexing.import_link": "Import links",
    "admin.google_indexing.remove_link": "Xóa links",
    "admin.google_indexing.post_indexing": "Index links",

    "admin.printing_unit.list": "Xem danh sách",
    "admin.printing_unit.create": "Thêm mới",
    "admin.printing_unit.detail": "Xem chi tiết",
    "admin.printing_unit.update": "Cập nhật",
    "admin.printing_unit.remove": "Xóa",

    "admin.payment_gateways.list": "Xem danh sách",
    "admin.payment_gateways.create": "Thêm mới",
    "admin.payment_gateways.detail": "Xem chi tiết",
    "admin.payment_gateways.update": "Cập nhật",
    "admin.payment_gateways.remove": "Xóa",

    "admin.blacklist.list": "Xem danh sách",
    "admin.blacklist.create": "Thêm mới",
    "admin.blacklist.detail": "Xem chi tiết",
    "admin.blacklist.update": "Cập nhật",
    "admin.blacklist.remove": "Xóa",

    "admin.chatgpt.list": "Xem danh sách",
    "admin.chatgpt.create": "Thêm mới",
    "admin.chatgpt.detail": "Xem chi tiết",
    "admin.chatgpt.update": "Cập nhật",
    "admin.chatgpt.remove": "Xóa",

    "admin.log_mail_tracking.list": "Xem danh sách",
    "admin.log_mail_tracking.remove": "Xóa",

    "admin.log_order_shield.list": "Xem danh sách",

    "admin.idea.list": "Xem danh sách",
    "admin.idea.create": "Tạo mới",
    "admin.idea.update": "Cập nhật",
    "admin.idea.remove": "Xóa",
    "admin.idea.send_request_design": "Gửi yêu cầu thiết kế",

    "admin.posts.list": "Xem danh sách",
    "admin.posts.create": "Tạo mới",
    "admin.posts.update": "Cập nhật",
    "admin.posts.remove": "Xóa",

    "admin.project.list": "Xem danh sách",
    "admin.project.create": "Tạo mới",
    "admin.project.update": "Cập nhật",
    "admin.project.clone": "Nhân bản",
    "admin.project.remove": "Xóa",

    "admin.project_based_job.list": "Xem danh sách",
    "admin.project_based_job.create": "Tạo mới",
    "admin.project_based_job.update": "Cập nhật",
    "admin.project_based_job.detail": "Xem chi tiết",
    "admin.project_based_job.update_result": "Cập nhật kết quả",
    "admin.project_based_job.update_status": "Cập nhật tình trạng",
    "admin.project_based_job.assign": "Giao việc cho nhân viên",
    "admin.project_based_job.remove": "Xóa",

    "admin.product_cat_des.list": "Xem danh sách",
    "admin.product_cat_des.create": "Tạo mới",
    "admin.product_cat_des.update": "Cập nhật",
    "admin.product_cat_des.publish_tosite": "Publish",
    "admin.product_cat_des.remove": "Xóa",

    "admin.trademark_category.list": "Xem danh sách",
    "admin.trademark_category.create": "Tạo mới",
    "admin.trademark_category.update": "Cập nhật",
    "admin.trademark_category.remove": "Xóa",

    "admin.handle_s3_objects.delete": "Xóa file S3",


    "admin.trademark_keyword.update": "Cập nhật keyword",
    "admin.trademark_keyword.list": "Xem danh sách keyword",

    // card title
    "admin.role": "Quản lý vai trò",
    "admin.user": "User",
    "admin.config": "Cài đặt chung",
    "admin.niche": "Quản lý Niche",
    "admin.product_type": "Quản lý loại sản phẩm",
    "admin.design": "Quản lý Design",
    "admin.orders": "Quản lý đơn hàng",
    "admin.holiday": "Quản lý các ngày lễ",
    "admin.trending": "Quản lý trending",
    "admin.store": "Cấu hình Store",
    "admin.products": "Quản lý sản phẩm",
    "admin.product_categories": "Quản lý danh mục sản phẩm",
    "admin.product_tags": "Quản lý tags sản phẩm",
    "admin.revenue_expenditure": "Cân đối tài chính",
    "admin.workflow_management": "Quản lý công việc",
    "admin.training": "Trainings",
    "admin.guide": "Guide",
    "admin.telegram": "Cài đặt telegram",
    "admin.products_import": "Import sản phẩm",
    "admin.products_description": "Mô tả sản phẩm",
    "admin.work_report": "Báo cáo công việc",
    "admin.file_png": "Quản lý file PNG",
    "admin.tool_license": "Quản lý license tool",
    "admin.google_indexing": "Google indexing",
    "admin.printing_unit": "Quản lý đơn vị in",
    "admin.payment_gateways": "Cài đặt cổng thanh toán",
    "admin.blacklist": "Quản lý blacklist",
    "admin.chatgpt": "Cài đặt api ChatGPT",
    "admin.log_mail_tracking": "Log gửi mail tracking",
    "admin.log_order_shield": "Log order shield",
    "admin.idea": "Quản lý Idea",
    "admin.posts": "Quản lý bài viết",
    "admin.project": "Danh sách dự án",
    "admin.project_based_job": "Công việc của dự án",
    "admin.product_cat_des": "Mô tả danh mục sản phẩm",
    "admin.trademark_category": "Trademark category",
    "admin.handle_s3_objects": "Quản lý file S3",
    "admin.trademark_keyword": "Trademark keyword",
};

import api from '../../utils/api';
import {
    GET_LIST_ALL,
    GET_ONE_IDEA,
    CREATE_IDEA,
    UPDATE_IDEA,
    REMOVE_IDEA,
    UPLOAD_IDEA_FILE,
    GET_LIST_APPROVED,
    UPDATE_IDEA_LIMIT_FIELD,
    GET_ALL_IDEA_SIMILAR,
    GET_LIST_OVERVIEW
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAll = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/idea', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_ALL,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getListOverview = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/idea/overview', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_OVERVIEW,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getListSimilar = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/idea/search-similar', { params: filter }).then(res => {
            dispatch({
                type: GET_ALL_IDEA_SIMILAR,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneIdea = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/idea/${id}`).then(res => {
            dispatch({
                type: GET_ONE_IDEA,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createIdea = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/idea', data).then(res => {
            dispatch({
                type: CREATE_IDEA,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateIdea = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/idea/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_IDEA,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateIdeaFieldLimit = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/idea/update/limit-field/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_IDEA_LIMIT_FIELD,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const approvedIdea = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/idea/approved/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_IDEA,
                payload: res.data.data
            });
            //NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeIdea = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/idea', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_IDEA,
                payload: ids
            });
            //console.log(res)
            if (res.data.data == 0) {
                NotificationManager.error(res.data.msg);
            } else {
                NotificationManager.success(res.data.msg);
            }
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const uploadFile = (data, header) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/idea/upload-file', data, header).then((res) => {
            dispatch({
                type: UPLOAD_IDEA_FILE,
                payload: res,
            });
            resolve(res);
        }).catch(err => {
            // NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
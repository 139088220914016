import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { NotificationManager } from 'react-notifications';
import { vapidKey, firebaseConfig } from '../config';

class FirebaseService {
    constructor() {
        this.firebaseApp = initializeApp(firebaseConfig);
        this.messaging = getMessaging(this.firebaseApp);
        this.setupTokenFetching();
    }

    setupTokenFetching() {
        this.fetchToken = (setCurrentToken) => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    this.getTokenForNotifications(setCurrentToken);
                } else if (permission === 'denied') {
                    // Xử lý khi quyền bị từ chối
                } else {
                    // Xử lý khi quyền bị bỏ qua
                }
            });
        };
    }

    getTokenForNotifications(setCurrentToken) {
        getToken(this.messaging, { vapidKey })
            .then((currentToken) => {
                if (currentToken) {
                    setCurrentToken(currentToken);
                } else {
                    // Xử lý khi không có token
                }
            })
            .catch((err) => {
                // Xử lý khi có lỗi
            });
    }

    onMessageListener(callback) {
        onMessage(this.messaging, (payload) => {
            console.log("onMessageListener: ", payload);
            NotificationManager.warning(payload.notification.body, payload.notification.title, 15000);
            // Gọi lại hàm callback nếu được xác định
            if (typeof callback === 'function') {
                callback();
            }
        });
    }
}

const firebaseService = new FirebaseService();
export default firebaseService;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Row, Col } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { _newLine } from '../../utils/helpers';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
// actions
import { createNiche, updateNiche } from '../../redux/actions/NicheActions';

class NicheForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            image: []
        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.state.image.length) {
                    //const [image] = this.state.image;
                    values.image = this.state.image;
                }
                if (this.props.niche) {
                    await this.props.updateNiche(this.props.niche.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    await this.props.createNiche(values);
                    this.handCancel()
                    this.props.reloadData()
                }
            }
        });
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Image complete.");
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false, image: [], isResetUpload: true })
    }

    render() {
        const { isloading, isResetUpload } = this.state;
        var { visible, niche } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title={niche ? "Sửa niche" : "Thêm mới niche"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={[
                    <Button key="back" onClick={() => this.handCancel()}>
                        Huỷ
                    </Button>,
                    <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {niche ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tên">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: niche ? niche.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Copyright">
                                    {getFieldDecorator('copyright', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: niche ? niche.copyright : 'N/A'
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Trademark", value: 'trademark' },
                                                { label: "Patent", value: 'Patent' },
                                                { label: "N/A", value: 'N/A' }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: niche ? niche.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Kích hoạt", value: 1 },
                                                { label: "Chưa kích hoạt", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Mô tả">
                            {getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: niche ? _newLine(niche.description) : ''
                            })(
                                <Input.TextArea rows={4} />
                            )}
                        </Form.Item>
                        <Form.Item label="Ảnh mẫu">
                            {getFieldDecorator("image", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={10}
                                    uploadText="Chọn ảnh"
                                    onChangeData={this.onChangeImage}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNiche: (data) => dispatch(createNiche(data)),
        updateNiche: (id, data) => dispatch(updateNiche(id, data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'NicheForm' })(NicheForm));

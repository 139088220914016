import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Button, Input, Checkbox, Card, Row } from 'antd';
// actions
import { login } from '../../redux/actions/AuthActions';
import { getConfig } from '../../redux/actions/ConfigActions';
import { getStoreActive } from '../../redux/actions/StoreActions';

import { addDeviceToken } from '../../redux/actions/NotificationsActions';
import firebaseService from '../../utils/FirebaseService';

import logo from '../../assets/img/logo.png';

class Login extends Component {
    onFinish = async (values) => {
        //console.log('Success:', values);
        await this.props.login(values);
        await this.props.getConfig();
        await this.props.getStoreActive();

        // Firebase lấy token
        firebaseService.fetchToken(this.setCurrentTokenCallback);
    };

    setCurrentTokenCallback = (token) => {
        console.log('Current token:', token);
        this.props.addDeviceToken({ device_token: token });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        var { authUser } = this.props;

        if (authUser) return <Redirect to='/' />;

        return (
            <Row justify='center'>
                {/* <Col lg={6} md={24}> */}
                <Card className="login-card">
                    <div className="text-center">
                        <img src={logo} className="login-logo" alt="Logo" />
                    </div>
                    {/* <div style={{color: 'red'}}>
                        Thời hạn sử dụng tool của bạn đã hết. Vui lòng liên hệ Admin để gia hạn tool<br /> Trân trọng!
                    </div> */}
                    <Form
                        className="mt-4"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền username!',
                                },
                            ]}
                        >
                            <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                            name="user_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền mật khẩu!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Mật khẩu" />
                        </Form.Item>

                        <Row justify="space-between">
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Nhớ mật khẩu</Checkbox>
                            </Form.Item>
                            <Link to='/reset-password'>Quên mật khẩu?</Link>
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="w-100">
                                Đăng nhập
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                {/* </Col> */}
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (data) => dispatch(login(data)),
        getConfig: () => dispatch(getConfig()),
        getStoreActive: (filter) => dispatch(getStoreActive(filter)),
        addDeviceToken: (data) => dispatch(addDeviceToken(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
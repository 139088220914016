import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, EyeOutlined, ReadOutlined } from '@ant-design/icons';
import { Table, Modal, Divider, Switch, Drawer, TreeSelect, Tag, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import ProductsDescriptionForm from './ProductsDescriptionForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, makeTree, YouTubeGetID, isValidDate } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';

// actions
import {
    getAllProductsDescription,
    getProductsDescription,
    updateProductsDescription,
    removeProductsDescription
} from '../../redux/actions/ProductsDescriptionActions';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getStoreByField } from '../../redux/actions/StoreActions';
import ProductsDescriptionContentList from '../products-description-content/ProductsDescriptionContentList';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/products-description',
        breadcrumbName: 'Danh sách mô tả sản phẩm',
    },
]

class ProductsDescriptionList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null,
        openView: false,
        isOpenContent: false
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductsDescription(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStoreByField({ platform: ['pod', 'shield'] })
        this.props.getAllCategories();
        this.props.getProductTypeByActive();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.props.getAllCategories({ store_id: this.props.items.store_id });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProductsDescription(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/products-description',
            search: qs.stringify(query)
        });
    }

    onChangeStore = (store_id) => {
        this.onChangeFilter('store_id', store_id);
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/products-description',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id, store_id) {
        this.props.getProductsDescription(id).then(res => {
            this.toggleOpenForm(true, res);
        })
        this.props.getAllCategories({ store_id: store_id });
    }

    onView(id) {
        this.props.getProductsDescription(id).then(res => {
            this.setState({ openView: true, currentData: res });
        })
    }

    onRemove() {
        this.props.removeProductsDescription(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.reloadData()
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductsDescription(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    openContent(visible) {
        this.setState({ isOpenContent: visible })
    }

    render() {
        var { items, pagination, stores, categories, producttype } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, openView, currentData, isOpenContent } = this.state;
        const catTree = makeTree(categories || [], 0);
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: 'Actions',
                align: 'center',
                width: '80px',
                render: (text, record) => (
                    <div>
                        <EyeOutlined className='primary-color item-action-btn' onClick={() => this.onView(record.id)} />
                        {
                            checkPermission('products_description', 'update') ? (
                                <>
                                    <Divider type='vertical' />
                                    <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id, record.store_id)} />
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                key: 'content',
                width: '200px',
                render: (text, record) => {
                    if (record.type == 'image') {
                        return (
                            <div className='table-image' style={{ wordBreak: "break-all" }}>
                                {ReactHtmlParser(text)}
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ wordBreak: "break-all" }}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Store',
                dataIndex: 'store_name',
                key: 'store_name',
                sorter: true,
            },
            {
                title: 'Loại sản phẩm',
                width: '130px',
                dataIndex: 'product_type_name',
                key: 'product_type_name',
                sorter: true,
            },
            {
                title: 'Danh mục',
                width: '150px',
                dataIndex: 'category_name',
                key: 'category_name',
                sorter: true,
            },
            {
                title: 'Loại nội dung',
                width: '150px',
                dataIndex: 'type',
                key: 'type',
                align: 'center',
                sorter: true,
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                sorter: true,
                render: (text, record) => (
                    <div>
                        <Switch
                            defaultChecked={record.status}
                            checkedChildren="Active"
                            unCheckedChildren="InActive"
                            onChange={(value) => this.props.updateProductsDescription(record.id, { status: value, action: 'status' })}
                        />
                    </div>
                )
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>{isValidDate(record.created_at)}</>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{record.created_user} </div>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách mô tả sản phẩm" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('products_description', 'create')}
                        isShowDeleteButton={checkPermission('products_description', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[
                            <TreeSelect
                                style={{ width: '200px' }}
                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                treeData={catTree}
                                allowClear
                                showSearch
                                treeNodeFilterProp='title'
                                placeholder="Danh mục"
                                treeDefaultExpandAll
                                defaultValue={parseInt(query.category) || null}
                                onChange={(value) => this.onChangeFilter("category", value)}
                            />,
                            <BaseSelect
                                options={producttype}
                                optionValue="id"
                                optionLabel="name"
                                placeholder="loại sản phẩm"
                                showSearch={false}
                                style={{ width: '150px' }}
                                defaultValue={parseInt(query.product_type_id) || null}
                                onChange={(value) => this.onChangeFilter("product_type_id", value)}
                            />,
                            <BaseSelect
                                style={{ width: '200px' }}
                                options={[
                                    { label: "Mô tả niche", value: 'niche_des' },
                                    { label: "Mô tả sản phẩm", value: 'product_des' },
                                    { label: "Content trust site", value: 'trust_site' },
                                    { label: "Video", value: 'video' },
                                    { label: "Hình ảnh", value: 'image' },
                                ]}
                                onChange={(value) => this.onChangeFilter("type", value)}
                                defaultValue={query.type || null}
                                placeholder="Loại nội dung"
                            />,
                            <BaseSelect
                                options={[
                                    { value: 1, label: 'Active' },
                                    { value: 0, label: 'InActive' }
                                ]}
                                onChange={(value) => this.onChangeFilter("status", value)}
                                placeholder="Trạng thái"
                                defaultValue={parseInt(query.status) || null}
                                style={{ width: '200px' }}
                            />
                        ]}
                    >
                        <BaseSelect
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            defaultValue={parseInt(query.store_id) || null}
                            optionLabel="name"
                            showSearch
                            placeholder="Chọn store"
                            style={{ width: '200px' }}
                            className="table-button"
                        />
                        <Divider type="vertical" />
                        <Button className="table-button" type='default' icon={<ReadOutlined />} onClick={() => this.openContent(true)}>Nội dung mặc định</Button>
                        <Divider type="vertical" />
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={items?.data || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductsDescriptionForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                    currentStore={this.onChangeStore}
                />

                <ProductsDescriptionContentList
                    visible={isOpenContent}
                    onCancel={() => this.openContent(false)}
                />

                <Drawer
                    visible={openView}
                    title="CHI TIẾT"
                    onClose={() => this.setState({ openView: false })}
                    maskClosable={true}
                    width={isMobile ? '100%' : '60%'}
                >
                    {
                        openView ?
                            <div className='p-description-detail'>
                                {
                                    currentData ? (
                                        <>
                                            {
                                                currentData.type == 'video' ? (
                                                    <iframe width='100%' height='450' class="ql-video" frameborder="0" allowfullscreen="true" src={`https://www.youtube.com/embed/${YouTubeGetID(currentData?.content)}?showinfo=0`}></iframe>
                                                ) : (
                                                    <>{ReactHtmlParser(currentData?.content)}</>
                                                )
                                            }
                                        </>
                                    ) : null
                                }
                            </div>
                            : null
                    }
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: state.ProductsDescriptionReducer.items,
        pagination: state.ProductsDescriptionReducer.pagination,
        stores: state.StoreReducer.store_by_field_list,
        categories: state.ProductsCategories.categories,
        producttype: state.ProductType.producttype
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProductsDescription: (filter) => dispatch(getAllProductsDescription(filter)),
        getProductsDescription: (id) => dispatch(getProductsDescription(id)),
        removeProductsDescription: (ids) => dispatch(removeProductsDescription(ids)),
        updateProductsDescription: (id, data) => dispatch(updateProductsDescription(id, data)),
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDescriptionList);

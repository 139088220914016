import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Table, Modal, DatePicker, Tooltip, Divider, Avatar } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import WorkReportForm from './WorkReportForm';
import Detail from './Detail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml, replaceHeadingsWithDivs, getFirstLetterOfWords } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
// actions
import { getAllData, getData, removeData } from '../../redux/actions/WorkReportActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/work-report',
        breadcrumbName: 'Báo cáo công việc',
    },
]

class WorkReportList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
        isOpenDetail: false,
        current_id: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllData(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/work-report',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/work-report',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getData(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getData(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    onRemove() {
        this.props.removeData(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { data_list, pagination, users, authUser } = this.props;
        var {
            isLoading,
            selectedRowKeys,
            isOpenForm,
            currentData,
            isOpenDetail,
            current_id
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div className='primary-color'>
                        {
                            checkPermission('work_report', 'update') ? (
                                <Tooltip title="Cập nhật">
                                    <EditOutlined onClick={() => this.onEdit(record.id)} />
                                </Tooltip>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Người báo cáo',
                dataIndex: 'user',
                key: 'user',
                width: '200px',
                render: (text, record) => {
                    if (checkPermission('work_report', 'detail')) {
                        return (
                            <Tooltip title="Xem chi tiết" placement="right">
                                <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                    <Avatar
                                        style={{
                                            backgroundColor: '#31cdf5',
                                            verticalAlign: 'middle'
                                        }}
                                        size="small">
                                        {record.user ? getFirstLetterOfWords(record.user) : ''}
                                    </Avatar>
                                    &nbsp;{text}
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <>{text}</>
                        )
                    }
                }
            },
            {
                title: 'Nội dung báo cáo',
                dataIndex: 'content',
                key: 'content',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </div>
                    )
                }
            },
            {
                title: 'Ngày báo cáo',
                dataIndex: 'report_date',
                key: 'report_date',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    return (<div>{isValidDate(text, false)}</div>)
                }
            },
            {
                title: 'Nhận xét',
                dataIndex: 'comment',
                key: 'comment',
                align: 'center',
                width: '200px',
                render: (text, record) => {
                    return (
                        <Tooltip placement="topLeft" title={ReactHtmlParser(text)}>
                            <div style={{ wordBreak: "break-all" }}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                render: (text, record) => {
                    return (
                        <Tooltip title={ReactHtmlParser(text)}>
                            <div style={{ wordBreak: "break-all" }}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        </Tooltip>
                    )
                }
            }
        ];
        var filters = [
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />,
            <BaseSelect
                options={[
                    { value: 'daily', label: 'Hàng ngày' }
                ]}
                onChange={(value) => this.onChangeFilter("type", value)}
                placeholder="Loại công việc"
                defaultValue={query.type ? query.type : null}
                style={{ width: '150px' }}
            />,
            <BaseSelect
                options={[
                    { value: 'done', label: 'Đã hoàn thành' },
                    { value: 'unfinished', label: 'Chưa hoàn thành' }
                ]}
                onChange={(value) => this.onChangeFilter("result", value)}
                placeholder="Kết quả"
                defaultValue={query.result ? query.result : null}
                style={{ width: '150px' }}
            />
        ];

        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người báo cáo"
                    showSearch={false}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />
            );
        }

        return (
            <div>
                <PageTitle routes={routes} title="Báo cáo công việc" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('work_report', 'create')}
                        isShowDeleteButton={checkPermission('work_report', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.type}
                        filters={filters}
                    >
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={data_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <WorkReportForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <Detail
                    visible={isOpenDetail}
                    currentData={currentData}
                    reloadList={() => this.reloadData()}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log("state: ",state.WorkReportReducer.data_list)
    return {
        data_list: state.WorkReportReducer.data_list,
        pagination: state.WorkReportReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllData: (filter) => dispatch(getAllData(filter)),
        getData: (id) => dispatch(getData(id)),
        removeData: (ids) => dispatch(removeData(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkReportList);

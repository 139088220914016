import api from '../../utils/api';
import {
    GET_LIST_USER_COLUMN_PREFERENCES,
    CREATE_NEW_USER_COLUMN_PREFERENCES
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListColumns = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/user-column-preferences', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_USER_COLUMN_PREFERENCES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createColumns = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/user-column-preferences', data).then(res => {
            dispatch({ type: CREATE_NEW_USER_COLUMN_PREFERENCES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
import api from '../../utils/api';
import {
    GET_ALL_WORK_REPORT,
    GET_SPECIFIC_WORK_REPORT,
    UPDATE_SPECIFIC_WORK_REPORT,
    CREATE_NEW_WORK_REPORT,
    REMOVE_WORK_REPORT,
    UPDATE_PROCESS_WORK_REPORT,
    UPDATE_STATUS_WORK_REPORT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllData = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/work-report', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_WORK_REPORT, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/work-report', data).then(res => {
            dispatch({ type: CREATE_NEW_WORK_REPORT, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getData = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/work-report/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_WORK_REPORT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateData = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/work-report/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_WORK_REPORT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const commentVork = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/work-report/comment/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_WORK_REPORT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeData = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/work-report`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_WORK_REPORT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

import api from '../../utils/api';
import {
    DELETE_OBJECT_S3
} from './types';
import { NotificationManager } from 'react-notifications'


export const deleteObject = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/handle-s3-objects', data).then(res => {
            dispatch({ type: DELETE_OBJECT_S3, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
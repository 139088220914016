import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, Radio, Row, Col } from 'antd'
import { isMobile } from 'react-device-detect';
// actions
import { approvedData } from '../../redux/actions/WorkflowManagementActions';

class ManyApprovedForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false
        };
    }
    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.ids.length) {
                    values.ids = this.props.ids;
                    await this.props.approvedData(values);
                    this.props.form.resetFields();
                    this.props.onCancel();
                    this.props.reloadData()
                }
                this.setState({ isloading: false })
            }
        });
    }

    handleCancel() {
        this.setState({ isloading: false })
        this.props.form.resetFields();
        this.props.onCancel()
    }

    render() {
        const { isloading } = this.state;
        var { visible } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log(this.props.ids)

        return (
            <Modal
                visible={visible}
                title="Duyệt công việc"
                onOk={(e) => this.submit(e)}
                onCancel={() => this.handleCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                footer={[
                    <Button key="back" onClick={() => this.handleCancel()}>
                        Huỷ
                    </Button>,
                    <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        Duyệt
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col xs={24} md={10}>
                                <Form.Item label="Option">
                                    {getFieldDecorator('approved_status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: 1
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>Duyệt</Radio>
                                            <Radio value={0}>Chưa duyệt</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={14}>
                                <Form.Item label="Đánh giá">
                                    {getFieldDecorator('vote', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: 'good'
                                    })(
                                        <Radio.Group>
                                            <Radio value='good'>Tốt</Radio>
                                            <Radio value='normal'>Đạt yêu cầu</Radio>
                                            <Radio value='not_reached'>Chưa đạt yêu cầu</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Diễn giải" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('approved_des', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: null
                            })(
                                <Input.TextArea rows={2} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        approvedData: (data) => dispatch(approvedData(data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'ManyApprovedForm' })(ManyApprovedForm));

import { NotificationManager } from 'react-notifications';
import {
    GET_TRADEMARK_KEYWORD,
    UPDATE_TRADEMARK_KEYWORD
} from './types';
import api from '../../utils/api';

export const getTrademarkKeyword = () => dispatch => {
    return api.get('/trademark-keyword/get').then(res => {
        dispatch({ type: GET_TRADEMARK_KEYWORD, payload: res.data.data })
    }).catch(err => {
        console.log(err);
    })
}

export const updateTrademarkKeyword = (data) => dispatch => {
    return api.post('/trademark-keyword/update', data).then(res => {
        dispatch({ type: UPDATE_TRADEMARK_KEYWORD });
        NotificationManager.success("Cập nhật thành công");
    }).catch(err => {
        console.log(err);
    })
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import { Spin, Button, Modal, Divider, Comment, List, Input, Card } from 'antd';
import { Form } from "@ant-design/compatible";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
// actions
import { getTraining } from '../../redux/actions/TrainingActions';
import { createComment, removeComment } from '../../redux/actions/CommentsAction';

const { confirm } = Modal;
const { TextArea } = Input;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/training',
        breadcrumbName: 'Training',
    },
    {
        path: '#',
        breadcrumbName: 'Chi tiết',
    },
]

class TrainingDetail extends Component {
    state = {
        isLoading: true,
        comments: []
    }

    componentDidMount() {
        this.props.getTraining(this.props.match.params.id).then(() => {
            this.setState({ isLoading: false })
        })
    }

    reloadData() {
        this.props.getTraining(this.props.match.params.id).then(() => {
            this.setState({ isLoading: false, submitting: false })
        })
        this.props.form.resetFields();
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                const { currentData } = this.props;
                await this.props.createComment({ type: 'training', content: values.content, parent_id: currentData.id }).then(res => {
                    this.reloadData();
                })
            }
        });
    }
    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData();
            }),
            onCancel() { },
        })
    }

    render() {
        var { currentData, authUser } = this.props;
        var { isLoading, submitting } = this.state;
        const { getFieldDecorator } = this.props.form;

        if (isLoading || !currentData) {
            return (
                <div className="text-center" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <Spin size="large" />
                </div>
            )
        }

        const comments = [];
        if (currentData) {
            currentData.comments.map(item => {
                var disabledDeleteComment = true;
                if (authUser.role_code == 'ADMIN') {
                    disabledDeleteComment = false
                } else if (authUser.id == item.sender) {
                    disabledDeleteComment = false
                }
                comments.push(
                    {
                        actions: [<Button disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <UserOutlined style={{ fontSize: '20px' }} className='primary-color' />,
                        content: <p>{ReactHtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        return (
            <div>
                <PageTitle routes={routes} title={currentData?.title} />
                {
                    currentData ? (
                        <Card
                            title={currentData?.title}
                            className='mt-2'>
                            <div className="view ql-editor training-img">
                                {ReactHtmlParser(currentData?.content)}
                            </div>
                            <Divider />
                            <h6>THẢO LUẬN</h6>
                            <br />
                            {comments.length > 0 && <CommentList comments={comments} />}
                            <Comment
                                avatar={<UserOutlined style={{ fontSize: '20px' }} className='primary-color' />}
                                content={
                                    <>
                                        <Form.Item>
                                            {getFieldDecorator('content', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: ""
                                            })(<TextArea rows={4} />)}
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" loading={submitting} onClick={(e) => this.submitComment(e)} type="primary">
                                                Đăng
                                            </Button>
                                        </Form.Item>
                                    </>
                                }
                            />
                        </Card>
                    ) : null
                }
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        currentData: state.Training.currentTraining,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getTraining: (id) => dispatch(getTraining(id)),
        createComment: (data) => dispatch(createComment(data)),
        removeComment: (ids) => dispatch(removeComment(ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "TrainingDetail" })(TrainingDetail));
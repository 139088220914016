import {
    GET_LIST_ALL_DESIGN,
    GET_ONE_DESIGN,
    CREATE_DESIGN,
    UPDATE_DESIGN,
    REMOVE_DESIGN,
    GET_LIST_DESIGN_APPROVED
} from '../actions/types';

const INIT_STATE = {
    design: [],
    design_approved: [],
    currentDesign: null,
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_ALL_DESIGN:
            return {
                ...state,
                design: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_LIST_DESIGN_APPROVED:
            return {
                ...state,
                design_approved: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_ONE_DESIGN:
            return {
                ...state,
                currentDesign: action.payload
            };
        default:
            return { ...state }
    }
};

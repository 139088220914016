import {
    GET_LIST_POSTS,
} from '../actions/types';

const INIT_STATE = {
    posts: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_POSTS:
            return {
                ...state,
                posts: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        default:
            return { ...state }
    }
};

import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { Modal, Image, Card, Row, Col, Pagination, Empty, Button } from 'antd';
import { DownloadOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, downloadFromLink } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications'
import FilePngAddForm from './FilePngAddForm'

// actions
import { getListAll, removeFilePng } from '../../redux/actions/FilePngAction';

const { Meta } = Card;
const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/file-png',
        breadcrumbName: 'Danh sách file png',
    }
]

class FilePngList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenAddForm: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAll(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onChangeTable(page, pageSize) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            page: page,
            per_page: pageSize,
        }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    toggleOpenAddForm(isOpenAddForm) {
        this.setState({
            isOpenAddForm: isOpenAddForm,
        });
    }

    onRemove(id) {
        if (checkPermission('file_png', 'remove')) {
            confirm({
                title: 'Cảnh báo',
                content: 'Bạn chắc chắn muốn xoá bản ghi này?',
                okText: 'OK',
                cancelText: 'Huỷ',
                onOk: () => this.props.removeFilePng([id]).then(() => {
                    setTimeout(() => {
                        Modal.destroyAll();
                    }, 800);
                    //this.reloadData()
                }),
                onCancel() { },
            })
        } else {
            NotificationManager.error("Bạn không được cấp quyền thực hiện thao tác này!");
        }
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    onDownload(url) {
        try {
            setTimeout(() => {
                const response = {
                    file: url,
                };
                downloadFromLink(response.file);
            }, 100);
        } catch (e) {
            NotificationManager.error("Error!");
        }
    }

    openInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    render() {
        var {
            isLoading,
            isOpenAddForm
        } = this.state;
        const { file_png, pagination } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách file png" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('file_png', 'create')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenAddForm(true)}
                        disabled={true}
                        onDelete={() => this.onRemove()}
                        showSearch={true}
                        showFilter={false}
                        activeFilter={query.status}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[]}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                </div>
                <Card>
                    {
                        file_png.length ? (
                            <Row gutter={20} style={{ height: 'max-content' }} key={1}>
                                <Image.PreviewGroup>
                                    {
                                        file_png.map(item => {
                                            return (
                                                <Col lg={4} md={8} sm={12} xs={24} key={item.id} className="mb-4" >
                                                    <Card
                                                        loading={isLoading}
                                                        hoverable
                                                        style={{ width: '100%', overflow: 'hidden' }}
                                                        size="small"
                                                        className='file-png-card'
                                                        bodyStyle={{ padding: 0 }}
                                                        actions={[
                                                            <Button type='link' size='small' onClick={() => this.openInNewTab(item.src)}>Tải xuống file in</Button>,
                                                            <DeleteOutlined key="Delete" onClick={() => this.onRemove(item.id)} />,
                                                        ]}
                                                    >
                                                        <Image
                                                            width="100%"
                                                            src={item.src_small}
                                                        />
                                                        <Meta className='mt-2 p-1' description={item.name} />
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Image.PreviewGroup>
                            </Row>
                        ) : (
                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center' }} key={1}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Row>
                        )
                    }
                    <div style={{ textAlign: 'right', }}>
                        <Pagination
                            pageSize={pagination.perPage}
                            defaultPageSize={pagination.perPage}
                            defaultCurrent={pagination.currentPage}
                            total={pagination.total}
                            pageSizeOptions={['10', '20', '30', '40', '50', '100']}
                            showSizeChanger={true}
                            showTotal={total => `Tổng ${total} bản ghi`}
                            onChange={(page, pageSize) => this.onChangeTable(page, pageSize)}
                        />
                    </div>
                </Card>

                <FilePngAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.toggleOpenAddForm(false)}
                    reloadData={() => this.reloadData()}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    //console.log(state.FilePngReducer)
    return {
        file_png: state.FilePngReducer.file_png,
        pagination: state.FilePngReducer.pagination,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAll: filter => dispatch(getListAll(filter)),
        removeFilePng: ids => dispatch(removeFilePng(ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FilePngList);

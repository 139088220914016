import api from '../../utils/api';
import {
    GET_LIST_PROJECT,
    GET_SPECIFIC_PROJECT,
    UPDATE_SPECIFIC_PROJECT,
    CREATE_NEW_PROJECT,
    REMOVE_PROJECT,
    GET_PROJECT_ACTIVE,
    CLONE_PROJECT
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListProject = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/project', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_PROJECT, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificProject = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/project/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_PROJECT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getProjectActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/project/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_PROJECT_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createProject = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/project', data).then(res => {
            dispatch({ type: CREATE_NEW_PROJECT, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateProject = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/project/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_PROJECT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeProject = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/project`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_PROJECT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const cloneProject = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/project/clone`, { ids: ids }).then(res => {
            dispatch({ type: CLONE_PROJECT, payload: ids });
            NotificationManager.success(res.data.msg);
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


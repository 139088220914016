import {
    GET_ALL_PRINTING_UNIT,
    GET_ALL_PRINTING_UNIT_BY_ACTIVE,
    GET_SPECIFIC_PRINTING_UNIT,
    UPDATE_SPECIFIC_PRINTING_UNIT,
    CREATE_NEW_PRINTING_UNIT,
    REMOVE_PRINTING_UNIT
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    printing_unit: [],
    currentPrintingUnit: null,
    printing_unit_active: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRINTING_UNIT: {
            return {
                ...state,
                printing_unit: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_ALL_PRINTING_UNIT_BY_ACTIVE: {
            return {
                ...state,
                printing_unit_active: action.payload,
            }
        }
        case GET_SPECIFIC_PRINTING_UNIT: {
            return { ...state, currentPrintingUnit: action.payload }
        }
        case CREATE_NEW_PRINTING_UNIT: {
            return { ...state, printing_unit: [action.payload, ...state.printing_unit], total: state.total + 1 }
        }
        case UPDATE_SPECIFIC_PRINTING_UNIT: {
            let index = state.printing_unit.findIndex((store) => {
                return store.id == action.payload.id;
            });

            let temp = [...state.printing_unit];
            temp[index] = action.payload;
            return { ...state, printing_unit: temp }
        }
        case REMOVE_PRINTING_UNIT: {
            let temp = state.printing_unit.filter(store => {
                return action.payload.indexOf(store.id) < 0;
            });
            return { ...state, printing_unit: temp }
        }
        default: return { ...state };
    }
}

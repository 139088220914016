import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, ExclamationCircleOutlined, ReloadOutlined, SyncOutlined, UpSquareOutlined } from '@ant-design/icons';
import { Table, Modal, Divider, Drawer, TreeSelect, Button, Row, Tooltip, Tag } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import ProductsCatDesForm from './ProductsCatDesForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, makeTree, isValidDate } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications'

// actions
import {
    getAllProductsCatDes,
    getProductsCatDes,
    updateProductsCatDes,
    removeProductsCatDes,
    publishToSite
} from '../../redux/actions/ProductCatDesActions';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getStoreByField } from '../../redux/actions/StoreActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/products-cat-des',
        breadcrumbName: 'Danh sách mô tả danh mục sản phẩm',
    },
]

class ProductsCatDesList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentData: null,
        openView: false,
        IsOpenViewKeyword: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductsCatDes(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStoreByField({ platform: ['pod'] })
        this.props.getAllCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.items !== this.props.items) {
            this.props.getAllCategories({ store_id: this.props.items.store_id });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProductsCatDes(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeStore = (store_id) => {
        this.onChangeFilter('store_id', store_id);
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id, store_id) {
        this.props.getProductsCatDes(id).then(res => {
            this.toggleOpenForm(true, res);
        })
        this.props.getAllCategories({ store_id: store_id });
    }

    onView(id) {
        this.props.getProductsCatDes(id).then(res => {
            this.setState({ openView: true, currentData: res });
        })
    }

    openViewKeyword(id) {
        this.props.getProductsCatDes(id).then(res => {
            this.setState({ IsOpenViewKeyword: true, currentData: res });
        })
    }

    onRemove() {
        this.props.removeProductsCatDes(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
            this.reloadData()
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductsCatDes(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onPublishToSite() {
        try {
            if (this.state.selectedRowKeys) {
                confirm({
                    title: 'Xác nhận',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Chắc chắn đăng lên site?',
                    okText: 'OK',
                    cancelText: 'Huỷ',
                    onOk: () => {
                        this.props.publishToSite({ description_ids: this.state.selectedRowKeys });
                        setTimeout(() => {
                            this.reloadData()
                        }, 800);
                        this.handleCancel()
                        NotificationManager.success("Nội dung đang được đăng lên site")
                    }
                })
            } else {
                NotificationManager.error("Không nhận được ids");
            }
        } catch (error) {
        }
    }

    handleCancel = () => {
        this.setState({
            selectedRowKeys: []
        })
    }

    render() {
        var { items, pagination, stores, categories } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, openView, currentData, IsOpenViewKeyword } = this.state;
        const catTree = makeTree(categories || [], 0);
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: '#',
                align: 'center',
                width: '35px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('product_cat_des', 'update') ? (
                                <>
                                    <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id, record.store_id)} />
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Nội dung',
                dataIndex: 'contents',
                key: 'contents',
                width: '200px',
                render: (text, record) => {
                    if (record.status == 1) {
                        return (
                            <Tooltip title="Xem chi tiết nội dung" placement='right'>
                                <div style={{ wordBreak: "break-all" }} className='primary-color item-action-btn' onClick={() => this.onView(record.id)}>
                                    <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                                </div>
                            </Tooltip>
                        )
                    } else if (record.status == 0) {
                        return (
                            <>
                                <Tag icon={<SyncOutlined spin />} color="green">Đang tạo nội dung</Tag>
                            </>
                        )
                    }
                }
            },
            {
                title: 'Từ khóa',
                dataIndex: 'keywords',
                key: 'keywords',
                width: '200px',
                render: (text, record) => {
                    return (
                        <Tooltip title="Xem tất cả từ khóa" placement='right'>
                            <div style={{ wordBreak: "break-all" }} className='primary-color item-action-btn' onClick={() => this.openViewKeyword(record.id)}>
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </div>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Store',
                dataIndex: 'store_name',
                key: 'store_name',
                sorter: true,
            },
            {
                title: 'Danh mục',
                width: '150px',
                dataIndex: 'category_name',
                key: 'category_name',
                sorter: true,
            },
            {
                title: 'Publish',
                dataIndex: 'publish_status',
                key: 'publish_status',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    if (text == 1) {
                        return (
                            <Tag color='green'>Đã đăng lên site</Tag>
                        )
                    }
                    if (text == 0) {
                        return (
                            <Tag color='#e38d13'>Chưa đăng lên site</Tag>
                        )
                    }
                }
            },
            {
                title: 'Start time',
                dataIndex: 'start_time',
                key: 'start_time',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    return (
                        <>{isValidDate(record.start_time)}</>
                    )
                }
            },
            {
                title: 'End time',
                dataIndex: 'end_time',
                key: 'end_time',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    return (
                        <>{isValidDate(record.end_time)}</>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{record.created_user} </div>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách mô tả danh mục sản phẩm" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('product_cat_des', 'create')}
                        isShowDeleteButton={checkPermission('product_cat_des', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={[
                            <TreeSelect
                                style={{ width: '250px' }}
                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                treeData={catTree}
                                allowClear
                                showSearch
                                treeNodeFilterProp='title'
                                placeholder="Danh mục"
                                treeDefaultExpandAll
                                defaultValue={parseInt(query.category) || null}
                                onChange={(value) => this.onChangeFilter("category", value)}
                            />,
                            <BaseSelect
                                options={[
                                    { value: 1, label: 'Đã đăng lên site' },
                                    { value: 0, label: 'Chưa đăng lên site' }
                                ]}
                                onChange={(value) => this.onChangeFilter("publish_status", value)}
                                placeholder="Publish"
                                defaultValue={parseInt(query.publish_status) || null}
                                style={{ width: '200px' }}
                            />
                        ]}
                    >
                        <BaseSelect
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            defaultValue={parseInt(query.store_id) || null}
                            optionLabel="name"
                            //additionalLabel="platform"
                            showSearch
                            placeholder="Chọn store"
                            style={{ width: '220px' }}
                            className="table-button"
                        />
                        {
                            checkPermission('product_cat_des', 'publish_tosite') ? (
                                <>
                                    <Divider type='vertical' />
                                    <Button icon={<UpSquareOutlined />} onClick={() => this.onPublishToSite()} disabled={!selectedRowKeys.length ? true : false} className="table-button" type="primary">Publish</Button>
                                </>
                            ) : null
                        }
                        <Divider type="vertical" />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={items?.data || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductsCatDesForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                    currentStore={this.onChangeStore}
                />

                <Drawer
                    visible={openView}
                    title="Nội dung"
                    onClose={() => this.setState({ openView: false })}
                    maskClosable={true}
                    width={isMobile ? '100%' : '50%'}
                >
                    {
                        openView ?
                            <div className='p-description-detail'>
                                {
                                    currentData ? (
                                        <>{ReactHtmlParser(currentData?.contents)}</>
                                    ) : null
                                }
                            </div>
                            : null
                    }
                </Drawer>
                <Drawer
                    visible={IsOpenViewKeyword}
                    title="Từ khóa"
                    onClose={() => this.setState({ IsOpenViewKeyword: false })}
                    maskClosable={true}
                    width={isMobile ? '100%' : '50%'}
                >
                    {
                        IsOpenViewKeyword ?
                            <div className='p-description-detail'>
                                {
                                    currentData ? (
                                        <>{ReactHtmlParser(currentData?.keywords)}</>
                                    ) : null
                                }
                            </div>
                            : null
                    }
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: state.ProductCatDesReducer.items,
        pagination: state.ProductCatDesReducer.pagination,
        stores: state.StoreReducer.store_by_field_list,
        categories: state.ProductsCategories.categories,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProductsCatDes: (filter) => dispatch(getAllProductsCatDes(filter)),
        getProductsCatDes: (id) => dispatch(getProductsCatDes(id)),
        removeProductsCatDes: (ids) => dispatch(removeProductsCatDes(ids)),
        updateProductsCatDes: (id, data) => dispatch(updateProductsCatDes(id, data)),
        publishToSite: (data) => dispatch(publishToSite(data)),
        getAllCategories: filter => dispatch(getAllCategories(filter)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCatDesList);

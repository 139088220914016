import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Form, Input, Button, Row, Col, Modal, Descriptions, Tag, Alert, Divider } from 'antd';
import { isMobile } from 'react-device-detect';
import PageTitle from '../../components/PageTitle';
import { EditOutlined } from '@ant-design/icons';
// actions
import { changePassword, updateAuthUser } from '../../redux/actions/AuthActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/profile',
        breadcrumbName: 'Thông tin tài khoản',
    },
]

class Profile extends Component {
    state = {
        loading: false,
        visible: false,
        confirmLoading: false
    }

    formRef = React.createRef();

    handleSubmit = (values) => {
        this.setState({ loading: true });
        this.props.changePassword(values).then(() => {
            this.formRef.current.resetFields();
            this.setState({ loading: false })
        }).catch(() => {
            this.setState({ loading: false })
        })
    }

    updateBankAccount = async (values) => {
        this.setState({ confirmLoading: true });
        await this.props.updateAuthUser(values).then((res) => {
            this.handleCancel()
        }).catch(() => {
            this.setState({ confirmLoading: false })
        })
    }

    handleCancel() {
        this.setState({ visible: false, confirmLoading: false })
    }

    render() {
        var { loading, visible, confirmLoading } = this.state;
        var { authUser } = this.props;

        return (
            <div>
                <PageTitle routes={routes} />
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Card
                            className="mb-4 mt-3"
                            title="TÀI KHOẢN"
                            size='small'
                            extra={
                                <Button onClick={() => this.setState({ visible: true })}
                                    size='small'
                                    type='primary'
                                    icon={<EditOutlined />}>
                                    Cập nhật
                                </Button>}
                        >
                            <Row gutter={24}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Full Name">
                                            {authUser?.full_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Username">
                                            {authUser?.user_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Email">
                                            {authUser?.user_email}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Vai trò">
                                            <Tag color="cyan">{authUser?.role_name}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="ID telegram">
                                            {authUser?.telegram_id}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tài khoản ngân hàng">
                                            {authUser?.account_bank}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Card className="mb-4"
                            size='small'
                            title="ĐỔI MẬT KHẨU"
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.handleSubmit}
                                ref={this.formRef}
                            >
                                <Form.Item
                                    label="Mật khẩu hiện tại của bạn"
                                    name="old_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng điền mật khẩu hiện tại của bạn!'
                                        }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Mật khẩu mới"
                                            name="new_password"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền mật khẩu mới!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Xác nhận mật khẩu mới"
                                            name="confirm_new_password"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền lại mật khẩu mới!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ textAlign: 'right' }} className='mb-0'>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Xác nhận
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    width={isMobile ? '100%' : '30%'}
                    title="Cập nhật tài khoản"
                    visible={visible}
                    onCancel={() => this.handleCancel()}
                    maskClosable={false}
                    footer={false}
                >
                    <Alert
                        message="Hướng dẫn lấy ID Telegram"
                        description={
                            <div>
                                <a href='https://cms.ecompublic.com/guide/19' target="_blank">Xem hướng dẫn tại đây</a>
                            </div>
                        }
                        type="info"
                    />
                    <br />
                    <Form
                        layout="vertical"
                        onFinish={this.updateBankAccount}
                        ref={this.formRef}
                        initialValues={{
                            ...authUser,
                            account_bank: authUser && authUser.account_bank ? authUser.account_bank.replace(/<br ?\/?>/g, "\n") : null,
                        }}
                    >
                        <Form.Item
                            label="ID telegram"
                            name="telegram_id"
                            rules={[
                                { required: false, message: 'Vui lòng điền!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Tài khoản ngân hàng"
                            name="account_bank"
                            rules={[
                                { required: true, message: 'Vui lòng điền!' }
                            ]}
                        >
                            <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }} className='mb-0'>
                            <Button type="primary" htmlType="submit" loading={confirmLoading}>
                                Cập nhật
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (data) => dispatch(changePassword(data)),
        updateAuthUser: (data) => dispatch(updateAuthUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
import React from 'react';

import { EnvironmentOutlined } from '@ant-design/icons';

class ClockUTC extends React.Component {
    constructor(props) {
        super(props);
        this.state = { utcTime: '' };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.updateTime();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    addZero = (i) => {
        return i < 10 ? '0' + i : i;
    };

    updateTime = () => {
        const d = new Date();
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        const day_names = ["Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"];

        const day = d.getUTCDate();
        const month = months[d.getUTCMonth()];
        const year = d.getUTCFullYear();
        const h = this.addZero(d.getUTCHours());
        const m = this.addZero(d.getUTCMinutes());
        const s = this.addZero(d.getUTCSeconds());
        const current_day = d.getUTCDay();
        const day_name = day_names[current_day];

        const time = `${day}/${month}/${year} ${h}:${m}:${s} (${day_name})`;
        this.setState({ utcTime: time });
    };

    render() {
        return (
            <>
                <EnvironmentOutlined /> Giờ UTC+0: <span>{this.state.utcTime}</span>
            </>
        )
    }
}

export default ClockUTC;

import {
    GET_ALL_LICENSE,
    UPDATE_SPECIFIC_LICENSE,
    REMOVE_LICENSE,
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    license_list: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_LICENSE: {
            return {
                ...state,
                license_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case UPDATE_SPECIFIC_LICENSE: {
            let index = state.license_list.findIndex((item) => {
                return item.id == action.payload.id;
            });

            let temp = [...state.license_list];
            temp[index] = action.payload;
            return { ...state, license_list: temp }
        }
        case REMOVE_LICENSE: {
            let temp = state.license_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, license_list: temp }
        }
        default: return { ...state };
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Modal, Divider, Switch, DatePicker, Button, Input } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import { Form } from '@ant-design/compatible';
import _ from 'lodash';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import TextTruncate from 'react-text-truncate';

import moment from 'moment';
// actions
import { getAllLicense, updateLicense, removeLicense } from '../../redux/actions/ToolLicenseActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/tool-license',
        breadcrumbName: 'Quản lý license tool',
    },
]

class ToolLicenseList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        isOpenFormNote: false,
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllLicense(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllLicense(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/tool-license',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/tool-license',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeLicense(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeLicense(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllLicense(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    async submitNote(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({ isloadingFormNote: true });
                    var params = {
                        note: values.note
                    }
                    await this.props.updateLicense(this.state.license_id, params);
                    await this.props.form.resetFields();
                    this.reloadData();
                    this.setState({ isloadingFormNote: false });
                    this.toggleFormNote(false);
                } catch (error) {
                    this.setState({ isloadingFormNote: false });
                }
            }
        })
    }

    toggleFormNote(visible, license_id = null, note = null) {
        if (visible) {
            this.setState({
                isOpenFormNote: visible,
                license_id: license_id,
                note: note
            })
        } else {
            this.props.form.resetFields();
            this.setState({
                isOpenFormNote: visible,
                license_id: null,
                note: null
            })
        }
    }

    render() {
        var { license_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenFormNote, isloadingFormNote, note } = this.state;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
            },
            {
                title: '#',
                align: 'center',
                width: '40px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('tool_license', 'remove') ? (
                                <DeleteOutlined style={{ fontSize: '15px', color: '#f00' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Name',
                dataIndex: 'Name',
                key: 'Name',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <TextTruncate line={1} truncateText="…" text={text} />
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Serial',
                dataIndex: 'Serial',
                key: 'Serial',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <TextTruncate line={1} truncateText="…" text={text} />
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Note',
                dataIndex: 'Note',
                key: 'Note',
                width: '300px',
                render: (text, record) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {text ? (
                            <>{text} <Divider type='vertical' /></>
                        ) : null}
                        <EditOutlined onClick={() => this.toggleFormNote(true, record.id, text)} />
                    </div>
                ),
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                sorter: true,
                render: (text, record) => (
                    <div>
                        <Switch
                            disabled={!checkPermission('tool_license', 'update')}
                            defaultChecked={record.status}
                            checkedChildren="Active"
                            unCheckedChildren="InActive"
                            onChange={(value) => this.props.updateLicense(record.id, { status: value })}
                        />
                    </div>
                )
            },
            {
                title: 'Date',
                dataIndex: 'Date',
                key: 'Date',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>
                            <div>
                                {isValidDate(record.Date)}
                            </div>
                        </div>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Quản lý license tool" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('tool_license', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        showFilter={false}
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            style={{ width: '150px' }}
                            placeholder="Ngày bắt đầu"
                            className="table-button"
                            onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                            defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />
                        <Divider type='vertical' />
                        <DatePicker
                            format="DD/MM/YYYY"
                            style={{ width: '150px' }}
                            placeholder="Ngày kết thúc"
                            className="table-button"
                            onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                            defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
                        <Divider type='vertical' />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Active' },
                                { value: 0, label: 'InActive' },
                            ]}
                            onChange={(value) => this.onChangeFilter("status", value)}
                            placeholder="Trạng thái"
                            className="table-button"
                            defaultValue={parseInt(query.status) || null}
                            style={{ width: '150px' }}
                        />
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={license_list || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <Modal
                    visible={isOpenFormNote}
                    title={note ? "Sửa ghi chú" : "Thêm mới ghi chú"}
                    width='40%'
                    onOk={(e) => this.submitNote(e)}
                    onCancel={() => this.toggleFormNote(false)}
                    footer={[
                        <Button key="back" onClick={() => this.toggleFormNote(false)}>Huỷ</Button>,
                        <Button loading={isloadingFormNote} key="submit" type="primary" onClick={(e) => this.submitNote(e)}>{note ? "Cập nhật" : "Thêm mới"}</Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Form.Item>
                            {
                                getFieldDecorator("note", {
                                    initialValue: note ? note : "",
                                    rules: [{ required: false, message: "Bắt buộc" }]
                                })(<Input.TextArea rows={6} />)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        license_list: state.ToolLicenseReducer.license_list,
        pagination: state.ToolLicenseReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllLicense: (filter) => dispatch(getAllLicense(filter)),
        removeLicense: (ids) => dispatch(removeLicense(ids)),
        updateLicense: (id, data) => dispatch(updateLicense(id, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ToolLicenseList' })(ToolLicenseList));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, DatePicker, Row, Col, Drawer, Divider, InputNumber } from 'antd';
import moment from 'moment';
import { _newLine } from '../../utils/helpers';
import BaseCheckBox from '../../components/Elements/BaseCheckboxes';
import BaseRadios from '../../components/Elements/BaseRadios';
import Editor from "../../components/Editor";
import { isMobile } from 'react-device-detect';
// actions
import { createData, updateData } from '../../redux/actions/WorkReportActions';

class WorkReportForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            showDate: true,
            result: 'done'
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {

        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                values.start_date = values.start_date ? values.start_date.format("YYYY-MM-DD") : null;
                values.end_date = values.end_date ? values.end_date.format("YYYY-MM-DD") : null;
                if (Array.isArray(values.type)) {
                    const [type] = values.type;
                    values.type = type;
                }
                //console.log("values: ", values)
                if (this.props.currentData) {
                    await this.props.updateData(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    await this.props.createData(values);
                    this.handCancel()
                    this.props.reloadData()
                }
                this.setState({ isloading: false })
            }
        });
    }

    handCancel() {
        this.setState({ isloading: false, showDate: true, result: 'done' })
        this.props.form.resetFields();
        this.props.onCancel();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentData !== this.props.currentData) {
            const { currentData } = this.props;
            if (currentData) {
                if (currentData.type == 'daily') {
                    this.setState({ showDate: false })
                } else {
                    this.setState({ showDate: true })
                }
                this.setState({ result: currentData.result })
            }
        }
    }

    onChangeType(val) {
        if (val == 'daily') {
            this.setState({ showDate: false })
        } else {
            this.setState({ showDate: true })
        }
    }

    onChangeResult(val) {
        this.setState({ result: val })
    }

    render() {
        const { isloading, showDate, result } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa báo cáo" : "Thêm mới báo cáo"}
                onOk={(e) => this.submit(e)}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.handCancel()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                            {currentData ? "Cập nhật" : "Thêm"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Ngày báo cáo">
                            {getFieldDecorator('report_date', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? moment(currentData.report_date).isValid() ? moment(currentData.report_date) : null : moment()
                            })(
                                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Nội dung báo cáo">
                            {getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.content : ''
                            })(
                                <Editor />
                            )}
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Loại công việc">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.type : ""
                                    })(
                                        <BaseCheckBox
                                            options={[
                                                { value: 'daily', label: 'Hàng ngày' }
                                            ]}
                                            onChange={(val) => this.onChangeType(val)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                showDate ? (
                                    <Col xs={24} md={24}>
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Ngày bắt đầu">
                                                    {getFieldDecorator("start_date", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? moment(currentData.start_date).isValid() ? moment(currentData.start_date) : null : ''
                                                    })(
                                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item label="Ngày hoàn thành">
                                                    {getFieldDecorator("end_date", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: currentData ? moment(currentData.end_date).isValid() ? moment(currentData.end_date) : null : ''
                                                    })(
                                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Kết quả">
                                    {getFieldDecorator('result', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.result : "done"
                                    })(
                                        <BaseRadios
                                            options={[
                                                { value: 'done', label: 'Đã hoàn thành' },
                                                { value: 'unfinished', label: 'Chưa hoàn thành' }
                                            ]}
                                            onChange={(val) => this.onChangeResult(val)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            result == 'done' ? (
                                <></>
                            ) : (
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Nguyên nhân">
                                            {getFieldDecorator("reason", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: currentData ? _newLine(currentData.reason) : ''
                                            })(
                                                <Input.TextArea rows={3} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Hướng giải quyết">
                                            {getFieldDecorator("solution", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: currentData ? _newLine(currentData.solution) : ''
                                            })(
                                                <Input.TextArea rows={3} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? _newLine(currentData.note) : ''
                            })(
                                <Input.TextArea rows={2} />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        users: state.config.users,
        authUser: state.auth.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'WorkReportForm' })(WorkReportForm));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Space, Modal } from 'antd';
import BaseCheckboxes from '../../components/Elements/BaseCheckboxes';
import { isMobile } from 'react-device-detect';

// actions
import { updateDescription } from '../../redux/actions/ProductsImportAction';

class DescriptionSelect extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            isLoadingCat: false
        };
    }

    onChangeStore(value) {
        this.setState({ isLoadingCat: true })
        this.props.getAllCategories({ store_id: value }).then(res => {
            this.setState({ isLoadingCat: false })
        });
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                values.ids = this.props.ids
                //console.log(values)
                if (this.props.ids) {
                    await this.props.updateDescription(values);
                    this.handCancel()
                    this.props.reloadData()
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false })
    }

    render() {
        const { isloading } = this.state;
        var { visible } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                visible={visible}
                onCancel={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                closable={false}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.handCancel()}>
                                HỦY
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={isloading}
                                onClick={(e) => this.submit(e)}
                            >
                                TẠO
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Form.Item label="Chọn loại nội dung">
                            {getFieldDecorator('type', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: ['niche_des', 'product_des', 'trust_site']
                            })(
                                <BaseCheckboxes
                                    style={{ width: '100%' }}
                                    options={[
                                        { label: "Mô tả niche", value: 'niche_des' },
                                        { label: "Mô tả sản phẩm", value: 'product_des' },
                                        { label: "Content trust site", value: 'trust_site' },
                                        { label: "Video", value: 'video' },
                                        { label: "Hình ảnh", value: 'image' },
                                    ]}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateDescription: (data) => dispatch(updateDescription(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'DescriptionSelect' })(DescriptionSelect));

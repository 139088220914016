import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, EyeOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, Image, Drawer, Divider, Space } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import NicheForm from './NicheForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';

// actions
import { getAllNiche, getNiche, removeNiche } from '../../redux/actions/NicheActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/niche',
        breadcrumbName: 'Danh sách niche',
    },
]

class NicheList extends Component {
    state = {
        isLoading: false, // loading table
        selectedRowKeys: [], // id of selected rows
        isOpenForm: false,
        currentNiche: null,
        openView: false
    }

    componentDidMount() {
        //console.log("Props: ", this.props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllNiche(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllNiche(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/niche',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/niche',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, niche = null) {
        this.setState({ isOpenForm: isOpenForm, currentNiche: niche });
    }

    onEdit(id) {
        this.props.getNiche(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeNiche(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeNiche(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllNiche(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onView(id) {
        this.props.getNiche(id).then(res => {
            this.setState({ openView: true, currentNiche: res });
        })
    }

    render() {
        var { niches, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, openView, currentNiche } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: '#',
                width: '100px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        <EyeOutlined className='primary-color item-action-btn' onClick={() => this.onView(record.id)} />
                        <Divider type='vertical' />
                        {
                            checkPermission('niche', 'update') ? (
                                <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                            ) : null
                        }
                        <Divider type='vertical' />
                        {
                            checkPermission('niche', 'remove') ? (
                                <DeleteOutlined className='item-action-btn-remove item-action-btn' onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                width: '250px',
                render: (text, record) => (
                    <>{text}</>
                )
            },
            {
                title: 'Mô tả',
                dataIndex: 'description',
                key: 'description',
                width: '300px',
                render: (text, record) => (
                    <div style={{ wordBreak: "break-all" }}>
                        <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                    </div>
                )
            },
            {
                title: 'Ảnh mẫu',
                width: '100px',
                render: (text, record) => {
                    if (Array.isArray(record.image)) {
                        return (
                            <div>
                                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                    <Image.PreviewGroup>
                                        <Space>
                                            {
                                                record.image.map(item => {
                                                    return (
                                                        <Image
                                                            height={25}
                                                            src={item}
                                                        />
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Copyright',
                dataIndex: 'copyright',
                key: 'copyright',
                width: '100px',
                render: (text, record) => (
                    <Tag>{text}</Tag>
                )
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                align: 'center',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách niche" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('niche', 'create')}
                        isShowDeleteButton={checkPermission('niche', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={niches}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <NicheForm
                    visible={isOpenForm}
                    niche={currentNiche}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <Drawer
                    visible={openView}
                    title={`Mô tả: ${currentNiche?.name}`}
                    onClose={() => this.setState({ openView: false })}
                    maskClosable={true}
                    width={isMobile ? '100%' : '50%'}
                >
                    {
                        openView ?
                            <div>
                                {
                                    currentNiche ? (
                                        <div>
                                            {ReactHtmlParser(currentNiche?.description)}
                                        </div>
                                    ) : null
                                }
                            </div>
                            : null
                    }

                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        niches: state.NicheReducer.niches,
        pagination: state.NicheReducer.pagination,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllNiche: (filter) => dispatch(getAllNiche(filter)),
        getNiche: (id) => dispatch(getNiche(id)),
        removeNiche: (ids) => dispatch(removeNiche(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NicheList);

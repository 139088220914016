import {
    GET_ALL_BLACKLIST,
    GET_SPECIFIC_BLACKLIST,
    UPDATE_SPECIFIC_BLACKLIST,
    REMOVE_BLACKLIST
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    blacklist_list: [],
    currentBlacklist: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_BLACKLIST: {
            return {
                ...state,
                blacklist_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_SPECIFIC_BLACKLIST: {
            return { ...state, currentBlacklist: action.payload }
        }

        case UPDATE_SPECIFIC_BLACKLIST: {
            let index = state.blacklist_list.findIndex((item) => {
                return item.id == action.payload.id;
            });

            let temp = [...state.blacklist_list];
            temp[index] = action.payload;
            return { ...state, blacklist_list: temp }
        }
        case REMOVE_BLACKLIST: {
            let temp = state.blacklist_list.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, blacklist_list: temp }
        }
        default: return { ...state };
    }
}

import api from '../../utils/api';
import { getBase64 } from '../../utils/helpers';

class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    async upload() {
        try {
            const file = await this.loader.file;
            const fileBase64 = await getBase64(file);
            const file_name = file.name;
            const fileData = {
                file_name: file_name,
                fileBase64: fileBase64
            };
            const res = await api.post('/upload', { files: [fileData] });
            //console.log("res: ", res);
            const responseData = res.data;
            const [image] = responseData.data;

            if (responseData.status === "success") {
                return {
                    default: image
                };
            } else {
                throw new Error(responseData.msg || 'Upload failed');
            }
        } catch (error) {
            console.error(error);
            throw new Error('Upload failed');
        }
    }

    abort() {
        // Implement if needed
    }
}

export default UploadAdapter;

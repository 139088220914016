import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Modal, Button, Divider } from "antd";
import { isMobile } from 'react-device-detect';
import UploadImage from './FilePngUpload'
import { NotificationManager } from 'react-notifications';
// actions
import { createFilePng } from '../../redux/actions/FilePngAction';

class FilePngAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            image: [],
        };
    }

    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const params = {
                        src: this.state.image
                    }
                    //console.log(params)
                    await this.props.createFilePng(params);
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChange = (data) => {
        //console.log("data: ", data)
        this.setState({
            isResetUpload: false,
            image: data
        });
        const { image } = this.state;
        this.props.form.setFieldsValue({
            src: image,
            name: image[0].name
        });
        NotificationManager.success("File load complete.");
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            image: []
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        const { visible } = this.props;
        const { isLoading, isResetUpload } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title="THÊM MỚI FILE"
                width={isMobile ? '100%' : '40%'}
                onCancel={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Upload
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Form.Item label="File ảnh">
                            {getFieldDecorator('src', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={20}
                                    onChangeData={this.onChange}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                    </Form>
                ) : null
                }
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFilePng: (data) => dispatch(createFilePng(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "FilePngAddForm" })(FilePngAddForm));

import {
    STATIS_IDEA,
    STATIS_DESIGN,
    STATIS_MONEY,
    STATIS_STAFF,
    STATIS_PRODUCT,
    STATIS_ORDER,
    STATIS_ORDER_BY_STORE
} from '../actions/types';

const INIT_STATE = {
    statis_idea: [],
    statis_design: [],
    statis_money: [],
    statis_staff: [],
    statis_product: [],
    statis_order_by_store: {
        statis: [],
        store: [],
        total: []
    },
    statis_order: {
        order_success: [],
        cancelled: [],
        refunded: [],
        pending: [],
        on_hold: [],
        failed: [],
        profit: [],
        order_success_total: 0,
        cancelled_total: 0,
        refunded_total: 0,
        pending_total: 0,
        on_hold_total: 0,
        failed_total: 0,
        total_profit: 0
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case STATIS_IDEA:
            return {
                ...state,
                statis_idea: action.payload
            };
        case STATIS_DESIGN:
            return {
                ...state,
                statis_design: action.payload
            };
        case STATIS_MONEY:
            return {
                ...state,
                statis_money: action.payload
            };
        case STATIS_STAFF:
            return {
                ...state,
                statis_staff: action.payload
            };
        case STATIS_ORDER:
            let statis_order = { ...state.statis_order };
            statis_order.total_profit = action.payload.data.total_profit;
            if (action.payload.status == 'order_success') {
                statis_order.order_success = action.payload.data.orders;
                statis_order.order_success_total = action.payload.data.total;
            } else if (action.payload.status == 'cancelled') {
                statis_order.cancelled = action.payload.data.orders;
                statis_order.cancelled_total = action.payload.data.total;
            } else if (action.payload.status == 'refunded') {
                statis_order.refunded = action.payload.data.orders;
                statis_order.refunded_total = action.payload.data.total;
            } else if (action.payload.status == 'pending') {
                statis_order.pending = action.payload.data.orders;
                statis_order.pending_total = action.payload.data.total;
            } else if (action.payload.status == 'on-hold') {
                statis_order.on_hold = action.payload.data.orders;
                statis_order.on_hold_total = action.payload.data.total;
            } else if (action.payload.status == 'failed') {
                statis_order.failed = action.payload.data.orders;
                statis_order.failed_total = action.payload.data.total;
            } else if (action.payload.status == 'profit') {
                statis_order.profit = action.payload.data.orders;
            }
            return {
                ...state,
                statis_order: statis_order
            }
        case STATIS_PRODUCT:
            return {
                ...state,
                statis_product: action.payload
            };
        case STATIS_ORDER_BY_STORE:
            return {
                ...state,
                statis_order_by_store: {
                    statis: action.payload.metrics,
                    store: action.payload.stores,
                    total: action.payload.total
                }
            };
        default:
            return { ...state }
    }
};

import {
    GET_ALL_TRAINING,
    GET_SPECIFIC_TRAINING
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    training: [],
    currentTraining: [],
    pagination: {
        current: 1,
        total: 0, // total records
        defaultPageSize: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRAINING: {
            return {
                ...state,
                training: action.payload.data,
                pagination: {
                    current: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    defaultPageSize: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_SPECIFIC_TRAINING:
            return {
                ...state,
                currentTraining: action.payload
            };
        default: return { ...state };
    }
}

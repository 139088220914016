import {
    LIST_PRODUCTS,
    GET_ONE_PRODUCTS,
    GET_LIST_FINISHED_DESIGN,
    GET_PRODUCT_HISTORIES
} from '../actions/types';

const INIT_STATE = {
    list_products: {
        store_id: null,
        store_url: null,
        items: []
    },
    finished_design: [],
    product_histories: [],
    currentProduct: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_PRODUCTS:
            return {
                ...state,
                list_products: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    items: action.payload.data
                },
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_LIST_FINISHED_DESIGN:
            return {
                ...state,
                finished_design: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_PRODUCT_HISTORIES:
            return {
                ...state,
                product_histories: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case GET_ONE_PRODUCTS:
            return {
                ...state,
                currentProduct: action.payload
            };

        default:
            return { ...state }
    }
};

import api from '../../utils/api';
import {
    CHATGPT_GET_ALL,
    CHATGPT_GET_SPECIFIC,
    CHATGPT_UPDATE_SPECIFIC,
    CHATGPT_CREATE_NEW,
    CHATGPT_REMOVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const chatGPTGetAll = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/chatgpt', { params: filter }).then(res => {
            dispatch({ type: CHATGPT_GET_ALL, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const chatGPTCreate = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/chatgpt', data).then(res => {
            dispatch({ type: CHATGPT_CREATE_NEW, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const chatGPTGetOne = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/chatgpt/${id}`).then(res => {
            dispatch({ type: CHATGPT_GET_SPECIFIC, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const chatGPTUpdate = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/chatgpt/${id}`, data).then(res => {
            dispatch({ type: CHATGPT_UPDATE_SPECIFIC, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const chatGPTRemove = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/chatgpt`, { data: { ids: ids } }).then(res => {
            dispatch({ type: CHATGPT_REMOVE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const chatGPTCompletions = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/chatgpt/chat/completions', data).then(res => {
            dispatch({ type: CHATGPT_CREATE_NEW, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
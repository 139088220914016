import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { RetweetOutlined, EditOutlined, IeOutlined, GoogleOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, Image, Button, Divider, Input, TreeSelect, Row, DatePicker, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import { Link } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import TextTruncate from 'react-text-truncate';
import { cleanObject, makeTree, stripHtml } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';
import ProductsEditForm from './ProductsEditForm';
import ProductsAddForm from './ProductsAddForm';
import DeleteProductByLink from './DeleteProductByLink';
import { NotificationManager } from 'react-notifications';

// actions
import { getAllProducts, deleteProduct, updateStatus, getOneProduct, updatePrice } from '../../redux/actions/ProductsAction';
import { getAllCategories } from '../../redux/actions/ProductsCategoriesAction';
import { getAllTagsInProductForm } from '../../redux/actions/ProductsTagsAction';
import { postTaskInProductList } from '../../redux/actions/GoogleIndexingActions';
import { getStoreByField } from '../../redux/actions/StoreActions';

const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/products',
        breadcrumbName: 'Danh sách sản phẩm',
    }
];

const { RangePicker } = DatePicker;
const { confirm } = Modal;

class ProductsList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isloading_delete: false,
            isloading_status: false,
            visible_status: false,
            visible_edit: false,
            visible_add: false,
            disabledAddBtn: true,
            visible_update_price: false,
            isloading_update_price: false,
            isLoadingIndexing: false,
            openFormDelete: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllProducts(query).then((res) => {
            if (res?.data?.data?.length) {
                this.setState({ disabledAddBtn: false })
            }
            this.setState({
                isLoading: false
            })
        });
        this.props.getStoreByField({ platform: ['pod', 'dropship', 'shield'] })
        this.props.getAllCategories();
        this.props.getAllTagsInProductForm();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.list_products !== this.props.list_products) {
            this.props.getAllCategories({ store_id: this.props.list_products.store_id });
            this.props.getAllTagsInProductForm({ store_id: this.props.list_products.store_id });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.list_products.store_id == 'undefined') {
            this.setState({ disabledAddBtn: true })
        } else {
            this.setState({ disabledAddBtn: false })
        }
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProducts(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/products',
            search: qs.stringify(query),
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getAllProducts(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.start_publish_time = moment(value[0]).format('YYYY-MM-DD');
                query.end_publish_time = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.start_publish_time;
                delete query.end_publish_time;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: '/products',
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    onDelete() {
        this.setState({ isloading_delete: true })
        this.props.deleteProduct(this.props.list_products.store_id, this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
                isloading_delete: false
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.reloadData()
        })
    }

    async onUpdateStatus(e) {
        e.preventDefault();
        this.props.form.validateFields(['status'], async (err, values) => {
            if (!err) {
                this.setState({ isloading_status: true });
                try {
                    await this.props.updateStatus(this.props.list_products.store_id, this.state.selectedRowKeys, values.status).then(() => {
                        this.setState({
                            selectedRowKeys: [],
                            isloading_status: false,
                            visible_status: false
                        })
                        setTimeout(() => {
                            Modal.destroyAll();
                        }, 800)
                        this.props.form.resetFields()
                        this.reloadData()
                    })
                } catch (error) {
                    this.setState({ isloading_status: false });
                }
            }
        });
    }

    async onUpdatePrice(e) {
        e.preventDefault();
        this.props.form.validateFields(['regular_price', 'sale_price'], async (err, values) => {
            if (!err) {
                this.setState({ isloading_update_price: true });
                try {
                    const price = {
                        regular_price: values.regular_price,
                        sale_price: values.sale_price
                    }
                    await this.props.updatePrice(this.props.list_products.store_id, this.state.selectedRowKeys, price).then(() => {
                        this.setState({
                            selectedRowKeys: [],
                            isloading_update_price: false,
                            visible_update_price: false
                        })
                        setTimeout(() => {
                            Modal.destroyAll();
                        }, 800)
                        this.props.form.resetFields()
                        this.reloadData()
                    })
                } catch (error) {
                    this.setState({ isloading_update_price: false });
                }
            }
        });
    }

    toggleOpenEditForm(visible_edit, item = null) {
        this.setState({
            visible_edit: visible_edit,
            item: item
        });
    }

    onEdit(id) {
        this.props.getOneProduct(id, { store_id: this.props.list_products.store_id }).then((res) => {
            this.toggleOpenEditForm(true, res)
        })
    }

    googleIdexing() {
        confirm({
            title: 'Xác nhận',
            icon: <ExclamationCircleOutlined />,
            content: 'Chắc chắn chỉ chọn những sản phẩm đã public?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                if (this.state.selectedRowKeys.length) {
                    this.setState({ isLoadingIndexing: true })
                    const params = {
                        ids: this.state.selectedRowKeys,
                        store_id: this.props.list_products.store_id
                    };

                    this.props.postTaskInProductList(params);
                    this.setState({
                        selectedRowKeys: [],
                        isLoadingIndexing: false
                    })
                    NotificationManager.success("Gửi links thành công! Truy cập trang 'Tools -> Google indexing' để xem kết quả")
                } else {
                    NotificationManager.error("Chưa chọn sản phẩm");
                }
            }
        })
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isloading_delete,
            isloading_status,
            visible_status,
            item,
            visible_edit,
            visible_add,
            disabledAddBtn,
            visible_update_price,
            isloading_update_price,
            isLoadingIndexing,
            openFormDelete
        } = this.state;
        const { list_products, pagination, categories, stores, list_tags, authUser } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const catTree = makeTree(categories || [], 0);

        const columns = [
            {
                title: 'ID',
                dataIndex: 'ID',
                sorter: true,
                key: 'ID',
                width: '80px'
            },
            {
                title: 'Actions',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="Cập nhật">
                                <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.ID)} />
                            </Tooltip>
                            <Divider type='vertical' />
                            <Tooltip title="Cập nhật trên web">
                                <a href={`${this.props.list_products.store_url}/wp-admin/post.php?post=${record.ID}&action=edit`} target="_blank">
                                    <IeOutlined className='primary-color item-action-btn' /> Web
                                </a>
                            </Tooltip>
                        </>
                    )
                }
            },
            {
                title: 'Image',
                width: '100px',
                render: (text, record) => {
                    const [image] = record.attachments; // lấy ảnh đầu tiên trong mảng 
                    if (typeof image != 'undefined') {
                        return (
                            <>
                                <Image
                                    width={100}
                                    src={image.src}
                                    onClick={() => this.setState({ visibleGallery: true })}
                                />
                            </ >
                        )
                    }
                }
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '300px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Paragraph style={{ margin: 0 }} copyable={{ tooltips: ['Copy url'], text: this.props.list_products.store_url + '/product/' + record.slug }}>{text}</Paragraph>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'Price ($)',
                dataIndex: 'price',
                key: 'price',
                render: (text, record) => {
                    return (
                        <>
                            <div><span className='font-weight-500'>Regular: </span>{record.regular_price}</div>
                            <div><span className='font-weight-500'>Sale: </span>{record.sale_price}</div>
                        </>
                    )
                }
            },
            {
                title: 'Sale',
                dataIndex: 'total_sale',
                key: 'total_sale',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>
                            <div><span className='font-weight-500'>Count: </span>{record.order_count}</div>
                            <div><span className='font-weight-500'>Total sale: </span>{record.total_sales}</div>
                        </>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'post_status',
                key: 'post_status',
                align: 'center',
                sorter: true,
                render: (text, record) => {
                    var tag = "";
                    if (record.status === 'pending') {
                        tag = <Tag color="#ffec3d">{record.status}</Tag>
                    } else if (record.status === 'draft') {
                        tag = <Tag color="#1890ff">{record.status}</Tag>
                    } else if (record.status === 'publish') {
                        tag = <Tag color="#52c41a">{record.status}</Tag>
                    } else if (record.status === 'private') {
                        tag = <Tag color="#f5222d">{record.status}</Tag>
                    }
                    return (
                        <div>{tag}</div>
                    )
                }
            },
            {
                title: 'Categories',
                render: (text, record) => {
                    return (
                        <ul className="custom-ul">
                            {
                                record.categories.map(item => {
                                    return (
                                        <li>{item.name}</li>
                                    )
                                })
                            }
                        </ul>
                    )

                }
            },
            {
                title: 'Tags',
                width: '150px',
                render: (text, record) => {
                    // return (
                    //     <small>
                    //         {
                    //             record.tags.map(item => {
                    //                 return (
                    //                     <>{item.name}, </>
                    //                 )
                    //             })
                    //         }
                    //     </small>
                    // )
                    return (
                        <Tooltip placement="topLeft" title={record.tags}>
                            <TextTruncate line={3} truncateText="…" text={stripHtml(record.tags)} />
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Created UTC+7',
                dataIndex: 'post_date_gmt',
                key: 'post_date_gmt',
                align: 'center',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            <div>
                                {moment(record.post_date_gmt).format('DD/MM/YYYY HH:mm')}
                            </div>
                            <small><strong>By: </strong>{record.user_create}</small>
                        </div>
                    )
                }
            }
        ];

        const filters = [
            <TreeSelect
                style={{ width: '220px' }}
                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                treeData={catTree}
                allowClear
                showSearch
                treeNodeFilterProp='title'
                placeholder="Danh mục"
                treeDefaultExpandAll
                defaultValue={parseInt(query.category_id) || null}
                onChange={(value) => this.onChangeFilter("category_id", value)}
            />,
            <BaseSelect
                options={[
                    { value: 'pending', label: 'Pending' },
                    { value: 'draft', label: 'Draft' },
                    { value: 'publish', label: 'Publish' },
                    { value: 'private', label: 'Private' }
                ]}
                onChange={(value) => this.onChangeFilter("status", value)}
                defaultText="Trạng thái"
                placeholder="Trạng thái"
                defaultValue={query.status || null}
                style={{ width: '150px' }}
            />,
            <RangePicker
                format="DD/MM/YYYY"
                placeholder={['Từ ngày', 'Đến ngày']}
                style={{ width: '220px' }}
                onChange={(value) => this.onChangeFilter('created_at', value)}
                defaultValue={[query.start_publish_time ? moment(query.start_publish_time, 'YYYY-MM-DD') : null, query.end_publish_time ? moment(query.end_publish_time, 'YYYY-MM-DD') : null]}
            />
        ];
        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={[
                        { value: 'all_product', label: 'All product' },
                        //{ value: 'have_sale', label: 'Have sale' },
                        { value: 'have_sale_sort', label: 'Have sale' }
                    ]}
                    onChange={(value) => this.onChangeFilter("action", value)}
                    defaultText="List type"
                    placeholder="List type"
                    defaultValue={query.action || null}
                    style={{ width: '150px' }}
                />
            );
        }
        if (checkPermission('products', 'update')) {
            filters.unshift(
                <>
                    <Button className="table-button" onClick={() => this.setState({ openFormDelete: true })} disabled={disabledAddBtn} type='danger' icon={<DeleteOutlined />}>Xóa theo permalink</Button>
                    <Divider type='vertical' />
                    <Button className="table-button" onClick={() => this.setState({ visible_update_price: true })} disabled={!selectedRowKeys.length ? true : false} type='primary' icon={<RetweetOutlined />}>Update price</Button>
                    <Divider type='vertical' />
                    <Button onClick={() => this.setState({ visible_status: true })} disabled={!selectedRowKeys.length ? true : false} type='primary' icon={<RetweetOutlined />}>Update status</Button>
                    <Divider type='vertical' />
                </>
            )
        }
        return (
            <div>
                <PageTitle routes={routes} title="Danh sách sản phẩm" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('products', 'create')}
                        disabledAddBtn={disabledAddBtn}
                        isShowDeleteButton={checkPermission('products', 'remove')}
                        onAdd={() => this.setState({ visible_add: true })}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onDelete()}
                        isloading_delete={isloading_delete}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={
                            query.status ||
                            parseInt(query.category_id) ||
                            query.store_id ||
                            query.action || null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        {
                            checkPermission('products_import', 'list_queue') ? (
                                <>
                                    <Link to="/products-import" className="table-button">
                                        <Button type='primary'>Import</Button>
                                    </Link>
                                    <Divider type="vertical" />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('google_indexing', 'post_indexing') ? (
                                <>
                                    <Button className="table-button" loading={isLoadingIndexing} onClick={() => this.googleIdexing()} disabled={!selectedRowKeys.length ? true : false} type='primary' icon={<GoogleOutlined />}>Submit index</Button>
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        <BaseSelect
                            className="table-button"
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            showSearch
                            defaultValue={parseInt(query.store_id) || ''}
                            optionLabel="name"
                            defaultText="Chọn store"
                            style={{ width: '170px' }}
                        />
                        <Divider type='vertical' />
                        <Button className="table-button" loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>

                <Table
                    rowKey="ID"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={list_products.items}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <Modal
                    visible={visible_status}
                    title="UPDATE STATUS"
                    onOk={(e) => this.onUpdateStatus(e)}
                    width="40%"
                    onCancel={() => this.setState({ visible_status: false })}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visible_status: false })}>
                            Huỷ
                        </Button>,
                        <Button
                            loading={isloading_status}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onUpdateStatus(e)}
                        >
                            Ok
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Form.Item label="Chọn trạng thái">
                            {getFieldDecorator("status", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                            })(
                                <BaseSelect
                                    options={[
                                        { value: 'pending', label: 'Pending' },
                                        { value: 'draft', label: 'Draft' },
                                        { value: 'publish', label: 'Publish' },
                                        { value: 'private', label: 'Private' }
                                    ]}
                                    defaultText="Trạng thái"
                                    style={{ width: '100%' }}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    visible={visible_update_price}
                    title="CẬP NHẬT GIÁ SẢN PHẨM"
                    onOk={(e) => this.onUpdatePrice(e)}
                    width="40%"
                    onCancel={() => this.setState({ visible_update_price: false })}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ visible_update_price: false })}>
                            Huỷ
                        </Button>,
                        <Button
                            loading={isloading_update_price}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onUpdatePrice(e)}
                        >
                            Ok
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Form.Item label="Regular price">
                            {getFieldDecorator("regular_price", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Sale price">
                            {getFieldDecorator("sale_price", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                            })(
                                <Input />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <ProductsEditForm
                    visible={visible_edit}
                    item={item}
                    isLoading={isLoading}
                    store_id={list_products.store_id}
                    store_url={this.props.list_products.store_url}
                    categories={categories}
                    tags={list_tags?.items}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
                <ProductsAddForm
                    visible={visible_add}
                    isLoading={isLoading}
                    store_id={list_products.store_id}
                    store_url={this.props.list_products.store_url}
                    categories={categories}
                    tags={list_tags?.items}
                    onCancel={() => this.setState({ visible_add: false })}
                    reloadData={() => this.reloadData()}
                />
                <DeleteProductByLink
                    visible={openFormDelete}
                    onCancel={() => this.setState({ openFormDelete: false })}
                    store_id={list_products.store_id}
                    store_url={this.props.list_products.store_url}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    //console.log(state.ProductsCategories)
    return {
        list_products: state.ProductsReducer.list_products,
        pagination: state.ProductsReducer.pagination,
        authUser: state.auth.authUser,
        categories: state.ProductsCategories.categories,
        pagination_cat: state.ProductsCategories.pagination,
        list_tags: state.ProductsTags.tags_in_product_form,
        stores: state.StoreReducer.store_by_field_list,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllProducts: filter => dispatch(getAllProducts(filter)),
        deleteProduct: (store_id, ids) => dispatch(deleteProduct(store_id, ids)),
        getAllCategories: (filter, concatenate) => dispatch(getAllCategories(filter, concatenate)),
        getAllTagsInProductForm: filter => dispatch(getAllTagsInProductForm(filter)),
        updateStatus: (store_id, ids, status) => dispatch(updateStatus(store_id, ids, status)),
        updatePrice: (store_id, ids, price) => dispatch(updatePrice(store_id, ids, price)),
        getOneProduct: (id, store_id) => dispatch(getOneProduct(id, store_id)),
        postTaskInProductList: (data) => dispatch(postTaskInProductList(data)),
        getStoreByField: (filter) => dispatch(getStoreByField(filter))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ProductsList" })(ProductsList));

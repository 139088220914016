import {
    GET_ALL_TRENDING,
    GET_SPECIFIC_TRENDING,
    UPDATE_SPECIFIC_TRENDING,
    CREATE_NEW_TRENDING,
    REMOVE_TRENDING,
    GET_NOTIFY_TRENDING
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    trending: [],
    currentTrending: null,
    notify: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 20
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRENDING: {
            return {
                ...state,
                trending: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_SPECIFIC_TRENDING: {
            return { ...state, currentTrending: action.payload }
        }
        case CREATE_NEW_TRENDING: {
            return { ...state, trending: [action.payload, ...state.trending], total: state.total + 1 }
        }
        case UPDATE_SPECIFIC_TRENDING: {
            let index = state.trending.findIndex((trend) => {
                return trend.id == action.payload.id;
            });

            let temp = [...state.trending];
            temp[index] = action.payload;
            return { ...state, trending: temp }
        }
        case REMOVE_TRENDING: {
            let temp = state.trending.filter(trend => {
                return action.payload.indexOf(trend.id) < 0;
            });
            return { ...state, trending: temp }
        }
        case GET_NOTIFY_TRENDING:
            return {
                ...state,
                notify: action.payload.data
            };
        default: return { ...state };
    }
}

export default {
    API_URL: 'https://cms.ecompublic.com/backend/admin',
    MEDIA_URL: 'https://cms.ecompublic.com/backend',
    // ====  Api local  ======
    //API_URL: 'http://localhost/Ecompublic/Pod_manager/v4/backend/admin',
    //MEDIA_URL: 'http://localhost/Ecompublic/Pod_manager/v4/backend'
}

// firebase config
export const vapidKey = "BEnaBv7Fr5rKWS1d0FapZtW-u0CSSRKDWpnQRWl1ZPPwbR9_n_3z3NmIpkGgHMRHjXiSzv9FmCSX0rs4x_1d27Q";

export const firebaseConfig = {
    apiKey: "AIzaSyBS7EXJSCF1trcl0bs115d--Ay-5K4-0Rw",
    authDomain: "push-notification-ecompublic.firebaseapp.com",
    projectId: "push-notification-ecompublic",
    storageBucket: "push-notification-ecompublic.appspot.com",
    messagingSenderId: "878976424559",
    appId: "1:878976424559:web:efb785d47fe2a31e1dcb9a",
    measurementId: "G-Z2FM56FY5G"
};
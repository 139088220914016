import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Drawer, Descriptions, Divider, Space, Input, Comment, List, Tag, Row, Col } from 'antd'
import { EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { _newLine, isValidDate } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';

// actions
import { getData, commentVork } from '../../redux/actions/WorkReportActions';
import { createComment, removeComment } from '../../redux/actions/CommentsAction';

const { TextArea } = Input;
const { confirm } = Modal;
class Detail extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isOpenFormUpdate: false,
            comments: [],
            submitting: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {

        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(['comment'], async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    //console.log(values)
                    await this.props.commentVork(this.props.currentData.id, values);
                    this.cancelFormUpdate()
                }
                this.setState({ isloading: false })
            }
        });
    }

    handleCancel() {
        this.setState({ isOpenFormUpdate: false })
        this.props.onCancel()
        //this.props.reloadData()
    }

    cancelFormUpdate() {
        this.setState({
            isOpenFormUpdate: false,
        })
        this.props.reloadData()
        this.props.form.resetFields();
    }

    async submitComment(e) {
        e.preventDefault();
        this.props.form.validateFields(['content'], async (err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                const { currentData } = this.props;
                await this.props.createComment({ type: 'work_report', content: values.content, parent_id: currentData.id }).then(res => {
                    this.reloadData();
                })
            }
        });
    }
    deleteComment(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá thảo luận này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeComment([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
                this.reloadData();
            }),
            onCancel() { },
        })
    }

    reloadData() {
        this.props.reloadData();
        this.setState({ isLoading: false, submitting: false })
        this.props.form.resetFields();
    }

    render() {
        const {
            isloading,
            isOpenFormUpdate,
            submitting
        } = this.state;
        var { visible, currentData, authUser } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log(currentData)

        const comments = [];
        if (currentData) {
            currentData.comments.map(item => {
                var disabledDeleteComment = true;
                if (authUser.role_code == 'ADMIN') {
                    disabledDeleteComment = false
                } else if (authUser.id == item.sender) {
                    disabledDeleteComment = false
                }
                comments.push(
                    {
                        actions: [<Button disabled={disabledDeleteComment} type='link' onClick={() => this.deleteComment(item.id)} className="icon-delete-comment" key={`comment-list-delete-${item.id}`} icon={<DeleteOutlined />}>Delete</Button>],
                        author: item?.created_user,
                        avatar: <UserOutlined style={{ fontSize: '20px' }} className='primary-color' />,
                        content: <p>{ReactHtmlParser(item?.content)}</p>,
                        datetime: moment(item?.created_at).fromNow(),
                    }
                );
            })
        }

        const CommentList = ({ comments }) => (
            <List
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment {...props} />}
            />
        );

        var allowEdit = false;
        if (checkPermission('work_report', 'comment')) {
            if (authUser.role_code == 'ADMIN') {
                allowEdit = true;
            }
        }
        return (
            <Drawer
                visible={visible}
                title="Chi tiết báo cáo"
                onClose={() => this.handleCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                extra={
                    <Space>
                        {
                            allowEdit ?
                                <Button onClick={() => this.setState({ isOpenFormUpdate: true })} type="primary" icon={<EditOutlined />}>Nhận xét</Button>
                                : null
                        }
                    </Space>
                }
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Button key="back" onClick={() => this.props.onCancel()}>
                            Huỷ
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <div>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Loại công việc">
                                        <Tag>{currentData?.type == 'daily' ? 'Hàng ngày' : null}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày báo cáo">
                                        {isValidDate(currentData?.report_date)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Kết quả">
                                        {
                                            currentData?.result == 'done' ? (
                                                <Tag color="green">Đã hoàn thành</Tag>
                                            ) : (
                                                <Tag color="red">Chưa hoàn thành</Tag>
                                            )
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Người báo cáo">
                                        {currentData?.user}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày tạo">
                                        {isValidDate(currentData?.created_at)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày bắt đầu">
                                        {isValidDate(currentData?.start_date)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                            <Col xs={24} md={24}>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ngày hoàn thành">
                                        {isValidDate(currentData?.end_date)}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider className="no-margin" />
                            </Col>
                        </Row>
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Chi tiết báo cáo</span>
                            }
                            content={<div className='chingo-content-detail'>{ReactHtmlParser(currentData?.content)}</div>}
                        />
                        <Divider className="no-margin" />
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Nguyên nhân</span>
                            }
                            content={ReactHtmlParser(currentData?.reason)}
                        />
                        <Divider className="no-margin" />
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Hướng giải quyết</span>
                            }
                            content={ReactHtmlParser(currentData?.solution)}
                        />
                        <Divider className="no-margin" />
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Ghi chú</span>
                            }
                            content={ReactHtmlParser(currentData?.note)}
                        />
                        <Divider className="no-margin" />
                        <Comment
                            author={<span className='ant-descriptions-item-label'>Người nhận xét <Tag>{currentData.user_comment}</Tag></span>
                            }
                            content={
                                <>
                                    {ReactHtmlParser(currentData?.comment)}
                                </>
                            }
                        />
                        <Divider />
                        <h6>THẢO LUẬN</h6>
                        <br />
                        {comments.length > 0 && <CommentList comments={comments} />}
                        <Comment
                            avatar={<UserOutlined style={{ fontSize: '20px' }} className='primary-color' />}
                            content={
                                <>
                                    <Form.Item>
                                        {getFieldDecorator('content', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: ""
                                        })(<TextArea rows={4} />)}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" loading={submitting} onClick={(e) => this.submitComment(e)} type="primary">
                                            Đăng
                                        </Button>
                                    </Form.Item>
                                </>
                            }
                        />
                    </div>
                ) : null}

                <Modal
                    width={isMobile ? '100%' : '40%'}
                    title="Nhận xét"
                    visible={isOpenFormUpdate}
                    onOk={(e) => this.submit(e)}
                    confirmLoading={isloading}
                    onCancel={() => this.cancelFormUpdate()}
                    maskClosable={false}
                >
                    <Form layout="vertical">
                        <Form.Item label="Nội dung">
                            {getFieldDecorator('comment', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: currentData ? _newLine(currentData?.comment) : null
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Drawer>
        )
    }
}
function mapStateToProps(state) {
    //console.log(state)
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commentVork: (id, data) => dispatch(commentVork(id, data)),
        getData: (id) => dispatch(getData(id)),
        createComment: (data) => dispatch(createComment(data)),
        removeComment: (ids) => dispatch(removeComment(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Detail' })(Detail));

import api from '../../utils/api';
import {
    GET_ALL_GUIDE,
    GET_SPECIFIC_GUIDE,
    UPDATE_SPECIFIC_GUIDE,
    CREATE_NEW_GUIDE,
    REMOVE_GUIDE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllGuide = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/guide', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_GUIDE, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createGuide = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/guide', data).then(res => {
            dispatch({ type: CREATE_NEW_GUIDE, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getGuide = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/guide/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_GUIDE, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateGuide = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/guide/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_GUIDE, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeGuide = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/guide`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_GUIDE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { Table, Modal, DatePicker, Tag, Switch, Image, Divider, Tooltip, Button, Input, Radio, Typography, Badge } from 'antd';
import { EyeOutlined, EditOutlined, CheckOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { Form } from "@ant-design/compatible";
import qs from 'qs';
import moment from 'moment';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, _newLine, stripHtml, ConvertPriority, _priority, ConvertRequestType } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import DesignAddForm from './DesignAddForm'
import DesignEditForm from './DesignEditForm';
import DesignDetailDrawer from './DesignDetailDrawer';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications'
import no_image from '../../assets/img/no-image.png';

// actions
import { getListAll, removeDesign, updateDesign, getOneDesign, approvedMany } from '../../redux/actions/DesignAction';
import DynamicTable from '../../components/DynamicTable';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/design',
        breadcrumbName: 'Danh sách design',
    }
]
const { confirm } = Modal;
const { Paragraph } = Typography;

class DesignList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            isOpenEditForm: false,
            isOpenDetail: false,
            currentData: null,
            isOpenFormApproved: false,
            confirmLoading: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        });
        if (query.id) {
            this.onDetail(query.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListAll(query).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/design',
            search: qs.stringify(query),
        })
    }

    toggleOpenAddForm(isOpenAddForm) {
        this.setState({
            isOpenAddForm: isOpenAddForm,
        });
    }

    onRemove() {
        this.props.removeDesign(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.reloadData()
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/design',
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            isOpenFormApproved: false,
            confirmLoading: false,
            selectedRowKeys: []
        })
        this.reloadData();
        this.props.form.resetFields();
    }

    toggleOpenEditForm(isOpenEditForm, data = null) {
        this.setState({ isOpenEditForm: isOpenEditForm, currentData: data });
    }

    onEdit(id) {
        this.props.getOneDesign(id).then(res => {
            this.toggleOpenEditForm(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.props.getOneDesign(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    loadData() {
        this.reloadData();
        this.props.getOneDesign(this.state.currentData.id).then(res => {
            this.setState({ currentData: res });
        })
    }

    onSearch() { }
    onScrollEnd() { }

    async approvedDesign(e) {
        e.preventDefault();
        this.props.form.validateFields(['approved_status', 'approved_des'], async (err, values) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                const { selectedRowKeys } = this.state;
                var title = '';
                var content = '';
                if (values.approved_status == 0) {
                    title = "Xác nhận Chưa Duyệt sản phẩm"
                    content = "Chắc chắn Chưa Duyệt sản phẩm này"
                } else if (values.approved_status == 1) {
                    title = "Xác nhận Duyệt sản phẩm"
                    content = "Chắc chắn Duyệt sản phẩm này"
                } else if (values.approved_status == 2) {
                    title = "Xác nhận Không Duyệt sản phẩm"
                    content = "Chắc chắn Không Duyệt sản phẩm này"
                }
                values.ids = selectedRowKeys;
                try {
                    confirm({
                        title: title,
                        icon: <ExclamationCircleOutlined />,
                        content: content,
                        okText: 'OK',
                        cancelText: 'Huỷ',
                        onCancel: () => {
                            this.setState({ confirmLoading: false });
                        },
                        onOk: async () => {
                            if (selectedRowKeys.length) {
                                this.props.approvedMany(values).then(async (res) => {
                                    if (res.status == "success") {
                                        NotificationManager.success(res.msg)
                                    }
                                    this.handleCancel()
                                })
                            } else {
                                NotificationManager.error("Lỗi không tìm thấy bản ghi")
                            }
                        }
                    })
                } catch (error) {
                    this.setState({ confirmLoading: false });
                }
            }
        });
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenAddForm,
            isOpenEditForm,
            isOpenDetail,
            currentData,
            isOpenFormApproved,
            confirmLoading
        } = this.state;
        const { design, pagination, authUser, users, admins, users_design } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            },
            {
                title: '#',
                align: 'center',
                width: '80px',
                key: 'action',
                render: (text, record) => {
                    var allowEdit = false;
                    if (checkPermission('design', 'update')) {
                        // Nếu đã 'Duyệt' thì chỉ admin mới có quyền sửa
                        if (authUser.role_code == 'ADMIN' || authUser.role_code != 'DESIGN') {
                            allowEdit = true;
                        }
                    }
                    return (
                        <>
                            <Tooltip title="Xem chi tiết">
                                <EyeOutlined onClick={() => this.onDetail(record.id)} className='primary-color item-action-btn' />
                            </Tooltip>
                            {allowEdit ? (
                                <>
                                    <Divider type='vertical' />
                                    <Tooltip title="Cập nhật">
                                        <EditOutlined onClick={() => this.onEdit(record.id)} className='primary-color item-action-btn' />
                                    </Tooltip>
                                </>
                            ) : null}
                        </>
                    )
                }
            },
            {
                title: 'Deadline',
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: true,
                width: '120px',
                render: (text, record) => {
                    var diffDate = record.deadline ? moment(record.deadline).diff(moment().startOf('day'), 'days') : 0;
                    var show_text;
                    var badge_color;
                    if (diffDate) {
                        if (diffDate > 0) {
                            show_text = `Còn ${diffDate} ngày`;
                            badge_color = '#52c41a';
                        } else {
                            show_text = `Quá ${-1 * diffDate} ngày`;
                            badge_color = 'red';
                        }
                    } else {
                        show_text = "Hết hạn hôm nay";
                        badge_color = '#f50';
                    }
                    if (record.status == 1) { // Đã hoàn thiện
                        return (
                            <span>{moment(text).format('DD/MM/YYYY')}</span>
                        )
                    } else {
                        // return (
                        //     <Badge count={show_text} size='small' offset={[-33, -4]} color={badge_color}>
                        //         {moment(text).format('DD/MM/YYYY')}
                        //     </Badge>
                        // )
                        return (
                            <Badge count={show_text} size='small' className='custom-badge' color={badge_color}>
                                {moment(text).format('DD/MM/YYYY')}
                            </Badge>
                        )
                    }
                }
            },
            {
                title: "Files",
                width: '50px',
                align: 'center',
                key: 'list_design',
                render: (text, record) => {
                    if (Array.isArray(record.list_design)) {
                        const [print_file] = record.list_design;
                        return (
                            <div className='file-png-design-list'>
                                <Image
                                    height={25}
                                    src={print_file}
                                    fallback={no_image}
                                />
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Tên sản phẩm',
                dataIndex: 'name',
                key: 'name',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            <Tooltip title={record.name}>
                                <TextTruncate text={text} line={1} truncateText="..." />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Loại',
                dataIndex: 'request_type',
                key: 'request_type',
                width: '80px',
                render: (text, record) => {
                    return (
                        <>{ConvertRequestType(text)}</>
                    )
                }
            },
            {
                title: 'Tình trạng',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return (
                        <div>
                            <Switch
                                disabled={record.approved_status == 1}
                                defaultChecked={record.status}
                                checkedChildren="Đã hoàn thiện"
                                unCheckedChildren="Chưa hoàn thiện"
                                onChange={(value) => this.props.updateDesign(record.id, { status: value })}
                            />
                        </div>
                    )
                }
            },
            {
                title: 'Order ID',
                dataIndex: 'order_id',
                key: 'order_id',
                width: '90px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div className='ecom-paragraph'>
                                <Paragraph copyable>{text}</Paragraph>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'approved_status',
                key: 'approved_status',
                render: (text, record) => {
                    let approved_status = <Tag color="#ffc069">Chưa duyệt</Tag>
                    let approved_by_user = "";
                    if (record.approved_status === 1) {
                        approved_status = <Tag color="#52c41a">Đã duyệt</Tag>
                        approved_by_user = <div><small><b>By: </b>{record.user_approved}</small></div>
                    } else if (record.approved_status === 2) {
                        approved_status = <Tag color="#f00">Không được duyệt</Tag>
                    }
                    return (
                        <>
                            <div>{approved_status}</div>
                            {/* {approved_by_user} */}
                        </>
                    )
                }
            },
            {
                title: 'Độ ưu tiên',
                dataIndex: 'priority',
                key: 'priority',
                render: (text, record) => {
                    return (
                        <>{ConvertPriority(text)}</>
                    )
                }
            },
            {
                title: 'Ghi chú',
                width: '200px',
                key: 'description',
                render: (text, record) => {
                    return (
                        <div>
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                <Tooltip title={_newLine(record.description)}>
                                    <TextTruncate text={stripHtml(record.description)} line={1} truncateText="..." />
                                </Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: 'NV Design',
                dataIndex: 'user_design',
                key: 'user_design'
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{moment(text).format('DD/MM/YYYY')}</div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'user_created',
                key: 'user_created',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>{record.user_created}</>
                    )
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={[
                    { value: 1, label: 'Đã duyệt' },
                    { value: 0, label: 'Chưa duyệt' },
                    { value: 2, label: 'Không được duyệt' },
                ]}
                onChange={(value) => this.onChangeFilter("approved_status", value)}
                placeholder="Trạng thái"
                defaultValue={parseInt(query.approved_status) || null}
                style={{ width: '150px' }}
            />,
            <BaseSelect
                options={[
                    { value: 1, label: 'Đã hoàn thiện' },
                    { value: 0, label: 'Chưa hoàn thiện' },
                ]}
                onChange={(value) => this.onChangeFilter("status", value)}
                placeholder="Tình trạng"
                defaultValue={parseInt(query.status) || null}
                style={{ width: '150px' }}
            />,
            <BaseSelect
                options={[
                    { value: 'newdesign', label: 'Thiết kế mới' },
                    { value: 'clone', label: 'Nhân bản' }
                ]}
                onChange={(value) => this.onChangeFilter("request_type", value)}
                placeholder="Loại"
                defaultValue={query.request_type || null}
                style={{ width: '150px' }}
            />,
            <BaseSelect
                options={_priority}
                onChange={(value) => this.onChangeFilter("priority", value)}
                placeholder="Độ ưu tiên"
                defaultValue={query.priority || null}
                style={{ width: '150px' }}
            />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày bắt đầu"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Ngày kết thúc"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];
        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users_design}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="NV Thiết kế"
                    showSearch={false}
                    onSearch={() => this.onSearch()}
                    onScrollEnd={() => this.onScrollEnd()}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.user_design) || null}
                    onChange={(value) => this.onChangeFilter("user_design_id", value)}
                />,
                <BaseSelect
                    options={users}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người tạo"
                    showSearch={false}
                    onSearch={() => this.onSearch()}
                    onScrollEnd={() => this.onScrollEnd()}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.created_user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />,
                <BaseSelect
                    options={admins}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người duyệt"
                    showSearch={false}
                    onSearch={() => this.onSearch()}
                    onScrollEnd={() => this.onScrollEnd()}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.approved_by_user_id) || null}
                    onChange={(value) => this.onChangeFilter("approved_by_user_id", value)}
                />
            );
        }

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách design" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('design', 'create')}
                        isShowDeleteButton={checkPermission('design', 'remove')}
                        onAdd={() => this.toggleOpenAddForm(true)}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        showSearch={true}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                        activeFilter={
                            parseInt(query.approved_status) ||
                            parseInt(query.status) ||
                            query.request_type ||
                            query.priority ||
                            query.start_date ||
                            query.end_date ||
                            parseInt(query.user_design) ||
                            parseInt(query.created_user_id) ||
                            parseInt(query.approved_by_user_id)
                        }
                    >
                        {
                            checkPermission('design', 'approved') ? <><Button className="table-button" disabled={!selectedRowKeys.length ? true : false} type="primary" onClick={() => this.setState({ isOpenFormApproved: true })} icon={<CheckOutlined />}>Duyệt</Button> </> : null
                        }
                        <Divider type="vertical" />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </TableActionBar>
                </div>

                {/* <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={design}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                /> */}

                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={design || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="design"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>

                <Modal
                    width={isMobile ? '100%' : '40%'}
                    title="Duyệt sản phẩm"
                    visible={isOpenFormApproved}
                    onOk={(e) => this.approvedDesign(e)}
                    confirmLoading={confirmLoading}
                    onCancel={() => this.handleCancel()}
                    maskClosable={false}
                >
                    <Form layout="vertical">
                        <Form.Item label="Option" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('approved_status', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: 1
                            })(
                                <Radio.Group>
                                    <Radio value={1}>Duyệt</Radio>
                                    <Radio value={2}>Không được duyệt</Radio>
                                    <Radio value={0}>Chưa duyệt</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="Diễn giải" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('approved_des', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Field is required.'
                                    }
                                ],
                                initialValue: design ? design.approved_des : null
                            })(
                                <Input.TextArea rows={2} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <DesignAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.toggleOpenAddForm(false)}
                    reloadData={() => this.reloadData()}
                />
                <DesignEditForm
                    visible={isOpenEditForm}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenEditForm(false)}
                    reloadData={() => this.reloadData()}
                />
                <DesignDetailDrawer
                    visible={isOpenDetail}
                    design={currentData}
                    onCancel={() => this.toggleOpenDetail(false)}
                    reloadData={() => this.loadData()}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        design: state.DesignReducer.design,
        pagination: state.DesignReducer.pagination,
        authUser: state.auth.authUser,
        users: state.config.users,
        admins: state.config.admins,
        users_design: state.config.users_design
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAll: filter => dispatch(getListAll(filter)),
        removeDesign: ids => dispatch(removeDesign(ids)),
        updateDesign: (id, data) => dispatch(updateDesign(id, data)),
        getOneDesign: id => dispatch(getOneDesign(id)),
        approvedMany: data => dispatch(approvedMany(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "DesignListForm" })(DesignList));
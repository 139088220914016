import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Modal, Button, Input, DatePicker, Row, InputNumber, Col, Radio, Image, Divider, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { NotificationManager } from 'react-notifications'
import UploadImage from '../../components/UploadImage';
import { isMobile } from 'react-device-detect';
// actions
import { createData, updateData } from '../../redux/actions/RevenueExpenditureActions';

class RevenueExpenditureForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            isResetUpload: false,
            image: [],
            currentImages: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            this.setState({
                currentImages: nextProps?.currentData?.images
            })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                try {
                    const { currentImages, image } = this.state;
                    const newImages = [];
                    image.map(item => {
                        newImages.push({
                            id: Math.random(),
                            src: item
                        })
                    })
                    values.date = values.date ? values.date.format("YYYY-MM-DD") : null;
                    if (currentImages) {
                        values.images = [...currentImages, ...newImages];
                    } else {
                        values.images = [...newImages];
                    }

                    if (this.props.currentData) {
                        await this.props.updateData(this.props.currentData.id, values);
                        this.handCancel()
                        this.props.reloadData()
                    } else {
                        await this.props.createData(values);
                        this.handCancel()
                        this.props.reloadData()
                    }
                } catch (err) {
                    this.handCancel();
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        // this.props.reloadData()
        this.setState({ isloading: false, image: [], isResetUpload: true })
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        NotificationManager.success("Image complete.");
    }

    deleteCurrentImages = (id) => {
        const items = [...this.state.currentImages];
        this.setState({ currentImages: items.filter(item => item.id !== id) });
    }

    render() {
        const { isloading, isResetUpload, currentImages } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;
        const currentImagesList = [];
        //console.log("currentImages: ", currentImages)
        if (currentImages && Array.isArray(currentImages)) {
            currentImages.map(item => {
                currentImagesList.push(
                    <div className="list-img-thumbnail">
                        <Image
                            height={50}
                            src={item?.src}
                        />
                        <DeleteOutlined
                            title="Xóa"
                            className="btn-delete-img"
                            onClick={() => this.deleteCurrentImages(item?.id)}
                        />
                    </div>
                )
            })
        }

        return (
            <Modal
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.submit(e)}
                onCancel={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={[
                    <Button key="back" onClick={() => this.handCancel()}>
                        Huỷ
                    </Button>,
                    <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                        {currentData ? "Cập nhật" : "Tạo"}
                    </Button>,
                ]}
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item>
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Field is required.'
                                            }
                                        ],
                                        initialValue: currentData ? currentData.type : ""
                                    })(
                                        <Radio.Group>
                                            <div>
                                                <Divider orientation="left" style={{ fontWeight: "normal" }} orientationMargin="0">
                                                    Các khoản thu
                                                </Divider>
                                                <Radio className='mb-2' value='tien_von'>Vốn kinh doanh</Radio>
                                                <Radio value='khoan_di_vay'>Khoản đi vay</Radio>
                                                <Radio value='thu_hoi_khoan_cho_vay'>Thu hồi khoản cho vay</Radio>
                                                <Radio value='loi_nhuan_ban_hang'>Lợi nhuận bán hàng</Radio>
                                                <Radio value='khoan_thu_khac'>Khoản thu khác</Radio>
                                            </div>
                                            <div>
                                                <Divider orientation="left" style={{ fontWeight: "normal" }} orientationMargin="0">
                                                    Các khoản chi
                                                </Divider>
                                                <Radio className='mb-2' value='tra_no_khoan_vay'>Trả nợ khoản vay</Radio>
                                                <Radio value='cho_vay'>Cho vay</Radio>
                                                <Radio value='chi_phi_van_hanh'>Chi phí vận hành</Radio>
                                                <Radio value='chi_phi_san_xuat'>Chi phí sản xuất</Radio>
                                                <Radio value='chi_phi_quay_dau'>Chi phí quay đầu</Radio>
                                                <Radio value='chi_phi_khac'>Chi phí khác</Radio>
                                                <Radio value='chia_co_tuc'>Chia cổ tức</Radio>
                                            </div>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày thu/chi">
                                    {getFieldDecorator('date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? (moment(currentData.date).isValid() ? moment(currentData.date) : null) : null
                                    })(
                                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Số tiền">
                                    {getFieldDecorator('money', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.money : ""
                                    })(
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            style={{ width: '100%' }}
                                            step={50000}
                                            min={0}
                                            defaultText=""
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Ghi chú">
                            {getFieldDecorator('note', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: currentData ? currentData.note : ''
                            })(
                                <Input.TextArea rows={3} />
                            )}
                        </Form.Item>
                        <Form.Item label="Hình ảnh (tối đa 30 ảnh)">
                            <Image.PreviewGroup>
                                <Space wrap>
                                    {currentImagesList}
                                </Space>
                            </Image.PreviewGroup>
                            <div className='mb-2'>&nbsp;</div>
                            {getFieldDecorator('images', {
                                rules: [
                                    {
                                        required: false,
                                        message: "Vui lòng chọn!",
                                    },
                                ],
                                initialValue: null
                            })(
                                <UploadImage
                                    multiple={true}
                                    maxCount={30}
                                    onChangeData={this.onChange}
                                    isResetUpload={isResetUpload}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createData: (data) => dispatch(createData(data)),
        updateData: (id, data) => dispatch(updateData(id, data))
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'RevenueExpenditureForm' })(RevenueExpenditureForm));

import api from '../../utils/api';
import {
    ADD_DEVICE_TOKEN,
    GET_LIST_NOTIFICATIONS,
    GET_COUNT_NOTIFICATIONS,
    UPDATE_IS_READ_NOTIFICATION
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListNotifications = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/notifications', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_NOTIFICATIONS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCountNotifications = () => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/notifications/count/unread').then(res => {
            dispatch({ type: GET_COUNT_NOTIFICATIONS, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateIsRead = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/notifications/update-is-read/${id}`).then(res => {
            dispatch({ type: UPDATE_IS_READ_NOTIFICATION, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const addDeviceToken = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/notifications/add-device-token', data).then(res => {
            dispatch({ type: ADD_DEVICE_TOKEN, payload: res.data.data });
            //NotificationManager.success("Thêm mới token thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
import api from '../../utils/api';
import {
    GOOGLE_INDEXING_GET_LIST_LINK,
    GOOGLE_INDEXING_IMPORT_LINK,
    GOOGLE_INDEXING_REMOVE_LINK,
    GOOGLE_INDEXING_POST_TASK
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListLink = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/google-indexing', { params: filter }).then(res => {
            dispatch({ type: GOOGLE_INDEXING_GET_LIST_LINK, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const importLink = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/google-indexing', data).then(res => {
            //console.log("res: ", res)
            dispatch({ type: GOOGLE_INDEXING_IMPORT_LINK, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const postTask = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/google-indexing/post-task', data).then(res => {
            //console.log("res: ", res)
            dispatch({ type: GOOGLE_INDEXING_POST_TASK, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const postTaskInProductList = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/google-indexing/post-task/product-list', data).then(res => {
            //console.log("res: ", res)
            dispatch({ type: GOOGLE_INDEXING_POST_TASK, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeLink = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/google-indexing`, { data: { ids: ids } }).then(res => {
            dispatch({ type: GOOGLE_INDEXING_REMOVE_LINK, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
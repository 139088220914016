import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Table, Modal, Tag, DatePicker, Tooltip, Row, Card, Image, Descriptions, Button, Divider, Radio, Col, Space } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import { Form } from '@ant-design/compatible';
import _ from 'lodash';
import qs from 'qs';
import NumberFormat from 'react-number-format';
import RevenueExpenditureForm from './RevenueExpenditureForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, downloadFromLink } from '../../utils/helpers';
import TextTruncate from 'react-text-truncate';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
import appConfig from '../../config';
import { isMobile } from 'react-device-detect';
// actions
import { getAllData, getData, removeData, getStatistical, exportRevenueExpenditure, manyUpdate } from '../../redux/actions/RevenueExpenditureActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/revenue-expenditure',
        breadcrumbName: 'Cân đối tài chính',
    },
]

class RevenueExpenditureList extends Component {
    state = {
        isLoading: false,
        isLoading2: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
        isLoadingExport: false,
        visible: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true, isLoading2: true });
        this.props.getStatistical(query).then(() => {
            this.setState({
                isLoading2: false
            })
        })
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true, isLoading2: true });
            this.props.getAllData(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
            this.props.getStatistical(query).then(() => {
                this.setState({
                    isLoading2: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: '/revenue-expenditure',
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: '/revenue-expenditure',
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, data = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: data });
    }

    onEdit(id) {
        this.props.getData(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeData(this.state.selectedRowKeys).then(() => {
            this.reloadData()
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true, isLoading2: true });
        this.props.getStatistical(query).then(() => {
            this.setState({
                isLoading2: false
            })
        })
        this.props.getAllData(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    do_export() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportRevenueExpenditure(query).then((res) => {
            //console.log("res: ", res)
            setTimeout(() => {
                const response = {
                    file: `${appConfig.MEDIA_URL}/${res.path}`,
                };
                downloadFromLink(response.file, "_can_doi_tai_chinh.xlsx");
            }, 100);
            this.setState({ isLoadingExport: false });
        }).catch(err => {
            this.setState({ isLoadingExport: false });
        });

    }

    onCancel() {
        this.setState({ visible: false })
    }

    async onManyUpdate(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.props.manyUpdate(this.state.selectedRowKeys, values.type).then(() => {
                    this.setState({
                        selectedRowKeys: []
                    });
                    this.reloadData();
                    this.onCancel();
                })
            }
        });
    }

    render() {
        var { data_list, pagination, users, statistical_revenue_expenditure } = this.props;
        var { isLoading, isLoading2, selectedRowKeys, isOpenForm, currentData, isLoadingExport, visible } = this.state;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px'
            },
            {
                title: '#',
                align: 'center',
                width: '50px',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('revenue_expenditure', 'update') ? (
                                <EditOutlined className='primary-color item-action-btn' onClick={() => this.onEdit(record.id)} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Loại',
                dataIndex: 'type',
                key: 'type',
                width: '100px',
                render: (text, record) => {
                    let type = <Tag>{text}</Tag>;
                    if (record.type == 'tien_von') {
                        type = <Tag color='#1890ff'>Vốn kinh doanh</Tag>
                    } else if (record.type == 'loi_nhuan_ban_hang') {
                        type = <Tag color='#0564ff'>Lợi nhuận bán hàng</Tag>
                    } else if (record.type == 'chi_phi_van_hanh') {
                        type = <Tag color='#00abe2'>Chi phí vận hành</Tag>
                    } else if (record.type == 'chi_phi_san_xuat') {
                        type = <Tag color='#FFB302'>Chi phí sản xuất</Tag>
                    } else if (record.type == 'khoan_di_vay') {
                        type = <Tag color='#779ffd'>Khoản đi vay</Tag>
                    } else if (record.type == 'cho_vay') {
                        type = <Tag color='#b65051'>Cho vay</Tag>
                    } else if (record.type == 'thu_hoi_khoan_cho_vay') {
                        type = <Tag color='#47a90f'>Thu hồi khoản cho vay</Tag>
                    } else if (record.type == 'tra_no_khoan_vay') {
                        type = <Tag color='#FF3838'>Trả nợ khoản vay</Tag>
                    } else if (record.type == 'chi_phi_khac') {
                        type = <Tag color='#7B8089'>Chi phí khác</Tag>
                    } else if (record.type == 'khoan_thu_khac') {
                        type = <Tag color='#55782a'>Khoản thu khác</Tag>
                    } else if (record.type == 'chi_phi_quay_dau') {
                        type = <Tag color='#5c7b0b'>Chi phí quay đầu</Tag>
                    } else if (record.type == 'chia_co_tuc') {
                        type = <Tag color='#1053c2'>Chia cổ tức</Tag>
                    }
                    return (
                        <>
                            <div>{type}</div>
                        </>
                    )
                }
            },
            {
                title: 'Ngày thu/chi',
                dataIndex: 'date',
                key: 'date',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Số tiền',
                dataIndex: 'money',
                key: 'money',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix='đ' thousandSeparator={true} />
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text) => (
                    <div style={{ wordBreak: "break-word" }}>
                        <Tooltip title={text}>
                            <TextTruncate line={1} truncateText="…" text={text} />
                        </Tooltip>
                    </div>
                )
            },
            {
                title: 'Người tạo',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '120px',
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Hình ảnh',
                dataIndex: 'images',
                key: 'images',
                width: '120px',
                render: (text, record) => {
                    if (Array.isArray(record.images) && record.images.length) {
                        return (
                            <div>
                                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                    <Image.PreviewGroup>
                                        <Space size='small'>
                                            {
                                                record.images.map(item => {
                                                    return (
                                                        <Image
                                                            height={25}
                                                            width={25}
                                                            src={item}
                                                        />
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                        )
                    }

                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Cân đối tài chính" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('revenue_expenditure', 'create')}
                        isShowDeleteButton={checkPermission('revenue_expenditure', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={parseInt(query.created_user_id) || query.start_date || query.end_date || query.type}
                        filters={[
                            <BaseSelect
                                options={users}
                                optionValue="user_id"
                                optionLabel="full_name"
                                placeholder="Người tạo"
                                showSearch={false}
                                style={{ width: '150px' }}
                                defaultValue={parseInt(query.created_user_id) || null}
                                onChange={(value) => this.onChangeFilter("created_user_id", value)}
                            />,
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '150px' }}
                                placeholder="Ngày bắt đầu"
                                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '150px' }}
                                placeholder="Ngày kết thúc"
                                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />,
                            <BaseSelect
                                options={[
                                    { value: 'tien_von', label: 'Vốn kinh doanh' },
                                    { value: 'khoan_di_vay', label: 'Khoản đi vay' },
                                    { value: 'thu_hoi_khoan_cho_vay', label: 'Thu hồi khoản cho vay' },
                                    { value: 'loi_nhuan_ban_hang', label: 'Lợi nhuận bán hàng' },
                                    { value: 'khoan_thu_khac', label: 'Khoản thu khác' },

                                    { value: 'tra_no_khoan_vay', label: 'Trả nợ khoản vay' },
                                    { value: 'cho_vay', label: 'Cho vay' },
                                    { value: 'chi_phi_van_hanh', label: 'Chi phí vận hành' },
                                    { value: 'chi_phi_san_xuat', label: 'Chi phí sản xuất' },
                                    { value: 'chi_phi_quay_dau', label: 'Chi phí quay đầu' },
                                    { value: 'chi_phi_khac', label: 'Chi phí khác' },
                                    { value: 'chia_co_tuc', label: 'Chia cổ tức' }
                                ]}
                                onChange={(value) => this.onChangeFilter("type", value)}
                                placeholder="Loại"
                                defaultValue={query.type || null}
                                style={{ width: '170px' }}
                            />,
                        ]}
                    >
                        <Button className="table-button" loading={isLoadingExport} type='default' onClick={() => this.do_export()}>Export</Button>
                        <Divider type="vertical" />
                        {/* <Button className="table-button" disabled={!selectedRowKeys.length ? true : false} type='primary' onClick={() => this.setState({ visible: true })}>Cập nhật</Button> */}
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                    <Row gutter={16} className="mb-2 statistical-revenue_expenditure" justify="space-between">
                        <Col xs={24} md={8} className='mb-2'>
                            <Card loading={isLoading2} title="Khoản thu" bordered={false} size='small' bodyStyle={{ padding: 0 }} style={{ height: '100%' }}>
                                <Descriptions
                                    column={1}
                                    size="small"
                                    bordered
                                >
                                    <Descriptions.Item span={2} label="Vốn kinh doanh">
                                        <NumberFormat value={statistical_revenue_expenditure?.tien_von} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Khoản đi vay">
                                        <NumberFormat value={statistical_revenue_expenditure?.khoan_di_vay} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Thu hồi khoản cho vay">
                                        <NumberFormat value={statistical_revenue_expenditure?.thu_hoi_khoan_cho_vay} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Lợi nhận bán hàng">
                                        <NumberFormat value={statistical_revenue_expenditure?.loi_nhuan_ban_hang} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Khoản thu khác">
                                        <NumberFormat value={statistical_revenue_expenditure?.khoan_thu_khac} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Nợ phải thu">
                                        <NumberFormat value={statistical_revenue_expenditure?.cho_vay - statistical_revenue_expenditure?.thu_hoi_khoan_cho_vay} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                        <Col xs={24} md={8} className='mb-2'>
                            <Card loading={isLoading2} title="Khoản chi" bordered={false} size='small' bodyStyle={{ padding: 0 }} style={{ height: '100%' }}>
                                <Descriptions
                                    column={1}
                                    size="small"
                                    bordered
                                >
                                    <Descriptions.Item span={1} label="Chi phí quay đầu">
                                        <NumberFormat value={statistical_revenue_expenditure?.chi_phi_quay_dau} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Trả nợ khoản vay">
                                        <NumberFormat value={statistical_revenue_expenditure?.tra_no_khoan_vay} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Cho vay">
                                        <NumberFormat value={statistical_revenue_expenditure?.cho_vay} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Chi phí vận hành">
                                        <NumberFormat value={statistical_revenue_expenditure?.chi_phi_van_hanh} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Chi phí sản xuất">
                                        <NumberFormat value={statistical_revenue_expenditure?.chi_phi_san_xuat} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Chi phí khác">
                                        <NumberFormat value={statistical_revenue_expenditure?.chi_phi_khac} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                        <Col xs={24} md={8} className='mb-2'>
                            <Card loading={isLoading2} title="Lợi nhuận" bordered={false} size='small' bodyStyle={{ padding: 0 }} style={{ height: '100%' }}>
                                <Descriptions
                                    column={1}
                                    size="small"
                                    bordered
                                >
                                    <Descriptions.Item span={2} label="Lợi nhuận gộp">
                                        <NumberFormat value={statistical_revenue_expenditure?.loi_nhuan_gop} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Lợi nhuận thuần">
                                        <NumberFormat value={statistical_revenue_expenditure?.loi_nhuan_thuan} displayType={'text'} suffix='đ' thousandSeparator={true} />
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Lợi nhuận ròng">
                                        <strong><NumberFormat value={statistical_revenue_expenditure?.loi_nhuan_rong} displayType={'text'} suffix='đ' thousandSeparator={true} style={{ color: statistical_revenue_expenditure?.loi_nhuan_rong > 0 ? 'green' : 'red' }} /></strong>
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Chia cổ tức">
                                        <strong><NumberFormat value={statistical_revenue_expenditure?.chia_co_tuc} displayType={'text'} suffix='đ' thousandSeparator={true} style={{ color: statistical_revenue_expenditure?.chia_co_tuc > 0 ? 'green' : 'red' }} /></strong>
                                    </Descriptions.Item>
                                    <Descriptions.Item span={2} label="Số tiền tồn">
                                        <strong><NumberFormat value={statistical_revenue_expenditure?.tien_mat_hien_tai} displayType={'text'} suffix='đ' thousandSeparator={true} style={{ color: statistical_revenue_expenditure?.tien_mat_hien_tai > 0 ? 'green' : 'red' }} /></strong>
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1} label="Lợi nhuận thực tế">
                                        <strong><NumberFormat value={statistical_revenue_expenditure?.loi_nhuan_thuc_te} displayType={'text'} suffix='đ' thousandSeparator={true} style={{ color: statistical_revenue_expenditure?.loi_nhuan_thuc_te > 0 ? 'green' : 'red' }} /></strong>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={data_list}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <RevenueExpenditureForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />

                <Modal
                    visible={visible}
                    title="Sửa bản ghi"
                    onOk={(e) => this.onManyUpdate(e)}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '60%'}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Huỷ
                        </Button>,
                        <Button key="submit" type="primary" onClick={(e) => this.onManyUpdate(e)}>
                            Cập nhật
                        </Button>,
                    ]}
                >
                    {visible ?
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item>
                                        {getFieldDecorator('type', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Field is required.'
                                                }
                                            ],
                                            initialValue: currentData ? currentData.type : ""
                                        })(
                                            <Radio.Group>
                                                <div>
                                                    <Divider orientation="left" style={{ fontWeight: "normal" }} orientationMargin="0">
                                                        Các khoản thu
                                                    </Divider>
                                                    <Radio value='tien_von'>Vốn kinh doanh</Radio>
                                                    <Radio value='khoan_di_vay'>Khoản đi vay</Radio>
                                                    <Radio value='thu_hoi_khoan_cho_vay'>Thu hồi khoản cho vay</Radio>
                                                    <Radio value='loi_nhuan_ban_hang'>Lợi nhuận bán hàng</Radio>
                                                    <Radio value='khoan_thu_khac'>Khoản thu khác</Radio>
                                                </div>
                                                <div>
                                                    <Divider orientation="left" style={{ fontWeight: "normal" }} orientationMargin="0">
                                                        Các khoản chi
                                                    </Divider>
                                                    <Radio value='tra_no_khoan_vay'>Trả nợ khoản vay</Radio>
                                                    <Radio value='cho_vay'>Cho vay</Radio>
                                                    <Radio value='chi_phi_van_hanh'>Chi phí vận hành</Radio>
                                                    <Radio value='chi_phi_san_xuat'>Chi phí sản xuất</Radio>
                                                    <Radio value='chi_phi_quay_dau'>Chi phí quay đầu</Radio>
                                                    <Radio value='chi_phi_khac'>Chi phí khác</Radio>
                                                    <Radio value='chia_co_tuc'>Chia cổ tức</Radio>
                                                </div>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        : null}
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        data_list: state.RevenueExpenditure.data_list,
        pagination: state.RevenueExpenditure.pagination,
        users: state.config.users,
        statistical_revenue_expenditure: state.RevenueExpenditure.statistical_revenue_expenditure
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllData: (filter) => dispatch(getAllData(filter)),
        getData: (id) => dispatch(getData(id)),
        removeData: (ids) => dispatch(removeData(ids)),
        getStatistical: (filter) => dispatch(getStatistical(filter)),
        exportRevenueExpenditure: (filter) => dispatch(exportRevenueExpenditure(filter)),
        manyUpdate: (ids, type) => dispatch(manyUpdate(ids, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RevenueExpenditureForm' })(RevenueExpenditureList));

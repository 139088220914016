import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { Table, Modal, DatePicker, Button, Divider, Row } from 'antd';
import { connect } from 'react-redux';
import { Form } from "@ant-design/compatible";
import qs from 'qs';
import moment from 'moment';
import _, { } from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';

// actions
import { getListLogMailTracking, removeLogMailTracking } from '../../redux/actions/LogMailTrackingActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/log-mail-tracking',
        breadcrumbName: 'Danh sách log',
    }
]

class LogMailTrackingList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: []
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListLogMailTracking({ ...query }).then((res) => {
            this.setState({
                isLoading: false
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListLogMailTracking({ ...query }).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removeLogMailTracking(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            this.reloadData()
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenAddForm: false
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListLogMailTracking({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        var { selectedRowKeys, isLoading } = this.state;
        const { list_log_mail_tracking, pagination, stores } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: true,
                width: '50px'
            },
            {
                title: 'Store',
                dataIndex: 'store',
                key: 'store',
                width: '200px'
            },
            {
                title: 'Order Id',
                dataIndex: 'order_id',
                key: 'order_id',
                width: '80px',
                align: 'center'
            },
            {
                title: 'Customer',
                dataIndex: 'customer_name',
                key: 'customer_name',
                width: '80px'
            },
            {
                title: 'Customer email',
                dataIndex: 'customer_email',
                key: 'customer_email',
                width: '100px'
            },
            {
                title: 'Tracking_number',
                dataIndex: 'tracking_number',
                key: 'tracking_number',
                width: '100px'
            },
            {
                title: 'Mail result',
                dataIndex: 'mail_result',
                key: 'mail_result',
                width: '100px'
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: '100px'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '100px'
            },
            {
                title: 'Exception',
                dataIndex: 'exception',
                key: 'exception',
                width: '300px'
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '130px',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        </>
                    )
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={[
                    { value: 'success', label: 'Success' },
                    { value: 'error', label: 'Error' }
                ]}
                placeholder="Status"
                showSearch={true}
                style={{ width: '150px' }}
                defaultValue={parseInt(query.status) || null}
                onChange={(value) => this.onChangeFilter("status", value)}
            />,
            <BaseSelect
                options={[
                    { value: 'shipment_created', label: 'Created' },
                    { value: 'shipment_delivered', label: 'Delivered' }
                ]}
                onChange={(value) => this.onChangeFilter("action", value)}
                showSearch
                placeholder="Action"
                defaultValue={parseInt(query.action) || null}
                style={{ width: '150px' }}
            />,

            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Từ ngày"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Đến ngày"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách log" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('log_mail_tracking', 'remove')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.setState({ isOpenAddForm: true })}
                        showSearch={true}
                        showFilter={true}
                        activeFilter={
                            query.created_user_id ||
                            query.response_code ||
                            query.start_date ||
                            query.end_date ||
                            query.indexing ||
                            query.store_id || null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <BaseSelect
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            defaultValue={parseInt(query.store_id) || null}
                            optionLabel="name"
                            showSearch={true}
                            placeholder="Chọn site"
                            style={{ width: '170px' }}
                            className="table-button"
                        />
                        <Divider type='vertical' />
                        <Button className="table-button" onClick={() => this.reloadData()} loading={isLoading}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={list_log_mail_tracking || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        list_log_mail_tracking: state.LogMailTrackingReducer.list_log_mail_tracking,
        pagination: state.LogMailTrackingReducer.pagination,
        stores: state.StoreReducer.store_active_list,
        users: state.config.users,
        authUser: state.auth.authUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListLogMailTracking: filter => dispatch(getListLogMailTracking(filter)),
        removeLogMailTracking: ids => dispatch(removeLogMailTracking(ids))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "LogMailTrackingList" })(LogMailTrackingList));

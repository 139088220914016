import api from '../../utils/api';
import {
    LIST_PRODUCTS,
    GET_ONE_PRODUCTS,
    DELETE_PRODUCTS,
    CREATED_PRODUCTS,
    UPDATE_STATTUS_PRODUCTS,
    GET_LIST_FINISHED_DESIGN,
    PRODUCT_GET_DESIGN,
    UPDATE_PRODUCT,
    UPDATE_PRICE_PRODUCTS,
    GET_PRODUCT_HISTORIES,
    PRODUCT_GET_HISTORIES,
    DELETE_PRODUCTS_HISTORIES,
    DELETE_PRODUCT_BY_PERMALINK
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllProducts = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/products', { params: filter }).then(res => {
            dispatch({
                type: LIST_PRODUCTS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deletetProductByPermalink = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products/list/by-post-name', data).then(res => {
            dispatch({ type: DELETE_PRODUCT_BY_PERMALINK, payload: res.data.data });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getOneProduct = (id, params) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/products/${id}`, { params: params }).then(res => {
            dispatch({
                type: GET_ONE_PRODUCTS,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deleteProduct = (store_id, ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/products', { data: { store_id: store_id, ids: ids } }).then(res => {
            dispatch({
                type: DELETE_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createdProduct = data => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put('/products', data).then(res => {
            dispatch({
                type: CREATED_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateStatus = (store_id, ids, status) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products/update-status', { store_id: store_id, ids: ids, status: status }).then(res => {
            dispatch({
                type: UPDATE_STATTUS_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updatePrice = (store_id, ids, price) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products/update-price', { store_id: store_id, ids: ids, price: price }).then(res => {
            dispatch({
                type: UPDATE_PRICE_PRODUCTS,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}


export const updateProduct = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/products/update/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_PRODUCT,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getListFinished = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/products/finished-design', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_FINISHED_DESIGN,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getProductHistories = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/products/product/histories', { params: filter }).then(res => {
            dispatch({
                type: GET_PRODUCT_HISTORIES,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getDesign = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products/get-design', { ids: ids }).then(res => {
            dispatch({
                type: PRODUCT_GET_DESIGN,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getHistories = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/products/product/get-histories', { ids: ids }).then(res => {
            dispatch({
                type: PRODUCT_GET_HISTORIES,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const deleteHistories = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/products/delete/histories', { data: { ids: ids } }).then(res => {
            dispatch({
                type: DELETE_PRODUCTS_HISTORIES,
                payload: res.data.data
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
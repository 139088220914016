import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { GoogleOutlined } from '@ant-design/icons';
import { Table, Modal, DatePicker, Tag, Tooltip, Button, Divider, Row, Col, Drawer, Input, Typography } from 'antd';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { Form } from "@ant-design/compatible";
import qs from 'qs';
import moment from 'moment';
import _, { } from 'lodash';
import { isMobile } from 'react-device-detect';
import { checkPermission } from '../../utils/permission';
import { cleanObject, response_code } from '../../utils/helpers';
import BaseSelect from '../../components/Elements/BaseSelect';
import { NotificationManager } from 'react-notifications';

// actions
import { getListLink, removeLink, importLink, postTask } from '../../redux/actions/GoogleIndexingActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/indexing-link',
        breadcrumbName: 'Danh sách link',
    }
]
const { confirm } = Modal;
const { Paragraph } = Typography;

class GoogleIndexingList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            isloading_import: false,
            isLoadingSubmitIndex: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListLink({ ...query }).then((res) => {
            this.setState({
                isLoading: false
            })
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListLink({ ...query }).then(() => {
                this.setState({ isLoading: false });
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove() {
        this.props.removeLink(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: [],
            })
            setTimeout(() => {
                Modal.destroyAll();
            }, 800)
            //this.reloadData()
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            isOpenAddForm: false
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({
            isLoading: true
        });
        this.props.getListLink({ ...query }).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onImportLink(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading_import: true });
                try {
                    await this.props.importLink(values);
                    this.reloadData()
                    this.onClose();
                } catch (error) {
                    this.setState({ isloading_import: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isOpenAddForm: false,
            isLoadingSubmitIndex: false,
            selectedRowKeys: [],
            isloading_import: false,
            isLoading: false
        })
        this.props.form.resetFields();
    }

    submitIndex() {
        var query = qs.parse(this.props.location.search.slice(1));
        var store_id = query.store_id;
        if (store_id) {
            confirm({
                title: 'Xác nhận',
                content: 'Bạn chắc chắn muốn submit các url này?',
                okText: 'OK',
                cancelText: 'Huỷ',
                onOk: () => {
                    this.props.postTask({ ids: this.state.selectedRowKeys, store_id: store_id });
                    this.onClose();
                    NotificationManager.success("Gửi links thành công! Refresh trang để xem kết quả")
                },
                onCancel() { },
            })
        } else {
            NotificationManager.error("Vui lòng chọn site trước khi thực hiện")
        }
    }

    render() {
        var {
            selectedRowKeys,
            isLoading,
            isOpenAddForm,
            isloading_import,
            isLoadingSubmitIndex
        } = this.state;
        const { list_links, pagination, stores, users, authUser } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));
        var hasSelected = selectedRowKeys.length > 0;
        const { getFieldDecorator } = this.props.form;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        var disableTask = true;
        if (selectedRowKeys.length) {
            disableTask = false;
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: true,
                width: '50px'
            },
            {
                title: 'Link',
                dataIndex: 'link',
                key: 'link',
                width: '350px',
                render: (text, record) => {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            <Tooltip title={text}>
                                <TextTruncate text={text} line={1} truncateText="..." />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: '',
                dataIndex: 'link',
                key: 'link',
                width: '40px',
                align: 'center',
                render: (text, record) => {
                    return (
                        <Paragraph style={{ marginBottom: 0 }} copyable={{ tooltips: ['Copy url'], text: text }}></Paragraph>
                    )
                }
            },
            {
                title: 'Code',
                dataIndex: 'response_code',
                key: 'response_code',
                align: 'center',
                sorter: true,
                width: '80px',
                render: (text, record) => {
                    if (text) {
                        var color = "red"
                        if (text == '200') {
                            color = "green";
                        }
                        return (
                            <>
                                <Tag color={color}>{text}</Tag>
                            </>
                        )
                    }
                }
            },
            {
                title: 'Indexing',
                dataIndex: 'indexing',
                key: 'indexing',
                align: 'center',
                sorter: true,
                width: '80px',
                render: (text, record) => {
                    var indexing = 'Chưa gửi'
                    var color = "#FEBE8C"
                    if (text == 1) {
                        indexing = 'Đã gửi'
                        color = "#689752";
                    }
                    return (
                        <>
                            <Tag color={color}>{indexing}</Tag>
                        </>
                    )
                }
            },
            {
                title: 'Site',
                dataIndex: 'store_name',
                key: 'store_name',
                sorter: true,
                width: '100px',
                render: (text, record) => {
                    return (
                        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                            <Tooltip title={record.store_name}>
                                <a href={`https://${record.store_name}`} target='_blank'>
                                    <TextTruncate text={record.store_name} line={1} truncateText="" />
                                </a>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'user_created',
                key: 'user_created',
                sorter: true,
                width: '130px',
                render: (text, record) => {
                    return (
                        <>
                            {record.user_created}
                        </>
                    )
                }
            }, {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '130px',
                render: (text, record) => {
                    return (
                        <>
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        </>
                    )
                }
            },
            {
                title: 'Submit lần cuối',
                dataIndex: 'last_submit',
                key: 'last_submit',
                sorter: true,
                width: '130px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <>
                                <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                            </>
                        )
                    }
                }
            }
        ];

        var filters = [
            <BaseSelect
                options={[
                    { value: 1, label: 'Đã gửi' },
                    { value: 0, label: 'Chưa gửi' }
                ]}
                placeholder="Indexing"
                showSearch={true}
                style={{ width: '150px' }}
                defaultValue={parseInt(query.indexing) || null}
                onChange={(value) => this.onChangeFilter("indexing", value)}
            />,
            <BaseSelect
                options={response_code}
                onChange={(value) => this.onChangeFilter("response_code", value)}
                showSearch
                placeholder="Response code"
                defaultValue={parseInt(query.response_code) || null}
                style={{ width: '150px' }}
            />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Last submit to"
                onChange={(value) => this.onChangeFilter("start_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.start_date ? moment(query.start_date, 'YYYY-MM-DD') : null} />,
            <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '150px' }}
                placeholder="Last submit"
                onChange={(value) => this.onChangeFilter("end_date", value ? moment(value).format('YYYY-MM-DD') : null)}
                defaultValue={query.end_date ? moment(query.end_date, 'YYYY-MM-DD') : null} />
        ];
        if (authUser.role_code == 'ADMIN') {
            filters.push(
                <BaseSelect
                    options={users}
                    optionValue="user_id"
                    optionLabel="full_name"
                    placeholder="Người tạo"
                    showSearch={true}
                    style={{ width: '150px' }}
                    defaultValue={parseInt(query.created_user_id) || null}
                    onChange={(value) => this.onChangeFilter("created_user_id", value)}
                />
            )
        }

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách link" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('google_indexing', 'import_link')}
                        isShowDeleteButton={checkPermission('google_indexing', 'remove_link')}
                        disabled={!selectedRowKeys.length ? true : false}
                        onDelete={() => this.onRemove()}
                        onAdd={() => this.setState({ isOpenAddForm: true })}
                        showSearch={true}
                        showFilter={true}
                        addText="Import links"
                        activeFilter={
                            query.created_user_id ||
                            query.response_code ||
                            query.start_date ||
                            query.end_date ||
                            query.indexing ||
                            query.store_id || null}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        filters={filters}
                    >
                        <Button className="table-button" disabled={disableTask} type='primary' onClick={() => this.submitIndex()} loading={isLoadingSubmitIndex} icon={<GoogleOutlined />}>Submit index</Button>
                        <Divider type='vertical' />
                        <BaseSelect
                            options={stores || []}
                            onChange={(value) => this.onChangeFilter("store_id", value)}
                            optionValue="id"
                            defaultValue={parseInt(query.store_id) || null}
                            optionLabel="name"
                            showSearch={true}
                            placeholder="Chọn site"
                            style={{ width: '170px' }}
                            className="table-button"
                        />
                        <Divider type='vertical' />
                        <Button className="table-button" onClick={() => this.reloadData()} loading={isLoading}>Refresh</Button>
                    </TableActionBar>
                    <Row className="mt-2 mb-2" align="middle">
                        {
                            hasSelected ? <span style={{ marginLeft: '10px' }}>Đang chọn {selectedRowKeys.length} bản ghi </span> : ''
                        }
                    </Row>
                </div>
                <Table
                    rowKey="id"
                    size='small'
                    tableLayout='auto'
                    columns={columns}
                    loading={isLoading}
                    dataSource={list_links.items || []}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <Drawer
                    visible={isOpenAddForm}
                    title="Import link"
                    width={isMobile ? '100%' : '50%'}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Divider type='vertical' />
                            <Button
                                loading={isloading_import}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.onImportLink(e)}
                            >
                                Import
                            </Button>
                        </div>
                    }
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Site">
                                    {getFieldDecorator('store_id', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={stores || []}
                                            optionValue="id"
                                            showSearch={true}
                                            optionLabel="name"
                                            placeholder="Chọn site"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Paste links">
                                    {getFieldDecorator('list_links', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng nhập!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <Input.TextArea rows={10} placeholder="Mỗi link trên 1 dòng theo mẫu sau:
                                        http://example-1.com/link-1/
                                        http://example-2.com/link-2/
                                        http://example-3.com/link-3/" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        list_links: state.GoogleIndexingReducer.list_links,
        pagination: state.GoogleIndexingReducer.pagination,
        stores: state.StoreReducer.store_active_list,
        users: state.config.users,
        authUser: state.auth.authUser,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListLink: filter => dispatch(getListLink(filter)),
        removeLink: ids => dispatch(removeLink(ids)),
        importLink: (data) => dispatch(importLink(data)),
        postTask: (data) => dispatch(postTask(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "GoogleIndexingList" })(GoogleIndexingList));

import {
    GOOGLE_INDEXING_GET_LIST_LINK,
    GOOGLE_INDEXING_REMOVE_LINK
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    list_links: {
        store_id: null,
        items: []
    },
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 40
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GOOGLE_INDEXING_GET_LIST_LINK: {
            return {
                ...state,
                list_links: {
                    store_id: action.payload.store_id,
                    items: action.payload.data.data
                },
                pagination: {
                    currentPage: parseInt(action.payload.data.current_page),
                    total: parseInt(action.payload.data.total),
                    perPage: parseInt(action.payload.data.per_page),
                }
            }
        }
        case GOOGLE_INDEXING_REMOVE_LINK: {
            let temp = state.list_links.items.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            //console.log("temp: ", temp)
            return { ...state, list_links: { items: temp } }
        }
        default: return { ...state };
    }
}

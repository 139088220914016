import {
    PRODUCTS_IMPORT_QUEUE,
    GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE,
    SET_THUMBNAIL,
    DELETE_QUEUE_IMAGE,
    REMOVE_PRODUCTS_QUEUE,
    UPDATE_SPECIFIC_RECORD,
    UPDATE_PRODUCTS_IMPORT_QUEUE
} from '../actions/types';

const INIT_STATE = {
    items: {
        store_id: null,
        store_url: null,
        data: []
    },
    currentItem: null,
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 20
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRODUCTS_IMPORT_QUEUE:
            return {
                ...state,
                items: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    data: action.payload.data.data
                },
                pagination: {
                    currentPage: parseInt(action.payload.data.current_page),
                    total: parseInt(action.payload.data.total),
                    perPage: parseInt(action.payload.data.per_page)
                }
            };
        case GET_SPECIFIC_PRODUCTS_IMPORT_QUEUE: {
            return { ...state, currentItem: action.payload }
        }

        case REMOVE_PRODUCTS_QUEUE: {
            let temp = state.items.data.filter(item => {
                return action.payload.indexOf(item.id) < 0; // get only item not in removed ids
            });
            return {
                ...state,
                items: {
                    store_id: null,
                    store_url: null,
                    data: temp
                },
                pagination: {
                    currentPage: state.pagination.currentPage,
                    total: state.pagination.total - action.payload.length,
                    perPage: state.pagination.perPage
                }
            }
        }

        case SET_THUMBNAIL:
        case DELETE_QUEUE_IMAGE:
        case UPDATE_SPECIFIC_RECORD: {
            let index = state.items.data.findIndex((item) => {
                return item.id == action.payload.data.id;
            });
            let temp = [...state.items.data];
            temp[index] = action.payload.data;
            return {
                ...state,
                items: {
                    store_id: action.payload.store_id,
                    store_url: action.payload.store_url,
                    data: temp
                },
            }
        }

        default:
            return { ...state }
    }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, Drawer, Divider } from "antd";
import { } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import CKEditorComponent from '../../components/Editor/CKEditorComponent'

// actions
import { updatePosts, createNewPost } from "../../redux/actions/PostsAction";

class PostsForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            editorData: ''
        };
    }

    submit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    if (this.props.currentData) {
                        this.props.updatePosts(this.props.currentData.id, values);
                    } else {
                        this.props.createNewPost(values);
                    }
                    console.log(values)
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleEditorChange = (data) => {
        //console.log(data)
        this.setState({ editorData: data });
    };

    render() {
        const { visible, currentData } = this.props;
        const { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        //console.log(currentData)

        return (
            <Drawer
                visible={visible}
                title={currentData ? `Cập nhật bài viết` : 'Thêm bài viết mới'}
                width={isMobile ? '100%' : '60%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type='vertical' />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tiêu đề">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData?.title : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nội dung">
                                    {getFieldDecorator('edited_content', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData?.edited_content || null
                                    })(
                                        <CKEditorComponent
                                            onChange={this.handleEditorChange}
                                            initialHeight="300px"
                                            editorData={currentData?.edited_content}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData?.note ?? null
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null
                }
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_id: state.auth.authUser.id,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePosts: (id, data) => dispatch(updatePosts(id, data)),
        createNewPost: (data) => dispatch(createNewPost(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "PostsForm" })(PostsForm));

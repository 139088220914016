import {
    GET_ALL_WORK_REPORT,
    GET_SPECIFIC_WORK_REPORT,
    UPDATE_SPECIFIC_WORK_REPORT,
    CREATE_NEW_WORK_REPORT,
    REMOVE_WORK_REPORT
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    data_list: [],
    currentData: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_WORK_REPORT: {
            return {
                ...state,
                data_list: action.payload.result.data,
                pagination: {
                    currentPage: parseInt(action.payload.result.current_page),
                    total: parseInt(action.payload.result.total),
                    perPage: parseInt(action.payload.result.per_page),
                }
            }
        }
        case GET_SPECIFIC_WORK_REPORT: {
            return { ...state, currentData: action.payload }
        }
        case CREATE_NEW_WORK_REPORT: {
            return { ...state, data_list: [action.payload, ...state.data_list], total: state.total + 1 }
        }
        // case UPDATE_SPECIFIC_WORK_REPORT: {
        //     let index = state.data_list.findIndex((data) => {
        //         return data.id == action.payload.id;
        //     });
        //     let temp = [...state.data_list];
        //     temp[index] = action.payload;
        //     return { ...state, data_list: temp }
        // }
        case REMOVE_WORK_REPORT: {
            let temp = state.data_list.filter(data => {
                return action.payload.indexOf(data.id) < 0;
            });
            return { ...state, data_list: temp }
        }
        default: return { ...state };
    }
}

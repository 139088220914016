import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Input, Col, Row, List, Image, Drawer, Divider, Space } from "antd";
import { } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import { isMobile } from 'react-device-detect';
import DraggerFile from './DraggerFile';

// actions
import { getListSimilar, createIdea } from "../../redux/actions/IdeaAction";
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';
import { getNicheByActive } from '../../redux/actions/NicheActions';

class IdeaAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            activeTab: 'spy',
            isResetUpload: false,
            image: [],
            ideaQuery: '',
            loadingSimilarIdea: false,
            visibleSimilarIdea: false,
            select: false,
            files: []
        };
    }

    componentDidMount() {
        this.props.getProductTypeByActive();
        this.props.getNicheByActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.attach_file;
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    values.files = this.state.files;
                    //console.log(values)
                    await this.props.createIdea(values);
                    this.props.reloadData();
                    this.onClose();

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            image: [],
            visibleSimilarIdea: false,
            files: [],
            select: !this.state.select
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }
    onChangeTab(activeTab) {
        this.setState({ activeTab })
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            image: data
        });
        const { image } = this.state;
        this.props.form.setFieldsValue({
            images_demo: image
        });
        NotificationManager.success("Image complete.");
    }

    onSearchIdea(e) {
        var val = e.target.value;
        this.setState({ loadingSimilarIdea: true })
        this.setState({ ideaQuery: val }, () => {
            this.props.getListSimilar({ keyword: val }).then(res => {
                const { ideas } = this.props;
                if (typeof ideas != 'undefined' && ideas.length) {
                    this.setState({ visibleSimilarIdea: true })
                } else {
                    this.setState({ visibleSimilarIdea: false })
                }
                this.setState({ loadingSimilarIdea: false })
            });
        })
    }

    onChangeFiles = (files) => {
        this.setState({
            ...this.state,
            files: files,
        });
    }

    render() {
        const { visible, producttype, niche, ideas } = this.props;
        const { isLoading, isResetUpload, loadingSimilarIdea, visibleSimilarIdea } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Thêm idea mới"
                    width={isMobile ? '100%' : '50%'}
                    placement="right"
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Divider type="vertical" />
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Lưu
                            </Button>
                        </div>
                    }
                >
                    {visible ? (
                        <Form layout='vertical'>
                            <div>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Tên sản phẩm">
                                            {getFieldDecorator("name", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: null
                                            })(<Input
                                                allowClear
                                                onChange={(e) => this.onSearchIdea(e)}
                                            />)}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Loại sản phẩm">
                                            {getFieldDecorator("product_type_id", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <BaseSelect
                                                    options={producttype}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    placeholder="Chọn loại sản phẩm"
                                                    showSearch
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Niche">
                                            {getFieldDecorator("niche_id", {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <BaseSelect
                                                    options={niche}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    placeholder="Chọn Niche"
                                                    showSearch
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="File đính kèm">
                                            {getFieldDecorator('attach_file', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: ''
                                            })(
                                                <DraggerFile onChangeData={this.onChangeFiles} select={this.state.select} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Mô tả yêu cầu thiết kế">
                                            {getFieldDecorator('request_des_for_design', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: ''
                                            })(
                                                <Input.TextArea rows={4} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Ghi chú">
                                            {getFieldDecorator('note', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng điền!",
                                                    },
                                                ],
                                                initialValue: ''
                                            })(
                                                <Input.TextArea rows={4} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Ảnh sản phẩm mẫu (tối đa 4 ảnh)">
                                            {getFieldDecorator('images_demo', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Vui lòng chọn!",
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    multiple={true}
                                                    maxCount={4}
                                                    onChangeData={this.onChange}
                                                    isResetUpload={isResetUpload}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    ) : null
                    }
                </Drawer>
                <Drawer
                    visible={visibleSimilarIdea}
                    title="Giống idea"
                    width={isMobile ? '100%' : '35%'}
                    placement="left"
                    onClose={() => this.setState({ visibleSimilarIdea: false })}
                    maskClosable={false}
                    mask={false}
                    size="small"
                    contentWrapperStyle={{ boxShadow: 'none', borderRight: "1px solid #dedede" }}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.setState({ visibleSimilarIdea: false })}>
                                Đóng
                            </Button>
                            <Divider type="vertical" />
                        </div>
                    }
                >
                    {
                        visibleSimilarIdea ? (
                            <>
                                {
                                    ideas && ideas.length ? (
                                        <List
                                            itemLayout="horizontal"
                                            size="small"
                                            loading={loadingSimilarIdea}
                                            dataSource={ideas || []}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={
                                                            Array.isArray(item.images_demo) ? (
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            item.images_demo.map(img => {
                                                                                return (
                                                                                    <Image
                                                                                        height={30}
                                                                                        src={img}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            ) : null
                                                        }
                                                        title={<>{item.name}</>}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : null
                                }
                            </>
                        ) : null
                    }
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = state => {
    //console.log(state.config.amazonS3)
    return {
        user_id: state.auth.authUser.id,
        producttype: state.ProductType.producttype,
        niche: state.NicheReducer.niche,
        ideas: state.IdeaReducer.ideaSimilar
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createIdea: (data) => dispatch(createIdea(data)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
        getNicheByActive: (filter) => dispatch(getNicheByActive(filter)),
        getListSimilar: (filter) => dispatch(getListSimilar((filter)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "IdeaAddForm" })(IdeaAddForm));

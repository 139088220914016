import api from '../../utils/api';
import {
    GET_ALL_HOLIDAY,
    GET_SPECIFIC_HOLIDAY,
    UPDATE_SPECIFIC_HOLIDAY,
    CREATE_NEW_HOLIDAY,
    REMOVE_HOLIDAY
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAllHoliday = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/holiday', { params: filter }).then(res => {
            dispatch({ type: GET_ALL_HOLIDAY, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createHoliday = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/holiday', data).then(res => {
            dispatch({ type: CREATE_NEW_HOLIDAY, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getHoliday = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/holiday/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_HOLIDAY, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateHoliday = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/holiday/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_HOLIDAY, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeHoliday = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/holiday`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_HOLIDAY, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, Drawer, Space, Tag } from 'antd';
import { _newLine } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications'
import { isMobile } from 'react-device-detect';
// actions
import { updateOrderManagement } from '../../redux/actions/OrdersAction';
import BaseSelect from '../../components/Elements/BaseSelect';

class OrderCostForm extends Component {
    constructor() {
        super();
        this.state = {
            isloading: false,
            copied: false
        };
    }

    componentDidUpdate() {
        if (this.state.copied) {
            NotificationManager.success('Copied')
            this.setState({ copied: false })
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateOrderManagement(this.props.currentData.id, values);
                    this.props.reloadData()
                    this.onCancel();
                }
                this.setState({ isloading: false })
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, users, authUser } = this.props;
        const { getFieldDecorator } = this.props.form;

        if (currentData) {
            var status = '';
            if (currentData?.status == 'NewOrder') {
                status = <Tag color='#dc8665'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'Designing') {
                status = <Tag color='#138086'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'FulFillment') {
                status = <Tag color='#534666'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'Shipped') {
                status = <Tag color='#cd7672'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'Delivered') {
                status = <Tag color='green'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'DeliveryFail') {
                status = <Tag color='#f00'>{currentData?.status}</Tag>
            } else if (currentData?.status == 'Refund') {
                status = <Tag color='yellow'>{currentData?.status}</Tag>
            }
        }

        var disabled_fulfi_user = true;
        if (authUser.role_code == 'ADMIN') {
            disabled_fulfi_user = false;
        }

        return (
            <Drawer
                visible={visible}
                title="CHI PHÍ ĐƠN HÀNG"
                onClose={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '80%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onCancel()}>
                                Huỷ
                            </Button>
                            <Button loading={isloading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <div>
                        <Form layout='vertical'>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Cost (Phí fulfi + chuyển đổi)">
                                        {getFieldDecorator('fulfillment_amount', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentData ? currentData.fulfillment_amount : null
                                        })(
                                            <Input suffix="$" placeholder='Phí fulfi + chuyển đổi' />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Phí thiết kế file in">
                                                {getFieldDecorator('png_design_amount', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentData ? currentData.png_design_amount : null
                                                })(
                                                    <Input suffix="$" />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Phí thiết kế mockup">
                                                {getFieldDecorator('mockup_design_amount', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentData ? currentData.mockup_design_amount : null
                                                })(
                                                    <Input suffix="$" />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Đơn vị in">
                                                {getFieldDecorator('printing_unit', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentData ? currentData.printing_unit : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Mã in">
                                                {getFieldDecorator('print_code', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentData ? currentData.print_code : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Status">
                                                {getFieldDecorator('status', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Field is required.'
                                                        }
                                                    ],
                                                    initialValue: currentData ? currentData.status : 'NewOrder'
                                                })(
                                                    <BaseSelect
                                                        options={[
                                                            { value: 'NewOrder', label: 'New Order' },
                                                            { value: 'Designing', label: 'Designing' },
                                                            { value: 'FulFillment', label: 'FulFillment' },
                                                            { value: 'Shipped', label: 'Shipped' },
                                                            { value: 'Delivered', label: 'Delivered' },
                                                            { value: 'DeliveryFail', label: 'Delivery Fail' },
                                                            { value: 'Refund', label: 'Refund' }
                                                        ]}
                                                        defaultText="Status"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Form.Item label="Nhân viên Fulfill">
                                                {getFieldDecorator('fulfi_user_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Field is required.'
                                                        }
                                                    ],
                                                    initialValue: currentData ? currentData.fulfi_user_id : ''
                                                })(
                                                    <BaseSelect
                                                        options={users}
                                                        optionValue="user_id"
                                                        optionLabel="full_name"
                                                        defaultText="Chọn nhân viên"
                                                        showSearch={true}
                                                        style={{ width: '100%' }}
                                                        disabled={disabled_fulfi_user}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Phí PayPal">
                                        {getFieldDecorator('fee_payment', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Field is required.'
                                                }
                                            ],
                                            initialValue: currentData ? currentData.fee_payment : null
                                        })(
                                            <Input disabled suffix="$" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="TK nhận thanh toán">
                                        {getFieldDecorator('payment_receiving_account', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentData ? currentData.payment_receiving_account : null
                                        })(
                                            <Input disabled />
                                        )}
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} md={12}>
                                    <Form.Item label="Mã tracking">
                                        {getFieldDecorator('tracking_code', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentData ? currentData.tracking_code : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col> */}
                            </Row>
                            <Form.Item label="Ghi chú">
                                {getFieldDecorator('note', {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Vui lòng chọn!",
                                        },
                                    ],
                                    initialValue: currentData ? currentData.note : ''
                                })(
                                    <Input.TextArea rows={4} />
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    : null}
            </Drawer>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.config.users,
        authUser: state.auth.authUser
    }
}
function mapDispatchToProps(dispatch) {
    return {
        updateOrderManagement: (id, data) => dispatch(updateOrderManagement(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'OrderCostForm' })(OrderCostForm));

import {
    GET_ALL_PRODUCT_TYPE,
    GET_SPECIFIC_PRODUCT_TYPE,
    UPDATE_SPECIFIC_PRODUCT_TYPE,
    CREATE_NEW_PRODUCT_TYPE,
    REMOVE_PRODUCT_TYPE,
    GET_PRODUCT_TYPE_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    product_type: [],
    producttype: [],
    currentProductType: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_TYPE: {
            return {
                ...state,
                product_type: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_PRODUCT_TYPE_ACTIVE: {
            return {
                ...state,
                producttype: action.payload.data,
            }
        }
        case GET_SPECIFIC_PRODUCT_TYPE: {
            return { ...state, currentProductType: action.payload }
        }
        case CREATE_NEW_PRODUCT_TYPE: {
            return { ...state, product_type: [action.payload, ...state.product_type], total: state.total + 1 }
        }
        case UPDATE_SPECIFIC_PRODUCT_TYPE: {
            let index = state.product_type.findIndex((producttype) => {
                return producttype.id == action.payload.id;
            });

            let temp = [...state.product_type];
            temp[index] = action.payload;
            return { ...state, product_type: temp }
        }
        case REMOVE_PRODUCT_TYPE: {
            let temp = state.product_type.filter(producttype => {
                return action.payload.indexOf(producttype.id) < 0;
            });
            return { ...state, product_type: temp }
        }
        default: return { ...state };
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Row, Col, Drawer, Space } from 'antd';
import BaseSelect from '../../components/Elements/BaseSelect';
import { isMobile } from 'react-device-detect';
import Editor from "../../components/Editor";
// actions
import { createProductsDescriptionContent, updateProductsDescriptionContent } from '../../redux/actions/ProductsDescriptionContentActions';
import { getProductTypeByActive } from '../../redux/actions/ProductTypeActions';

class ProductsDescriptionContentForm extends Component {

    constructor() {
        super();
        this.state = {
            isloading: false
        };
    }

    componentDidMount() {
        this.props.getProductTypeByActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isloading: true })
                if (this.props.currentData) {
                    await this.props.updateProductsDescriptionContent(this.props.currentData.id, values);
                    this.handCancel()
                    this.props.reloadData()
                } else {
                    values.category_name = this.state.categoryName;
                    await this.props.createProductsDescriptionContent(values);
                    this.props.form.setFieldsValue({ content: '' })
                    this.setState({ isloading: false })
                    this.props.reloadData()
                }
            }
        });
    }

    handCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isloading: false, showCategory: true })
    }

    render() {
        const { isloading } = this.state;
        var { visible, currentData, producttype } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onClose={() => this.handCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.handCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={isloading}
                                onClick={(e) => this.submit(e)}
                            >
                                {currentData ? "Cập nhật" : "Lưu"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại nội dung">
                                    {getFieldDecorator('type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.type : null
                                    })(
                                        <BaseSelect
                                            style={{ width: '100%' }}
                                            options={[
                                                { label: "Mô tả sản phẩm", value: 'product_des' },
                                                { label: "Content trust site", value: 'trust_site' }
                                            ]}
                                            placeholder="Chọn loại nội dung"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Loại sản phẩm">
                                    {getFieldDecorator("product_type_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.product_type_id : null
                                    })(
                                        <BaseSelect
                                            options={producttype || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            placeholder="Chọn loại sản phẩm"
                                            showSearch
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Nội dung">
                            {getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Vui lòng nhập!",
                                    },
                                ],
                                initialValue: currentData ? currentData.content : null
                            })(
                                <Editor />
                            )}
                        </Form.Item>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

const mapStateToProps = state => {
    return {
        producttype: state.ProductType.producttype
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createProductsDescriptionContent: (data) => dispatch(createProductsDescriptionContent(data)),
        updateProductsDescriptionContent: (id, data) => dispatch(updateProductsDescriptionContent(id, data)),
        getProductTypeByActive: (filter) => dispatch(getProductTypeByActive(filter)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductsDescriptionContentForm' })(ProductsDescriptionContentForm));

import React from 'react';
import ShowMenuText from './ShowMenuText';

import {
    ApartmentOutlined,
    SettingOutlined,
    BgColorsOutlined,
    UserOutlined,
    DashboardOutlined,
    DollarOutlined,
    ShoppingCartOutlined,
    FolderOutlined,
    ShoppingOutlined,
    ReadOutlined,
    ProjectOutlined,
    GlobalOutlined,
    ToolOutlined,
    FormOutlined,
    BulbOutlined,
    FileDoneOutlined
} from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const menu = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardOutlined className="sidebar-icon" />,
        permission: null
    },
    {
        path: '#',
        name: 'Setting',
        icon: <SettingOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/store',
                name: 'Stores',
                permission: 'store'
            },
            {
                path: '/payment-gateways',
                name: 'Payment Gateway',
                permission: 'payment_gateways'
            },
            {
                path: '/blacklist',
                name: 'Blacklist',
                permission: 'blacklist'
            },
            {
                path: '/config',
                name: 'System Configuration',
                permission: 'config'
            },
            {
                path: '/chatgpt',
                name: 'API ChatGPT',
                permission: 'chatgpt'
            },
            {
                path: '/telegram',
                name: 'Telegram Configuration',
                permission: 'telegram'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Users & Permissions',
        icon: <ApartmentOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/users',
                name: 'Users',
                permission: 'user'
            },
            {
                path: '/permissions',
                name: 'Permissions',
                permission: 'permissions'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Task',
        icon: <FileDoneOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/workflow-management',
                name: 'Task Assignment',
                permission: 'workflow_management',
            },
            {
                path: '/work-report',
                name: 'Report',
                permission: 'work_report',
            }
        ]
    },
    {
        path: '#',
        name: 'Project management',
        icon: <ProjectOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/project',
                name: 'Project list',
                permission: 'project'
            },
            {
                path: '/project-based-job',
                name: 'Project based job',
                permission: 'project_based_job'
            }
        ]
    },
    {
        path: '#',
        name: 'Financial',
        icon: <DollarOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/revenue-expenditure',
                name: 'Income/Expense',
                permission: 'revenue_expenditure',
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Information',
        icon: <FolderOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/trending',
                name: <ShowMenuText mode="trending" />,
                permission: 'trending',
            },
            {
                path: '/holidays',
                name: 'Holiday Calendar',
                permission: 'holiday',
            },
            {
                path: '/niche',
                name: 'Niche',
                permission: 'niche',
            },
            {
                path: '/product-type',
                name: 'Product type',
                permission: 'product_type',
            },
            // {
            //     path: '/products-description',
            //     name: 'Product description',
            //     permission: 'products_description',
            // },
            // {
            //     path: '/product-tags',
            //     name: 'Product tags',
            //     permission: 'product_tags',
            // }
        ]
    },
    {
        path: '/ideas',
        name: 'Ideas',
        icon: <BulbOutlined className="sidebar-icon" />,
        permission: 'idea'
    },
    {
        path: '/design',
        name: 'Designs',
        icon: <BgColorsOutlined className="sidebar-icon" />,
        permission: 'design'
    },
    {
        path: '/orders-list?order_status=processing,completed',
        name: 'Orders',
        icon: <ShoppingCartOutlined className="sidebar-icon" />,
        permission: 'orders'
    },
    {
        path: '/products',
        name: 'Products List',
        icon: <ShoppingOutlined className="sidebar-icon" />,
        permission: 'products'
    },
    // {
    //     path: '#',
    //     name: 'Products',
    //     icon: <ShoppingOutlined className="sidebar-icon" />,
    //     permission: 'products',
    //     children: [
    //         {
    //             path: '/products',
    //             name: 'Product List',
    //             permission: 'products',
    //         },
    //         {
    //             path: '/products-import',
    //             name: 'Products import',
    //             permission: 'products_import',
    //         }
    //     ]
    // },
    {
        path: '/posts',
        name: 'Posts',
        icon: <FormOutlined className="sidebar-icon" />,
        permission: 'posts'
    },
    {
        path: '#',
        name: 'Fulfillment',
        icon: <GlobalOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/printing-unit',
                name: 'Supplier',
                permission: 'printing_unit',
            },
            {
                path: '/file-png',
                name: 'File PNG',
                permission: 'file_png',
            },
            {
                path: '/trademark-category',
                name: 'Trademark category',
                permission: 'trademark_category',
            }
        ]
    },
    {
        path: '#',
        name: 'Training & Guide',
        icon: <ReadOutlined className="sidebar-icon" />,
        permission: null,
        children: [

            {
                path: '/training',
                name: 'Training',
                permission: 'training',
            },
            {
                path: '/guide',
                name: 'Guide',
                permission: 'guide',
            }
        ]
    },
    {
        path: '#',
        name: 'Tools',
        icon: <ToolOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/google-indexing',
                name: 'Google Indexing',
                permission: 'google_indexing',
            },
            {
                path: '/tool-license',
                name: 'License Tool',
                permission: 'tool_license',
            },
            {
                path: '/trademark-keyword',
                name: 'Trademark Keyword',
                permission: 'trademark_keyword',
            },
            {
                path: '/product-cat-des',
                name: 'Categories description',
                permission: 'product_cat_des',
            },
            {
                path: '/log-mail-tracking',
                name: 'Log Email Tracking',
                permission: 'log_mail_tracking',
            },
            {
                path: '/log-order-shield',
                name: 'Log Order Shield',
                permission: 'log_order_shield',
            },
            {
                path: '/delete-object',
                name: 'Delete S3 file',
                permission: 'handle_s3_objects',
            }
        ]
    },
    {
        path: '/profile',
        name: 'Account Profile',
        icon: <UserOutlined className="sidebar-icon" />,
        permission: null,
    }
];

export default menu;
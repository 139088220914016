import api from '../../utils/api';
import {
    LIST_PRODUCTS_CATEGORIES,
    LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE
} from './types';
import { NotificationManager } from 'react-notifications'

export const getAllCategories = (filter, concatenate = false) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/categories', { params: filter }).then(res => {
            if (!concatenate) {
                dispatch({
                    type: LIST_PRODUCTS_CATEGORIES,
                    payload: res.data.data
                });
            } else {
                dispatch({
                    type: LIST_PRODUCTS_CATEGORIES_WITH_CONCATENATE,
                    payload: res.data.data
                });
            }
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}
/**
 * Helpers Functions
 */
import moment from 'moment';
import React, { Component } from 'react';
import { Tag, Row, Col, } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { defaultTo } from 'lodash';
import { decodeEntity, decode } from 'html-entities';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    return path[1];
}

/**
 * 
 * @param {int} x 
 * Thousand delimiter
 */
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

/**
 * 
 * @param {*function} callback 
 * @param {*number} delay 
 * @param  {...any} args 
 * 
 */
export function debounce(callback, delay, ...args) {
    const timerClear = () => clear = true;
    var clear = true;
    return event => {
        if (clear) {
            clear = false;
            setTimeout(timerClear, delay);
            callback(event, ...args);
        }
    }
}


export function capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getFirstLetterOfWords(str) {
    var acronym = '';
    let temp = str.split(" ");
    if (temp.length >= 2) acronym = temp[temp.length - 2].charAt(0) + temp[temp.length - 1].charAt(0);
    else acronym = str.charAt(0);
    // var matches = str.match(/\b(\w)/g);
    // if(!matches) return str.charAt(0);

    return acronym;
}

export function cleanObject(obj) {
    Object.keys(obj).map(key => {
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        }
    });

    return obj;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export async function getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}

/**
 * render function for breadcrumb
 * @param {*} route 
 * @param {*} params 
 * @param {*} routes 
 * @param {*} paths 
 */
export const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
}

/**
 * 
 * @param {*} url URL need to download
 * Downlaod without pop-up block warning
 */
export const downloadFromLink = (url) => {
    let link = document.createElement("a");
    //link.download = filename;
    link.href = url;
    link.target = '_blank';
    link.click();
    URL.revokeObjectURL(link.href);
}

/**
 * Format number thousand seperator
 * @param {*} num original number
 */
export function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function removeSign(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/[^0-9a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s]/gi, '');
    str = str.replace(/\s+/g, '');
    str.trim();
    return str;
}

export function convertToSlug(title) {
    var slug;
    //Đổi chữ hoa thành chữ thường , //Decode các ký tự đặc biệt html
    slug = decode(title.toLowerCase());
    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    slug = slug.replace(/\s/g, '');
    return slug;
}

// Use Object.sort(dynamicSort('key'))
export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function stripHtml(html) {
    if (typeof html != 'undefined' && html != null) {
        // let tmp = document.createElement("div");
        // tmp.innerHTML = html;
        // return tmp.textContent || tmp.innerText || "";
        return html.replace(/(<([^>]+)>)/gi, " ");
    } else {
        return html;
    }
}

export function _newLine(text) {
    if (typeof text != 'undefined' && text != null) {
        text = text.replace(/<br ?\/?>/g, "\n");
        text = text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '\n');
        //text = text.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '\n').replace(/\n\s*\n/g, '\n');
        return text;
    } else {
        return text;
    }
}

export const makeTree = (arr, parent) => {
    const branch = arr.filter(node => node.parent == parent);
    branch.forEach(node => {
        node.title = node.name
        node.value = parseInt(node.id)
        const children = makeTree(arr, node.id);
        if (children.length) {
            node.children = children;
        }
    });
    return branch;
};

export function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

export const response_code = [
    { value: 'Error', label: 'Error' },
    { value: 'Undefined', label: 'Undefined' },
    { value: '504', label: '504' },
    { value: '200', label: '200' },
    { value: '202', label: '202' },
    { value: '203', label: '203' },
    { value: '204', label: '204' },
    { value: '301', label: '301' },
    { value: '302', label: '302' },
    { value: '303', label: '303' },
    { value: '307', label: '307' },
    { value: '308', label: '308' },
    { value: '400', label: '400' },
    { value: '401', label: '401' },
    { value: '402', label: '402' },
    { value: '403', label: '403' },
    { value: '404', label: '404' },
    { value: '405', label: '405' },
    { value: '406', label: '406' },
    { value: '408', label: '408' },
    { value: '409', label: '409' },
    { value: '410', label: '410' },
    { value: '412', label: '412' },
    { value: '421', label: '421' },
    { value: '422', label: '422' },
    { value: '426', label: '426' },
    { value: '429', label: '429' },
    { value: '451', label: '451' },
    { value: '499', label: '499' },
    { value: '500', label: '500' },
    { value: '502', label: '502' },
    { value: '503', label: '503' },
    { value: '508', label: '508' },
    { value: '509', label: '509' },
    { value: '514', label: '514' },
    { value: '520', label: '520' },
    { value: '521', label: '521' },
    { value: '522', label: '522' },
    { value: '523', label: '523' },
    { value: '524', label: '524' },
    { value: '525', label: '525' },
    { value: '526', label: '526' },
    { value: '529', label: '529' },
    { value: '530', label: '530' },
    { value: '600', label: '600' },
    { value: '999', label: '999' }
];
export function setNullItem(input) {
    var value = input;
    if (input == undefined) {
        value = null;
    }
    return value;
}

export function isValidDateForm(dateField, show_default = true) {
    if (show_default) {
        return moment(dateField).isValid() ? moment(dateField) : moment()
    } else {
        return moment(dateField).isValid() ? moment(dateField) : null
    }

}

export function isValidDate(dateField, show_time = true) {
    if (!dateField) return "";
    if (show_time) {
        return moment(dateField).isValid() ? moment(dateField).format('DD/MM/YYYY HH:mm') : ""
    } else {
        return moment(dateField).isValid() ? moment(dateField).format('DD/MM/YYYY') : ""
    }
}

export function customDataImageUpload(data = [], oldData = []) {
    const arr = [];
    data.map(item => {
        arr.push({ id: Math.random(), src: item })
    })
    if (oldData.length) {
        return [...oldData, ...arr];
    } else {
        return [...arr];
    }

}

export function replaceHeadingsWithDivs(htmlContent) {
    // Create a temporary div element
    const tempDiv = document.createElement('div');

    // Set the innerHTML of the temporary div with the provided HTML content
    tempDiv.innerHTML = htmlContent;

    // Get all headings from h1 to h6 within the temporary div
    const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

    // Iterate through each heading and replace with div
    headings.forEach((heading) => {
        const div = document.createElement("div");
        div.innerHTML = heading.innerHTML;
        heading.parentNode.replaceChild(div, heading);
    });

    // Remove empty tags
    removeEmptyTags(tempDiv);

    // Optionally, log or return the modified HTML content
    const modifiedHtmlContent = tempDiv.innerHTML;
    //console.log(modifiedHtmlContent);

    // Clean up any resources (if needed)
    // Note: Since we're not using useEffect, cleanup can be done here directly

    // Return the modified HTML content
    return modifiedHtmlContent;
}

function removeEmptyTags(element) {
    // Get all child nodes of the element
    const childNodes = element.childNodes;

    // Iterate through each child node
    for (let i = childNodes.length - 1; i >= 0; i--) {
        const child = childNodes[i];

        // Check if the child node is an element
        if (child.nodeType === 1) {
            // Recursively remove empty tags in the child element
            removeEmptyTags(child);

            // If the element has no children and no text content, remove it
            if (!child.hasChildNodes() && !child.textContent.trim()) {
                element.removeChild(child);
            }
        }
    }
}

export const _priority = [
    { value: 'binhthuong', label: 'Bình thường', color: '#0000004f' },
    { value: 'uutien', label: 'Ưu tiên', color: '#ff0003' }
];

export function ConvertPriority(value) {
    if (!value) return "";
    const result = _priority.map(item => {
        if (item.value == value) {
            return (<Tag color={item.color}>{item.label}</Tag>);
        }
    })
    return result
}

export const _request_type = [
    { value: 'newdesign', label: "New design", color: '#009E60' },
    { value: 'redesign', label: "Redesign", color: '#00B7EB' },
    { value: 'clone', label: "Clone", color: '#1560BD' },
    { value: 'mockup', label: "Mockup", color: '#8B008B' }
];

export function ConvertRequestType(value) {
    if (!value) return "";
    const result = _request_type.map(item => {
        if (item.value == value) {
            return (<Tag color={item.color}>{item.label}</Tag>);
        }
    })
    return result
}